import { EmployeeSystemException } from "../../models/EmployeeSystemException";

export const EMPLOYEE_SYSTEM_EXCEPTIONS_FETCHED = "EMPLOYEE_SYSTEM_EXCEPTIONS_FETCHED";
export const EDIT_EMPLOYEE_SYSTEM_EXCEPTION = "EDIT_EMPLOYEE_SYSTEM_EXCEPTION";

export interface EmployeeSystemExceptionsFetchedAction {
    type: typeof EMPLOYEE_SYSTEM_EXCEPTIONS_FETCHED;
    employeeSystemExceptions: EmployeeSystemException[];
}

export interface EditEmployeeSystemExceptionAction {
    type: typeof EDIT_EMPLOYEE_SYSTEM_EXCEPTION;
    originalEmployeeSystemException: EmployeeSystemException;
    editedEmployeeSystemException: EmployeeSystemException;
}

export type EmployeeSystemExceptionActions = EmployeeSystemExceptionsFetchedAction | EditEmployeeSystemExceptionAction;
