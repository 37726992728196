import {
    EDIT_EMPLOYEE,
    EmployeeActions,
    EMPLOYEES_FETCHED,
    MERGE_EMPLOYEE,
} from "../../actions/employee/EmployeeAction";
import { EmployeeState } from "../../state/EmployeeState";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: EmployeeState = {
    employees: [],
};

export function employeeReducer(state: EmployeeState = initialState, action: EmployeeActions): EmployeeState {
    switch (action.type) {
        case EMPLOYEES_FETCHED: {
            return {
                ...state,
                employees: action.employees,
            };
        }
        case EDIT_EMPLOYEE:
            return {
                ...state,
                employees: replaceInCollection(state.employees, action.originalEmployee!, action.editedEmployee),
            };
        default:
            return state;
    }
}
