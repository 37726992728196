import { inject, injectable } from "inversify";
import { ICustomerReportDisplayService } from "./ICustomerReportDisplayService";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Store } from "redux";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ShowToastAction } from "../../actions/ToastActions";
import { showToast } from "../../actions/ToastActionCreators";
import { UPSERT_CUSTOMER_REPORT_DISPLAYS } from "../../actions/customerReportDisplay/CustomerReportDisplayAction";
import { CustomerReportDisplay } from "../../models/CustomerReportDisplay";

@injectable()
export class CustomerReportDisplayService implements ICustomerReportDisplayService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getCustomerReportDisplays(tenantId: number): Promise<void | CustomerReportDisplay[]> {
        return this.httpClient
            .get<CustomerReportDisplay[]>(`/customer-report-display/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customer report displays. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    upsertCustomerReportDisplays(customerReportDisplays: CustomerReportDisplay[]): Promise<any> {
        return this.httpClient
            .post<CustomerReportDisplay[]>("/customer-report-display", customerReportDisplays)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: UPSERT_CUSTOMER_REPORT_DISPLAYS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Display preferences (${t.length}) updated successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while updating a customer report display. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
