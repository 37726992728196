import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ISubReportCategoryService } from "./ISubReportCategoryService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { SubReportCategory } from "../../models/SubReportCategory";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_REPORT_SUCCESS, DEACTIVATE_REPORT_SUCCESS, EDIT_SUB_REPORT_CATEGORY_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import { createPatch } from "rfc6902";

@injectable()
export class SubReportCategoryService implements ISubReportCategoryService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createSubReportCategory(subReportCategory: SubReportCategory): Promise<void | SubReportCategory> {
        return this.httpClient
            .post<SubReportCategory>("/subreport-category", subReportCategory)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `SubReportCategory "${t.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a subReportCategory. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    deleteSubReportCategory(subReportCategory: SubReportCategory): Promise<void | SubReportCategory> {
        return this.httpClient
            .delete(`/subreport-category/${subReportCategory.id}`)
            .then(() => {
                const returnSubReportCategory = { ...subReportCategory };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `SubReportCategory "${subReportCategory.name}" deleted successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnSubReportCategory;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the subReportCategory. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getSubReportCategories(): Promise<void | SubReportCategory[]> {
        return this.httpClient
            .get<SubReportCategory[]>(`/subreport-category`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving subReportCategories. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getSubReportCategoryById(id: number): Promise<void | SubReportCategory> {
        return this.httpClient
            .get<SubReportCategory>(`/subreport-category/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the subReportCategory. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editSubReportCategory(subReportCategory: SubReportCategory, newSubReportCategory: SubReportCategory): Promise<void | SubReportCategory> {
        const operations = createPatch(subReportCategory, newSubReportCategory);
        return this.httpClient
            .patch<SubReportCategory>(`/subreport-category/${subReportCategory.id}`, operations)
            .then((patchedSubReportCategory) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_SUB_REPORT_CATEGORY_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedSubReportCategory.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedSubReportCategory;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing sub-report "${subReportCategory.name}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }
}
