import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ICustomerDocumentsStatisticsService } from "./ICustomerDocumentService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { CustomerDocument, CustomerDocumentStatistics } from "../../models/CustomerDocument";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_REPORT_SUCCESS, DEACTIVATE_REPORT_SUCCESS, EDIT_REPORT_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { AppState } from "../../state/AppState";
import { Token } from "../../models/Token";
import { IProgramReference } from '../../models/ProgramReference';

@injectable()
export class CustomerDocumentsStatisticsService implements ICustomerDocumentsStatisticsService {
    private token: Token = {
        access_token: "",
        refresh_token: "",
    };
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {
        this.store.subscribe(() => {
            const state = this.store.getState() as AppState;
            this.token.access_token = state.authenticationState.accessToken;
            this.token.refresh_token = state.authenticationState.refreshToken;
        });
    }

    getCustomerDocumentsStatistics(): Promise<void | CustomerDocumentStatistics[]> {
        return this.httpClient
            .get<CustomerDocumentStatistics[]>(`/customer-document/statistics`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customerDocuments. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
  
  
   

  


}

