import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerImports } from "../actions/customerImport/CustomerImportActionCreators";
import { getCustomerImportFileById } from "../actions/customerImportFile/CustomerImportFileActionCreators";
import {
    createCustomerReportReview,
    getCustomerReportReviews,
} from "../actions/customerReport/CustomerReportActionCreator";
import { getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { getReportAttributes } from "../actions/reportAttributes/ReportAttributeActionCreator";
import { getReports } from "../actions/reports/ReportActionCreators";
import { getSubReports } from "../actions/subReports/SubReportActionCreators";
import { AppState } from "../state/AppState";
import { CustomerReportReviewCreate, ICustomerReportReview } from "../models/CustomerReportReview";
import { getSubReportAttributes } from "../actions/subReportAttributes/SubReportAttributeActionCreator";
import { selectAttributesForReportList } from "../selectors/ReportAttributeSelectors";
import { useCustomerImportJson } from "./customerImportJsonHook";
import { CustomerReportFlag } from "../models/CustomerReport";
import { usePdfNotes } from "./notesPdfHook";
import { getCustomerReportAttributes } from "../actions/customerReportAttributes/CustomerReportAttributeActionCreator";

export const useCustomerReportPdfListForm = (customerReportId: number) => {
    const dispatch = useDispatch();

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const tenantId = decodedAccessToken.tenant_id;
    const user = `${decodedAccessToken.given_name} ${decodedAccessToken.family_name}`;
    const tenantName = decodedAccessToken.tenant_name;

    const customerReport = useSelector((state: AppState) =>
        state.customerReportState.customerReports.find((c) => c.id === customerReportId)
    );

    const finosecSystem = useSelector((state: AppState) =>
        state.finosecSystemState.finosecSystems.find((fs) => fs.id === customerReport?.finosecSystemId)
    );
    const customerImportFile = useSelector((state: AppState) =>
        state.customerImportFileState.customerImportFiles.find((cif) => cif.id === customerReport?.customerImportFileId)
    );

    const customerImport = useSelector((state: AppState) =>
        state.customerImportState.customerImports.find((ci) => ci.id === customerReport?.customerImportId)
    );

    const { notes, notesLoading } = usePdfNotes(customerImport?.id);

    const customerReportReviewState = useSelector((state: AppState) => state.customerReportState.customerReportReviews);

    const customerReportReviews = useMemo(
        () =>
            customerImportFile
                ? customerReportReviewState.filter((crr) => crr.customerImportFileId === customerImportFile.id)
                : [],
        [customerImportFile, customerReportReviewState]
    );

    const [pageSize, setPageSize] = useState<number>();

    useEffect(() => {
        dispatch(getCustomerReportAttributes(customerReportId));
    }, [dispatch, customerReportId]);

    const attributes = useSelector((state: AppState) =>
        selectAttributesForReportList(state, {
            reportId: customerReport?.reportId,
            subReportId: customerReport?.subReportId,
            customerReportId: customerReport?.id,
            isCurrent: customerImport?.isCurrent,
        })
    );

    const { tableData, headers, loading, setHasLoadedData } = useCustomerImportJson(
        customerImportFile?.id,
        attributes,
        customerReportReviews,
        customerReport?.subReportFilter,
        customerReport?.filter,
        customerImport?.isCurrent
    );

    /*useEffect(() => {
        if (decodedAccessToken) {
            dispatch(getCustomerImportFiles());
        }
    }, [decodedAccessToken]);*/

    useEffect(() => {
        if (decodedAccessToken.userId && customerImportFile) {
            dispatch(getFinosecSystems());
            dispatch(getCustomerImports());
            dispatch(getReports());
            dispatch(getSubReports());
            dispatch(getCustomerReportReviews(customerReportId, customerImportFile.id));
            dispatch(getCustomerReportAttributes(customerReportId));
        }
    }, [decodedAccessToken.userId, customerImportFile, customerReportId, dispatch]);

    useEffect(() => {
        if (decodedAccessToken.userId && customerReport?.customerImportFileId) {
            dispatch(getCustomerImportFileById(customerReport?.customerImportFileId));
        }
    }, [customerReport, decodedAccessToken.userId, dispatch]);

    useEffect(() => {
        if (customerReport?.isSubReport && customerReport.subReportId !== undefined) {
            dispatch(getSubReportAttributes(customerReport.subReportId));
        }

        if (customerReport?.reportId !== undefined) {
            dispatch(getReportAttributes(customerReport.reportId));
        }
    }, [customerReport, dispatch, decodedAccessToken.userId]);

    const updateReportReview = (
        customerReportReview: ICustomerReportReview | CustomerReportReviewCreate,
        customerReportFlag: CustomerReportFlag
    ) => {
        // The method handles add and or update
        dispatch(createCustomerReportReview(customerReportReview, customerReportFlag));

        /* Comment out if we need this back
        if (customerReportReview.id !== undefined && oldReview) {
            dispatch(editCustomerReportReview(oldReview, customerReportReview as ICustomerReportReview));
        } else {
            dispatch(createCustomerReportReview(customerReportReview));
        }*/
    };

    const hasProps = (item: object) => {
        return Object.keys(item).length > 0;
    };

    return {
        tenantId,
        user,
        updateReportReview,
        tableData: tableData.map((td: any) => ({
            ...td,
            customerReportReview: customerReportReviews.find((cr) => cr.lineNumber === td.Row) || {},
        })),
        headers,
        setPageSize,
        pageSize,
        attributes,
        customerReport,
        tenantName,
        finosecSystem,
        customerImport,
        notes,
        loading,
        notesLoading,
        customerImportFileId: customerImportFile?.id,
        setHasLoadedData,
    };
};
