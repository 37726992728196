import { CustomerReportDisplay } from "../../models/CustomerReportDisplay";

export const CUSTOMER_REPORT_DISPLAYS_FETCHED = "CUSTOMER_REPORT_DISPLAYS_FETCHED";
export const FETCHING_CUSTOMER_REPORT_DISPLAYS = "FETCHING_CUSTOMER_REPORT_DISPLAYS";
export const UPSERT_CUSTOMER_REPORT_DISPLAYS = "UPDATE_CUSTOMER_REPORT_DISPLAYS";

export interface CustomerReportDisplaysFetchedAction {
    type: typeof CUSTOMER_REPORT_DISPLAYS_FETCHED;
    customerReportDisplays: CustomerReportDisplay[];
}

export interface UpsertCustomerReportDisplaysAction {
    type: typeof UPSERT_CUSTOMER_REPORT_DISPLAYS;
    customerReportDisplays: CustomerReportDisplay[];
}

export interface FetchingCustomerReportDisplaysAction {
    type: typeof FETCHING_CUSTOMER_REPORT_DISPLAYS;
}

export type CustomerReportDisplayActions =
    | CustomerReportDisplaysFetchedAction
    | UpsertCustomerReportDisplaysAction
    | FetchingCustomerReportDisplaysAction;
