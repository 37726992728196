import { GETTING_TABS, GettingTabsAction, NEW_TABS, NewTabsAction } from "../actions/TabActions";
import { Store } from "redux";
import { Tab } from "../models/Tab";
import { AppState } from "../state/AppState";
import { IHttpClient } from "./HttpClient/IHttpClient";
import { inject, injectable } from "inversify";
import { TYPES } from "../dependencyInjection/Types";

@injectable()
export class TabService {
    constructor(
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.Store) private store: Store<AppState>
    ) {
        this.store.subscribe(() => {
            getTabs(this.store, httpClient);
        });
    }
}

export function getTabs(store: Store<AppState>, httpClient: IHttpClient): void {
    const state = store.getState();
    if (!state) {
        return;
    }
    if (state.tabState.fetchTabs) {
        store.dispatch<GettingTabsAction>({
            type: GETTING_TABS
        });
        httpClient.get<Tab[]>("/tabs").then(t => {
            store.dispatch<NewTabsAction>({
                tabs: t,
                type: NEW_TABS
            });
        });
    }
}
