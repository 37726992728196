import { Report } from "../../models/Report";

export const REPORTS_FETCHED = "REPORTS_FETCHED";
export const FETCH_REPORT = "FETCH_REPORT";
export const CREATE_REPORT = "CREATE_REPORT";
export const EDIT_REPORT = "EDIT_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";

export interface ReportsFetchedAction {
    type: typeof REPORTS_FETCHED;
    reports: Report[];
}

export interface FetchReportAction {
    type: typeof FETCH_REPORT;
    report: Report;
}


export interface CreateReportAction {
    type: typeof CREATE_REPORT;
    report: Report;
}

export interface EditReportAction{
    type: typeof EDIT_REPORT;
    orininalRport: Report;
    newReport:Report;
}

export interface DeleteReportAction {
    type: typeof DELETE_REPORT;
    report: Report;
}


export type ReportActions =
    | ReportsFetchedAction
    | FetchReportAction
    | CreateReportAction
    | EditReportAction
    | DeleteReportAction;
