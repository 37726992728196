import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import {
    ADD_WORKFLOW_JOB_SCHEDULE_SUCCESS,
    DELETE_WORKFLOW_JOB_SCHEDULE_SUCCESS,
    EDIT_WORKFLOW_JOB_SCHEDULE_SUCCESS,
} from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import { IWorkflowJobScheduleService } from "./IWorkflowJobScheduleService";
import { WorkflowJobSchedule } from "../../models/WorkflowJobSchedule";

@injectable()
export class WorkflowJobScheduleService implements IWorkflowJobScheduleService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createWorkflowJobSchedule(workflowJobSchedule: WorkflowJobSchedule): Promise<void | WorkflowJobSchedule> {
        return this.httpClient
            .post<WorkflowJobSchedule>("/workflow-job-schedule", workflowJobSchedule)
            .then((w) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_WORKFLOW_JOB_SCHEDULE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow Job Schedule "${w.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return w;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating workflow job schedule. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getWorkflowJobSchedules(): Promise<void | WorkflowJobSchedule[]> {
        return this.httpClient
            .get<WorkflowJobSchedule[]>("/workflow-job-schedule")
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow job schedules. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getWorkflowJobSchedulesByTenantId(tenantId: number): Promise<void | WorkflowJobSchedule[]> {
        return this.httpClient
            .get<WorkflowJobSchedule[]>(`/workflow-job-schedule/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow job schedules. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getWorkflowJobSchedulesByDate(date: string): Promise<void | WorkflowJobSchedule[]> {
        return this.httpClient
            .get<WorkflowJobSchedule[]>(`/workflow-job-schedule/date/${date}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow job schedules. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getWorkflowJobScheduleById(id: number): Promise<void | WorkflowJobSchedule> {
        return this.httpClient
            .get<WorkflowJobSchedule>(`/workflow-job-schedule/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow job schedules. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    editWorkflowJobSchedule(
        workflowJobSchedule: WorkflowJobSchedule,
        newWorkflowJobSchedule: WorkflowJobSchedule
    ): Promise<void | WorkflowJobSchedule> {
        const operations = createPatch(workflowJobSchedule, newWorkflowJobSchedule);
        return this.httpClient
            .patch<WorkflowJobSchedule>(`/workflow-job-schedule/${workflowJobSchedule.id}`, operations)
            .then((patchedSchedule) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_WORKFLOW_JOB_SCHEDULE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow Job Schedule "${patchedSchedule.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedSchedule;
            })
            .catch((exception) => {
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while updating workflow job schedule "${workflowJobSchedule.name}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    deleteWorkflowJobSchedule(workflowJobSchedule: WorkflowJobSchedule): Promise<void | WorkflowJobSchedule> {
        return this.httpClient
            .delete(`/workflow-job-schedule/${workflowJobSchedule.id}`)
            .then(() => {
                const toastProps: KeysysToastProps = {
                    name: DELETE_WORKFLOW_JOB_SCHEDULE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow Job Schedule "${workflowJobSchedule.name}" deactivated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return { ...workflowJobSchedule, isActive: false };
            })
            .catch((exception) => {
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while deactivating workflow job schedule "${workflowJobSchedule.name}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }
}
