import { TYPES } from "../../dependencyInjection/Types";
import { EmployeeSystem } from "../../models/EmployeeSystem";
import { IEmployeeSystemService } from "../../services/employeeSystem/IEmployeeSystemService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    CreateEmployeeSystemAction,
    CREATE_EMPLOYEE_SYSTEM,
    EditEmployeeSystemAction,
    EDIT_EMPLOYEE_SYSTEM,
} from "./EmployeeSystemAction";

export function createEmployeeSystem(
    employeeSystem: EmployeeSystem
): KeysysThunkAction<void, CreateEmployeeSystemAction> {
    return async (dispatch, getState, container) => {
        const employeeSystemService = container.get<IEmployeeSystemService>(TYPES.EmployeeSystemService);
        const createdEmployeeSystem = await employeeSystemService.createEmployeeSystem(employeeSystem);
        if (createdEmployeeSystem) {
            dispatch<CreateEmployeeSystemAction>({
                type: CREATE_EMPLOYEE_SYSTEM,
                employeeSystem: createdEmployeeSystem,
            });
        }
        return !!createEmployeeSystem;
    };
}

export function editEmployeeSystem(
    originalEmployeeSystem: EmployeeSystem,
    editedEmployeeSystem: EmployeeSystem
): KeysysThunkAction<void, EditEmployeeSystemAction> {
    return async (dispatch, getState, container) => {
        const employeeSystemService = container.get<IEmployeeSystemService>(TYPES.EmployeeSystemService);
        const patchedEmployeeSystem = await employeeSystemService.editEmployeeSystem(
            originalEmployeeSystem,
            editedEmployeeSystem
        );
        if (patchedEmployeeSystem) {
            dispatch<EditEmployeeSystemAction>({
                type: EDIT_EMPLOYEE_SYSTEM,
                originalEmployeeSystem: originalEmployeeSystem,
                editedEmployeeSystem: patchedEmployeeSystem,
            });
        }
    };
}
