import { SystemStatisticsData, FinosecSystemStatistics } from "../../models/FinosecSystemStatistics";

export const FINOSEC_SYSTEM_STATISTICS_DATA_FETCHED = "FINOSEC_SYSTEM_STATISTICS_DATA_FETCHED";
export const FINOSEC_SYSTEM_STATISTICS_PARTNER_DATA_FETCHED = "FINOSEC_SYSTEM_STATISTICS_PARTNER_DATA_FETCHED";

export interface FinosecSystemStatisticsDataFetchedAction {
    type: typeof FINOSEC_SYSTEM_STATISTICS_DATA_FETCHED;
    systemStatisticsData: SystemStatisticsData;
}

export interface FinosecSystemStatisticsPartnerDataFetchedAction {
    type: typeof FINOSEC_SYSTEM_STATISTICS_PARTNER_DATA_FETCHED;
    partnerStatisticsData: FinosecSystemStatistics[];
}

export type FinosecSystemStatisticAction =
    | FinosecSystemStatisticsDataFetchedAction
    | FinosecSystemStatisticsPartnerDataFetchedAction;
