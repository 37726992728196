import React from "react";
import SmallSquareRestoreButton from "../buttons/SmallSquareRestoreButton/SmallSquareRestoreButton";
import { Identifiable } from "../../models/Identifiable";

interface RestoreButtonRenderProps {
    data: Identifiable;
    entityName: string;
    showRestore: (id: number) => void;
    isActive?: boolean;
}

export default function RestoreButtonRender(props: RestoreButtonRenderProps) {
    return (
        <SmallSquareRestoreButton
            key={`delete-${props.data.id}`}
            entityNameSingular={props.entityName}
            onClick={() => props.showRestore(props.data.id)}
            isActive={props.isActive}
        />
    );
}
