import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { Row, Col } from "react-bootstrap";
import KeysysSelect from "../form/select/KeysysSelect";
import { useImportList } from "../../hooks/importListHook";
import MonthPicker from "../form/datetime/MonthPicker";
import { useDispatch } from "react-redux";
import {
    createWorkflowJobSchedule,
    editWorkflowJobSchedule,
} from "../../actions/workflowJobSchedule/WorkflowJobScheduleActionCreators";
import KeysysInput from "../form/input/KeysysInput";
import { useWorkflowJobSchedules } from "../../hooks/useWorkflowJobSchedules";

interface WorkflowJobScheduleFormModel {
    name: string;
    customerId: number;
    systemId: number;
    startDate: string;
    frequencyId: number;
}

const schema = yup.object<WorkflowJobScheduleFormModel>().shape({
    name: yup.string().required("Customer Schedule Name is required"),
    customerId: yup.number().min(1, "Customer is required").required("Customer is required"),
    systemId: yup.number().min(1, "System is required").required("System is required"),
    startDate: yup.string().required("Start Date is required"),
    frequencyId: yup.number().min(1, "Frequency is required").required("Frequency is required"),
});

interface WorkflowJobScheduleFormProps {
    onClose?: VoidFunction;
}

export const WorkflowJobScheduleForm = ({ onClose }: WorkflowJobScheduleFormProps) => {
    const dispatch = useDispatch();

    const {
        customerTenants,
        filterImports,
        partnerTenants,
        selectPartnerId,
        setSelectedCustomerOption,
        finosecSystems,
    } = useImportList();

    const { frequencies, findTenant, selectedSchedule } = useWorkflowJobSchedules();

    const [selectedPartner, setSelectedPartner] = useState<{ value: number; label: string }>({
        value: 0,
        label: "Select Partner",
    });

    useEffect(() => {
        if (selectedSchedule) {
            const partner = findTenant(findTenant(selectedSchedule.customerId)?.parentTenantId);
            if (!partner) return;
            setSelectedPartner({ value: partner.id, label: partner.name });
            selectPartnerId(partner.id);
        }
    }, []);

    const initialValues = useMemo(
        (): WorkflowJobScheduleFormModel => ({
            name: selectedSchedule?.name ?? "",
            customerId: selectedSchedule?.customerId ?? 0,
            systemId: selectedSchedule?.systemId ?? 0,
            startDate: selectedSchedule?.startDate ?? "",
            frequencyId: selectedSchedule?.frequencyId ?? 0,
        }),
        [selectedSchedule]
    );

    const clearFormAndHide = useCallback(
        (formProps: FormikProps<WorkflowJobScheduleFormModel> | FormikHelpers<WorkflowJobScheduleFormModel>) => {
            formProps.resetForm();
            if (onClose) {
                onClose();
            }
        },
        [onClose]
    );

    const handleSubmit = useCallback(
        (values: WorkflowJobScheduleFormModel, helpers: FormikHelpers<WorkflowJobScheduleFormModel>) => {
            if (!selectedSchedule) {
                dispatch(
                    createWorkflowJobSchedule({
                        ...values,
                        id: 0,
                        workflowId: 1,
                        nextDate: values.startDate,
                        isActive: true,
                    })
                );
            } else {
                dispatch(
                    editWorkflowJobSchedule(selectedSchedule, {
                        ...selectedSchedule,
                        ...values,
                    })
                );
            }
            clearFormAndHide(helpers);
        },
        [clearFormAndHide, selectedSchedule]
    );

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<WorkflowJobScheduleFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditWorkflowJobScheduleModal"
                            show={true}
                            title={!selectedSchedule ? "Create Customer Schedule" : "Edit Customer Schedule"}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={true}
                            showDeleteButton={false}
                            deleteButtonText={"Deactivate"}
                            showRestoreButton={false}
                            restoreButtonText={"Activate"}
                            onDeleteButtonClick={() => console.log("delete")}
                            onRestoreButtonClick={() => console.log("restore")}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="addEditWorkflowJobScheduleForm">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id="name"
                                            fieldName="name"
                                            label="Customer Schedule Name"
                                            placeholder="Name"
                                        />
                                    </Col>
                                </Row>
                                {!selectedSchedule && (
                                    <>
                                        <Row className={"mb-4"}>
                                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                <KeysysSelect
                                                    formProps={formProps}
                                                    id="partnerId"
                                                    placeholder="Select Partner"
                                                    fieldName="partnerId"
                                                    label="Partner"
                                                    options={partnerTenants.map((t) => ({
                                                        value: t.id,
                                                        label: t.name,
                                                    }))}
                                                    onChange={(partner: any) => {
                                                        selectPartnerId(partner?.value);
                                                        setSelectedPartner(partner);
                                                    }}
                                                    value={selectedPartner}
                                                    multiple={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                <KeysysSelect
                                                    formProps={formProps}
                                                    id="custerId"
                                                    placeholder="Select Customer"
                                                    fieldName="customerId"
                                                    label="Customer"
                                                    options={customerTenants.map((t) => ({
                                                        value: t.id,
                                                        label: t.name,
                                                    }))}
                                                    onChange={(change: any) => {
                                                        setSelectedCustomerOption({
                                                            value: change?.value,
                                                            label: change?.label,
                                                        });
                                                        filterImports();
                                                    }}
                                                    multiple={false}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id="systemId"
                                            placeholder="Select System"
                                            fieldName="systemId"
                                            label="System"
                                            disabled={!!selectedSchedule}
                                            options={finosecSystems.map((s) => ({ value: s.id, label: s.name }))}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <MonthPicker
                                            formProps={formProps}
                                            id="nextDate"
                                            placeholder="Next Run Month"
                                            fieldName="nextDate"
                                            label="Next Run Month"
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id="frequencyId"
                                            placeholder="Select Frequency"
                                            fieldName="frequencyId"
                                            label="Frequency"
                                            options={frequencies.map((f) => ({ value: f.id, label: f.name }))}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
            {/* <ConfirmationModal
                id="confirmDeleteUserModal"
                show={showConfirmDeleteModal}
                title="Deactivate User?"
                message={"Do you really want to deactivate this user?"}
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={() => handleDeleteUser(props.user!)}
                theme={"danger"}
            />
            <ConfirmationModal
                id="confirmRestoreUserModal"
                show={showConfirmRestoreModal}
                title="Activate User?"
                message={"Do you really want to activate this user?"}
                onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                onOkButtonClick={() => handleRestoreUser(props.user!)}
                theme={"danger"}
            /> */}
        </>
    );
};
