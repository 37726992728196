import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IAwsFileService } from "./IAwsFileService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { AwsFile } from "../../models/AwsFile";

@injectable()
export class AwsFileService implements IAwsFileService {
    constructor(
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}


    uploadImage(awsFile: AwsFile): Promise<void | AwsFile> {
        return this.httpClient
            .post<AwsFile>("/aws", awsFile)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while uploading. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

}
