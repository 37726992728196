import { EmployeeCoreId } from "../../models/EmployeeCoreId";

export const EMPLOYEE_CORE_IDS_FETCHED = "EMPLOYEE_CORE_IDS_FETCHED";
export const UPDATE_EMPLOYEE_CORE_IDS = "EMPLOYEE_CORE_IDS_FETCHED";

export interface EmployeeCoreIdsFetchedAction {
    type: typeof EMPLOYEE_CORE_IDS_FETCHED;
    employeeCoreIds: EmployeeCoreId[];
}

export interface UpdateEmployeeCoreIdsAction {
    type: typeof UPDATE_EMPLOYEE_CORE_IDS;
    employeeCoreIds: string[];
}

export type EmployeeCoreIdActions = EmployeeCoreIdsFetchedAction | UpdateEmployeeCoreIdsAction;
