/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { AppState } from "../../state/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUsers, deleteUser, editUser, restoreUser } from "../../actions/users/UserActionCreators";
import { Row, Col, Button } from "react-bootstrap";
import { User } from "../../models/User";
import { getRoles } from "../../actions/RoleActionCreators";
import { Role } from "../../models/Role";
import { UserTypes } from "../../models/UserTypes";
import { createSelector } from "reselect";
import { Identifiable } from "../../models/Identifiable";
import EditButtonRender from "../grid/EditButtonRender";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";
import { getTenants } from "../../actions/tenants/TenantActionCreators";
import DeleteButtonRender from "../grid/DeleteButtonRender";
import RestoreButtonRender from "../grid/RestoreButtonRender";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import UserForm from "./forms/UserForm";
import PageHeader from "../header/PageHeader";
import { impersonateUser } from "../../actions/AuthenticationActionCreators";

interface UserWithFullDetails extends Identifiable {
    fullName: string;
    email: string;
    tenant: string;
    partnerTenant: string;
    customerTenant: string;
    role: string;
    status: string;
}

export default function Users() {
    const dispatch = useDispatch();
    const [shouldShowInactiveUsers, setShouldshowInactiveUsers] = useState<boolean>(false);
    const [showUserFormModal, setShowUserFormModal] = useState<boolean>(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
    const [userFormType, setUserFormType] = useState<"add" | "edit">("add");

    var usersWithFullDetails = useSelector<AppState, UserWithFullDetails[]>(
        (state) => getUsersWithFullDetails(state, shouldShowInactiveUsers),
        shallowEqual
    );

    const impersonator = useSelector((state: AppState) => state.userState.impersonator);
    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);

    const usersRef = useSelectorAsRef((state: AppState) => state.userState.users);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getUsers());
        if (!usersRef || usersRef.current.length === 0) {
            dispatch(getTenants());
        }
    }, []);

    function showAddUserModal(): void {
        setSelectedUser(undefined);
        setUserFormType("add");
        setShowUserFormModal(true);
    }

    function handleDeleteUser(): void {
        if (selectedUser) {
            dispatch(deleteUser(selectedUser));
            setShowConfirmDeleteModal(false);
        }
    }

    function handleRestoreUser(): void {
        if (selectedUser) {
            dispatch(restoreUser(selectedUser));
            setShowConfirmRestoreModal(false);
        }
    }

    const showEditUserModal = useCallback(
        (params: User) => {
            const user = usersRef.current.find((user: User) => user.id === params.id)!;
            setSelectedUser(user);
            setUserFormType("edit");
            setShowUserFormModal(true);
        },
        [usersRef]
    );

    const showDeleteCallback = useCallback((params: number) => {
        const user = usersRef.current.find((user: User) => user.id === params)!;
        setSelectedUser(user);
        setShowConfirmDeleteModal(true);
    }, []);

    const showRestoreCallback = useCallback((params: number) => {
        const user = usersRef.current.find((user: User) => user.id === params)!;
        setSelectedUser(user);
        setShowConfirmRestoreModal(true);
    }, []);

    //TODO: Move to useUser hook or redux state
    const userType: UserTypes = useMemo((): UserTypes => {
        if (!decodedAccessToken.userId) {
            return UserTypes.Unauthenticated;
        }
        if (decodedAccessToken.roles === "GlobalAdmin") {
            return UserTypes.GlobalAdmin;
        }
        if (decodedAccessToken.roles === "PartnerAdmin") {
            return UserTypes.PartnerAdmin;
        }
        if (decodedAccessToken.roles === "CustomerAdmin") {
            return UserTypes.CustomerAdmin;
        }
        if (decodedAccessToken.roles === "User") {
            return UserTypes.User;
        }
        return UserTypes.Unauthenticated;
    }, [decodedAccessToken.userId, decodedAccessToken.roles]);

    const hasPartnerAdminAccess = [UserTypes.GlobalAdmin, UserTypes.PartnerAdmin].includes(userType);
    const hasCustomerAdminAccess = [UserTypes.GlobalAdmin, UserTypes.PartnerAdmin, UserTypes.CustomerAdmin].includes(
        userType
    );

    //End: Move to useUser hook or redux state

    const canImpersonateUser = useCallback(
        (userId: number): boolean => {
            const user = usersWithFullDetails.find((u) => u.id === userId);
            if (!!impersonator || !user || user.role === UserTypes.GlobalAdmin) return false;
            if (user.role === UserTypes.PartnerAdmin) {
                return userType === UserTypes.GlobalAdmin;
            }
            if (user.role === UserTypes.CustomerAdmin) {
                return hasPartnerAdminAccess;
            }
            if (user.role === UserTypes.User) {
                return hasCustomerAdminAccess;
            }
            return false;
        },
        [userType, usersWithFullDetails, hasPartnerAdminAccess, hasCustomerAdminAccess, impersonator]
    );

    const handleImpersonate = useCallback(
        async (userId: number) => {
            const user = usersRef.current.find((user: User) => user.id === userId);
            if (!user) return;
            // const success = await impersonateUser(user.emailAddress);
            dispatch(impersonateUser(user.emailAddress));
        },
        [impersonateUser, usersRef.current]
    );

    const columnDefs: ColDef[] = [
        {
            headerName: "Full Name",
            field: "fullName",
        },
        {
            headerName: "Email",
            field: "email",
        },
        {
            headerName: "Partner Tenant",
            field: "partnerTenant",
        },
        {
            headerName: "Customer Tenant",
            field: "customerTenant",
        },
        {
            headerName: "Role",
            field: "role",
        },
        {
            headerName: "Status",
            field: "status",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "User",
                showEdit: showEditUserModal,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit User",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: DeleteButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "User",
                    showDelete: showDeleteCallback,
                    isActive: data?.data?.status === "Active",
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Deactivate User",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: RestoreButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "User",
                    showRestore: showRestoreCallback,
                    isActive: data?.data?.status === "Active",
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Activate User",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: ({ data }: { data: any }) =>
                canImpersonateUser(data.id) ? (
                    <Button
                        size="sm"
                        variant="transparent"
                        title="Impersonate"
                        onClick={() => handleImpersonate(data.id)}
                    >
                        <i className="fas fa-user-secret" />
                    </Button>
                ) : (
                    <></>
                ),
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Impersonate User",
        },
    ];

    return (
        <>
            <PageHeader>
                <h2>User Management</h2>
            </PageHeader>
            <div>
                <Row className={"justify-content-end"}>
                    <Col lg={2}>
                        <i
                            className={!shouldShowInactiveUsers ? "far fa-circle" : "fas fa-check-circle"}
                            style={{
                                fontSize: "1.2rem",
                                color: !shouldShowInactiveUsers ? "gray" : "green",
                            }}
                            onClick={() => {
                                setShouldshowInactiveUsers(!shouldShowInactiveUsers);
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <label htmlFor="showInactiveUsersCheckbox">Show Inactive Users</label>
                    </Col>
                    <Col lg={1} className={"add-user-button-wrapper"}>
                        <Button
                            className={"add-user-button finosec-button-info"}
                            onClick={showAddUserModal}
                            data-testid={"add-user-button"}
                        >
                            Add User
                        </Button>
                    </Col>
                </Row>
                <Row className={"users-grid-container"}>
                    <Col className={"h-100 p-0"}>
                        <div className="ag-theme-alpine">
                            <KeysysGrid getRows={() => usersWithFullDetails} columnDefs={columnDefs} />
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                {showUserFormModal && (
                    <UserForm type={userFormType} user={selectedUser} onClose={() => setShowUserFormModal(false)} />
                )}
            </div>
            <div>
                <ConfirmationModal
                    id="confirmDeleteUserModal"
                    show={showConfirmDeleteModal}
                    title="Deactivate User?"
                    message={"Do you really want to deactivate this user?"}
                    onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                    onOkButtonClick={handleDeleteUser}
                    theme={"danger"}
                />
            </div>
            <div>
                <ConfirmationModal
                    id="confirmRestoreUserModal"
                    show={showConfirmRestoreModal}
                    title="Activate User?"
                    message={"Do you really want to activate this user?"}
                    onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                    onOkButtonClick={handleRestoreUser}
                    theme={"danger"}
                />
            </div>
        </>
    );
}

const roles = (state: AppState) => state.roleState.roles;
const users = (state: AppState) => state.userState.users;
const getRole = (roles: Role[], id: number) => roles.find((r) => r.id === id)?.name;
const showInactiveUsers = (state: AppState, showInactiveUsers: boolean) => showInactiveUsers;
const getUsersWithFullDetails = createSelector([users, roles, showInactiveUsers], (users, roles, showInactiveUsers) => {
    let details: UserWithFullDetails[];
    details = users.map(
        (user: User) =>
            ({
                id: user.id,
                fullName: `${user.lastName}, ${user.firstName}`,
                email: user.emailAddress,
                tenant: user.tenantName,
                partnerTenant: user.partnerTenantName,
                customerTenant: user.customerTenantName,
                role: getRole(roles, user.roleId),
                status: user.isActive ? "Active" : "Inactive",
            } as UserWithFullDetails)
    );
    return showInactiveUsers ? details : details.filter((u) => u.status === "Active");
});
