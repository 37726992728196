import { createSelector } from "reselect";
import _ from "lodash";
import { AppState } from "../state/AppState";
import { selectSystems } from "./FinosecSystemSelectors";


const selectId = (state: any, id: number | null) => id;
export const selectDashboards = (state: AppState) => state.dashboardState?.dashboards || [];

export const selectDashboardById = createSelector([selectDashboards, selectId], (dashboards, id) => {
    const returnItem = dashboards.find((r) => (id ? r.id === +id : false));
    return returnItem;
});

const selectSearchString = (state: AppState, searchString: string) => searchString;

export const selectMappedSystemsWithDashboards = createSelector(
    [selectSystems, selectDashboards, selectSearchString],
    (systems, dashboards, searchString) => {
        const mappedDashboards = dashboards.map((r) => ({
            ...r,
            active: r.isActive ? "Active" : "InActive",
        }));
        return dashboards.length > 0
            ? systems.map((s) => {
                  const filteredDashboards = mappedDashboards.filter(
                      (r) =>
                          r.finosecSystemId === s.id &&
                          (searchString ? r.name.toLowerCase().includes(searchString) : true)
                  );
                  return {
                      ...s,
                      dashboards: filteredDashboards,
                  };
              })
            : [];
    }
);