import "core-js/stable";
import "core-js/features/map";
import "reflect-metadata";
import "regenerator-runtime/runtime";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app/App";
import { CustomerReportPdf } from "./components/customerReportsPdf/customerReportPdf";
import { FullSystemReportPdf } from "./components/customerReportsPdf/fullSystemReportPdf";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import "./scss/main.scss";
import "@fortawesome/fontawesome-free/css/all.min.css";
import dotenv from "dotenv";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { HttpClient } from "./services/HttpClient/HttpClient";
import request from "request-promise-native";
import { DependencyInjectionProvider } from "./dependencyInjection/DependencyInjectionProvider";
import { Store } from "redux";
import { TYPES } from "./dependencyInjection/Types";
import { servicesContainer } from "./services/IOC/ServicesContainer";
import { DefaultExceptionHandler } from "./services/exceptionHandlers/DefaultExceptionHandler";
import { IExceptionHandler } from "./services/exceptionHandlers/IExceptionHandler";
import { TabService } from "./services/TabService";
import { RoleService } from "./services/role/RoleService";
import { SubtabService } from "./services/SubtabService";
import { UserService } from "./services/UserService";
import { createBrowserHistory } from "history";
import SignInOidc from "./components/sign-in/SignInOidc";
import { TenantService } from "./services/tenant/TenantService";
import { ReportAttributeService } from "./services/reportAttribute/ReportAttributeService";
import { ReportService } from "./services/report/ReportService";
import { SubReportService } from "./services/subReport/SubReportService";
import { SubReportAttributeService } from "./services/subReportAttribute/SubReportAttributeService";
import { AwsFileService } from "./services/aws/AwsFileService";
import { FinosecSystemService } from "./services/finosecSystemService/FinosecSystemService";
import { FinosecSystemStatisticService } from "./services/finosecSystemStatisticService/FinosecSystemStatisticService";
import { CustomerImportService } from "./services/customerImport/CustomerImportService";
import { CustomerImportFileService } from "./services/customerImportFile/CustomerImportFileService";
import { CustomerImportFileStatusService } from "./services/customerImportFileStatus/CustomerImportFileStatusService";
import { CustomerReportService } from "./services/customerReport/CustomerReportService";
import { NoteService } from "./services/note/NoteService";
import { CustomerDocumentService } from "./services/customerDocument/CustomerDocumentService";
import { CustomerDocumentsStatisticsService } from "./services/customerDocument/CustomerDocumentsStatisticsService";
import { FileStatusService } from "./services/fileStatus/FileStatusService";
import { FileTypeService } from "./services/fileType/FileTypeService";
import { DashboardService } from "./services/dashboard/DashboardService";
import { LicenseManager } from "ag-grid-enterprise";
import { CustomerImportSummary } from "./components/customerReportsPdf/customerImportSummary";
import { SubReportCategoryService } from "./services/subReportCategory/SubReportService";
import { MFAService } from "./services/mfaService/MFAService";
import { CustomerReportAttributeService } from "./services/customerReportAttribute/CustomerReportAttributeService";
import { ReportTypeService } from "./services/reportType/ReportTypeService";
import { CustomerReportDisplayService } from "./services/customerReportDisplay/CustomerReportDisplayService";
import { EmployeeService } from "./services/employee/EmployeeService";
import { EmployeeCoreIdService } from "./services/employeeCoreId/EmployeeCoreIdService";
import { EmployeeSamAccountService } from "./services/employeeSamAccount/EmployeeSamAccountService";
import { EmployeeSystemService } from "./services/employeeSystem/EmployeeSystemService";
import { EmployeeSystemExceptionService } from "./services/employeeSystemException/EmployeeSystemExceptionService";
import { SystemAccountTypeService } from "./services/systemAccountType/SystemAccountTypeService";
import { CustomerImportSubReportAssignmentService } from "./services/customerImportSubReportAssignment/CustomerImportSubReportAssignmentService";
import { IntakeSystemFileService } from "./services/intakeSystemFile/IntakeSystemFileService";
import { IntakeCustomerFileService } from "./services/intakeCustomerFile/IntakeCustomerFileService";
import { WorkflowJobScheduleService } from "./services/workflowJobSchedule/WorkflowJobScheduleService";
import { WorkflowFrequencyService } from "./services/workflowFrequency/WorkflowFrequencyService";
import { WorkflowJobService } from "./services/workflowJob/WorkflowJobService";
import { WorkflowStatusService } from "./services/workflowStatus/WorkflowStatusService";
import { WorkflowNotificationTemplateService } from "./services/workflowNotificationTemplate/WorkflowNotificationTemplateService";

//TODO: Need to move this to an enviromnet
LicenseManager.setLicenseKey(
    "CompanyName=KEY SYSTEMS CONSULTING INC,LicensedGroup=KEYSYS,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-010451,ExpiryDate=11_September_2021_[v2]_MTYzMTMxNDgwMDAwMA==56d0b93898a8e2d846c40b184cb1b2d8"
);

dotenv.config(); // Load environment variables
registerServices();

const history = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

ReactDOM.render(
    <Provider store={store}>
        <DependencyInjectionProvider container={servicesContainer}>
            <PersistGate loading={undefined} persistor={persistor}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/signin-oidc" render={() => React.createElement(SignInOidc)} />
                        <Route
                            path="/pdf/tenant/:tenantId/customer-import/:customerImportId/customer-report/:customerReportId/page/:page/page-size/:pageSize"
                            render={() => React.createElement(CustomerReportPdf)}
                        />
                        <Route
                            path="/pdf/tenant/:tenantId/customer-import/:customerImportId/customer-report/:customerReportId/page/:page"
                            render={() => React.createElement(CustomerReportPdf)}
                        />
                        <Route
                            path="/pdf/tenant/:tenantId/customer-import/:customerImportId/customer-report/:customerReportId"
                            render={() => React.createElement(CustomerReportPdf)}
                        />
                        <Route
                            path="/pdf/tenant/:tenantId/customer-import/:customerImportId"
                            render={() => React.createElement(FullSystemReportPdf)}
                        />
                        <Route
                            path="/pdf/tenant/:tenantId/customer-import-summary/:customerImportId"
                            render={() => React.createElement(CustomerImportSummary)}
                        />
                        <Route path="/" render={() => React.createElement(App)} />
                    </Switch>
                </BrowserRouter>
            </PersistGate>
        </DependencyInjectionProvider>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function registerServices() {
    const httpClient = new HttpClient(store, request);
    const exceptionHandler: IExceptionHandler = new DefaultExceptionHandler(store);
    servicesContainer.bind<Store>(TYPES.Store).toConstantValue(store as Store);
    servicesContainer.bind(TYPES.HttpClient).toConstantValue(httpClient);
    servicesContainer.bind(TYPES.RequestPromiseApi).toConstantValue(request);
    servicesContainer.bind(TYPES.RoleService).toConstantValue(new RoleService(httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.SubtabService).toConstantValue(new SubtabService(store, httpClient));
    servicesContainer.bind(TYPES.TabService).toConstantValue(new TabService(httpClient, store));
    servicesContainer.bind(TYPES.TenantService).toConstantValue(new TenantService(store, httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.UserService).toConstantValue(new UserService(store, httpClient));
    servicesContainer.bind(TYPES.AwsFileService).toConstantValue(new AwsFileService(httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.ReportService).toConstantValue(new ReportService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.ReportAttributeService)
        .toConstantValue(new ReportAttributeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.SubReportService)
        .toConstantValue(new SubReportService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.SubReportAttributeService)
        .toConstantValue(new SubReportAttributeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FinosecSystemService)
        .toConstantValue(new FinosecSystemService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FinosecSystemStatisticService)
        .toConstantValue(new FinosecSystemStatisticService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerImportService)
        .toConstantValue(new CustomerImportService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerImportFileService)
        .toConstantValue(new CustomerImportFileService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerImportFileStatusService)
        .toConstantValue(new CustomerImportFileStatusService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerReportService)
        .toConstantValue(new CustomerReportService(store, httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.NoteService).toConstantValue(new NoteService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerDocumentsStatisticsService)
        .toConstantValue(new CustomerDocumentsStatisticsService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerDocumentService)
        .toConstantValue(new CustomerDocumentService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FileStatusService)
        .toConstantValue(new FileStatusService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.FileTypeService)
        .toConstantValue(new FileTypeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.DashboardService)
        .toConstantValue(new DashboardService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.SubReportCategoryService)
        .toConstantValue(new SubReportCategoryService(store, httpClient, exceptionHandler));
    servicesContainer.bind(TYPES.MFAService).toConstantValue(new MFAService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerReportAttributeService)
        .toConstantValue(new CustomerReportAttributeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.ReportTypeService)
        .toConstantValue(new ReportTypeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerReportDisplayService)
        .toConstantValue(new CustomerReportDisplayService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.EmployeeService)
        .toConstantValue(new EmployeeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.EmployeeCoreIdService)
        .toConstantValue(new EmployeeCoreIdService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.EmployeeSamAccountService)
        .toConstantValue(new EmployeeSamAccountService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.EmployeeSystemService)
        .toConstantValue(new EmployeeSystemService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.SystemAccountTypeService)
        .toConstantValue(new SystemAccountTypeService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.EmployeeSystemExceptionService)
        .toConstantValue(new EmployeeSystemExceptionService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.CustomerImportSubReportAssignmentService)
        .toConstantValue(new CustomerImportSubReportAssignmentService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.IntakeSystemFileService)
        .toConstantValue(new IntakeSystemFileService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.IntakeCustomerFileService)
        .toConstantValue(new IntakeCustomerFileService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.WorkflowJobScheduleService)
        .toConstantValue(new WorkflowJobScheduleService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.WorkflowFrequencyService)
        .toConstantValue(new WorkflowFrequencyService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.WorkflowJobService)
        .toConstantValue(new WorkflowJobService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.WorkflowStatusService)
        .toConstantValue(new WorkflowStatusService(store, httpClient, exceptionHandler));
    servicesContainer
        .bind(TYPES.WorkflowNotificationTemplateService)
        .toConstantValue(new WorkflowNotificationTemplateService(store, httpClient, exceptionHandler));
}
