import { inject, injectable } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { SystemAccountType } from "../../models/SystemAccountType";
import { ISystemAccountTypeService } from "./ISystemAccountTypeService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";

@injectable()
export class SystemAccountTypeService implements ISystemAccountTypeService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getSystemAccountTypes(): Promise<void | SystemAccountType[]> {
        return this.httpClient
            .get<SystemAccountType[]>(`/system-account-type`)
            .catch((exception: any) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving system account types. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
