import {
    CustomerReportAttributeActions,
    CUSTOMER_REPORT_ATTRIBUTES_FETCHED,
} from "../../actions/customerReportAttributes/CustomerReportAttributeAction";
import { CustomerReportAttributeState } from "../../state/CustomerReportAttributeState";

const initialState: CustomerReportAttributeState = {
    customerReportAttributes: [],
};

export function customerReportAttributeReducer(
    state: CustomerReportAttributeState = initialState,
    action: CustomerReportAttributeActions
): CustomerReportAttributeState {
    switch (action.type) {
        case CUSTOMER_REPORT_ATTRIBUTES_FETCHED: {
            return {
                ...state,
                customerReportAttributes: [
                    ...state.customerReportAttributes.filter(
                        (cra) => !(action.customerReportAttributes || []).map((acra) => acra.id).includes(cra.id)
                    ),
                    ...action.customerReportAttributes,
                ],
            };
        }

        default:
            return state;
    }
}
