import {ToastState} from "../state/ToastState";
import {HIDE_TOAST, SHOW_TOAST, ToastActionTypes} from "../actions/ToastActions";
import {reject} from "lodash-es";
import {KeysysToastProps} from "../components/toast/KeysysToastProps";

const initialState: ToastState = {
    activeToasts: []
};

export function toastReducer(state: ToastState = initialState, action: ToastActionTypes): ToastState {
    switch (action.type) {
        case SHOW_TOAST:
            return {
                ...state,
                activeToasts: [...state.activeToasts, action.toastProps]
            };

        case HIDE_TOAST:
            return {
                ...state,
                activeToasts: reject(state.activeToasts, (toast: KeysysToastProps) => toast.name === action.name)
            };

        default:
            return state;
    }
}
