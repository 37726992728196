import { TYPES } from "../../dependencyInjection/Types";
import { IWorkflowStatusService } from "../../services/workflowStatus/IWorkflowStatusService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { WorkflowStatusesFetched, WORKFLOW_STATUSES_FETCHED } from "./WorkflowStatusActions";

export function getWorkflowStatuses(): KeysysThunkAction<void, WorkflowStatusesFetched> {
    return async (dispatch, getState, container) => {
        const workflowStatusService = container.get<IWorkflowStatusService>(TYPES.WorkflowStatusService);
        const workflowStatuses = await workflowStatusService.getWorkflowStatuses();
        if (workflowStatuses) {
            dispatch<WorkflowStatusesFetched>({
                type: WORKFLOW_STATUSES_FETCHED,
                workflowStatuses,
            });
        }
    };
}
