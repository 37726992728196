import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { INoteService } from "./INoteService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_CUSTOMER_IMPORT_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import { INote, IPostNote } from "../../models/Note";

@injectable()
export class NoteService implements INoteService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createNote(note: IPostNote): Promise<void | any> {
        return this.httpClient
            .post<IPostNote>("/customer-note", note)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_CUSTOMER_IMPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Note "${(t as INote).id}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a note. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getNotes(): Promise<void | INote[]> {
        return this.httpClient
            .get<INote[]>(`/customer-note`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving Customer Notes. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getNotesByCustomerReportId(customerReportId: number | undefined): Promise<void | INote[]> {
        return this.httpClient
            .get<INote[]>(`/customer-note/customerreport/${customerReportId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving Customer Notes. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getNotesByCustomerImportId(customerImportId: number | undefined): Promise<void | INote[]> {
        return this.httpClient
            .get<INote[]>(`/customer-note/customerimport/${customerImportId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving Customer Notes. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
