import {
    WorkflowNotificationTemplateActions,
    WORKFLOW_NOTIFICATION_TEMPLATES_FETCHED,
    WORKFLOW_NOTIFICATION_TEMPLATE_CREATED,
    WORKFLOW_NOTIFICATION_TEMPLATE_DELETED,
    WORKFLOW_NOTIFICATION_TEMPLATE_EDITED,
    WORKFLOW_NOTIFICATION_TEMPLATE_FETCHED,
    WORKFLOW_NOTIFICATION_TEMPLATE_SELECTED,
} from "../../actions/workflowNotificationTemplate/WorkflowNotificationTemplateActions";
import { WorkflowNotificationTemplate } from "../../models/WorkflowNotificationTemplate";
import { WorkflowNotificationTemplateState } from "../../state/WorkflowNotificationTemplateState";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: WorkflowNotificationTemplateState = {
    workflowNotificationTemplates: [],
};

export function workflowNotificationTemplateReducer(
    state: WorkflowNotificationTemplateState = initialState,
    action: WorkflowNotificationTemplateActions
): WorkflowNotificationTemplateState {
    switch (action.type) {
        case WORKFLOW_NOTIFICATION_TEMPLATES_FETCHED:
            const sortedWorkflowNotificationTemplates = [...action.workflowNotificationTemplates].sort(
                sortWorkflowNotificationTemplates
            );
            return {
                ...state,
                workflowNotificationTemplates: sortedWorkflowNotificationTemplates,
            };
        case WORKFLOW_NOTIFICATION_TEMPLATE_FETCHED:
            const existing = state.workflowNotificationTemplates.find(
                (j) => j.id === action.workflowNotificationTemplate.id
            );
            const workflowNotificationTemplates = existing
                ? replaceInCollection(
                      state.workflowNotificationTemplates,
                      existing,
                      action.workflowNotificationTemplate,
                      sortWorkflowNotificationTemplates
                  )
                : [...state.workflowNotificationTemplates, action.workflowNotificationTemplate].sort(
                      sortWorkflowNotificationTemplates
                  );
            return {
                ...state,
                workflowNotificationTemplates,
            };
        case WORKFLOW_NOTIFICATION_TEMPLATE_CREATED:
            return {
                ...state,
                workflowNotificationTemplates: [
                    ...state.workflowNotificationTemplates,
                    action.workflowNotificationTemplate,
                ].sort(sortWorkflowNotificationTemplates),
            };
        case WORKFLOW_NOTIFICATION_TEMPLATE_DELETED:
            const original = state.workflowNotificationTemplates.find(
                (j) => j.id === action.workflowNotificationTemplate.id
            );
            return {
                ...state,
                workflowNotificationTemplates: replaceInCollection(
                    state.workflowNotificationTemplates,
                    original!,
                    action.workflowNotificationTemplate
                ),
            };
        case WORKFLOW_NOTIFICATION_TEMPLATE_EDITED:
            return {
                ...state,
                workflowNotificationTemplates: replaceInCollection(
                    state.workflowNotificationTemplates,
                    action.workflowNotificationTemplate,
                    action.newWorkflowNotificationTemplate,
                    sortWorkflowNotificationTemplates
                ),
            };
        case WORKFLOW_NOTIFICATION_TEMPLATE_SELECTED:
            return { ...state, selectedWorkflowNotificationTemplate: action.workflowNotificationTemplate };

        default:
            return state;
    }
}

function sortWorkflowNotificationTemplates(a: WorkflowNotificationTemplate, b: WorkflowNotificationTemplate) {
    return a.name.localeCompare(b.name);
}
