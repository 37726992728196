import { IntakeSystemFileState } from "../state/IntakeSystemFileState";
import {
    CLEAR_SELECTED_INTAKESYSTEMFILE,
    INACTIVE_INTAKESYSTEMFILES_FETCHED,
    SELECT_INTAKESYSTEMFILE,
    INTAKESYSTEMFILE_CREATED,
    INTAKESYSTEMFILE_DELETED,
    INTAKESYSTEMFILE_EDITED,
    INTAKESYSTEMFILE_RESTORED,
    IntakeSystemFileActions,
    INTAKESYSTEMFILES_FETCHED,
} from "../actions/intakeSystemFiles/IntakeSystemFilesActions";
import { IntakeSystemFile } from "../models/IntakeSystemFile";
import { replaceInCollection } from "./ReplaceInCollection";

const initialState: IntakeSystemFileState = {
    intakeSystemFiles: [],
    selectedIntakeSystemFile: undefined,
};

export function intakeSystemFileReducer(
    state: IntakeSystemFileState = initialState,
    action: IntakeSystemFileActions
): IntakeSystemFileState {
    switch (action.type) {
        case INTAKESYSTEMFILES_FETCHED:
            const sortedIntakeSystemFiles = [...action.intakeSystemFiles];
            sortedIntakeSystemFiles.sort((a, b) => a.displayFileName.localeCompare(b.displayFileName));

            return {
                ...state,
                intakeSystemFiles: sortedIntakeSystemFiles,
            };
        case INTAKESYSTEMFILE_CREATED:
            return {
                ...state,
                intakeSystemFiles: addIntakeSystemFileAndGet(state.intakeSystemFiles, action.intakeSystemFile),
            };
        case INTAKESYSTEMFILE_DELETED:
            const originalActiveIntakeSystemFile = state.intakeSystemFiles.find(
                (u) => u.id === action.intakeSystemFile.id
            );

            return {
                ...state,
                intakeSystemFiles: removeOldIntakeSystemFileAndReplaceWithNew(
                    state.intakeSystemFiles,
                    originalActiveIntakeSystemFile!,
                    action.intakeSystemFile
                ),
            };
        case INTAKESYSTEMFILE_EDITED:
            return {
                ...state,
                intakeSystemFiles: removeOldIntakeSystemFileAndReplaceWithNew(
                    state.intakeSystemFiles,
                    action.intakeSystemFile,
                    action.newIntakeSystemFile
                ),
            };
        case INTAKESYSTEMFILE_RESTORED:
            const originalInactiveIntakeSystemFile = state.intakeSystemFiles.find(
                (u) => u.id === action.intakeSystemFile.id
            );

            return {
                ...state,
                intakeSystemFiles: removeOldIntakeSystemFileAndReplaceWithNew(
                    state.intakeSystemFiles,
                    originalInactiveIntakeSystemFile!,
                    action.intakeSystemFile
                ),
            };
        case SELECT_INTAKESYSTEMFILE:
            return {
                ...state,
                selectedIntakeSystemFile: action.intakeSystemFile,
            };
        case CLEAR_SELECTED_INTAKESYSTEMFILE:
            return {
                ...state,
                selectedIntakeSystemFile: undefined,
            };
        default:
            return state;
    }
}

function removeOldIntakeSystemFileAndReplaceWithNew(
    intakeSystemFiles: IntakeSystemFile[],
    originalIntakeSystemFile: IntakeSystemFile,
    newIntakeSystemFile: IntakeSystemFile
): IntakeSystemFile[] {
    return replaceInCollection(intakeSystemFiles, originalIntakeSystemFile, newIntakeSystemFile, (a, b) =>
        a.displayFileName.localeCompare(b.displayFileName)
    );
}

function addIntakeSystemFileAndGet(
    intakeSystemFiles: IntakeSystemFile[],
    intakeSystemFile: IntakeSystemFile
): IntakeSystemFile[] {
    return [...intakeSystemFiles, intakeSystemFile].sort((a, b) => a.displayFileName.localeCompare(b.displayFileName));
}
