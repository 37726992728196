import { createSelector } from "reselect";
import { AppState } from "../state/AppState";
import { selectCustomerImportsByCustomerId } from "./CustomerImportSelectors";
import { selectReports } from './ReportSelectors';

export const selectCustomerImportFiles = (state: AppState) => state.customerImportFileState?.customerImportFiles;
export const selectCustomerImports = (state: AppState) => state.customerImportState?.customerImports;
export const selectId = (state: any, customerId: number, id: number) => id;

export const selectCustomerImportFilesByCustomerImports = createSelector(
    [selectCustomerImportFiles, selectCustomerImportsByCustomerId, selectId],
    (customerImportFiles, customerImports, systemId) => {
        return customerImportFiles.filter((cif) =>
            customerImports
                .filter((x) => x.finosecSystemId === systemId)
                .map((ci) => ci.id)
                .includes(cif.customerImportId)
        );
    }
);

export const selectCustomerImportFilesWithReportsByImportId = createSelector(
    [selectCustomerImportFiles, selectReports, (state: AppState, id: any) => id], (files, reports, importId) => {
        return files.filter(f => f.customerImportId === importId).map(f => (
            {
                ...f,
                reportName: reports.find(r => r.id === f.reportId)?.name
            }
        ))
    }
)

export const selectCustomerImportFilesByCustomerImportsAndReportId = createSelector(
    [selectCustomerImportFiles, selectCustomerImportsByCustomerId, selectId],
    (customerImportFiles, customerImports, reportId) => {
        return customerImportFiles.filter(
            (cif) => cif.reportId === reportId && customerImports.map((ci) => ci.id).includes(cif.customerImportId)
        );
    }
);
