const TYPES = {
    AuthenticationService: Symbol.for("AuthenticationService"),
    AwsFileService: Symbol.for("AwsFileService"),
    HttpClient: Symbol.for("HttpClient"),
    IExceptionHandler: Symbol.for("IExceptionHandler"),
    RoleService: Symbol.for("RoleService"),
    RequestPromiseApi: Symbol.for("RequestPromiseApi"),
    Store: Symbol.for("Store"),
    SubtabService: Symbol.for("SubtabService"),
    ReportService: Symbol.for("ReportService"),
    DashboardService: Symbol.for("DashboardService"),
    SubReportService: Symbol.for("SubReportService"),
    TabService: Symbol.for("TabService"),
    TenantService: Symbol.for("TenantService"),
    UserService: Symbol.for("UserService"),
    ReportAttributeService: Symbol.for("ReportAttributeService"),
    SubReportAttributeService: Symbol.for("SubReportAttributeService"),
    FinosecSystemService: Symbol.for("FinosecSystemService"),
    FinosecSystemStatisticService: Symbol.for("FinosecSystemStatisticService"),
    CustomerImportService: Symbol.for("CustomerImportService"),
    CustomerImportFileService: Symbol.for("CustomerImportFileService"),
    CustomerImportFileStatusService: Symbol.for("CustomerImportFileStatusService"),
    CustomerReportService: Symbol.for("CustomerReportService"),
    NoteService: Symbol.for("NoteService"),
    CustomerDocumentService: Symbol.for("CustomerDocumentService"),
    CustomerDocumentsStatisticsService: Symbol.for("CustomerDocumentsStatisticsService"),
    FileStatusService: Symbol.for("FileStatusService"),
    FileTypeService: Symbol.for("FileTypeService"),
    SubReportCategoryService: Symbol.for("SubReportCategoryService"),
    MFAService: Symbol.for("MFAService"),
    CustomerReportAttributeService: Symbol.for("CustomerReportAttributeService"),
    ReportTypeService: Symbol.for("ReportTypeService"),
    CustomerReportDisplayService: Symbol.for("CustomerReportDisplayService"),
    EmployeeService: Symbol.for("EmployeeService"),
    EmployeeCoreIdService: Symbol.for("EmployeeCoreIdService"),
    EmployeeSamAccountService: Symbol.for("EmployeeSamAccountService"),
    SystemAccountTypeService: Symbol.for("SystemAccountTypeService"),
    EmployeeSystemService: Symbol.for("EmployeeSystemService"),
    EmployeeSystemExceptionService: Symbol.for("EmployeeSystemExceptionService"),
    CustomerImportSubReportAssignmentService: Symbol.for("CustomerImportSubReportAssignmentService"),
    IntakeSystemFileService: Symbol.for("IntakeSystemFileService"),
    IntakeCustomerFileService: Symbol.for("IntakeCustomerFileService"),
    WorkflowJobScheduleService: Symbol.for("WorkflowJobScheduleService"),
    WorkflowFrequencyService: Symbol.for("WorkflowFrequencyService"),
    WorkflowJobService: Symbol.for("WorkflowJobService"),
    WorkflowStatusService: Symbol.for("WorkflowStatusService"),
    WorkflowNotificationTemplateService: Symbol.for("WorkflowNotificationTemplateService"),
};

export { TYPES };
