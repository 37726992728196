import { IImportDetailsModal } from "./forms/IImportDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { IMPORT_DETAILS_MODAL } from "../../constants/ModalConstants";
import { useRef } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import moment from "moment";
import { hideModal } from "../../actions/ModalActionCreators";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { Col, Row } from "react-bootstrap";
import KeysysInput from "../form/input/KeysysInput";

export default function ImportDetailsModal(props: IImportDetailsModal) {
    const masterList = props.masterList;
    const customerImportFile = props.customerImportFile;
    const importStatus = props.status;
    const modalShow = useSelector((state: AppState) => state.modalState.activeModals.includes(IMPORT_DETAILS_MODAL));

    const dispatch = useDispatch();

    const formikRef = useRef<FormikProps<any>>(null);

    function getImportStatus() {
        if (importStatus === "Complete") {
            return importStatus + ` - (${moment(customerImportFile.endDate).format("MM-DD-YYYY hh:mm")})`;
        }
        return importStatus;
    }

    function getInitialValues() {
        return {
            masterList: masterList,
            fileName: customerImportFile.fileName,
            startDate: moment(customerImportFile.startDate).format("MM-DD-YYYY hh:mm"),
            status: getImportStatus(),
            rows: customerImportFile.noRows,
            columnsMatched: customerImportFile.noColumnsMatches,
        };
    }

    function clearFormAndHide(formProps: FormikProps<any> | FormikHelpers<any>): void {
        formProps.resetForm();
        dispatch(hideModal(IMPORT_DETAILS_MODAL));
    }

    function handleSubmit(values: any, FormikHelpers: FormikHelpers<any>) {
        clearFormAndHide(FormikHelpers);
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<any>) => {
                    return (
                        <VerticallyCenteredModal
                            id="importDetailsModal"
                            show={modalShow}
                            title=""
                            closeButtonText={"Close"}
                            okButtonText={"Close"}
                            dynamicHeight={true}
                            size="sm"
                            showSaveButton={false}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                            actionButtonsVertical={true}
                        >
                            <Form id="importDetailsModal" data-testid={"import-details-modal"}>
                                <div style={{ textAlign: "center" }}>
                                    <h4>Import Details</h4>
                                </div>
                                <Row className={"mb-1"}>
                                    <Col>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"masterList"}
                                            placeholder={""}
                                            fieldName={"masterList"}
                                            label={"Master List"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"fileName"}
                                            placeholder={""}
                                            fieldName={"fileName"}
                                            label={"File Selected"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"startDate"}
                                            placeholder={""}
                                            fieldName={"startDate"}
                                            label={"Upload Started"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"status"}
                                            placeholder={""}
                                            fieldName={"status"}
                                            label={"Upload Status"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"rows"}
                                            placeholder={""}
                                            fieldName={"rows"}
                                            label={"Rows"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-1"}>
                                    <Col>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"columnsMatched"}
                                            placeholder={""}
                                            fieldName={"columnsMatched"}
                                            label={"Column Headers Matched"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
