import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerImports } from "../actions/customerImport/CustomerImportActionCreators";
import { getCustomerImportFiles } from "../actions/customerImportFile/CustomerImportFileActionCreators";
import {
    bulkCreateCustomerReportReviews,
    createCustomerReportReview,
    editCustomerReport,
    getCustomerReportReviews,
    setCustomerReportReviewsLoading,
    setCustomerReportsLoading,
} from "../actions/customerReport/CustomerReportActionCreator";
import { getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { getReportAttributes } from "../actions/reportAttributes/ReportAttributeActionCreator";
import { getReports } from "../actions/reports/ReportActionCreators";
import { getSubReports } from "../actions/subReports/SubReportActionCreators";
import { AppState } from "../state/AppState";
import {
    CustomerReportReviewCreate,
    CustomerReportReviewCreateBulk,
    ICustomerReportReview,
} from "../models/CustomerReportReview";
import _ from "lodash";
import { getSubReportAttributes } from "../actions/subReportAttributes/SubReportAttributeActionCreator";
import { IPostNote } from "../models/Note";
import { selectAttributesForReportList } from "../selectors/ReportAttributeSelectors";
import { useNotes } from "./notesHook";
import { useCustomerImportJson } from "./customerImportJsonHook";
import { upsertCustomerImportFileStatus } from "../actions/customerImportFileStatus/CustomerImportFileStatusActionCreators";
import { CustomerImportFileStatus } from "../models/CustomerImportFileStatus";
import { CustomerReportFlag } from "../models/CustomerReport";
import { getCustomerReportAttributes } from "../actions/customerReportAttributes/CustomerReportAttributeActionCreator";

export const useCustomerReportListForm = (customerReportId: number) => {
    const dispatch = useDispatch();

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const tenantId = decodedAccessToken.tenant_id;
    const user = `${decodedAccessToken.given_name} ${decodedAccessToken.family_name}`;
    const tenantName = decodedAccessToken.tenant_name;

    const customerReport = useSelector((state: AppState) =>
        state.customerReportState.customerReports.find((c) => c.id === customerReportId)
    );

    const finosecSystem = useSelector((state: AppState) =>
        state.finosecSystemState.finosecSystems.find((fs) => fs.id === customerReport?.finosecSystemId)
    );
    const customerImportFile = useSelector((state: AppState) =>
        state.customerImportFileState.customerImportFiles.find((cif) => cif.id === customerReport?.customerImportFileId)
    );

    const { createNote, notes, notesLoading } = useNotes(
        (note) =>
            note.finosecSystemId === customerReport?.finosecSystemId &&
            note.customerImportId === customerImportFile?.customerImportId &&
            note.customerReportId === customerReportId,
        [customerReport, customerImportFile]
    );

    const customerImport = useSelector((state: AppState) =>
        state.customerImportState.customerImports.find((ci) => ci.id === customerReport?.customerImportId)
    );

    const customerReportReviewState = useSelector((state: AppState) => state.customerReportState.customerReportReviews);

    const customerReportReviews = useMemo(
        () =>
            customerImportFile
                ? customerReportReviewState.filter((crr) => crr.customerImportFileId === customerImportFile.id)
                : [],
        [customerImportFile, customerReportReviewState]
    );

    const [pageSize, setPageSize] = useState<number>();

    const attributes = useSelector((state: AppState) =>
        selectAttributesForReportList(state, {
            reportId: customerReport?.reportId,
            subReportId: customerReport?.subReportId,
            customerReportId: customerReportId,
            isCurrent: customerImport?.isCurrent,
        })
    );

    const { tableData, headers, loading, setHasLoadedData } = useCustomerImportJson(
        customerImportFile?.id,
        attributes,
        customerReportReviews,
        customerReport?.subReportFilter,
        customerReport?.filter,
        customerImport?.isCurrent
    );

    /*useEffect(() => {
        if (decodedAccessToken) {
            dispatch(getCustomerImportFiles());
        }
    }, [decodedAccessToken]);*/

    useEffect(() => {
        if (decodedAccessToken.userId && customerImportFile) {
            /*dispatch(getFinosecSystems());
            dispatch(getCustomerImports());
            dispatch(getReports());
            dispatch(getSubReports());*/
            dispatch(getCustomerReportReviews(customerReportId, customerImportFile.id));
        }
    }, [decodedAccessToken.userId, customerImportFile, customerReportId, dispatch]);

    useEffect(() => {
        if (customerReport?.isSubReport && customerReport.subReportId !== undefined) {
            dispatch(getSubReportAttributes(customerReport.subReportId));
            dispatch(getCustomerReportAttributes(customerReport.id));
        }

        if (customerReport?.reportId !== undefined) {
            dispatch(getReportAttributes(customerReport.reportId));
        }
    }, [customerReport, dispatch, decodedAccessToken.userId]);

    const updateReportReview = (
        customerReportReview: ICustomerReportReview | CustomerReportReviewCreate,
        customerReportFlag: CustomerReportFlag
    ) => {
        // The method handles add and or update
        dispatch(createCustomerReportReview(customerReportReview, customerReportFlag));

        /* Comment out if we need this back
        if (customerReportReview.id !== undefined && oldReview) {
            dispatch(editCustomerReportReview(oldReview, customerReportReview as ICustomerReportReview));
        } else {
            dispatch(createCustomerReportReview(customerReportReview));
        }*/
    };

    const hasProps = (item: object) => {
        return Object.keys(item).length > 0;
    };

    const markCustomerReportAsReviewed = useCallback(
        (note: string, automatic: boolean = false) => {
            dispatch(setCustomerReportsLoading());
            dispatch(setCustomerReportReviewsLoading());

            if (customerReport?.customerImportId && customerImportFile) {
                const noteToCreate: IPostNote = {
                    tenantId,
                    finosecSystemId: customerReport.finosecSystemId,
                    customerImportId: customerReport.customerImportId,
                    customerReportId: customerReportId,
                    noteText: note,
                    lastModifiedByName: user,
                    isAutoCreated: true,
                };

                createNote(noteToCreate);

                const customerImportFileStatus: CustomerImportFileStatus = {
                    customerImportFileId: customerImportFile.id,
                    isPositiveCheck: true,
                    isReviewComplete: true,
                    customerReportId: customerReportId,
                };
                dispatch(upsertCustomerImportFileStatus(customerImportFileStatus));

                dispatch(
                    editCustomerReport(customerReport, {
                        ...customerReport,
                        isReviewComplete: true,
                        reviewCompleteByName: user,
                    })
                );

                if (!automatic) {
                    const lineNumbersToUpdate = tableData
                        .filter((x) => !hasProps(x.customerReportReview) || !x.customerReportReview.isReviewed)
                        .map((x) => x.Row);
                    const bulkRequest: CustomerReportReviewCreateBulk = {
                        lineNumbers: lineNumbersToUpdate,
                        customerReportId: customerReportId,
                        customerImportFileId: customerImportFile.id,
                        isReviewed: true,
                        isAutoReviewed: true,
                        tenantId: tenantId,
                        lastModifiedByName: user,
                        lastModifiedBySubReport: customerReport.subReportName,
                    };

                    dispatch(bulkCreateCustomerReportReviews(bulkRequest));
                }
            }
        },
        [tableData, customerReport, customerImportFile, user, tenantId]
    );

    const markCustomerReportAsNotReviewed = useCallback(() => {
        if (customerReport?.customerImportId && !customerImport?.isComplete && customerImportFile) {
            dispatch(
                editCustomerReport(customerReport, {
                    ...customerReport,
                    isReviewComplete: false,
                    reviewCompleteByName: "",
                })
            );
        }
    }, [tableData, customerReport, customerImportFile, user, tenantId]);

    return {
        tenantId,
        user,
        updateReportReview,
        tableData: tableData.map((td: any) => ({
            ...td,
            customerReportReview: customerReportReviews.find((cr) => cr.lineNumber === td.Row) || {},
        })),
        headers,
        setPageSize,
        markCustomerReportAsReviewed,
        markCustomerReportAsNotReviewed,
        pageSize,
        attributes,
        customerReport,
        tenantName,
        finosecSystem,
        createNote,
        customerImport,
        notes,
        loading,
        notesLoading,
        customerImportFileId: customerImportFile?.id,
        setHasLoadedData,
    };
};
