import axios from "axios";
import { API_BASE_URL } from "../config/config";
import { PreSignedUrlType } from "../models/PreSignedUrlType";
import { computeSha256 } from "./computeSha256";

export const getPreSignedUrl = async (
    accessToken: string,
    filename: string,
    bucketName: string,
    preSignedUrlType: PreSignedUrlType,
    contentHash?: string
) => {
    const preSignedUrlModel = {
        Filename: filename,
        BucketName: bucketName,
        PreSignedUrlType: preSignedUrlType,
        contentHash: contentHash,
    };

    const response = await axios.post(`${API_BASE_URL}pre-signed-url`, preSignedUrlModel, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return response.data;
};

export async function uploadFileToS3WithPreSignedUrl(
    file: File,
    preSignedUrl: any,
    contentHash?: string
): Promise<number> {
    try {
        const headers: { [key: string]: string } = {
            "Content-Type": "application/octet-stream",
        };

        // If a content hash is provided, add it to the headers
        if (contentHash) {
            headers["x-amz-content-sha256"] = contentHash;
        }

        const config = {
            headers: headers,
        };
        var response = await axios.put(preSignedUrl, file, config);
        console.log("File successfully uploaded");
        return response.status;
    } catch (error) {
        console.error("Error uploading file:", error);
        return 500;
    }
}

export async function downloadFileFromS3WithPreSignedUrl(
    filename: string,
    preSignedUrl: string,
    expectedHash: string
): Promise<void> {
    try {
        if (!expectedHash) {
            const link = document.createElement("a");
            link.href = preSignedUrl;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            const response = await fetch(preSignedUrl);
            if (!response.ok) throw new Error("Error getting response.");

            const blob = await response.blob();
            const contentHash = await computeSha256(blob);

            if (contentHash !== expectedHash) {
                console.error("File integrity check failed.");
                return;
            }

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        }
    } catch (error) {
        console.error("Error downloading file:", error);
    }
}

export const getJsonContentFromPresignedUrl = async (preSignedUrl: string): Promise<void | any[]> => {
    const response = await fetch(preSignedUrl);
    if (response?.ok) {
        return response.json();
    } else {
        console.error("Error, reponse", response.status);
        console.error("s3 error", response.body);
        return;
    }
};
