import { inject } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { EmployeeSamAccount } from "../../models/EmployeeSamAccount";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IEmployeeSamAccountService } from "./IEmployeeSamAccountService";

export class EmployeeSamAccountService implements IEmployeeSamAccountService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getEmployeeSamAccounts(employeeId: number): Promise<void | EmployeeSamAccount[]> {
        return this.httpClient
            .get<EmployeeSamAccount[]>(`/employee-sam-account/employee/${employeeId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the employee sam accounts. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    updateEmployeeSamAccounts(employeeId: number, samAccounts: string[]): Promise<void | string[]> {
        return this.httpClient
            .post<string[]>(`/employee-sam-account/employee/${employeeId}`, samAccounts)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while updating Employee SAM Accounts. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
