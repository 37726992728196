import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IReportService } from "./IReportService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Report } from "../../models/Report";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_REPORT_SUCCESS, DEACTIVATE_REPORT_SUCCESS, EDIT_REPORT_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";

@injectable()
export class ReportService implements IReportService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createReport(report: Report): Promise<void | Report> {
        return this.httpClient
            .post<Report>("/reports", report)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Report "${t.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a report. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editReport(report:Report, newReport: Report): Promise<void|Report>{
        const operations = createPatch(report, newReport);
        return this.httpClient
            .patch<Report>(`/reports/${report.id}`, operations)
            .then((patchedReport) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedReport.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedReport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing report "${report.name}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteReport(report: Report): Promise<void | Report> {
        return this.httpClient
            .delete(`/reports/${report.id}`)
            .then(() => {
                const returnReport = { ...report, isActive: !report.isActive }; 
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Report "${report.name}" ${report.isActive?" deactivated":" reactivated"} successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnReport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the report. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getReports(): Promise<void | Report[]> {
        return this.httpClient
            .get<Report[]>(`/reports`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving reports. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getReportById(id: number): Promise<void | Report> {
        return this.httpClient
            .get<Report>(`/reports/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the report. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
