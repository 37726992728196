import { inject, injectable } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { MFAUpdateModel } from "../../models/MFAUpdateModel";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IMFAService } from "./IMFAService";

@injectable()
export class MFAService implements IMFAService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    updateMFA(model: MFAUpdateModel): Promise<void | MFAUpdateModel> {
        return this.httpClient.post<MFAUpdateModel>("users/updatemfa", model);
    }
}
