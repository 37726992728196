/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useContext, useMemo, useReducer } from "react";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { AppState } from "../../state/AppState";
import { useDispatch, useSelector } from "react-redux";
import {
    getIntakeSystemFiles,
    deleteIntakeSystemFile,
    restoreIntakeSystemFile,
} from "../../actions/intakeSystemFiles/IntakeSystemFilesActionCreators";
import {
    getIntakeCustomerFiles,
    deleteIntakeCustomerFile,
    selectIntakeCustomerFile,
} from "../../actions/intakeCustomerFiles/IntakeCustomerFilesActionCreators";
import {
    Row,
    Col,
    Button,
    Accordion,
    Card,
    useAccordionButton,
    ButtonGroup,
    Form,
    AccordionContext,
} from "react-bootstrap";
import { IntakeSystemFile } from "../../models/IntakeSystemFile";
import { getRoles } from "../../actions/RoleActionCreators";
import EditButtonRender from "../grid/EditButtonRender";
import RejectButtonRender from "../grid/RejectButtonRender";
import { getTenants } from "../../actions/tenants/TenantActionCreators";
import { WorkflowJob } from "../../models/WorkflowJob";
import DeleteButtonRender from "../grid/DeleteButtonRender";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import PageHeader from "../header/PageHeader";
import { WorkflowJobScheduleForm } from "./WorkflowJobScheduleForm";
import {
    clearWorkflowJobSchedule,
    getWorkflowJobSchedulesByTenantId,
    selectWorkflowJobSchedule,
} from "../../actions/workflowJobSchedule/WorkflowJobScheduleActionCreators";
import { useWorkflowJobSchedules } from "../../hooks/useWorkflowJobSchedules";
import { WorkflowJobSchedule } from "../../models/WorkflowJobSchedule";
import { groupBy } from "lodash-es";
import {
    createWorkflowJob,
    getWorkflowJobsByTenantId,
    selectWorkflowJob,
} from "../../actions/workflowJob/WorkflowJobActionCreators";
import { initialWorkflowJobFilterState, WorkflowJobFilterReducer } from "./WorkflowJobFilterReducer";
import { getFinosecSystems } from "../../actions/finosecSystem/FinosecSystemActionCreator";
import { getFileTypes } from "../../actions/fileType/FileTypeActionCreators";
import { IntakeCustomerFileForm } from "./IntakeCustomerFileForm";
import { IntakeCustomerFile } from "../../models/IntakeCustomerFile";
import { getFileStatuses } from "../../actions/fileStatus/FileStatusActionCreators";
import { isOptionDisabled } from "react-select/src/builtins";
import { WorkflowJobForm } from "../adminCustomerIntakeReview/WorkflowJobUpdateStatusForm";
import { useWorkflowJobs } from "../../hooks/useWorkflowJobs";
import { getWorkflowStatuses } from "../../actions/workflowStatus/WorkflowStatusActionCreators";
import { useIntakeSystemFiles } from "../../hooks/intakeSystemFileHook";
import SmallSquareDeleteButton from "../buttons/SmallSquareDeleteButton/SmallSquareDeleteButton";
import SmallRoundRejectButton from "../buttons/SmallRoundRejectButton/SmallRoundRejectButton";

export default function AdminCustomerIntakeReview() {
    const dispatch = useDispatch();

    const [showAddFileFormModal, setShowAddFileFormModal] = useState<boolean>(false);
    const [showConfirmDeleteIntakeCustomerFileModal, setShowConfirmDeleteIntakeCustomerFileModal] = useState<boolean>(
        false
    );
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false);
    const [selectedIntakeSystemFile, setSelectedIntakeSystemFile] = useState<IntakeSystemFile | undefined>(undefined);
    const [selectedIntakeCustomerFile, setSelectedIntakeCustomerFile] = useState<IntakeCustomerFile | undefined>(
        undefined
    );
    const [intakeSystemFileFormType, setIntakeSystemFileFormType] = useState<"add" | "edit">("add");
    const [isReject, setIsReject] = useState<boolean>(false);

    const workflowJobs = useSelector((state: AppState) => state.workflowJobState.workflowJobs);
    const intakeCustomerFiles = useSelector((state: AppState) => state.intakeCustomerFileState.intakeCustomerFiles);
    const [workflowJobFilterState, dispatchWorkflowJobFilter] = useReducer(
        WorkflowJobFilterReducer,
        initialWorkflowJobFilterState
    );

    const workflowStatuses = useSelector((state: AppState) => state.workflowStatusState.workflowStatuses);
    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const claimTenantId = decodedAccessToken.tenant_id;

    useEffect(() => {
        if (workflowStatuses.length === 0) {
            dispatch(getWorkflowStatuses());
        }
    }, []);

    const filteredWorkflowJobs = useMemo(() => {
        const { completed, processing, readyForIntake, rejected } = workflowJobFilterState;
        const filteredWorkflowJobs: WorkflowJob[] = [];

        for (const job of workflowJobs) {
            if (
                job.statusId === workflowStatuses.find((ws: any) => ws.name === "Ready for Intake")?.id &&
                readyForIntake
            ) {
                filteredWorkflowJobs.push(job);
            }
            if (job.statusId === workflowStatuses.find((ws: any) => ws.name === "Processing")?.id && processing) {
                filteredWorkflowJobs.push(job);
            }
            if (job.statusId === workflowStatuses.find((ws: any) => ws.name === "Rejected")?.id && rejected) {
                filteredWorkflowJobs.push(job);
            }
            if (
                (job.statusId === workflowStatuses.find((ws: any) => ws.name === "Intake Complete")?.id ||
                    job.statusId === workflowStatuses.find((ws: any) => ws.name === "Intake Complete No files")?.id) &&
                completed
            ) {
                filteredWorkflowJobs.push(job);
            }
        }
        return filteredWorkflowJobs;
    }, [workflowJobs, workflowJobFilterState]);

    const getFilteredJobCount = useCallback(
        (customerId: number) => {
            return filteredWorkflowJobs.filter((j) => j.customerId === customerId).length;
        },
        [filteredWorkflowJobs]
    );

    const getActiveJobs = useCallback(
        (schedule: WorkflowJobSchedule) => {
            const { customerId, systemId } = schedule;
            return filteredWorkflowJobs.filter(
                (j) =>
                    j.customerId === customerId &&
                    j.systemId === systemId &&
                    (j.statusId === workflowStatuses.find((ws: any) => ws.name === "Processing")?.id ||
                        j.statusId === workflowStatuses.find((ws: any) => ws.name === "Rejected")?.id ||
                        j.statusId === workflowStatuses.find((ws: any) => ws.name === "Ready for Intake")?.id)
            );
        },
        [filteredWorkflowJobs]
    );

    const [showScheduleForm, setShowScheduleForm] = useState(false);
    const [showJobForm, setShowJobForm] = useState(false);
    const [showCreateIntakeFileRequestModal, setShowCreateIntakeFileRequestModal] = useState(false);
    const [showIntakeCustomerFileModal, setShowIntakeCustomerFileModal] = useState<boolean>(false);
    const [showRejectCustomerFileModal, setShowRejectCustomerFileModal] = useState<boolean>(false);

    const intakeSystemFiles = useSelector((state: AppState) => state.intakeSystemFileState.intakeSystemFiles);
    const fileTypes = useSelector((state: AppState) => state.fileTypeState.fileTypes);
    const fileStatuses = useSelector((state: AppState) => state.fileStatusState.statuses);

    const [intakeSystemFile, setIntakeSystemFile] = useState<IntakeSystemFile | undefined>(undefined);
    const [intakeCustomerFile, setIntakeCustomerFile] = useState<IntakeCustomerFile | undefined>(undefined);
    const [workflowJob, setWorkflowJob] = useState<WorkflowJob | undefined>(undefined);

    const { findFrequency, findSystem, findTenant, workflowJobSchedules, selectedSchedule } = useWorkflowJobSchedules();

    const groupedSchedules = useMemo(() => {
        return groupBy(
            workflowJobSchedules.map((s, i) => ({
                ...s,
                partnerId: findTenant(s.customerId)?.parentTenantId ?? i,
            })),
            (s) => s.partnerId
        );
    }, [findTenant, workflowJobSchedules]);

    function ContextAwareToggle({ children, eventKey, callback }: any) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <button
                type="button"
                style={{ transform: isCurrentEventKey ? "rotate(-180deg)" : "rotate(0deg)" }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getIntakeSystemFiles());
        dispatch(getWorkflowJobSchedulesByTenantId(claimTenantId));
        dispatch(getWorkflowJobsByTenantId(claimTenantId));
        dispatch(getFinosecSystems());
        dispatch(getFileTypes());
        dispatch(getIntakeCustomerFiles());
        dispatch(getFileStatuses());
        if (!intakeSystemFiles || intakeSystemFiles.length === 0) {
            dispatch(getTenants());
        }
    }, []);

    function showAddFileModal(): void {
        setSelectedIntakeSystemFile(undefined);
        setIntakeSystemFileFormType("add");
        setShowAddFileFormModal(true);
    }

    const handleDeleteIntakeCustomerFile = (intakeCustomerFile: IntakeCustomerFile) => {
        if (intakeCustomerFile === undefined) {
            return;
        } else {
            dispatch(deleteIntakeCustomerFile(intakeCustomerFile));
            setShowConfirmDeleteIntakeCustomerFileModal(false);
        }
    };

    const getIntakeCustomerFileData = useCallback(
        (activeJobs, intakeSystemFile) => {
            const intakeCustomerFile: IntakeCustomerFile | undefined = intakeCustomerFiles.find(
                (icf) => icf.jobId === activeJobs[0].id && icf.intakeSystemFileId === intakeSystemFile.id
            );
            return {
                displayFileName: intakeSystemFile.displayFileName,
                uploadedFileName: intakeCustomerFile?.uploadFileName,
                fileType: intakeSystemFile.fileTypeId,
                fileStatus: intakeCustomerFile?.fileStatusId,
                fileStatusMessage: intakeCustomerFile?.fileStatusMessage,
                workflowJob: activeJobs[0],
                intakeSystemFile: intakeSystemFile,
                intakeCustomerFile: intakeCustomerFile,
                id: intakeSystemFile.id,
            };
        },
        [intakeCustomerFiles]
    );

    function handleRestoreIntakeSystemFile(): void {
        if (selectedIntakeSystemFile) {
            dispatch(restoreIntakeSystemFile(selectedIntakeSystemFile));
            setShowConfirmRestoreModal(false);
        }
    }

    const showDeleteIntakeCustomerFileCallback = useCallback(
        (params: IntakeCustomerFile) => {
            if (params === undefined) {
                return;
            } else {
                setIntakeCustomerFile(params);
                setShowConfirmDeleteIntakeCustomerFileModal(true);
            }
        },
        [intakeCustomerFiles, intakeCustomerFile]
    );

    const showAddScheduleForm = useCallback(() => {
        dispatch(clearWorkflowJobSchedule());
        setShowScheduleForm(true);
    }, [dispatch]);

    const showEditScheduleForm = useCallback(
        (schedule: WorkflowJobSchedule) => {
            dispatch(selectWorkflowJobSchedule(schedule));
            setShowScheduleForm(true);
        },
        [dispatch]
    );

    const showEditJobForm = useCallback(
        (workflowJob: WorkflowJob) => {
            dispatch(selectWorkflowJob(workflowJob));
            setShowJobForm(true);
        },
        [dispatch]
    );

    const showEditIntakeCustomerFileModal = useCallback(
        (data: any) => {
            setIntakeSystemFile(data.intakeSystemFile);
            setIntakeCustomerFile(data.intakeCustomerFile);
            setWorkflowJob(data.workflowJob);
            setIsReject(false);
            setShowIntakeCustomerFileModal(true);
        },
        [dispatch]
    );

    const showRejectIntakeCustomerFileModal = useCallback(
        (data: any) => {
            setIntakeSystemFile(data.intakeSystemFile);
            setIntakeCustomerFile(data.intakeCustomerFile);
            setWorkflowJob(data.workflowJob);
            setIsReject(true);
            setShowIntakeCustomerFileModal(true);
        },
        [dispatch]
    );

    const onCreateRequestClick = useCallback(
        (schedule: WorkflowJobSchedule) => {
            dispatch(selectWorkflowJobSchedule(schedule));
            setShowCreateIntakeFileRequestModal(true);
        },
        [dispatch]
    );

    const handleCreateIntakeFileRequest = useCallback(() => {
        if (!selectedSchedule) return;
        const { customerId, systemId } = selectedSchedule;
        const name = `${findTenant(customerId)?.name}-${findSystem(systemId)?.name}-${new Date().toISOString()}`;
        dispatch(
            createWorkflowJob({
                id: 0,
                name,
                workflowId: 1,
                systemId,
                comments: "",
                customerId,
                statusId: workflowStatuses.find((ws: any) => ws.name === "Ready for Intake")?.id ?? 1,
                isScheduled: false,
                isActive: true,
            })
        );
        setShowCreateIntakeFileRequestModal(false);
    }, [dispatch, findSystem, findTenant, selectedSchedule]);

    const columnDefs: ColDef[] = [
        {
            headerName: "File Name",
            field: "displayFileName",
        },
        {
            headerName: "Uploaded File Name",
            field: "uploadedFileName",
        },
        {
            headerName: "File Type",
            valueGetter: ({ data }: ValueGetterParams) =>
                fileTypes.find((ft) => ft.id === data.intakeSystemFile.fileTypeId)?.name,
        },
        {
            headerName: "Status",
            valueGetter: ({ data }: ValueGetterParams) =>
                fileStatuses.find((fs) => fs.id === data.intakeCustomerFile?.fileStatusId)?.name,
        },
        {
            headerName: "Rejections Reason",
            field: "fileStatusMessage",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "IntakeCustomerFile",
                showEdit: showEditIntakeCustomerFileModal,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit IntakeCustomerFile",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: RejectButtonRender,
            cellRendererParams: (data: any) => ({
                entityName: "IntakeCustomerFile",
                showEdit: showRejectIntakeCustomerFileModal,
                isActive: data.data.intakeCustomerFile ?? false,
            }),
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Reject IntakeCustomerFile",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: SmallSquareDeleteButton,
            cellRendererParams: (data: any) => {
                return {
                    entityNameSingular: "IntakeCustomerFile",
                    onClick: () => showDeleteIntakeCustomerFileCallback(data.data.intakeCustomerFile),
                    isActive: data.data.intakeCustomerFile ?? false,
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Delete Intake Customer File",
        },
    ];

    return (
        <>
            <Row>
                <Col className="col-md-4">
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label visuallyHidden>Search</Form.Label>
                            <Form.Control type="email" placeholder="Search" />
                        </Form.Group>
                    </Form>
                </Col>
                <Col></Col>
                <Col className="col-auto">
                    <Button className="float-right clear finosec-button-info" onClick={showAddScheduleForm}>
                        Create Customer Schedule
                    </Button>

                    <Form className="float-right clear">
                        <Form.Check
                            inline
                            id="ready-for-intake"
                            label="Ready For Intake"
                            checked={workflowJobFilterState.readyForIntake}
                            onChange={() => dispatchWorkflowJobFilter("readyForIntake")}
                        />
                        <Form.Check
                            inline
                            id="file-uploaded"
                            label="File Uploaded"
                            checked={workflowJobFilterState.processing}
                            onChange={() => dispatchWorkflowJobFilter("processing")}
                        />
                        <Form.Check
                            inline
                            id="rejected"
                            label="Rejected"
                            checked={workflowJobFilterState.rejected}
                            onChange={() => dispatchWorkflowJobFilter("rejected")}
                        />
                        <Form.Check
                            inline
                            id="complete"
                            label="Complete"
                            checked={workflowJobFilterState.completed}
                            onChange={() => dispatchWorkflowJobFilter("completed")}
                        />
                    </Form>
                </Col>
            </Row>
            {Object.entries(groupedSchedules)?.map(([partnerKey, schedules]) => {
                const customerGroup = groupBy(schedules, (s) => s.customerId);
                return (
                    <React.Fragment key={partnerKey}>
                        <PageHeader>
                            <h2>{findTenant(+partnerKey)?.name}</h2>
                        </PageHeader>
                        {Object.entries(customerGroup)?.map(([customerKey, customerSchedules]) => {
                            const filteredJobCount = getFilteredJobCount(+customerKey);
                            return (
                                <Accordion key={customerKey} defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            <Col className="col-10">
                                                <h2>{findTenant(+customerKey)?.name}</h2>
                                            </Col>
                                            <Col className="col-2">
                                                <dl>
                                                    <dt>Intake in Progress: </dt>
                                                    <dd>
                                                        {filteredJobCount} of {customerSchedules.length}
                                                    </dd>
                                                </dl>
                                            </Col>
                                        </Accordion.Header>
                                        <Accordion.Body className="margin-0 padding-0">
                                            {customerSchedules.map((schedule) => {
                                                const activeJobs = getActiveJobs(schedule);
                                                return (
                                                    <Accordion
                                                        key={`schedule-${schedule.id}`}
                                                        defaultActiveKey={!!activeJobs.length ? "0" : undefined}
                                                    >
                                                        <Card>
                                                            <Card.Header>
                                                                <Row>
                                                                    <Col className="col-sm-11">
                                                                        <Row>
                                                                            <Col className="col-md-2">
                                                                                <h3>
                                                                                    {
                                                                                        findSystem(schedule.systemId)
                                                                                            ?.name
                                                                                    }
                                                                                </h3>
                                                                                <dl>
                                                                                    <dt className="hidden">Status: </dt>
                                                                                    <dd>
                                                                                        <span
                                                                                            className={
                                                                                                "status-pill status-" +
                                                                                                workflowStatuses
                                                                                                    .find(
                                                                                                        (ws) =>
                                                                                                            ws.id ===
                                                                                                            activeJobs[0]
                                                                                                                ?.statusId
                                                                                                    )
                                                                                                    ?.name.replace(
                                                                                                        /\s/g,
                                                                                                        ""
                                                                                                    )
                                                                                                    .toLowerCase()
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                workflowStatuses.find(
                                                                                                    (ws) =>
                                                                                                        ws.id ===
                                                                                                        activeJobs[0]
                                                                                                            ?.statusId
                                                                                                )?.name
                                                                                            }
                                                                                        </span>
                                                                                    </dd>
                                                                                </dl>
                                                                            </Col>
                                                                            <Col className="col-md-6 padding-top-half">
                                                                                <dl>
                                                                                    <dt>Frequency: </dt>
                                                                                    <dd className="mx-2">
                                                                                        {
                                                                                            findFrequency(
                                                                                                schedule.frequencyId
                                                                                            )?.name
                                                                                        }
                                                                                    </dd>
                                                                                </dl>
                                                                                <dl>
                                                                                    <dt>Request Date: </dt>
                                                                                    <dd className="mx-2">
                                                                                        {activeJobs[0]?.createdOn
                                                                                            ? new Date(
                                                                                                  activeJobs[0]?.createdOn
                                                                                              ).toLocaleDateString()
                                                                                            : ""}
                                                                                    </dd>
                                                                                </dl>
                                                                                <dl>
                                                                                    <dt>Next Run Date:</dt>
                                                                                    <dd className="mx-2">
                                                                                        {new Date(
                                                                                            schedule.nextDate
                                                                                        ).toLocaleDateString()}
                                                                                    </dd>
                                                                                </dl>
                                                                            </Col>
                                                                            <Col className="col-md-4 padding-top-half">
                                                                                <ButtonGroup>
                                                                                    <Button
                                                                                        className="add-intakeSystemFile-button finosec-button-info"
                                                                                        onClick={() =>
                                                                                            onCreateRequestClick(
                                                                                                schedule
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Create Request
                                                                                    </Button>
                                                                                    <Button
                                                                                        className="add-intakeSystemFile-button finosec-button-info"
                                                                                        onClick={() =>
                                                                                            showEditScheduleForm(
                                                                                                schedule
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Update Schedule
                                                                                    </Button>
                                                                                    <Button
                                                                                        className={
                                                                                            !activeJobs[0]?.comments
                                                                                                ? "add-intakeSystemFile-button finosec-button-info"
                                                                                                : undefined
                                                                                        }
                                                                                        variant={
                                                                                            activeJobs[0]?.comments
                                                                                                ? `warning`
                                                                                                : undefined
                                                                                        }
                                                                                        onClick={() =>
                                                                                            showEditJobForm(
                                                                                                activeJobs[0]
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Update Status
                                                                                    </Button>
                                                                                </ButtonGroup>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Card.Header className="col-1 accordion-card">
                                                                        <ContextAwareToggle eventKey="0"></ContextAwareToggle>
                                                                    </Card.Header>
                                                                </Row>
                                                            </Card.Header>
                                                            {activeJobs.length > 0 && (
                                                                <Accordion.Collapse eventKey="0">
                                                                    <div className="ag-theme-alpine">
                                                                        <KeysysGrid
                                                                            getRows={() =>
                                                                                intakeSystemFiles
                                                                                    .filter(
                                                                                        (f) =>
                                                                                            f.systemId ===
                                                                                                schedule.systemId &&
                                                                                            (f.customerId ===
                                                                                                schedule.customerId ||
                                                                                                f.customerId === 0)
                                                                                    )
                                                                                    .map((intakeSystemFile) =>
                                                                                        getIntakeCustomerFileData(
                                                                                            activeJobs,
                                                                                            intakeSystemFile
                                                                                        )
                                                                                    )
                                                                            }
                                                                            columnDefs={columnDefs}
                                                                        />
                                                                    </div>
                                                                </Accordion.Collapse>
                                                            )}
                                                        </Card>
                                                    </Accordion>
                                                );
                                            })}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            );
                        })}
                    </React.Fragment>
                );
            })}

            {!!selectedSchedule && (
                <ConfirmationModal
                    id="confirmCreateIntakeFileRequestModal"
                    show={showCreateIntakeFileRequestModal}
                    title="Create Intake File Request"
                    message={`Are you sure you want to create an Intake File Request for ${
                        findTenant(selectedSchedule.customerId)?.name
                    } for ${findSystem(selectedSchedule.systemId)?.name}?`}
                    onCloseButtonClick={() => setShowCreateIntakeFileRequestModal(false)}
                    onOkButtonClick={handleCreateIntakeFileRequest}
                    theme={"primary"}
                />
            )}
            {!!intakeCustomerFile && (
                <ConfirmationModal
                    id="confirmDeleteFileModal"
                    show={showConfirmDeleteIntakeCustomerFileModal}
                    title="Delete File?"
                    message={"Do you really want to delete " + intakeCustomerFile.uploadFileName + " ?"}
                    onCloseButtonClick={() => setShowConfirmDeleteIntakeCustomerFileModal(false)}
                    onOkButtonClick={() => handleDeleteIntakeCustomerFile(intakeCustomerFile)}
                    theme={"danger"}
                />
            )}
            {showScheduleForm && <WorkflowJobScheduleForm onClose={() => setShowScheduleForm(false)} />}
            {showJobForm && <WorkflowJobForm onClose={() => setShowJobForm(false)} />}
            {showIntakeCustomerFileModal && intakeSystemFile && workflowJob && (
                <IntakeCustomerFileForm
                    onClose={() => setShowIntakeCustomerFileModal(false)}
                    onSave={() => setShowIntakeCustomerFileModal(false)}
                    intakeSystemFile={intakeSystemFile}
                    intakeCustomerFile={intakeCustomerFile}
                    workflowJob={workflowJob}
                    isRejectModal={isReject}
                />
            )}
        </>
    );
}
