import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row, Table, ListGroup } from "react-bootstrap";
import { Notes } from "../notes/Notes";
import _ from "lodash";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { Chart } from "react-google-charts";
import { CustomerImportFile } from "../../models/CustomerImportFile";
import { CustomerReport } from "../../models/CustomerReport";
import { FlagToggle } from "./FlagToggle";
import { ReviewToggle } from "./ReviewToggle";
import { ColDef } from "ag-grid-community";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { selectAttributesForReportList } from "../../selectors/ReportAttributeSelectors";
import {
    getCustomerReportReviews,
    submitGeneratePdfRequest,
} from "../../actions/customerReport/CustomerReportActionCreator";
import { getReportAttributes } from "../../actions/reportAttributes/ReportAttributeActionCreator";
import { getSubReportAttributes } from "../../actions/subReportAttributes/SubReportAttributeActionCreator";
import { useNotes } from "../../hooks/notesHook";
import finosecPDFLogo from "../../images/User Access Reporting Powered By FINOSEC.png";
import { useCustomerImportJson } from "../../hooks/customerImportJsonHook";
import "./printModal.scss";
import { GeneratePdfRequest } from "../../models/GeneratePdfRequest";
import { useParams } from "react-router-dom";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import { getCustomerReportAttributes } from "../../actions/customerReportAttributes/CustomerReportAttributeActionCreator";

interface ICustomerSystemPrintModal {
    tableData: any[];
    columnDefs: any[];
    notes: any[];
    systemName?: string;
    totalStatistics: any;
    customerImportFiles: any[];
    periodName?: string;
    customerReports?: CustomerReport[];
    buttonTitle?: string;
    tenantName?: string;
}

interface ICustomerReportPrintProps {
    customerReport: CustomerReport;
    onFinishLoading?: () => void;
}

export const CustomerReportPrint = (props: ICustomerReportPrintProps) => {
    const { customerReport, onFinishLoading } = props;
    const dispatch = useDispatch();

    const customerImportFile = useSelector((state: AppState) =>
        state.customerImportFileState.customerImportFiles.find((cif) => cif.id === customerReport?.customerImportFileId)
    );
    const customerReportReviewState = useSelector((state: AppState) => state.customerReportState.customerReportReviews);
    const customerReportReviews = useMemo(
        () =>
            customerImportFile
                ? customerReportReviewState.filter((crr) => crr.customerImportFileId === customerImportFile.id)
                : [],
        [customerImportFile, customerReportReviewState]
    );

    const customerImport = useSelector((state: AppState) =>
        state.customerImportState.customerImports.find((ci) => ci.id === customerReport.customerImportId)
    );

    const attributes = useSelector((state: AppState) =>
        selectAttributesForReportList(state, {
            reportId: customerReport.reportId,
            subReportId: customerReport.subReportId,
            customerReportId: customerReport.id,
            isCurrent: customerImport?.isCurrent,
        })
    );

    const { notes } = useNotes(
        (note) =>
            note.finosecSystemId === customerImport?.finosecSystemId &&
            note.customerImportId === customerReport.customerImportId &&
            note.customerReportId === customerReport.id
    );
    const { tableData, headers, loading } = useCustomerImportJson(
        customerImportFile?.id,
        attributes,
        customerReportReviews,
        customerReport?.subReportFilter,
        customerReport?.filter,
        customerImport?.isCurrent
    );

    useEffect(() => {
        if (customerImportFile) {
            dispatch(getCustomerReportReviews(customerReport.id, customerImportFile.id));
        }
        if (customerReport.reportId && customerReport.subReportId) {
            dispatch(getReportAttributes(customerReport.reportId));
            dispatch(getSubReportAttributes(customerReport.subReportId));
            dispatch(getCustomerReportAttributes(customerReport.id));
        }
    }, []);

    useEffect(() => {
        if (!loading && onFinishLoading) {
            onFinishLoading();
        }
    }, [loading]);

    let columnDefs = _.orderBy(headers, (h) => h.attribute?.ordinal)?.map(({ header, valueIndex }) => {
        let colDef: ColDef = {
            headerName: header,
            field: header,
            filterValueGetter: (params) => {
                return params.data?.Values[valueIndex];
            },
            valueGetter: (params) => {
                return params.data?.Values[valueIndex];
            },
        };
        return colDef;
    });
    columnDefs = [
        ...columnDefs,
        {
            headerName: "Flag",
            cellRendererFramework: (data: any) => {
                return data.data?.customerReportReview ? (
                    <>
                        <span style={{ marginRight: "5px" }}>
                            <FlagToggle
                                displayOnly={true}
                                customerReportReview={data?.data?.customerReportReview}
                                rowNo={data?.data?.Row}
                            />
                        </span>
                        <ReviewToggle
                            isReviewComplete={customerReport.isReviewComplete}
                            displayOnly={true}
                            customerReportReview={data?.data?.customerReportReview}
                            rowNo={data?.data?.Row}
                        />
                    </>
                ) : (
                    ""
                );
            },
        },
    ];
    return (
        <div style={{ pageBreakBefore: "always" }}>
            <h6>Subreport: {customerReport.subReportName}</h6>
            <Notes noScroll={true} displayOnly={true} notes={notes} />
            <div>
                <table
                    style={{
                        width: "100%",
                        tableLayout: "fixed",
                        overflowWrap: "break-word",
                    }}
                >
                    <thead>
                        <tr>
                            {columnDefs.map((cd) => (
                                <th key={cd.headerName} style={{ backgroundColor: "grey" }}>
                                    {cd.headerName}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((td, idx) => (
                            <tr key={idx}>
                                {columnDefs.map((cd) => (
                                    <td key={`${idx}-${cd.headerName}`}>
                                        {cd.cellRendererFramework
                                            ? cd.cellRendererFramework({ data: td })
                                            : cd.valueGetter
                                            ? (cd.valueGetter as any)({ data: td })
                                            : td[cd.field as any]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default function CustomerSystemPrintModal(props: ICustomerSystemPrintModal) {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [pdfConfirmModalShow, setPdfConfirmModalShow] = useState<boolean>(false);
    const [pdfRequestModalShow, setPdfRequestModalShow] = useState<boolean>(false);
    const [finishedLoadingCount, setFinishedLoadingCount] = useState<number>(0);
    const {
        tableData,
        notes,
        systemName,
        columnDefs,
        totalStatistics,
        customerImportFiles,
        customerReports,
        buttonTitle,
        periodName,
        tenantName,
    } = props;
    const ref = useRef();
    const dispatch = useDispatch();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    let { customerImportId } = useParams<{ customerImportId: any }>();

    const ReferingComponent = forwardRef((props: any, ref: any) => {
        return (
            <div>
                <table
                    style={{
                        width: "100%",
                        tableLayout: "fixed",
                        overflowWrap: "break-word",
                    }}
                >
                    <thead>
                        <tr>
                            {columnDefs.map((cd) => (
                                <th key={cd.headerName} style={{ backgroundColor: "grey" }}>
                                    {cd.headerName}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((td, idx) => (
                            <tr key={idx}>
                                {columnDefs.map((cd) => (
                                    <td
                                        key={`${idx}-${cd.headerName}`}
                                        style={cd.headerName === "Name" ? { textAlign: "start" } : {}}
                                    >
                                        {cd.cellRendererFramework
                                            ? cd.cellRendererFramework({ data: td })
                                            : cd.valueGetter
                                            ? (cd.valueGetter as any)({ data: td })
                                            : td[cd.field as any]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    });

    const printPdf = () => {
        if (ref?.current) {
            const options = {
                margin: [2.5, 2.5, 10, 2.5],
                filename: `${tenantName}_${systemName}_${periodName}`,
                jsPDF: { orientation: "landscape" },
                pagebreak: { before: ".pdfPageBreak", avoid: "tr" },
            };
            html2pdf()
                .from(ref?.current)
                .set(options)
                .toPdf()
                .get("pdf")
                .then(function (pdf: any) {
                    var totalPages = pdf.internal.getNumberOfPages();
                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.setTextColor(150);
                        pdf.text(
                            `Page ${i} of ${totalPages}`,
                            pdf.internal.pageSize.getWidth() - 25,
                            pdf.internal.pageSize.getHeight() - 5
                        );
                    }
                })
                .save();
        }
    };

    const generatePdf = () => {
        const timeStamp = moment().format("MMDDYYYYHHmmss");
        const request: GeneratePdfRequest = {
            tenantId: decodedAccessToken.tenant_id,
            summaryPdfUrl: `${window.location.origin}/pdf/tenant/${decodedAccessToken.tenant_id}/customer-import-summary/${customerImportId}`,
            subReportUrls:
                customerReports
                    ?.filter((cr) => cr.isSubReport)
                    .map(
                        (cr) =>
                            `${window.location.origin}/pdf/tenant/${decodedAccessToken.tenant_id}/customer-import/${customerImportId}/customer-report/${cr.id}`
                    ) || [],
            fileName: `${tenantName}_${systemName}_${periodName}_${timeStamp}.pdf`,
            customerImportName: `${periodName}`,
            email: decodedAccessToken.email,
            userId: decodedAccessToken.userId,
        };
        dispatch(submitGeneratePdfRequest(request, false));
        setModalShow(false);
        setPdfRequestModalShow(false);
        setPdfConfirmModalShow(true);
    };

    const toggleModal = (value: boolean) => {
        setFinishedLoadingCount(0);
        setModalShow(value);
    };

    const fullSystemReportTitle = "Download Full System Report";

    return (
        <>
            <Button
                type="submit"
                className="finosec-button-info"
                onClick={() =>
                    buttonTitle === fullSystemReportTitle ? setPdfRequestModalShow(true) : toggleModal(true)
                }
            >
                {buttonTitle}
            </Button>
            <VerticallyCenteredModal
                id="completeSystemImportModal"
                show={modalShow}
                title="Download PDF"
                disableUpdate={finishedLoadingCount !== customerReports?.length && !!customerReports}
                showButtonsInHeader={{
                    okButtonIcon: "fas fa-file-download",
                }}
                closeButtonText={"NO - CANCEL"}
                okButtonText={"Download PDF"}
                dynamicHeight={true}
                okButtonComponent={
                    <Button
                        disabled={finishedLoadingCount !== customerReports?.length && !!customerReports}
                        size={"lg"}
                        className="finosec-button-info"
                        onClick={() => printPdf()}
                    >
                        Download PDF
                    </Button>
                }
                showSaveButton={true}
                deleteButtonText={"Deactivate"}
                onCloseButtonClick={() => toggleModal(false)}
                onOkButtonClick={() => printPdf()}
                type={"success"}
            >
                <div ref={ref as any} className="print-modal-content">
                    <Row>
                        <Col>
                            <h6>System: {systemName}</h6>
                            <h6>Customer: {tenantName}</h6>
                            <h6>Time Frame: {periodName}</h6>
                            <h6>Export Date: {new Date().toLocaleDateString()}</h6>
                            <h6>User Access Reviews Approved By:</h6>
                            <h6>Approved Date: </h6>
                        </Col>
                        <Col>
                            <img
                                src={finosecPDFLogo}
                                alt="Finosec logo"
                                style={{ width: "50%", marginLeft: "260px" }}
                            />
                        </Col>
                    </Row>
                    <Row className="ps-3 pe-3">
                        <Col>
                            <Table hover>
                                <thead style={{ borderStyle: "hidden" }}>
                                    <tr>
                                        <td colSpan={2}>User Access Reports Summary</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Commenting out and not taking out completely in case they do want this back
                                    <tr>
                                        <td>Total User Account Changes:</td>
                                        <td>{totalStatistics.totalUserChanges}</td>
                                    </tr> */}
                                    <tr>
                                        <td className="tr-user-access-reports-summary-left">Report Count:</td>
                                        <td className="tr-user-access-reports-summary-right">
                                            {totalStatistics.totalSubReports}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tr-user-access-reports-summary-left">Reports Reviewed:</td>
                                        <td className="tr-user-access-reports-summary-right">
                                            {totalStatistics.totalReviewedReports}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tr-user-access-reports-summary-left">
                                            Reports Remaining to Review:
                                        </td>
                                        <td className="tr-user-access-reports-summary-right">
                                            {totalStatistics.totalNotReviewedReports}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="tr-user-access-reports-summary-left">Percent Complete:</td>
                                        <td className="tr-user-access-reports-summary-right">
                                            {Math.round(
                                                (totalStatistics.totalReviewedReports /
                                                    totalStatistics.totalSubReports) *
                                                    100
                                            )}
                                            %
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col>
                            <Table hover>
                                <thead style={{ borderStyle: "hidden" }}>
                                    <tr>
                                        <td>Review Progress</td>
                                    </tr>
                                </thead>
                            </Table>
                            <Row className="justify-content-center">
                                <Chart
                                    width={"500px"}
                                    height={"300px"}
                                    chartType="PieChart"
                                    loader={<div>Loading Chart</div>}
                                    data={[
                                        ["Task", ""],
                                        ["Total Reports Reviewed", totalStatistics.totalReviewedReports],
                                        ["Total Reports Not Reviewed", totalStatistics.totalNotReviewedReports],
                                    ]}
                                    options={{
                                        backgroundColor: "transparent",
                                        slices: {
                                            0: { color: "#079247" },
                                            1: { color: "#CCCCCC", textStyle: { color: "#000000" } },
                                        },
                                    }}
                                    rootProps={{ "data-testid": "1" }}
                                />
                            </Row>
                        </Col>
                    </Row>
                    <h6>
                        System Report Generated:
                        <ListGroup variant="flush">
                            {customerImportFiles.map((cif: CustomerImportFile & { reportName: string }) => (
                                <ListGroup.Item key={cif.id}>
                                    <span style={{ fontSize: "14px" }}>
                                        {cif.reportName} ({moment(cif.exportDate).format("MM/DD/YYYY")})
                                    </span>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </h6>
                    <div className="pdfPageBreak" />
                    <p className="title-bar ps-3 pt-2 pb-2"> </p>
                    <Notes noScroll={true} displayOnly={true} notes={notes} />
                    <ReferingComponent
                        getRowId={(data: any) => data.Row}
                        data={tableData}
                        columnDefs={columnDefs}
                        style={{ fontSize: "3rem" }}
                    />
                    <div className="pdfPageBreak" />
                    {customerReports?.map((cr) => (
                        <div key={cr.id}>
                            <div>
                                <p className="title-bar ps-3 pt-2 pb-2"> </p>
                                <div style={{ margin: "5px" }} key={cr.id}>
                                    <CustomerReportPrint
                                        onFinishLoading={() => setFinishedLoadingCount(finishedLoadingCount + 1)}
                                        customerReport={cr}
                                    />
                                </div>
                            </div>
                            <div className="pdfPageBreak" />
                        </div>
                    ))}
                </div>
            </VerticallyCenteredModal>
            <VerticallyCenteredModal
                id="PDF Confirmation Modal"
                title="PDF Request Submitted"
                show={pdfConfirmModalShow}
                showSaveButton={false}
                closeButtonText="OK"
                onCloseButtonClick={() => setPdfConfirmModalShow(false)}
                onOkButtonClick={() => setPdfConfirmModalShow(false)}
                okButtonText="OK"
                type="success"
                dynamicHeight={true}
                size="sm"
            >
                <p>You will receive an email when the PDF is available for download in Document Exchange.</p>
            </VerticallyCenteredModal>
            <ConfirmationModal
                id="Confirm PDF Request"
                title="Confirm PDF Request"
                show={pdfRequestModalShow}
                theme="primary"
                message="Do you want to generate a Full System Report PDF? If yes, you will receive an email when the PDF is available in Document Exchange."
                onCloseButtonClick={() => setPdfRequestModalShow(false)}
                onOkButtonClick={generatePdf}
            />
        </>
    );
}
