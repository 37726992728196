import { inject, injectable } from "inversify";
import { Store } from "redux";
import { createPatch } from "rfc6902";
import { showToast } from "../../actions/ToastActionCreators";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { EDIT_EMPLOYEE_SYSTEM_EXCEPTION_SUCCESS } from "../../constants/ToastConstants";
import { TYPES } from "../../dependencyInjection/Types";
import { EmployeeSystemException } from "../../models/EmployeeSystemException";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IEmployeeSystemExceptionService } from "./IEmployeeSystemExceptionService";

@injectable()
export class EmployeeSystemExceptionService implements IEmployeeSystemExceptionService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getEmployeeSystemExceptionsByTenantId(tenantId: number): Promise<void | EmployeeSystemException[]> {
        return this.httpClient
            .get<EmployeeSystemException[]>(`/employee-system-exception/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving employee system exceptionss. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
    editEmployeeSystemException(
        employeeSystemException: EmployeeSystemException,
        editedEmployeeSystemException: EmployeeSystemException
    ): Promise<void | EmployeeSystemException> {
        const operations = createPatch(employeeSystemException, editedEmployeeSystemException);
        return this.httpClient
            .patch<EmployeeSystemException>(`/employee-system-exception/${employeeSystemException.id}`, operations)
            .then((patchedEmployeeSystemException) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_EMPLOYEE_SYSTEM_EXCEPTION_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Employee System updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedEmployeeSystemException;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing employee system exception. Please try again. If the problem continues, contact your administrator`
                )
            );
    }
}
