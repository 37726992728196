import {
    CLEAR_SELECTED_USER,
    ClearSelectedUserAction,
    INACTIVE_USERS_FETCHED,
    InactiveUsersFetchedAction,
    SELECT_USER,
    SelectUserAction,
    USER_CREATED,
    USER_DELETED,
    USER_EDITED,
    USER_RESTORED,
    UserCreatedAction,
    UserDeletedAction,
    UserEditedAction,
    UserRestoredAction,
    USERS_FETCHED,
    UsersFetchedAction,
} from "./UserActions";
import { User } from "../../models/User";
import { UserService } from "../../services/UserService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { LoggedInAction, LOGGED_IN } from "../AuthenticateActions";
import jwt_decode from "jwt-decode";

export function clearSelectedUser(): ClearSelectedUserAction {
    return {
        type: CLEAR_SELECTED_USER,
    };
}

export function refreshUser(refresh_token: string): KeysysThunkAction {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const response = await userService.refresh(refresh_token);
        if (response) {
            const jsonResponse = JSON.parse(response);
            const decodedAccessToken = jwt_decode(jsonResponse.access_token) as any;
            dispatch<LoggedInAction>({
                accessToken: jsonResponse.access_token,
                refreshToken: jsonResponse.refresh_token ?? "",
                expiresIn: jsonResponse.expires_in,
                type: LOGGED_IN,
                decodedAccessToken: {
                    ...decodedAccessToken,
                    tenant_id: +decodedAccessToken.tenant_id,
                    userId: parseInt((decodedAccessToken.user_id as any) as string),
                    email: decodedAccessToken.email,
                    reports: decodedAccessToken.reports as string,
                },
            });
        }
    };
}

export function createUser(user: User): KeysysThunkAction {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const createdUser = await userService.addUser(user);
        if (createdUser) {
            dispatch<UserCreatedAction>({
                type: USER_CREATED,
                user: createdUser,
            });
        }
    };
}

export function deleteUser(user: User): KeysysThunkAction {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const deletedUser = await userService.deleteUser(user);
        if (deletedUser) {
            dispatch<UserDeletedAction>({
                type: USER_DELETED,
                user: deletedUser,
            });
        }
    };
}

export function editUser(originalUser: User, newUser: User): KeysysThunkAction {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const patchedUser = await userService.editUser(originalUser, newUser);
        if (patchedUser) {
            dispatch<UserEditedAction>({
                type: USER_EDITED,
                originalUser: originalUser,
                newUser: patchedUser,
            });
        }
    };
}

export function getInactiveUsers(): KeysysThunkAction<void, InactiveUsersFetchedAction> {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const users = await userService.getInactiveUsers();
        if (users) {
            dispatch<InactiveUsersFetchedAction>({
                type: INACTIVE_USERS_FETCHED,
                inactiveUsers: users,
            });
        }
    };
}

export function getUsers(): KeysysThunkAction<void, UsersFetchedAction> {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const users = await userService.getUsers();
        if (users) {
            dispatch<UsersFetchedAction>({
                type: USERS_FETCHED,
                users: users,
            });
        }
    };
}

export function restoreUser(user: User): KeysysThunkAction {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const restoredUser = await userService.restoreUser(user);
        if (restoredUser) {
            dispatch<UserRestoredAction>({
                type: USER_RESTORED,
                user: restoredUser,
            });
        }
    };
}

export function selectUser(user: User): SelectUserAction {
    return {
        type: SELECT_USER,
        user: user,
    };
}
