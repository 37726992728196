import { useState, useEffect, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logOut, unimpersonateUser } from "../../actions/AuthenticationActionCreators";
import { refreshUser } from "../../actions/users/UserActionCreators";
import { AppState } from "../../state/AppState";
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs";

export default function SessionTimeout() {
    const dispatch = useDispatch();
    const { decodedAccessToken, refreshToken, expiresIn: inactivityThreshold } = useSelector(
        (state: AppState) => state.authenticationState
    );
    const { impersonator } = useSelector((state: AppState) => state.userState);
    const [currentTime, setCurrentTime] = useState<number>(new Date().getTime());
    const [timeOfLastActivity, setTimeOfLastActivity] = useState<number>(new Date().getTime());
    const [showModal, setShowModal] = useState<boolean>(false);
    const autoRenewTokenThreshold = 5 * 60; // 5 minutes
    const showModalThreshold = 2 * 60; // 2 minutes

    const handleOnAction = () => setTimeOfLastActivity(new Date().getTime());

    useIdleTimer({
        onAction: handleOnAction,
        events: ["keydown", "wheel", "mousedown", "touchstart"],
        element: document,
    });

    const secondsRemainingOnToken = useMemo((): number => {
        const result = Math.floor(decodedAccessToken.exp - currentTime / 1000);
        return result < 0 ? 0 : result;
    }, [currentTime, decodedAccessToken.exp]);

    const secondsSinceLastActivity = useMemo((): number => {
        const result = Math.floor((currentTime - timeOfLastActivity) / 1000);
        return result < 0 ? 0 : result;
    }, [currentTime, timeOfLastActivity]);

    const secondsRemainingBeforeAutoLogout = useMemo((): number => {
        const result = inactivityThreshold - secondsSinceLastActivity;
        return result < 0 ? 0 : result;
    }, [inactivityThreshold, secondsSinceLastActivity]);

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date().getTime()), 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (secondsRemainingOnToken <= autoRenewTokenThreshold) {
            dispatch(refreshUser(refreshToken));
        }
    }, [autoRenewTokenThreshold, dispatch, refreshToken, secondsRemainingOnToken]);

    useEffect(() => {
        if (secondsRemainingBeforeAutoLogout <= 0) {
            if (!!impersonator) {
                dispatch(unimpersonateUser());
            }
            dispatch(logOut());
        }
    }, [dispatch, impersonator, secondsRemainingBeforeAutoLogout]);

    useEffect(() => {
        if (secondsSinceLastActivity >= inactivityThreshold - showModalThreshold) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [inactivityThreshold, secondsSinceLastActivity, showModalThreshold]);

    const timeRemaining = useMemo((): string => {
        const minutes = Math.floor(secondsRemainingBeforeAutoLogout / 60);
        const seconds = secondsRemainingBeforeAutoLogout - minutes * 60;
        const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;
        return minutes > 0 ? `${minutes}:${secondsDisplay}` : `${seconds} seconds`;
    }, [secondsRemainingBeforeAutoLogout]);

    const message = useMemo((): string => {
        return secondsRemainingBeforeAutoLogout > 0
            ? `Your session will expire in ${timeRemaining}.`
            : "Your session has expired. Please login again.";
    }, [secondsRemainingBeforeAutoLogout, timeRemaining]);

    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={showModal}
                onHide={() => setShowModal(false)}
                style={{ zIndex: 99999 }}
            >
                <Modal.Header>Session Expiring Soon</Modal.Header>
                <Modal.Body>
                    <p>{message}</p>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button
                        variant="danger"
                        onClick={() => {
                            if (!!impersonator) {
                                dispatch(unimpersonateUser());
                            }
                            dispatch(logOut());
                        }}
                    >
                        Log Out
                    </Button>
                    <Button className="finosec-button-info">Stay Logged In</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
