export const SHOW_MODAL = "ADD_ACTIVE_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export interface ShowModalAction {
    type: typeof SHOW_MODAL;
    modal: string;
    modalValues?: any;
}

export interface HideModalAction {
    type: typeof HIDE_MODAL;
    modal: string;
}

export type ModalActionTypes = ShowModalAction | HideModalAction;
