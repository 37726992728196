import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_EMPLOYEE_CONFIRM_MODAL } from "../../constants/ModalConstants";
import { AppState } from "../../state/AppState";
import { showModal } from "../../actions/ModalActionCreators";
import { useCallback, useEffect, useState } from "react";
import { getEmployeesByTenantId } from "../../actions/employee/EmployeeActionCreators";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import CreatableSelect from "react-select/creatable";
import EditButtonRender from "../grid/EditButtonRender";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";
import { Employee } from "../../models/Employee";
import EmployeeForm from "./EmployeeForm";
import { Link } from "react-router-dom";
import PageHeader from "../header/PageHeader";
import MergeButtonRender from "../grid/MergeButtonRender";
import EmployeeMergeForm from "./EmployeeMergeForm";

export default function EmployeeList() {
    const dispatch = useDispatch();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const tenants = useSelector((state: AppState) => state.tenantState.tenants.filter((t) => t.parentTenantId != null));
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showMergeModal, setShowMergeModal] = useState<boolean>(false);
    const [clickedCustomerFilter, setCustomerFilterClick] = useState<boolean>(false);
    const employees = useSelector((state: AppState) => state.employeeState.employees);
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
    const [textSearch, setSearchText] = useState<string>("");
    const [pageSize, setPageSize] = useState<number>(15);

    const { impersonator } = useSelector((state: AppState) => state.userState);
    const isReadOnlyCustomer = decodedAccessToken.roles === "ReadOnlyCustomer" || !!impersonator;

    const selectCustomerId = (tenantId: number | null) => {
        setSelectedCustomerId(tenantId);
        setCustomerFilterClick(false);
    };

    useEffect(() => {
        if (!isGlobalAdmin) {
            setSelectedCustomerId(decodedAccessToken.tenant_id);
            setCustomerFilterClick(true);
            dispatch(getEmployeesByTenantId(decodedAccessToken.tenant_id));
        }
    }, [decodedAccessToken.tenant_id, dispatch, isGlobalAdmin]);

    const filterEmployees = () => {
        setCustomerFilterClick(true);
        if (selectedCustomerId) {
            dispatch(getEmployeesByTenantId(selectedCustomerId));
        }
    };

    function showEmployeeUploadForm(): void {
        dispatch(showModal(UPLOAD_EMPLOYEE_CONFIRM_MODAL, { formType: "upload" }));
    }

    const employeesRef = useSelectorAsRef((state: AppState) => state.employeeState.employees);

    const showEditCallback = useCallback(
        (params: Employee) => {
            showEditForm(employeesRef.current.find((employee: Employee) => employee.id === params.id)!);
        },
        [employeesRef]
    );

    function showEditForm(employee: Employee): void {
        setSelectedEmployee(employee);
        setShowEditModal(true);
    }

    const showMergeCallback = useCallback(
        (params: Employee) => {
            showMergeForm(employeesRef.current.find((employee: Employee) => employee.id === params.id)!);
        },
        [employeesRef]
    );

    function showMergeForm(employee: Employee): void {
        setSelectedEmployee(employee);
        setShowMergeModal(true);
    }

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const columDefs: ColDef[] = [
        {
            headerName: "First Name",
            field: "firstName",
            cellRendererFramework: (data: any) => data.data?.firstName,
            tooltipField: "First name",
        },
        {
            headerName: "Last Name",
            field: "lastName",
            cellRendererFramework: (data: any) => data.data?.lastName,
            tooltipField: "Last Name",
        },
        {
            headerName: "Alternate First Name",
            field: "nickName",
            cellRendererFramework: (data: any) => data.data?.nickName,
            tooltipField: "Alternate First Name",
        },
        {
            headerName: "Email",
            field: "email",
            cellRendererFramework: (data: any) => data.data?.email,
            tooltipField: "Email",
        },
        {
            headerName: "Title",
            field: "title",
            cellRendererFramework: (data: any) => data.data?.title,
            tooltipField: "Title",
        },
        {
            headerName: "Department",
            field: "department",
            cellRendererFramework: (data: any) => data.data?.department,
            tooltipField: "Department",
        },
        {
            headerName: "Manager",
            field: "manager",
            cellRendererFramework: (data: any) => data.data?.manager,
            tooltipField: "Manager",
        },
        {
            headerName: "Hire Date",
            field: "hireDate",
            cellRendererFramework: (data: any) => data.data?.hireDate,
            tooltipField: "Hire Date",
        },
        {
            headerName: "Termination Date",
            field: "terminationDate",
            cellRendererFramework: (data: any) => data.data?.terminationDate,
            tooltipField: "Termination Date",
        },
        {
            headerName: "Core IDs",
            field: "CoreIds",
            cellRendererFramework: (data: any) => {
                return data.data?.employeeCoreIds.map((ec: any) => ec.coreId).join(",");
            },
            tooltipField: "Core IDs",
        },
        /*{
            headerName: "IDs",
            field: "Identities",
            cellRendererFramework: (data: any) => {
                return data.data?.employeeIdentities.map((ec: any) => ec.identity).join(",");
            },
            tooltipField: "Identities",
        },*/
        {
            headerName: "",
            field: "edit",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "Employee",
                showEdit: showEditCallback,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Employee",
        },
        {
            headerName: "",
            field: "merge",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: MergeButtonRender,
            cellRendererParams: {
                entityName: "Employee",
                showEdit: showMergeCallback,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Merge Employee",
        },
    ];

    return (
        <>
            {!inIframe() && (
                <Row className="padding-0">
                    <Col md={12}>
                        <PageHeader>
                            <h2>Employee Management</h2>
                        </PageHeader>
                    </Col>
                </Row>
            )}
            <br />
            <Row className={"mb-1 justify-content-end"}></Row>

            <Row className="keysys-action-bar padding-0">
                {isGlobalAdmin && (
                    <>
                        <Col lg={3} sm={3}>
                            <CreatableSelect
                                onChange={(v) => selectCustomerId(v?.value || null)}
                                defaultValue={null}
                                placeholder={"Select Customer"}
                                options={[
                                    { value: null, label: "Select Customer" },
                                    ...tenants.map((pt) => ({
                                        value: pt.id,
                                        label: pt.name,
                                    })),
                                ]}
                            />
                        </Col>
                        <Col md={1}>
                            <Button onClick={() => filterEmployees()} className="finosec-button-info">
                                Select
                            </Button>
                        </Col>
                    </>
                )}
                <Col md={6}></Col>
                <Col md={2}>
                    {isGlobalAdmin && (
                        <>
                            <Link to="EmployeeUpload.xlsx" target="_blank" download title="Download template">
                                <i className="fas fa-download" />
                            </Link>
                            &nbsp;Download Template&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                className={"employee-upload-button finosec-button-info"}
                                data-testid={"employee-upload-button"}
                                onClick={() => showEmployeeUploadForm()}
                            >
                                Upload Employee List
                            </Button>
                        </>
                    )}
                </Col>
            </Row>
            <br />
            {selectedCustomerId && clickedCustomerFilter && (
                <div className="ps-3 pe-3">
                    <div style={{ marginBottom: "10px" }}>
                        <Row className={"ag-theme-alpine"}>
                            <Col md={4}>
                                <input
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Col>
                            <Col md={2}>
                                <CreatableSelect
                                    style={{ width: "40%" }}
                                    onChange={(v) => setPageSize(v?.value || 15)}
                                    defaultValue={null}
                                    placeholder={"Page Size"}
                                    options={[
                                        { value: 10, label: "10" },
                                        { value: 15, label: "15" },
                                        { value: 25, label: "25" },
                                        { value: 50, label: "50" },
                                        { value: 100, label: "100" },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row className={"ag-theme-alpine"}>
                            <Col>
                                <KeysysGrid
                                    getRows={() => employees}
                                    columnDefs={
                                        isReadOnlyCustomer
                                            ? columDefs.filter((c) => c.field !== "edit" && c.field !== "merge")
                                            : columDefs
                                    }
                                    pagination={true}
                                    pageSize={pageSize}
                                    searchText={textSearch}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            )}

            {showEditModal && (
                <EmployeeForm type="edit" employee={selectedEmployee} onClose={() => setShowEditModal(false)} />
            )}

            {showMergeModal && (
                <EmployeeMergeForm type="edit" employee={selectedEmployee} onClose={() => setShowMergeModal(false)} />
            )}
        </>
    );
}
