import { FinosecSystem } from "../../models/FinosecSystem";

export const FINOSEC_SYSTEMS_FETCHED = "FINOSEC_SYSTEMS_FETCHED";
export const CREATE_FINOSEC_SYSTEM = "CREATE_FINOSEC_SYSTEM";

export interface FinosecSystemsFetchedAction{
    type: typeof FINOSEC_SYSTEMS_FETCHED;
    finosecSystems: FinosecSystem[];
}

export interface CreateFinosecSystemAction{
    type: typeof CREATE_FINOSEC_SYSTEM;
    finosecSystem: FinosecSystem;
}

export type FinosecSystemActions = 
    FinosecSystemsFetchedAction
    | CreateFinosecSystemAction;
