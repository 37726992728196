import { MutableRefObject, useEffect, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import useForceUpdate from "use-force-update";

export function useSelectorAsRef<S, T>(
    selector: (state: S) => T,
    equality?: (left: T, right: any) => boolean
): MutableRefObject<T> {
    const forceUpdate = useForceUpdate();
    const value = useSelector<S, T>(selector, equality ?? shallowEqual);
    const valueRef = useRef<T>(value);
    useEffect(() => {
        valueRef.current = value;
        forceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(value)]);
    return valueRef;
}
