/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { AppState } from "../../state/AppState";
import { useDispatch, useSelector } from "react-redux";
import { getTenants } from "../../actions/tenants/TenantActionCreators";
import { Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Tenant } from "../../models/Tenant";
import EditButtonRender from "../grid/EditButtonRender";
import { Identifiable } from "../../models/Identifiable";
import { Ownable } from "../../models/Ownable";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";
import PartnerForm from "./forms/PartnerForm";
import CustomerForm from "./forms/CustomerForm";

export interface TenantWithOwner extends Identifiable, Ownable {
    name: string;
    logoUri: string;
    isActive: Boolean;
    owner?: string;
}

export default function Tenants() {
    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);

    const history = useHistory();
    const dispatch = useDispatch();
    const tenants = useSelector((state: AppState) => state.tenantState.tenants);

    const [showPartnerModal, setShowPartnerModal] = useState<boolean>(false);
    const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
    const [partnerFormType, setPartnerFormType] = useState<"add" | "edit">("add");
    const [customerFormType, setCustomerFormType] = useState<"add" | "edit">("add");
    const [selectedTenant, setSelectedTenant] = useState<Tenant | undefined>(undefined);

    useEffect(() => {
        if (decodedAccessToken.roles !== "GlobalAdmin" && decodedAccessToken.roles !== "PartnerAdmin") {
            history.push("/");
        }
        if (!tenants || tenants.length === 0) {
            dispatch(getTenants());
        }
    }, []);

    function showAddPartnerForm(): void {
        setSelectedTenant(undefined);
        setPartnerFormType("add");
        setShowPartnerModal(true);
    }
    function showAddCustomerForm(): void {
        setSelectedTenant(undefined);
        setCustomerFormType("add");
        setShowCustomerModal(true);
    }

    function showEditForm(tenant: Tenant): void {
        setSelectedTenant(tenant);
        if (tenant.parentTenantId === undefined || tenant.parentTenantId === null) {
            setPartnerFormType("edit");
            setShowPartnerModal(true);
        } else if (tenant.parentTenantId === 1) {
            setPartnerFormType("edit");
            setShowPartnerModal(true);
        } else {
            setCustomerFormType("edit");
            setShowCustomerModal(true);
        }
    }

    function getStatus(status: boolean): string {
        return status ? "Active" : "Inactive";
    }

    function getName(tenant: Tenant): string {
        return tenant.parentTenantId ? tenant.name : `${tenant.name} (Partner)`;
    }

    const tenantsRef = useSelectorAsRef((state: AppState) => state.tenantState.tenants);

    const showEditCallback = useCallback(
        (params: TenantWithOwner) =>
            showEditForm(tenantsRef.current.find((tenant: Tenant) => tenant.id === params.id)!),
        [tenantsRef.current]
    );

    const columnDefs: ColDef[] = [
        {
            headerName: "Partner/Customer",
            valueGetter: (data) => getName(data.data),
            tooltipField: "Partner/Customer",
        },
        {
            headerName: "# of Users",
            field: "usersCount",
            cellRenderer: (data: any) => data.value,
            tooltipField: "User Count",
        },
        {
            headerName: "Status",
            field: "isActive",
            cellRenderer: (data: any) => getStatus(data.value),
            tooltipField: "Is Active",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "Tenant",
                showEdit: showEditCallback,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Tenant",
        },
    ];

    return (
        <div>
            <Row className={"mb-1 justify-content-end"}>
                <Col lg={2} className={"add-partner-customer-button-wrapper"}>
                    {decodedAccessToken.roles === "GlobalAdmin" && (
                        <Button
                            className={"add-partner-button finosec-button-info"}
                            onClick={() => showAddPartnerForm()}
                            data-testid={"add-partner-button"}
                        >
                            Add Partner
                        </Button>
                    )}
                    <Button
                        className={"add-customer-button ml-1 finosec-button-info"}
                        onClick={() => showAddCustomerForm()}
                        data-testid={"add-customer-button"}
                    >
                        Add Customer
                    </Button>
                </Col>
            </Row>

            <Row className={"tenants-grid-container"}>
                <Col className={"h-100 p-0"}>
                    <div className="ag-theme-alpine">
                        <KeysysGrid getRows={() => tenants} columnDefs={columnDefs} />
                    </div>
                </Col>
            </Row>

            {showPartnerModal && (
                <PartnerForm
                    type={partnerFormType}
                    tenant={selectedTenant}
                    onClose={() => setShowPartnerModal(false)}
                />
            )}
            {showCustomerModal && (
                <CustomerForm
                    type={customerFormType}
                    tenant={selectedTenant}
                    onClose={() => setShowCustomerModal(false)}
                />
            )}
        </div>
    );
}
