import {
    ICustomerDocumentService,
    ICustomerDocumentsStatisticsService,
} from "../../services/customerDocument/ICustomerDocumentService";
import {
    CUSTOMER_DOCUMENTS_FETCHED,
    CustomerDocumentsFetchedAction,
    CREATE_CUSTOMER_DOCUMENT,
    CreateCustomerDocumentAction,
    DELETE_CUSTOMER_DOCUMENT,
    DeleteCustomerDocumentAction,
    EDIT_CUSTOMER_DOCUMENT,
    FetchCustomerDocumentAction,
    FETCH_CUSTOMER_DOCUMENT,
    ProgramReferencesFetchedAction,
    PROGRAM_REFERENCES_FETCHED,
    CustomerDocumentsStatisticsAction,
    CUSTOMER_DOCUMENTS_STATISTICS,
    DownloadCustomerDocumentAction,
    DOWNLOAD_CUSTOMER_DOCUMENT,
    PushDocumentToMonarchAction,
    PUSH_DOCUMENT_TO_MONARCH,
    PRESIGNED_URL_FETCHED,
    PreSignedUrlFetchedAction,
} from "./CustomerDocumentActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { CustomerDocument } from "../../models/CustomerDocument";
import { PreSignedUrlModel } from "../../models/PreSignedUrlModel";

export function getCustomerDocuments(): KeysysThunkAction<void, CustomerDocumentsFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const customerDocuments = await customerDocumentService.getCustomerDocuments();

        if (customerDocuments) {
            dispatch<CustomerDocumentsFetchedAction>({
                type: CUSTOMER_DOCUMENTS_FETCHED,
                customerDocuments: customerDocuments,
            });
        }
    };
}

export function getCustomerDocumentsStatistics(): KeysysThunkAction<void, CustomerDocumentsStatisticsAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentStatisticsService = container.get<ICustomerDocumentsStatisticsService>(
            TYPES.CustomerDocumentsStatisticsService
        );
        const customerDocumentsStatistics = await customerDocumentStatisticsService.getCustomerDocumentsStatistics();

        if (customerDocumentsStatistics) {
            dispatch<CustomerDocumentsStatisticsAction>({
                type: CUSTOMER_DOCUMENTS_STATISTICS,
                customerDocumentStatistics: customerDocumentsStatistics,
            });
        }
    };
}

export function getProgramReferences(): KeysysThunkAction<void, ProgramReferencesFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const programReferences = await customerDocumentService.getProgramReferences();

        if (programReferences) {
            dispatch<ProgramReferencesFetchedAction>({
                type: PROGRAM_REFERENCES_FETCHED,
                programReferences,
            });
        }
    };
}

export function getPreSignedDownloadUrl(
    preSignedUrlModel: PreSignedUrlModel
): KeysysThunkAction<void, PreSignedUrlFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const presignedUrl = await customerDocumentService.getPreSignedDownloadUrl(preSignedUrlModel);

        if (presignedUrl) {
            dispatch<PreSignedUrlFetchedAction>({
                type: PRESIGNED_URL_FETCHED,
                presignedUrl,
            });
        }
    };
}

export function getPreSignedUploadUrl(
    preSignedUrlModel: PreSignedUrlModel
): KeysysThunkAction<void, PreSignedUrlFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const presignedUrl = await customerDocumentService.getPreSignedUploadUrl(preSignedUrlModel);

        if (presignedUrl) {
            dispatch<PreSignedUrlFetchedAction>({
                type: PRESIGNED_URL_FETCHED,
                presignedUrl,
            });
        }
    };
}

export function getCustomerDocumentById(
    customerDocumentId: number
): KeysysThunkAction<void, FetchCustomerDocumentAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const customerDocument = await customerDocumentService.getCustomerDocumentById(customerDocumentId);

        if (customerDocument) {
            dispatch<FetchCustomerDocumentAction>({
                type: FETCH_CUSTOMER_DOCUMENT,
                customerDocument: customerDocument,
            });
        }
    };
}

export function createCustomerDocument(
    customerDocument: FormData
): KeysysThunkAction<void, CreateCustomerDocumentAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const createdCustomerDocument = await customerDocumentService.createCustomerDocument(customerDocument);
        if (createdCustomerDocument) {
            dispatch<CreateCustomerDocumentAction>({
                type: CREATE_CUSTOMER_DOCUMENT,
                customerDocument: createdCustomerDocument,
            });
            dispatch(getCustomerDocumentsStatistics());
        }
        return !!createCustomerDocument;
    };
}

export function createCustomerDocumentWithoutUpload(
    customerDocument: Partial<CustomerDocument>
): KeysysThunkAction<void, CreateCustomerDocumentAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const createdCustomerDocument = await customerDocumentService.createCustomerDocumentWithoutUpload(
            customerDocument
        );
        if (createdCustomerDocument) {
            dispatch<CreateCustomerDocumentAction>({
                type: CREATE_CUSTOMER_DOCUMENT,
                customerDocument: createdCustomerDocument,
            });
            dispatch(getCustomerDocumentsStatistics());
        }
        return !!createCustomerDocument;
    };
}

export function editCustomerDocument(
    originalCustomerDocument: CustomerDocument,
    newCustomerDocument: CustomerDocument
): KeysysThunkAction<void, CreateCustomerDocumentAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const editedCustomerDocument = await customerDocumentService.editCustomerDocument(
            originalCustomerDocument,
            newCustomerDocument
        );
        if (editedCustomerDocument) {
            dispatch<any>({
                type: EDIT_CUSTOMER_DOCUMENT,
                originalCustomerDocument: originalCustomerDocument,
                newCustomerDocument: editedCustomerDocument,
            });
        }
    };
}

export function deleteCustomerDocument(
    customerDocument: CustomerDocument
): KeysysThunkAction<void, DeleteCustomerDocumentAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const deletedCustomerDocument = await customerDocumentService.deleteCustomerDocument(customerDocument);
        if (deletedCustomerDocument) {
            dispatch<DeleteCustomerDocumentAction>({
                type: DELETE_CUSTOMER_DOCUMENT,
                customerDocument: deletedCustomerDocument,
            });
        }
    };
}

export function downloadCustomerDocument(
    fileId: string,
    fileName: string
): KeysysThunkAction<void, DownloadCustomerDocumentAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        const customerDocument = await customerDocumentService.downloadCustomerDocument(fileId, fileName);
        if (customerDocument) {
            dispatch<DownloadCustomerDocumentAction>({
                type: DOWNLOAD_CUSTOMER_DOCUMENT,
                customerDocument: customerDocument,
            });
        }
    };
}

export function pushDocumentToMonarch(
    awsFileId: string,
    fileName: string,
    tenantId: number
): KeysysThunkAction<void, PushDocumentToMonarchAction> {
    return async (dispatch, getState, container) => {
        const customerDocumentService = container.get<ICustomerDocumentService>(TYPES.CustomerDocumentService);
        await customerDocumentService.pushDocumentToMonarch(awsFileId, fileName, tenantId);
        dispatch<PushDocumentToMonarchAction>({
            type: PUSH_DOCUMENT_TO_MONARCH,
            customerDocument: null,
        });
    };
}
