import React from "react";
import Button from "react-bootstrap/Button";
import { SmallSquareEditButtonProps } from "./SmallSquareEditButtonProps";

export default function SmallSquareEditButton({ isActive = true, ...props }: SmallSquareEditButtonProps) {
    function getButtonText(): string {
        return `Edit ${props.entityNameSingular}`;
    }

    return (
        <Button
            aria-label={getButtonText()}
            size={"sm"}
            onClick={props.onClick}
            className={props.className}
            variant={props.variant ?? "transparent"}
            disabled={!isActive}
        >
            <i className={"fas fa-edit"} />
        </Button>
    );
}
