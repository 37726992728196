import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { forwardRef, useCallback, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Notes } from "../notes/Notes";
import { FlagToggle } from "./FlagToggle";
import { ReviewToggle } from "./ReviewToggle";
import _ from "lodash";
import { ColDef } from "ag-grid-community";
import "./customerPrintModal.scss";
import "./printModal.scss";
import finosecPDFLogo from "../../images/User Access Reporting Powered By FINOSEC.png";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { GeneratePdfRequest } from "../../models/GeneratePdfRequest";
import { submitGeneratePdfRequest } from "../../actions/customerReport/CustomerReportActionCreator";

interface ICustomeReportPrintModal {
    tableData: any[];
    headers: any[];
    notes: any[];
    periodName?: string;
    systemName?: string;
    tenantName?: string;
    subReportName?: string;
    importDate?: string;
    isReviewComplete?: boolean;
    isReadOnly: boolean;
    loading: boolean;
}

export default function CustomerReportPrintModal(props: ICustomeReportPrintModal) {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [pdfConfirmModalShow, setPdfConfirmModalShow] = useState<boolean>(false);
    const [pdfDownloadModalShow, setPdfDownloadModalShow] = useState<boolean>(false);
    const {
        tableData,
        headers,
        notes,
        periodName,
        systemName,
        tenantName,
        subReportName,
        isReviewComplete,
        isReadOnly,
        loading,
    } = props;
    const ref = useRef();
    const [isPrinting, setIsPrinting] = useState<boolean>(false);
    const isBigDataSet = tableData.length > 1000;

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);

    const columnDefs = useMemo(() => {
        let defs = _.orderBy(headers, (h) => h.attribute?.ordinal)?.map(({ header, attribute, valueIndex }) => {
            let colDef: ColDef = {
                headerName: header,
                field: header,
                hide: attribute?.subReport?.hide,
                filterValueGetter: (params) => {
                    return params.data?.Values[valueIndex];
                },
                valueGetter: (params) => {
                    return params.data?.Values[valueIndex];
                },
            };
            return colDef;
        });

        if (!isReadOnly) {
            defs = [
                ...defs,
                {
                    headerName: "Flag",
                    cellRendererFramework: (data: any) => {
                        return data.data?.customerReportReview ? (
                            <FlagToggle
                                displayOnly={true}
                                customerReportReview={data?.data?.customerReportReview}
                                rowNo={data?.data?.Row}
                            />
                        ) : (
                            ""
                        );
                    },
                },
                {
                    headerName: "Review",
                    cellRendererFramework: (data: any) => {
                        return data.data?.customerReportReview ? (
                            <ReviewToggle
                                isReviewComplete={isReviewComplete}
                                displayOnly={true}
                                customerReportReview={data?.data?.customerReportReview}
                                rowNo={data?.data?.Row}
                                jsonIsReviewed={data?.data?.Review}
                                jsonReviewedBy={data?.data?.By}
                                jsonReviewedSR={data?.data?.BySR}
                            />
                        ) : (
                            ""
                        );
                    },
                },
            ];
        }
        return defs;
    }, [headers, isReadOnly, isReviewComplete]);

    const ReferingComponent = forwardRef((props: any, ref: any) => {
        return (
            <div>
                <table
                    style={{
                        width: "100%",
                        tableLayout: "fixed",
                        overflowWrap: "break-word",
                    }}
                >
                    <thead>
                        <tr>
                            {columnDefs
                                .filter((cd) => !cd.hide)
                                .map((cd) => (
                                    <th key={cd.headerName} style={{ backgroundColor: "grey" }}>
                                        {cd.headerName}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((td, idx) => (
                            <tr key={idx}>
                                {columnDefs
                                    .filter((cd) => !cd.hide)
                                    .map((cd) => (
                                        <td key={`${idx}-${cd.headerName}`}>
                                            {cd.cellRendererFramework
                                                ? cd.cellRendererFramework({ data: td })
                                                : cd.valueGetter
                                                ? (cd.valueGetter as any)({ data: td })
                                                : td[cd.field as any]}
                                        </td>
                                    ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    });

    const handlePrint = useCallback(() => {
        setIsPrinting(true);
        printPdf();
    }, []);

    const DeterminePrintModal = useCallback(() => {
        if (isBigDataSet) {
            setPdfDownloadModalShow(true);
            setModalShow(false);
        } else {
            setModalShow(true);
            setPdfDownloadModalShow(false);
        }
    }, [isBigDataSet]);

    const printPdf = useCallback(
        _.debounce(() => {
            setIsPrinting(true);
            if (ref?.current) {
                const options = {
                    margin: [2.5, 2.5, 10, 2.5],
                    filename: `${tenantName}_${systemName}_${periodName}_${subReportName}`,
                    jsPDF: { orientation: "landscape" },
                    pagebreak: { before: ".pdfPageBreak", avoid: "tr" },
                };
                html2pdf()
                    .from(ref?.current)
                    .set(options)
                    .toPdf()
                    .get("pdf")
                    .then(function (pdf: any) {
                        var totalPages = pdf.internal.getNumberOfPages();
                        for (var i = 1; i <= totalPages; i++) {
                            pdf.setPage(i);
                            pdf.setFontSize(10);
                            pdf.setTextColor(150);
                            pdf.text(
                                `Page ${i} of ${totalPages}`,
                                pdf.internal.pageSize.getWidth() - 25,
                                pdf.internal.pageSize.getHeight() - 5
                            );
                        }
                    })
                    .save();
            }
            setIsPrinting(false);
        }, 500),
        [ref, tenantName, systemName, periodName, subReportName]
    );

    const generatePdf = () => {
        const currentURL = window.location.href;
        const subReportURL =
            `${window.location.origin}/pdf/tenant/${decodedAccessToken.tenant_id}/` + currentURL.split("uar/")[1];

        const timeStamp = moment().format("MMDDYYYYHHmmss");
        const request: GeneratePdfRequest = {
            tenantId: decodedAccessToken.tenant_id,
            subReportUrls: [subReportURL],
            fileName: `${tenantName}_${systemName}_${periodName}_${subReportName}_${timeStamp}.pdf`,
            customerImportName: `${subReportName}`,
            email: decodedAccessToken.email,
            userId: decodedAccessToken.userId,
            summaryPdfUrl: "",
        };
        dispatch(submitGeneratePdfRequest(request, true));
        setModalShow(false);
        setPdfDownloadModalShow(false);
    };

    // const generatePdf = () => {
    //     const timeStamp = moment().format("MMDDYYYYHHmmss");
    //     const request: GeneratePdfRequest = {
    //         tenantId: decodedAccessToken.tenant_id,
    //         pdfUrl: `${window.location.origin}/pdf/tenant/${decodedAccessToken.tenant_id}/customer-import/${customerImportId}/customer-report/${customerReportId}`,
    //         fileName: `${tenantName}_${systemName}_${periodName}_${subReportName}_${timeStamp}.pdf`,
    //         customerImportName: `${periodName}`,
    //     };
    //     dispatch(submitGeneratePdfRequest(request));
    //     setModalShow(false);
    //     setPdfConfirmModalShow(true);
    // };

    return (
        <>
            <Button onClick={() => DeterminePrintModal()} className="finosec-button-info" disabled={loading}>
                Download PDF
            </Button>
            <VerticallyCenteredModal
                id="completeSystemImportModal"
                show={modalShow}
                showButtonsInHeader={{
                    okButtonIcon: isPrinting ? "fas fa-spinner" : "fas fa-file-download",
                }}
                title="Download PDF"
                closeButtonText={"NO - CANCEL"}
                okButtonText={""}
                dynamicHeight={true}
                okButtonComponent={
                    <Button
                        size={"lg"}
                        className="finosec-button-info"
                        onClick={handlePrint}
                        disabled={loading || isPrinting}
                    >
                        {isPrinting ? "Downloading..." : "Download PDF"}
                    </Button>
                }
                showSaveButton={true}
                deleteButtonText={"Deactivate"}
                onCloseButtonClick={() => setModalShow(false)}
                onOkButtonClick={handlePrint}
                type={"success"}
            >
                <div ref={ref as any} className="print-modal-content">
                    <Row>
                        <Col>
                            <h6>Subreport: {subReportName}</h6>
                            <h6>System: {systemName}</h6>
                            <h6>Customer: {tenantName}</h6>
                            <h6>Period: {periodName}</h6>
                        </Col>
                        <Col>
                            <img src={finosecPDFLogo} style={{ width: "50%", marginLeft: "260px" }} />
                        </Col>
                    </Row>

                    <Notes noScroll={true} displayOnly={true} notes={notes} />
                    <ReferingComponent getRowId={(data: any) => data.Row} data={tableData} columnDefs={columnDefs} />
                </div>
            </VerticallyCenteredModal>
            <VerticallyCenteredModal
                id="PDF Confirmation Modal"
                title="PDF Request Submitted"
                show={pdfConfirmModalShow}
                showSaveButton={false}
                closeButtonText="OK"
                onCloseButtonClick={() => setPdfConfirmModalShow(false)}
                onOkButtonClick={() => setPdfConfirmModalShow(false)}
                okButtonText="OK"
                type="success"
                dynamicHeight={true}
                size="sm"
            >
                <p>You will receive an email when the PDF is available for download in Document Exchange.</p>
            </VerticallyCenteredModal>
            <ConfirmationModal
                id="Confirm PDF Request"
                title="Confirm PDF Request"
                show={pdfDownloadModalShow}
                theme="primary"
                message="Do you want to generate a Report PDF? If yes, you will receive an email when the PDF is available in Document Exchange."
                onCloseButtonClick={() => setPdfDownloadModalShow(false)}
                onOkButtonClick={generatePdf}
            />
        </>
    );
}
