import { WorkflowJobSchedule } from "../../models/WorkflowJobSchedule";

export const WORKFLOW_JOB_SCHEDULES_FETCHED = "WORKFLOW_JOB_SCHEDULES_FETCHED";
export const WORKFLOW_JOB_SCHEDULE_FETCHED = "WORKFLOW_JOB_SCHEDULE_FETCHED";
export const WORKFLOW_JOB_SCHEDULE_CREATED = "WORKFLOW_JOB_SCHEDULE_CREATED";
export const WORKFLOW_JOB_SCHEDULE_EDITED = "WORKFLOW_JOB_SCHEDULE_EDITED";
export const WORKFLOW_JOB_SCHEDULE_DELETED = "WORKFLOW_JOB_SCHEDULE_DELETED";
export const WORKFLOW_JOB_SCHEDULE_SELECTED = "WORKFLOW_JOB_SCHEDULE_SELECTED";
export const WORKFLOW_JOB_SCHEDULE_CLEARED = "WORKFLOW_JOB_SCHEDULE_CLEARED";

export interface WorkflowJobSchedulesFetched {
    type: typeof WORKFLOW_JOB_SCHEDULES_FETCHED;
    workflowJobSchedules: WorkflowJobSchedule[];
}

export interface WorkflowJobScheduleFetched {
    type: typeof WORKFLOW_JOB_SCHEDULE_FETCHED;
    workflowJobSchedule: WorkflowJobSchedule;
}

export interface WorkflowJobScheduleCreated {
    type: typeof WORKFLOW_JOB_SCHEDULE_CREATED;
    workflowJobSchedule: WorkflowJobSchedule;
}

export interface WorkflowJobScheduleEdited {
    type: typeof WORKFLOW_JOB_SCHEDULE_EDITED;
    workflowJobSchedule: WorkflowJobSchedule;
    newWorkflowJobSchedule: WorkflowJobSchedule;
}

export interface WorkflowJobScheduleDeleted {
    type: typeof WORKFLOW_JOB_SCHEDULE_DELETED;
    workflowJobSchedule: WorkflowJobSchedule;
}

export interface WorkflowJobScheduleSelected {
    type: typeof WORKFLOW_JOB_SCHEDULE_SELECTED;
    workflowJobSchedule: WorkflowJobSchedule;
}

export interface WorkflowJobScheduleCleared {
    type: typeof WORKFLOW_JOB_SCHEDULE_CLEARED;
}

export type WorkflowJobScheduleActions =
    | WorkflowJobSchedulesFetched
    | WorkflowJobScheduleFetched
    | WorkflowJobScheduleCreated
    | WorkflowJobScheduleEdited
    | WorkflowJobScheduleDeleted
    | WorkflowJobScheduleSelected
    | WorkflowJobScheduleCleared;
