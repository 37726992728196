import { TYPES } from "../../dependencyInjection/Types";
import { IWorkflowFrequencyService } from "../../services/workflowFrequency/IWorkflowFrequencyService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { WorkflowFrequenciesFetched, WORKFLOW_FREQUENCIES_FETCHED } from "./WorkflowFrequencyActions";

export function getWorkflowFrequencies(): KeysysThunkAction<void, WorkflowFrequenciesFetched> {
    return async (dispatch, getState, container) => {
        const workflowFrequencyService = container.get<IWorkflowFrequencyService>(TYPES.WorkflowFrequencyService);
        const workflowFrequencies = await workflowFrequencyService.getWorkflowFrequencies();
        if (workflowFrequencies) {
            dispatch<WorkflowFrequenciesFetched>({
                type: WORKFLOW_FREQUENCIES_FETCHED,
                workflowFrequencies,
            });
        }
    };
}
