import { TYPES } from "../../dependencyInjection/Types";
import { IEmployeeCoreIdService } from "../../services/employeeCoreId/IEmployeeCoreIdService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    EmployeeCoreIdsFetchedAction,
    EMPLOYEE_CORE_IDS_FETCHED,
    UpdateEmployeeCoreIdsAction,
    UPDATE_EMPLOYEE_CORE_IDS,
} from "./EmployeeCoreIdAction";

export function getEmployeeCoreIds(employeeId: number): KeysysThunkAction<void, EmployeeCoreIdsFetchedAction> {
    return async (dispatch, getState, container) => {
        const employeeCoreIdService = container.get<IEmployeeCoreIdService>(TYPES.EmployeeCoreIdService);
        const employeeCoreIds = await employeeCoreIdService.getEmployeeCoreIds(employeeId);

        if (employeeCoreIds) {
            dispatch<EmployeeCoreIdsFetchedAction>({
                type: EMPLOYEE_CORE_IDS_FETCHED,
                employeeCoreIds: employeeCoreIds,
            });
        }
    };
}

export function updateEmployeeCoreIds(
    employeeId: number,
    coreIds: string[]
): KeysysThunkAction<void, UpdateEmployeeCoreIdsAction> {
    return async (dispatch, getState, container) => {
        const employeeCoreIdService = container.get<IEmployeeCoreIdService>(TYPES.EmployeeCoreIdService);
        const updatedCoredIds = await employeeCoreIdService.updateEmployeeCoreIds(employeeId, coreIds);
        if (updatedCoredIds) {
            dispatch<UpdateEmployeeCoreIdsAction>({
                type: UPDATE_EMPLOYEE_CORE_IDS,
                employeeCoreIds: updatedCoredIds,
            });
        }
        return !!updatedCoredIds;
    };
}
