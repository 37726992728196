import React from "react";
import { Identifiable } from "../../models/Identifiable";
import SmallSquareMergeButton from "../buttons/SmallSquareMergeButton/SmallSquareMergeButton";

interface MergeButtonRenderProps {
    data: Identifiable;
    entityName: string;
    showEdit: (identifiable: Identifiable) => void;
    isActive?: boolean;
}

export default function MergeButtonRender(props: MergeButtonRenderProps) {
    return (
        <SmallSquareMergeButton
            key={`merge-${props.data.id}`}
            entityNameSingular={props.entityName}
            onClick={() => props.showEdit(props.data)}
            isActive={props.isActive}
        />
    );
}
