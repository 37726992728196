import { FileType } from "../../models/FileType";

export const FILE_TYPES_FETCHED = "FILE_TYPES_FETCHED";

export interface FileTypesFetchedAction {
    type: typeof FILE_TYPES_FETCHED;
    fileTypes: FileType[];
}

export type FileTypeActions = FileTypesFetchedAction;
