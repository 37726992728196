/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createFinosecSystem, getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { getReportAttributes } from "../actions/reportAttributes/ReportAttributeActionCreator";
import { createReport, editReport, getReportById } from "../actions/reports/ReportActionCreators";
import { FinosecSystem } from "../models/FinosecSystem";
import { Report } from "../models/Report";
import { selectReportById } from "../selectors/ReportSelectors";
import { AppState } from "../state/AppState";

export const useMasterListForm = () => {
    let params = useParams<{ id: any }>();
    const dispatch = useDispatch();
    const masterReportInitValues: any = {
        name: "",
        system: "",
        columns: [],
    };
    const report = useSelector((state: AppState) => selectReportById(state, +params.id));

    const systems = useSelector<AppState, FinosecSystem[]>((s) =>
        s.finosecSystemState?.finosecSystems?.sort((a, b) => a.name.localeCompare(b.name))
    );

    useEffect(() => {
        if (params.id && !report) {
            dispatch(getReportById(params.id));
        }
        if (params.id && (!report?.columns || report.columns.length === 0)) {
            dispatch(getReportAttributes(params.id));
        }
        if (!systems || systems.length === 0) {
            dispatch(getFinosecSystems());
        }
    }, []);

    const onSave = (values: any) => {
        let reportToAdd = {
            name: values.name,
            finosecSystemId: values.finosecSystemId,
            isSystemAccessReport: values.isSystemAccessReport,
            id: params?.id || 0,
        } as Report;
        if (params?.id && report) {
            const newAttributes = values.columns.filter(
                (c: any) => !report?.columns.map((rc: any) => rc.id).includes(c.id)
            );
            const updatedAttributes = values.columns.filter((c: any) =>
                report?.columns.map((rc: any) => rc.id).includes(c.id)
            );
            const deletedAttributes = report?.columns.filter(
                (rc: any) => !values.columns.map((vc: any) => vc.id).includes(rc.id)
            );
            const originalReport = {
                name: report.name,
                finosecSystemId: report.finosecSystemId,
                isSystemAccessReport: report.isSystemAccessReport,
                id: +params.id,
            } as Report;
            dispatch(
                editReport(
                    originalReport,
                    reportToAdd,
                    newAttributes.map((na: any) => {
                        const { id, ...attribute } = na;
                        return attribute;
                    }),
                    updatedAttributes,
                    report.columns,
                    deletedAttributes
                )
            );
        } else {
            dispatch(
                createReport(
                    reportToAdd,
                    values.columns.map((vc: any) => {
                        const { id, ...attributes } = vc;
                        return attributes;
                    })
                )
            );
        }
    };

    const onCreateSystem = (system: string) => {
        dispatch(createFinosecSystem({ name: system } as FinosecSystem));
    };

    return {
        report: report
            ? report
            : {
                  ...masterReportInitValues,
                  columns: [{ attribute: "", ordinal: 1, id: 0 }],
              },
        systems,
        onSave,
        onCreateSystem,
        isEdit: !!params?.id,
    };
};
