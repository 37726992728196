import { TYPES } from "../../dependencyInjection/Types";
import { FinosecSystem } from "../../models/FinosecSystem";
import { IFinosecSystemService } from "../../services/finosecSystemService/IFinosecSystemService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { 
    CreateFinosecSystemAction, 
    CREATE_FINOSEC_SYSTEM, 
    FinosecSystemsFetchedAction, 
    FINOSEC_SYSTEMS_FETCHED 
} from "./FinosecSystemAction";

export function getFinosecSystems(): KeysysThunkAction<void, FinosecSystemsFetchedAction> {
    return async (dispatch, getState, container) => {
        const systemService = container.get<IFinosecSystemService>(TYPES.FinosecSystemService);
        const systems = await systemService.getFinosecSystems();

        if (systems) {
            dispatch<FinosecSystemsFetchedAction>({
                type: FINOSEC_SYSTEMS_FETCHED,
                finosecSystems: systems,
            });
        }
    };
}


export function createFinosecSystem(system: FinosecSystem): KeysysThunkAction<void, CreateFinosecSystemAction> {
    return async (dispatch, getState, container) => {
        const systemService = container.get<IFinosecSystemService>(TYPES.FinosecSystemService);
        const createdSystem = await systemService.createFinosecSystem(system);
        if (createdSystem) {
            dispatch<CreateFinosecSystemAction>({
                type: CREATE_FINOSEC_SYSTEM,
                finosecSystem: createdSystem,
            });
        }
        return !!createdSystem;
    };
}