import { createSelector } from "reselect";
import _ from "lodash";
import { AppState } from "../state/AppState";
import { selectTenants } from "./TenantSelectors";
import { selectCustomerImportFiles, selectCustomerImports } from "./CustomerImportSelectors";
import { selectSystems } from "./FinosecSystemSelectors";

const selectId = (state: any, reportId: number | null) => reportId;

export const selectReports = (state: AppState) => state.reportState?.reports || [];
const selectSubReports = (state: AppState) => state.subReportState?.subReports || [];
const selectReportAttributes = (state: AppState) => state.reportAttributeState?.reportAttributes || [];
const selectSubReportAttributes = (state: AppState) => state.subReportAttributeState?.subReportAttributes || [];
const selectCustomerReports = (state: AppState) => state.reportState?.reports || [];
const selectReportIds = (state: any, ids: number[] | null) => ids;

export const selectReportsByIds = createSelector([selectReports, selectReportIds], (reports, reportIds) => {
    return reports.filter((r) => reportIds?.includes(r.id));
});

export const selectImportData = createSelector(
    [selectTenants, selectReports, selectSystems, selectCustomerImportFiles, selectCustomerImports, selectId],
    (tenants, reports, systems, files, imports, tenantId) => {
        if (!tenantId) return [];
        return systems.map((s) => ({
            ...s,
            imports: reports
                .filter((r) => r.finosecSystemId === s.id)
                .map((r) => {
                    const customerImport = imports.find(
                        (i) => i.finosecSystemId === s.id && !i.completedDate && i.tenantId === tenantId
                    );
                    let file = null;
                    if (customerImport) {
                        file = files.find((f) => f.reportId === r.id && customerImport.id === f.customerImportId);
                    }

                    let importStatus = "Not Started";
                    if (file?.id && !customerImport?.completedDate) {
                        importStatus = "Processing";
                    }
                    if (file?.id && customerImport?.completedDate) {
                        importStatus = "Complete";
                    }
                    return {
                        date: file?.createdOn,
                        id: file?.id || `report-${r.id}`,
                        name: file?.fileName,
                        list: r.name,
                        rows: file?.noRows,
                        columnsMatched: file?.noColumnsMatches,
                        status: importStatus,
                        hasFile: !!file,
                    };
                }),
            completedImport: _.orderBy(
                imports.filter((i) => i.finosecSystemId === s.id && i.tenantId === tenantId && i.isCurrent),
                (i) => i.completedDate
            ).find((i) => i.completedDate),
            currentImport: imports.find(
                (i) => i.finosecSystemId === s.id && i.tenantId === tenantId && !i.completedDate
            ),
        }));
    }
);

export const selectMappedReports = createSelector([selectReports, selectReportAttributes], (reports, attributes) => {
    return reports.map((r) => ({
        ...r,
        columns: attributes.filter((a) => a.reportId === r.id),
    }));
});

export const selectMasterReportsBySystem = createSelector([selectMappedReports, selectId], (reports, systemId) => {
    return reports.filter((r) => (systemId ? r.finosecSystemId === +systemId : false));
});

export const selectReportById = createSelector([selectMappedReports, selectId], (reports, id) => {
    const returnItem = reports.find((r) => (id ? r.id === +id : false));
    return returnItem;
});

const selectMappedSubReportAttributes = createSelector(
    [selectReportAttributes, selectSubReportAttributes],
    (reportAttributes, subReportAttributes) => {
        return subReportAttributes.map((sr) => {
            const reportAttribute = reportAttributes.find((ra) => ra.id === sr.reportAttributeId);
            return {
                ...sr,
                attribute: reportAttribute?.attribute,
            };
        });
    }
);

const selectMappedSubReports = createSelector(
    [selectReports, selectSubReports, selectMappedSubReportAttributes],
    (reports, subReports, attributes) => {
        return subReports.map((sr) => ({
            ...sr,
            system: reports?.find((r) => r.id === sr.reportId)?.finosecSystemId,
            columns: attributes.filter((a) => a.subReportId === sr.id),
        }));
    }
);

export const selectSubReportById = createSelector([selectMappedSubReports, selectId], (subReports, id) =>
    subReports.find((sr) => (id ? sr.id === +id : false))
);

export const selectReportsBySystemId = createSelector([selectCustomerReports, selectId], (reports, systemId) => {
    return reports.filter((r) => r.finosecSystemId === systemId);
});

const selectReportsWithSubReports = createSelector(
    [selectMappedReports, selectMappedSubReports],
    (reports, subReports) => {
        return reports.map((r) => ({
            ...r,
            subReports: subReports.filter((sr) => sr.reportId === r.id),
        }));
    }
);

const selectSearchString = (state: AppState, searchString: string) => searchString;

export const selectMappedSystemsWithReports = createSelector(
    [selectSystems, selectReportsWithSubReports, selectSearchString],
    (systems, reports, searchString) => {
        const mappedSubReports = _.flatten(
            reports.map((r) =>
                r.subReports.map((sr: any) => ({
                    ...sr,
                    id: `sub-report-${sr.id}`,
                    finosecSystemId: r.finosecSystemId,
                    reportType: "Sub Report",
                    active: sr.isActive ? "Active" : "InActive",
                }))
            )
        );
        const mappedReports = reports.map((r) => ({
            ...r,
            reportType: "Master Report",
            active: r.isActive ? "Active" : "InActive",
        }));
        return reports.length > 0
            ? systems
                  .filter((sys) => (searchString ? sys.name.toLowerCase().includes(searchString) : true))
                  .map((s) => {
                      const filteredReports = mappedReports.filter((r) => r.finosecSystemId === s.id);
                      const filteredSubReports = mappedSubReports.filter(
                          (sr) => sr.isActive === true && sr.finosecSystemId === s.id
                      );
                      return {
                          ...s,
                          reports: [...filteredReports, ...filteredSubReports],
                      };
                  })
            : [];
    }
);
