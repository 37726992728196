import { TYPES } from "../../dependencyInjection/Types";
import { SubReportAttribute } from "../../models/SubReportAttribute";
import { ISubReportAttributeService } from "../../services/subReportAttribute/ISubReportAttributeService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { CreateSubReportAttributeAction, 
        SubReportAttributesFetchedAction, 
        SUB_REPORT_ATTRIBUTES_FETCHED,
        CREATE_SUB_REPORT_ATTRIBUTE } from "./SubReportAttributeAction";

export function getSubReportAttributes(subReportId: number):KeysysThunkAction<void,SubReportAttributesFetchedAction> {
    return async (dispatch, getState, container) =>{
        const subReportAttributeService = container.get<ISubReportAttributeService>(TYPES.SubReportAttributeService);
        const subReportAttributes = await subReportAttributeService.getSubReportAttributes(subReportId);
    
        if(subReportAttributes)
        {
            dispatch<SubReportAttributesFetchedAction>({
                type:SUB_REPORT_ATTRIBUTES_FETCHED,
                subReportAttributes:subReportAttributes
            });
        }
    }
}

export function createSubReportAttribute(subReportId:number, subReportAttribute:SubReportAttribute): KeysysThunkAction<void,CreateSubReportAttributeAction> {
        return async (dispatch, getState, container) => {
            const subReportAttributeService = container.get<ISubReportAttributeService>(TYPES.SubReportAttributeService);
            const createdSubReportAttribute = await subReportAttributeService.createSubReportAttribute(subReportId, subReportAttribute);
            
            if(createdSubReportAttribute)
            {
                dispatch<CreateSubReportAttributeAction>({
                    type: CREATE_SUB_REPORT_ATTRIBUTE,
                    subReportAttribute:createdSubReportAttribute
                });
                
            }
            return !!createdSubReportAttribute;
           
        }

    }
