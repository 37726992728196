import React from "react";
import SmallRoundRejectButton from "../buttons/SmallRoundRejectButton/SmallRoundRejectButton";
import { Identifiable } from "../../models/Identifiable";

interface RejectButtonRenderProps {
    data: Identifiable;
    entityName: string;
    showEdit: (identifiable: Identifiable) => void;
    isActive?: boolean;
    isReject?: boolean;
}

export default function RejectButtonRender(props: RejectButtonRenderProps) {
    return (
        <SmallRoundRejectButton
            key={`edit-${props.data.id}`}
            entityNameSingular={props.entityName}
            onClick={() => props.showEdit(props.data)}
            isActive={props.isActive}
        />
    );
}
