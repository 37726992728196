import { TYPES } from "../../dependencyInjection/Types";
import { IFinosecSystemStatisticService } from "../../services/finosecSystemStatisticService/IFinosecSystemStatisticService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    FinosecSystemStatisticsDataFetchedAction,
    FINOSEC_SYSTEM_STATISTICS_DATA_FETCHED,
    FINOSEC_SYSTEM_STATISTICS_PARTNER_DATA_FETCHED,
    FinosecSystemStatisticAction,
} from "./FinosecSystemStatisticAction";

export function getFinosecSystemStatisticsByTenantId(
    tenantId: number
): KeysysThunkAction<void, FinosecSystemStatisticsDataFetchedAction> {
    return async (dispatch, getState, container) => {
        const systemService = container.get<IFinosecSystemStatisticService>(TYPES.FinosecSystemStatisticService);
        const systemStatisticsData = await systemService.getFinosecSystemStatisticsDataByTenantId(tenantId);

        if (systemStatisticsData) {
            dispatch<FinosecSystemStatisticsDataFetchedAction>({
                type: FINOSEC_SYSTEM_STATISTICS_DATA_FETCHED,
                systemStatisticsData: systemStatisticsData,
            });
        }
    };
}

export function getFinosecSystemStatisticsByPartnerId(
    partnerId: number
): KeysysThunkAction<void, FinosecSystemStatisticAction> {
    return async (dispatch, getState, container) => {
        const systemService = container.get<IFinosecSystemStatisticService>(TYPES.FinosecSystemStatisticService);
        const partnerStatisticsData = await systemService.getFinosecSystemStatisticsDataByPartnerId(partnerId);

        if (partnerStatisticsData) {
            dispatch<FinosecSystemStatisticAction>({
                type: FINOSEC_SYSTEM_STATISTICS_PARTNER_DATA_FETCHED,
                partnerStatisticsData: partnerStatisticsData,
            });
        }
    };
}
