import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { IEmployeeSystemForm } from "./IEmployeeSystemForm";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import { EmployeeSystemFormModel } from "./EmployeeSystemFormModel";
import { EmployeeSystem } from "../../models/EmployeeSystem";
import { createEmployeeSystem } from "../../actions/employeeSystem/EmployeeSystemActionCreator";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { Col, Row } from "react-bootstrap";
import KeysysInput from "../form/input/KeysysInput";
import KeysysSelect from "../form/select/KeysysSelect";
import { IEmployeeSystemExceptionForm } from "./IEmployeeSystemExceptionForm";
import { editEmployeeSystemException } from "../../actions/employeeSystemException/EmployeeSystemExceptionActionCreator";
import { EmployeeSystemException } from "../../models/EmployeeSystemException";

export default function EmployeeSystemForm(props: IEmployeeSystemExceptionForm) {
    const dispatch = useDispatch();
    const formikRef = useRef<FormikProps<any>>(null);

    const employees = useSelector((state: AppState) => state.employeeState).employees;
    const employeeOptions: KeysysSelectOption[] = [
        { value: 1, label: "Orphan Account" },
        ...employees.map(
            (e: any) => ({ value: e.id, label: `${e.firstName} ${e.lastName} - ${e.email}` } as KeysysSelectOption)
        ),
    ];

    const getInitialValues = useCallback(() => {
        return {
            userId: props.employeeSystemException?.userId,
            name: props.employeeSystemException?.name,
            finosecSystem: props.employeeSystemException?.finosecSystem?.name,
            employeeId: 0,
        };
    }, [props]);

    function handleSubmit(values: EmployeeSystemFormModel, formikHelpers: FormikHelpers<EmployeeSystemFormModel>) {
        if (props.employeeSystemException?.id) {
            if (values.employeeId) {
                if (values.employeeId != 1) {
                    const employeeSystemToCreate: EmployeeSystem = {
                        id: 0,
                        finosecSystemId: props.employeeSystemException?.finosecSystemId,
                        employeeId: values.employeeId,
                        isActive: true,
                    };
                    dispatch(createEmployeeSystem(employeeSystemToCreate));
                }

                const editedEmployeeSystemException: EmployeeSystemException = {
                    ...props.employeeSystemException!,
                    employeeId: values.employeeId,
                };
                dispatch(editEmployeeSystemException(props.employeeSystemException!, editedEmployeeSystemException));
            }
        }
        clearFormAndHide(formikHelpers);
    }

    function clearFormAndHide(
        formProps: FormikProps<EmployeeSystemFormModel> | FormikHelpers<EmployeeSystemFormModel>
    ): void {
        formProps.resetForm();
        props.onClose();
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<EmployeeSystemFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="mergeEmployeeModal"
                            show={true}
                            title={"Merge Employee"}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                            showSaveButton={true}
                        >
                            <Form id="employee-system-form">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"userId"}
                                            placeholder={""}
                                            fieldName={"userId"}
                                            label={"User Id"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"name"}
                                            placeholder={""}
                                            fieldName={"name"}
                                            label={"Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"finosecSystem"}
                                            placeholder={""}
                                            fieldName={"finosecSystem"}
                                            label={"Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"employeeId"}
                                            placeholder={"Employee to merge to..."}
                                            fieldName={"employeeId"}
                                            label={"Select Employee"}
                                            options={employeeOptions}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
