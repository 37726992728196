import React, { CSSProperties } from "react";
import Button from "react-bootstrap/Button";
import { SmallSquareRestoreButtonProps } from "./SmallSquareRestoreButtonProps";

export default function SmallSquareRestoreButton(props: SmallSquareRestoreButtonProps) {
    function getButtonTitle(): string {
        return `Restore ${props.entityNameSingular}`;
    }

    return (
        <Button
            size={"sm"}
            variant="transparent"
            placeholder={getButtonTitle()}
            title={getButtonTitle()}
            onClick={props.onClick}
            disabled={props.isActive}
        >
            <i style={{ color: "#93bc3f" }} className={"fas fa-trash-restore-alt"} />
        </Button>
    );
}
