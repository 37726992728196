import type { Store } from "redux";
import { IntakeCustomerFile } from "../../models/IntakeCustomerFile";
import type { IHttpClient } from "../HttpClient/IHttpClient";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import {
    ADD_INTAKECUSTOMERFILE_SUCCESS,
    DELETE_INTAKECUSTOMERFILE_SUCCESS,
    EDIT_INTAKECUSTOMERFILE_SUCCESS,
    ERROR,
    RESTORE_INTAKECUSTOMERFILE_SUCCESS,
} from "../../constants/ToastConstants";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { showToast } from "../../actions/ToastActionCreators";
import { createPatch } from "rfc6902";
import { ShowToastAction } from "../../actions/ToastActions";
import { IIntakeCustomerFileService } from "./IIntakeCustomerFileService";
import type { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";

@injectable()
export class IntakeCustomerFileService implements IIntakeCustomerFileService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    addIntakeCustomerFile(intakeCustomerFile: IntakeCustomerFile): Promise<void | IntakeCustomerFile> {
        return this.httpClient
            .post<IntakeCustomerFile>("/intake-customer-file", intakeCustomerFile)
            .then((u) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_INTAKECUSTOMERFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeCustomerFile "${u.displayFileName}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return u;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while creating intakeCustomerFile "${intakeCustomerFile.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    deleteIntakeCustomerFile(intakeCustomerFile: IntakeCustomerFile): Promise<void | IntakeCustomerFile> {
        return this.httpClient
            .delete(`/intake-customer-file/${intakeCustomerFile.id}`)
            .then(() => {
                const returnIntakeCustomerFile = { ...intakeCustomerFile, isActive: false }; // TODO: Should API return the deleted intakeCustomerFile so we don't have to do this?
                const toastProps: KeysysToastProps = {
                    name: DELETE_INTAKECUSTOMERFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeCustomerFile "${intakeCustomerFile.displayFileName}" deactivated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnIntakeCustomerFile;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while deleting intakeCustomerFile "${intakeCustomerFile.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    editIntakeCustomerFile(
        intakeCustomerFile: IntakeCustomerFile,
        newIntakeCustomerFile: IntakeCustomerFile
    ): Promise<void | IntakeCustomerFile> {
        const operations = createPatch(intakeCustomerFile, newIntakeCustomerFile);
        return this.httpClient
            .patch<IntakeCustomerFile>(`/intake-customer-file/${intakeCustomerFile.id}`, operations)
            .then((patchedIntakeCustomerFile) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_INTAKECUSTOMERFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeCustomerFile "${patchedIntakeCustomerFile.displayFileName}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedIntakeCustomerFile;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while updating intakeCustomerFile "${intakeCustomerFile.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    getInactiveIntakeCustomerFiles(): Promise<void | IntakeCustomerFile[]> {
        return this.httpClient
            .get<IntakeCustomerFile[]>("/intake-customer-file/inactive")
            .catch((exception) =>
                this.handleError(
                    exception,
                    `We encountered an error while retrieving inactive intakeCustomerFiles. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getIntakeCustomerFiles(): Promise<void | IntakeCustomerFile[]> {
        return this.httpClient
            .get<IntakeCustomerFile[]>("/intake-customer-file")
            .catch((exception) =>
                this.handleError(
                    exception,
                    `We encountered an error while retrieving intakeCustomerFiles. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    handleError(exception: any, bodyText: string): void {
        if (exception.statusCode === 401) {
            return;
        }

        const toastProps: KeysysToastProps = {
            name: ERROR,
            theme: "danger",
            titleInHeader: "Error",
            body: bodyText,
            delay: 15000,
        };
        this.store.dispatch(showToast(toastProps));
    }

    restoreIntakeCustomerFile(intakeCustomerFileToRestore: IntakeCustomerFile): Promise<void | IntakeCustomerFile> {
        const updatedIntakeCustomerFile = { ...intakeCustomerFileToRestore, isActive: true };
        const operations = createPatch(intakeCustomerFileToRestore, updatedIntakeCustomerFile);

        return this.httpClient
            .patch<IntakeCustomerFile>(`/intake-customer-file/${intakeCustomerFileToRestore.id}`, operations)
            .then((restoredIntakeCustomerFile) => {
                const toastProps: KeysysToastProps = {
                    name: RESTORE_INTAKECUSTOMERFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeCustomerFile "${restoredIntakeCustomerFile.displayFileName}" activated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return restoredIntakeCustomerFile;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while restoring intakeCustomerFile "${intakeCustomerFileToRestore.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }
}
