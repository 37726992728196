import { TYPES } from "../../dependencyInjection/Types";
import { ICustomerImportSubReportAssignmentService } from "../../services/customerImportSubReportAssignment/ICustomerImportSubReportAssignmentService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    CustomerImportSubReportAssignmentsFetchedAction,
    CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED,
} from "./CustomerImportSubReportAssignmentAction";

export function getCustomerImportSubReportAssignments(
    customerImportId: number
): KeysysThunkAction<void, CustomerImportSubReportAssignmentsFetchedAction> {
    return async (dispatch, getState, container) => {
        dispatch<any>({
            type: CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED,
        });
        const customerImportSubReportAssignmentService = container.get<ICustomerImportSubReportAssignmentService>(
            TYPES.CustomerImportSubReportAssignmentService
        );
        const customerImportSubReportAssignments = await customerImportSubReportAssignmentService.getCustomerImportSubReportAssignments(
            customerImportId
        );
        if (customerImportSubReportAssignments) {
            dispatch<CustomerImportSubReportAssignmentsFetchedAction>({
                type: CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED,
                customerImportSubReportAssignments: customerImportSubReportAssignments,
            });
        }
    };
}
