import { createSelector } from "reselect";
import { AppState } from "../state/AppState";
import { selectCustomerImportsByCustomerId } from "./CustomerImportSelectors";

export const selectSystems = (state: AppState) => state.finosecSystemState?.finosecSystems;
export const customerImports = (state: AppState) => state.customerImportState?.customerImports;
export const subReports = (state: AppState) => state.subReportState?.subReports;

export const selectCustomerSystems = createSelector(
    [selectSystems, selectCustomerImportsByCustomerId],
    (systems, customerImports) => {
        return systems.filter((s) => customerImports.map((ci) => ci.finosecSystemId).includes(s.id));
    }
);
