import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { CustomerDocument } from "../../models/CustomerDocument";
import { AppState } from "../../state/AppState";
import DocumentsTable from "./documentsTable";
import DocumentUpload from "./documentUpload";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

import {
    createCustomerDocumentWithoutUpload,
    getCustomerDocuments,
    getProgramReferences,
} from "../../actions/customerDocument/CustomerDocumentActionCreators";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import PageHeader from "../header/PageHeader";
import { uploadFileToS3WithPreSignedUrl, getPreSignedUrl } from "../../helper-functions";
import { showToast } from "../../actions/ToastActionCreators";
import { DOCUMENT_EXCHANGE_BUCKET_NAME } from "../../config/config";
import { PreSignedUrlType } from "../../models/PreSignedUrlType";
import { computeSha256 } from "../../helper-functions/computeSha256";

const selectCustomerDocuments = (state: AppState) => state.customerDocumentState.customerDocuments;
const selectUsers = (state: AppState) => state.userState.users;
const selectProgramReferences = (state: AppState) => state.customerDocumentState.programReferences;
const selectCustomerDocumentData = createSelector(
    [selectCustomerDocuments, selectUsers, selectProgramReferences, (state: AppState, id: number) => id],
    (documents, users, references, tenantId) => {
        return documents
            .map((d) => ({
                ...d,
                programReference: references.find((r) => r.id === d.programReferenceId)?.name,
                userName: users.find((u) => u.id === d.createdById)?.firstName,
            }))
            .filter((d) => d.tenantId === tenantId);
    }
);

export default function CustomerDocuments() {
    const dispatch = useDispatch();

    const { tenantId } = useParams<{ tenantId: string }>();

    const { currentTenant: tenant } = useCurrentTenant(+tenantId);
    const { decodedAccessToken, accessToken } = useSelector((state: AppState) => state.authenticationState);
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin" || decodedAccessToken.roles === "PartnerAdmin";

    const customerDocuments = useSelector((state: AppState) => selectCustomerDocumentData(state, +tenantId));
    const programReferences = useSelector(selectProgramReferences);

    const [pageSize, setPageSize] = useState<number>(15);
    const [searchText, setSearchText] = useState<string>("");

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    useEffect(() => {
        dispatch(getCustomerDocuments());
        dispatch(getProgramReferences());
    }, [tenantId]);

    const saveCustomerDocument = async (file: File, customerDocument: CustomerDocument) => {
        const filePathNameSplit = file.name.split(".");
        const fileExtension = file.name.split(".")[filePathNameSplit.length - 1];
        const fileName = filePathNameSplit[0];
        const timeStamp = moment().format("MMDDYYYYHHmmss");
        const preSignedUrlType = PreSignedUrlType.Upload;
        const awsFileId = `${tenantId}/${fileName}_${timeStamp}.${fileExtension}`;

        try {
            var contentHash = await computeSha256(file);

            var preSignedUrl = await getPreSignedUrl(
                accessToken,
                awsFileId,
                DOCUMENT_EXCHANGE_BUCKET_NAME!,
                preSignedUrlType,
                contentHash
            );

            if (preSignedUrl) {
                //upload to s3
                const awsResult = await uploadFileToS3WithPreSignedUrl(file, preSignedUrl, contentHash);

                // create CustomerDocument in our db
                if (awsResult === 200) {
                    dispatch(
                        createCustomerDocumentWithoutUpload({
                            ...customerDocument,
                            tenantId: +tenantId,
                            awsFileId: `${tenantId}/${fileName}_${timeStamp}.${fileExtension}`,
                            fileName: `${fileName}.${fileExtension}`,
                            contentHash: contentHash,
                        })
                    );
                } else {
                    dispatch(
                        showToast({
                            name: "UPLOAD_S3_ERROR",
                            titleInHeader: "Error",
                            body:
                                "Upload failed. Please try again. If the problem continues, contact the system administrator.",
                            theme: "danger",
                        })
                    );
                }
            }
        } catch (error) {
            dispatch(
                showToast({
                    name: "UPLOAD_S3_ERROR",
                    titleInHeader: "Error",
                    body:
                        "Upload failed. Please try again. If the problem continues, contact the system administrator.",
                    theme: "danger",
                })
            );
        }
    };

    // if (!tenant?.parentTenantId) {
    //     return <h1>An Error Goes Here! Maybe a Cool One 🤷‍♂️?</h1>;
    // }

    return (
        <>
            {!inIframe() && (
                <Row className="padding-0">
                    <Col md={12}>
                        <PageHeader>
                            <h2>Document Exchange</h2>
                        </PageHeader>
                    </Col>
                </Row>
            )}
            <div className="ps-3 pe-3">
                <div style={{ marginBottom: "10px" }}>
                    <Row className={"ag-theme-alpine"}>
                        <Col md={4}>
                            <div>
                                <input
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </div>
                        </Col>
                        <Col md={2}>
                            <CreatableSelect
                                style={{ width: "20%" }}
                                onChange={(v) => setPageSize(v?.value || 15)}
                                defaultValue={null}
                                placeholder={"Page Size"}
                                options={[
                                    { value: 10, label: "10" },
                                    { value: 15, label: "15" },
                                    { value: 25, label: "25" },
                                    { value: 50, label: "50" },
                                    { value: 100, label: "100" },
                                ]}
                            />
                        </Col>
                        <Col>
                            <DocumentUpload
                                isAdmin={isAdmin}
                                onSave={saveCustomerDocument}
                                tenantName={tenant?.name}
                                programReferences={programReferences}
                            />
                        </Col>
                    </Row>
                    <Row className={"ag-theme-alpine"}>
                        <Col lg={12}>
                            <DocumentsTable searchText={searchText} pageSize={pageSize} documents={customerDocuments} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
