import { CustomerImportFile } from "../../models/CustomerImportFile";

export const CUSTOMER_IMPORT_FILES_FETCHED = "CUSTOMER_IMPORT_FILES_FETCHED";
export const FETCH_CUSTOMER_IMPORT_FILE = "FETCH_CUSTOMER_IMPORT_FILE";
export const CREATE_CUSTOMER_IMPORT_FILE = "CREATE_CUSTOMER_IMPORT_FILE";
export const EDIT_CUSTOMER_IMPORT_FILE = "EDIT_CUSTOMER_IMPORT_FILE";
export const DELETE_CUSTOMER_IMPORT_FILE = "DELETE_CUSTOMER_IMPORT_FILE";
export const FETCH_CUSTOMER_IMPORT_JSON_FILE = "FETCH_CUSTOMER_IMPORT_JSON_FILE";

export interface CustomerImportFilesFetchedAction {
    type: typeof CUSTOMER_IMPORT_FILES_FETCHED;
    customerImportFiles: CustomerImportFile[];
}

export interface FetchCustomerImportFileAction {
    type: typeof FETCH_CUSTOMER_IMPORT_FILE;
    customerImportFile: CustomerImportFile;
}

export interface CreateCustomerImportFileAction {
    type: typeof CREATE_CUSTOMER_IMPORT_FILE;
    customerImportFile: CustomerImportFile;
}

export interface EditCustomerImportFileAction {
    type: typeof EDIT_CUSTOMER_IMPORT_FILE;
    orininalRport: CustomerImportFile;
    newCustomerImportFile: CustomerImportFile;
}

export interface DeleteCustomerImportFileAction {
    type: typeof DELETE_CUSTOMER_IMPORT_FILE;
    customerImportFile: CustomerImportFile;
}

export interface FetchCustomerImportJsonFileAction {
    type: typeof FETCH_CUSTOMER_IMPORT_JSON_FILE;
    customerImportJsonFile: any[];
}

export type CustomerImportFileActions =
    | CustomerImportFilesFetchedAction
    | FetchCustomerImportFileAction
    | CreateCustomerImportFileAction
    | EditCustomerImportFileAction
    | DeleteCustomerImportFileAction
    | FetchCustomerImportJsonFileAction;
