import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ISubReportService } from "./ISubReportService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { SubReport } from "../../models/SubReport";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_REPORT_SUCCESS, DEACTIVATE_REPORT_SUCCESS, EDIT_SUB_REPORT_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import { createPatch } from "rfc6902";

@injectable()
export class SubReportService implements ISubReportService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createSubReport(subReport: SubReport): Promise<void | SubReport> {
        return this.httpClient
            .post<SubReport>("/sub-reports", subReport)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `SubReport "${t.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a subReport. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    deleteSubReport(subReport: SubReport): Promise<void | SubReport> {
        return this.httpClient
            .delete(`/sub-reports/${subReport.id}`)
            .then(() => {
                const returnSubReport = { ...subReport, isActive: !subReport.isActive };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `SubReport "${subReport.name}" ${
                        subReport.isActive ? " deactivated" : " reactivated"
                    } successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnSubReport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the subReport. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getSubReports(): Promise<void | SubReport[]> {
        return this.httpClient
            .get<SubReport[]>(`/sub-reports`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving subReports. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getSubReportById(id: number): Promise<void | SubReport> {
        return this.httpClient
            .get<SubReport>(`/sub-reports/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the subReport. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editSubReport(subReport: SubReport, newSubReport: SubReport): Promise<void | SubReport> {
        const operations = createPatch(subReport, newSubReport);
        return this.httpClient
            .patch<SubReport>(`/sub-reports/${subReport.id}`, operations)
            .then((patchedSubReport) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_SUB_REPORT_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedSubReport.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedSubReport;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing sub-report "${subReport.name}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }
}
