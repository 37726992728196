import { Employee } from "../../models/Employee";
import { EmployeeMerge } from "../../models/EmployeeMerge";

export const EMPLOYEES_FETCHED = "EMPLOYEES_FETCHED";
export const EMPLOYEE_FETCHED = "EMPLOYEE_FETCHED";
export const UPLOAD_EMPLOYEE_FILE = "UPLOAD_EMPLOYEE_FILE";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const MERGE_EMPLOYEE = "MERGE_EMPLOYEE";

export interface EmployeesFetchedAction {
    type: typeof EMPLOYEES_FETCHED;
    employees: Employee[];
}

export interface EmployeeFetchedAction {
    type: typeof EMPLOYEE_FETCHED;
    employee: Employee;
}

export interface UploadEmployeeFileAction {
    type: typeof UPLOAD_EMPLOYEE_FILE;
    employees: Employee[];
}

export interface EditEmployeeAction {
    type: typeof EDIT_EMPLOYEE;
    originalEmployee: Employee;
    editedEmployee: Employee;
}

export interface MergeEmployeeAction {
    type: typeof MERGE_EMPLOYEE;
    employeeMerge: EmployeeMerge;
}

export type EmployeeActions =
    | EmployeesFetchedAction
    | EmployeeFetchedAction
    | UploadEmployeeFileAction
    | EditEmployeeAction
    | MergeEmployeeAction;
