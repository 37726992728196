export const ReviewToggle = (props: any) => {
    const {
        displayOnly,
        onReview,
        isReviewComplete,
        customerReportReview: { lastModifiedByName, isFlagged, ...review },
        rowNo,
        jsonIsReviewed,
        jsonReviewedBy,
        jsonReviewedSR,
    } = props;
    const reviewRecordExists = Object.keys(review).length > 0;
    const recordIsReviewedInDB = reviewRecordExists && review.isReviewed;
    const isGreen = reviewRecordExists && review.isReviewed;
    const id = props.customerReportReview.id;
    const reviewedByName = lastModifiedByName ? lastModifiedByName : jsonReviewedBy || "";
    const reviewedBySR = review.lastModifiedBySubReport ? review.lastModifiedBySubReport : jsonReviewedSR || "";

    function getCheckedStatus(): boolean {
        if (reviewRecordExists && recordIsReviewedInDB) {
            return true;
        }
        if (reviewRecordExists && !recordIsReviewedInDB) {
            return false;
        }
        if ((!reviewRecordExists && jsonIsReviewed === 1) || isReviewComplete) {
            return true;
        }
        return false;
    }
    function getGreeenStatus(): string {
        if (reviewRecordExists && recordIsReviewedInDB) {
            return "green";
        }
        if (reviewRecordExists && !recordIsReviewedInDB) {
            return "grey";
        }
        if ((!reviewRecordExists && jsonIsReviewed === 1) || isReviewComplete) {
            return "grey";
        }
        return "empty";
    }

    function getNewReviewedStatus() {
        if ((!reviewRecordExists && isReviewComplete) || (!reviewRecordExists && jsonIsReviewed === 1)) {
            return false;
        } else {
            return !recordIsReviewedInDB;
        }
    }

    return (
        <span title={reviewedBySR}>
            <i
                className={getCheckedStatus() ? "fas fa-check-circle" : "far fa-circle"}
                style={{ fontSize: "1rem", textAlign: "center", color: getGreeenStatus(), cursor: "pointer" }}
                id={`Reviewed_${id}`}
                onClick={() => {
                    if (!displayOnly) {
                        onReview(getNewReviewedStatus(), rowNo, isFlagged || false, id, review);
                    }
                }}
            />
            <div>{recordIsReviewedInDB || (!reviewRecordExists && jsonIsReviewed === 1) ? reviewedByName : ""}</div>
        </span>
    );
};
