import { FieldArray } from "formik";
import React from "react";
import { Table } from "react-bootstrap";
import { ApiViewFields } from "./FormBuilder";

const DynamicEditableTable = (props: { getControl: any; control: ApiViewFields; values: any[] }) => {
    const { getControl, control, values } = props;
    return (
        <Table striped>
            <thead>
                <tr>
                    {control.fields?.map((field) => (
                        !field.hidden &&
                        <th key={field.key}>{field.label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <FieldArray
                    name={control.key}
                    render={(arrayHelpers) =>
                        values?.map((_value: any, index: number) => (
                            <tr key={`${control.key}-${index}`}>
                                {control.fields?.map((field, fieldIndex) => (
                                    !field.hidden &&
                                    <td key={`${control.key}-${fieldIndex}-${field.key}`}>
                                        {getControl({
                                            ...field,
                                            key: `${control.key}[${index}].${field.key}`,
                                            label: "",
                                        })}
                                    </td>
                                ))}
                            </tr>
                        )) || <tr></tr>
                    }
                />
            </tbody>
        </Table>
    );
};

export default DynamicEditableTable;
