import { HeaderState } from "../state/HeaderState";

const initialState: HeaderState = {
    title: "",
};

export function headerReducer(state: HeaderState = initialState, action: any): HeaderState {
    switch (action.type) {
        case "SET_HEADER_CONTENT": {
            return {
                ...state,
                title: action.title,
                dropDown: action.dropDown,
                dropDown2: action.dropDown2,
            };
        }
        default:
            return state;
    }
}
