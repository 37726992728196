import { inject } from "inversify";
import { Store } from "redux";
import { createPatch } from "rfc6902";
import { showToast } from "../../actions/ToastActionCreators";
import { ShowToastAction } from "../../actions/ToastActions";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import {
    ADD_EMPLOYEE_FILE_SUCCESS,
    EDIT_EMPLOYEE_SUCCESS,
    MERGE_EMPLOYEE_SUCCESS,
} from "../../constants/ToastConstants";
import { TYPES } from "../../dependencyInjection/Types";
import { Employee } from "../../models/Employee";
import { EmployeeMerge } from "../../models/EmployeeMerge";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IEmployeeService } from "./IEmployeeService";
import { EmployeeUploadModel } from "../../components/employee/EmployeeUploadModel";

export class EmployeeService implements IEmployeeService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getEmployeesByTenantId(tenantId: number): Promise<void | Employee[]> {
        return this.httpClient
            .get<Employee[]>(`/employee/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving employees. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getEmployeeById(id: number): Promise<void | Employee> {
        return this.httpClient
            .get<Employee>(`/employee/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving employees. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    uploadEmployees(employeeFile: EmployeeUploadModel): Promise<void | Employee[]> {
        return this.httpClient
            .post<EmployeeUploadModel>("employee", employeeFile)
            .then((t: any) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_EMPLOYEE_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Employee File created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while uploading Employees. Please make sure to match the template. Download template from the page. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editEmployee(employee: Employee, editedEmployee: Employee): Promise<void | Employee> {
        const operations = createPatch(employee, editedEmployee);
        return this.httpClient
            .patch<Employee>(`/employee/${employee.id}`, operations)
            .then((patchedEmployee) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_EMPLOYEE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Employee "${patchedEmployee.email}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedEmployee;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing employee "${employee.email}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    mergeEmployee(employeeMerge: EmployeeMerge): Promise<void | EmployeeMerge> {
        return this.httpClient
            .post<EmployeeMerge>("/employee/merge", employeeMerge)
            .then((m) => {
                const toastProps: KeysysToastProps = {
                    name: MERGE_EMPLOYEE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Employee merged successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return m;
            })
            .catch((exception) => {
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while merging employees. Please try again. If the problem continues, contact your administrator.`
                );
            });
    }
}
