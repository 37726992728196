import React, { CSSProperties, forwardRef } from "react";
import logo from "../../images/finosec_logo.png";

function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

const Footer = forwardRef((props: { partnerLogo?: string }, ref: any) => {
    const { partnerLogo } = props;
    const fixedStyle: CSSProperties = {
        position: "fixed",
        bottom: "0",
    };
    if (inIframe()) {
        return null;
    }
    return (
        <div id="keysys-footer" ref={ref} className="small" style={ref && ref.current ? undefined : fixedStyle}>
            <div id="keysys-footer-logo" style={{ float: "right" }}>
                <div>
                    <img
                        alt={"Company logo"}
                        src={partnerLogo || logo}
                        style={{ maxWidth: "166px", maxHeight: "50px" }}
                    />
                </div>
            </div>
        </div>
    );
});

export default Footer;
