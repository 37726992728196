import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerReportsByCustomerImportId } from "../actions/customerReport/CustomerReportActionCreator";
import { AppState } from "../state/AppState";

export const useCustomerImportReportList = (customerImportId: number) => {
    const dispatch = useDispatch();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);

    useEffect(() => {
        if (decodedAccessToken.userId) {
            dispatch(getCustomerReportsByCustomerImportId(customerImportId));
        }
    }, [customerImportId, decodedAccessToken.userId, dispatch]);
};
