import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IEmployeeForm } from "./IEmployeeForm";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { MergeEmployeeFormModel } from "./MergeEmployeeFormModel";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { Col, Row } from "react-bootstrap";
import KeysysInput from "../form/input/KeysysInput";
import KeysysSelect from "../form/select/KeysysSelect";
import { AppState } from "../../state/AppState";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import { mergeEmployee } from "../../actions/employee/EmployeeActionCreators";

export default function EmployeeMergeForm(props: IEmployeeForm) {
    const dispatch = useDispatch();
    const formikRef = useRef<FormikProps<any>>(null);

    const employees = useSelector((state: AppState) => state.employeeState).employees;
    const employeeOptions: KeysysSelectOption[] = employees
        .filter((e: any) => e.id !== props.employee?.id)
        .map((e: any) => ({ value: e.id, label: `${e.firstName} ${e.lastName} - ${e.email}` } as KeysysSelectOption));

    const getInitialValues = useCallback(() => {
        return {
            firstName: props.employee?.firstName ?? "",
            lastName: props.employee?.lastName ?? "",
            emailAddress: props.employee?.email ?? "",
            mergeToEmployeeId: 0,
        };
    }, [props]);

    function handleSubmit(values: MergeEmployeeFormModel, formikHelpers: FormikHelpers<MergeEmployeeFormModel>) {
        if (props.employee?.id) {
            if (values.mergeToEmployeeId) {
                const employeeMerge: any = {
                    fromId: props.employee?.id,
                    toId: values.mergeToEmployeeId,
                };
                dispatch(mergeEmployee(employeeMerge, props.employee.tenantId));
            }
        }
        clearFormAndHide(formikHelpers);
    }

    function clearFormAndHide(
        formProps: FormikProps<MergeEmployeeFormModel> | FormikHelpers<MergeEmployeeFormModel>
    ): void {
        formProps.resetForm();
        props.onClose();
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<MergeEmployeeFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="mergeEmployeeModal"
                            show={true}
                            title={"Merge Employee"}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                            showSaveButton={true}
                        >
                            <Form id="employee-merge-form">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"firstName"}
                                            placeholder={""}
                                            fieldName={"firstName"}
                                            label={"First Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"lastName"}
                                            placeholder={""}
                                            fieldName={"lastName"}
                                            label={"Last Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"emailAddress"}
                                            placeholder={""}
                                            fieldName={"emailAddress"}
                                            label={"Email Address"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"mergeToEmployeeId"}
                                            placeholder={"Employee to merge to..."}
                                            fieldName={"mergeToEmployeeId"}
                                            label={"Select Employee"}
                                            options={employeeOptions}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
