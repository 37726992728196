import { ShowModalAction, SHOW_MODAL, HideModalAction, HIDE_MODAL } from "./ModalActions";

export function showModal(modalName: string, modalValue?: any): ShowModalAction {
    return {
        type: SHOW_MODAL,
        modal: modalName,
        modalValues: modalValue,
    };
}

export function hideModal(modalName: string): HideModalAction {
    return {
        type: HIDE_MODAL,
        modal: modalName,
    };
}
