import { IDashboardService } from "../../services/dashboard/IDashboardService";
import {
    DASHBOARDS_FETCHED,
    DashboardsFetchedAction,
    CREATE_DASHBOARD,
    CreateDashboardAction,
    DELETE_DASHBOARD,
    DeleteDashboardAction,
    EDIT_DASHBOARD,
    FetchDashboardAction,
    FETCH_DASHBOARD,
} from "./DashboardActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { Dashboard } from "../../models/Dashboard";

export function getDashboards(): KeysysThunkAction<void, DashboardsFetchedAction> {
    return async (dispatch, getState, container) => {
        const dashboardService = container.get<IDashboardService>(TYPES.DashboardService);
        const dashboards = await dashboardService.getDashboards();

        if (dashboards) {
            dispatch<DashboardsFetchedAction>({
                type: DASHBOARDS_FETCHED,
                dashboards: dashboards,
            });
        }
    };
}

export function getDashboardById(dashboardId: number): KeysysThunkAction<void, FetchDashboardAction> {
    return async (dispatch, getState, container) => {
        const dashboardService = container.get<IDashboardService>(TYPES.DashboardService);
        const dashboard = await dashboardService.getDashboardById(dashboardId);

        if (dashboard) {
            dispatch<FetchDashboardAction>({
                type: FETCH_DASHBOARD,
                dashboard: dashboard
            })
        }
    }
}


export function createDashboard(dashboard: Dashboard): KeysysThunkAction<void, CreateDashboardAction> {
    return async (dispatch, getState, container) => {
        const dashboardService = container.get<IDashboardService>(TYPES.DashboardService);
        const createdDashboard = await dashboardService.createDashboard(dashboard);
        if (createdDashboard) {
            dispatch<CreateDashboardAction>({
                type: CREATE_DASHBOARD,
                dashboard: createdDashboard,
            });
        }
        return !!createDashboard;
    };
}

export function editDashboard(
    originalDashboard: Dashboard,
    newDashboard: Dashboard
): KeysysThunkAction<void, CreateDashboardAction> {
    return async (dispatch, getState, container) => {
        const dashboardService = container.get<IDashboardService>(TYPES.DashboardService);
        const editedDashboard = await dashboardService.editDashboard(originalDashboard, newDashboard);
        if (editedDashboard) {
            dispatch<any>({
                type: EDIT_DASHBOARD,
                originalDashboard: originalDashboard,
                newDashboard: editedDashboard,
            });

        }

    };
}

export function deleteDashboard(dashboard: Dashboard): KeysysThunkAction<void, DeleteDashboardAction> {
    return async (dispatch, getState, container) => {
        const dashboardService = container.get<IDashboardService>(TYPES.DashboardService);
        const deletedDashboard = await dashboardService.deleteDashboard(dashboard);
        if (deletedDashboard) {
            dispatch<DeleteDashboardAction>({
                type: DELETE_DASHBOARD,
                dashboard: deletedDashboard,
            });
        }
    };
}
