import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IFileTypeService } from "./IFileTypeService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Store } from "redux";
import { AppState } from "../../state/AppState";
import { Token } from "../../models/Token";
import { FileType } from "../../models/FileType";

@injectable()
export class FileTypeService implements IFileTypeService {
    private token: Token = {
        access_token: "",
        refresh_token: "",
    };
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {
        this.store.subscribe(() => {
            const state = this.store.getState() as AppState;
            this.token.access_token = state.authenticationState.accessToken;
            this.token.refresh_token = state.authenticationState.refreshToken;
        });
    }

    getFileTypes(): Promise<void | FileType[]> {
        return this.httpClient
            .get<FileType[]>(`/file-type`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving file types. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
