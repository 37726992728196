import { Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFinosecSystemStatisticsByTenantId } from "../../hooks/customerFinosecSystemStatisticHook";
import { AppState } from "../../state/AppState";
import { selectCustomerSystems } from "../../selectors/FinosecSystemSelectors";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import moment from "moment";
import { selectCustomerImportsByCustomerId } from "../../selectors/CustomerImportSelectors";
import LinkWrapper from "../routing/LinkWrapper";
import { Chart } from "react-google-charts";
import { useCallback, useEffect, useMemo } from "react";
import { getFinosecSystems } from "../../actions/finosecSystem/FinosecSystemActionCreator";
import { getCustomerImports } from "../../actions/customerImport/CustomerImportActionCreators";
import { getCustomerImportFiles } from "../../actions/customerImportFile/CustomerImportFileActionCreators";
import { getReports } from "../../actions/reports/ReportActionCreators";
import { getSubReports } from "../../actions/subReports/SubReportActionCreators";
import { getCustomerReportDisplays } from "../../actions/customerReportDisplay/CustomerReportDisplayActionCreator";
import { getSubReportCategories } from "../../actions/subReportCategories/SubReportCategoryActionCreators";

const UARList = () => {
    const dispatch = useDispatch();
    const tenantId = useSelector((state: AppState) => state.authenticationState.decodedAccessToken.tenant_id);
    const customerImports = useSelector((state: AppState) => selectCustomerImportsByCustomerId(state, tenantId));
    const selectedSystems = useSelector((state: AppState) => selectCustomerSystems(state, tenantId)).filter((s) =>
        customerImports.map((ci) => ci.finosecSystemId)
    );

    const { systemStatistics, systemsSummary } = useFinosecSystemStatisticsByTenantId();

    const getCustomerImportId = useCallback(
        (finosecSystemId: number) => {
            return customerImports.filter((ci) => ci.finosecSystemId === finosecSystemId && ci.isCurrent)[0].id;
        },
        [customerImports]
    );

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    useEffect(() => {
        dispatch(getFinosecSystems());
        dispatch(getCustomerImports());
        dispatch(getCustomerImportFiles());
        dispatch(getReports());
        dispatch(getSubReports());
        dispatch(getSubReportCategories());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCustomerReportDisplays(tenantId));
    }, [tenantId, dispatch]);

    const populateData = useCallback(
        (importId: number) => {
            return systemStatistics.find((item) => item.importId === importId);
        },
        [systemStatistics]
    );

    const columnDefs: ColDef[] = useMemo(
        () => [
            {
                headerName: "System",
                field: "finosecSystemName",
                cellRendererFramework: (data: any) => {
                    return (
                        <LinkWrapper path={`uar/customer-import/${getCustomerImportId(data?.data?.id)}`}>
                            <span style={{ textDecorationLine: "underline" }}>{data?.data?.name}</span>
                        </LinkWrapper>
                    );
                },
            },
            {
                headerName: "Report Count",
                field: "totalSubReports",
                valueGetter: (data: any) => populateData(getCustomerImportId(data?.data?.id))?.totalSubReports,
            },
            {
                headerName: "Reports Reviewed",
                field: "totalReviewedReports",
                valueGetter: (data: any) => populateData(getCustomerImportId(data?.data?.id))?.totalReviewedReports,
            },
            {
                headerName: "Reports Remaining to Review",
                field: "totalNotReviewedReports",
                valueGetter: (data: any) => populateData(getCustomerImportId(data?.data?.id))?.totalNotReviewedReports,
            },
            {
                headerName: "Percentage Complete",
                cellRendererFramework: (data: any) => {
                    const statisticsData = populateData(getCustomerImportId(data?.data?.id));

                    if (!statisticsData?.totalSubReports) {
                        return "0%";
                    }

                    if (statisticsData && statisticsData.totalReviewedReports && statisticsData.totalSubReports)
                        return (
                            <span>
                                {" "}
                                {Math.round(
                                    (statisticsData.totalReviewedReports / statisticsData.totalSubReports) * 100
                                )}
                                %
                            </span>
                        );
                    return null;
                },
            },
            {
                headerName: "Review Complete Date",
                field: "reviewCompletedDate",
                cellRendererFramework: (data: any) => {
                    const date = populateData(getCustomerImportId(data?.data?.id))?.reviewCompletedDate;
                    if (date) {
                        return <span>{moment(date).format("MM-DD-YYYY")}</span>;
                    }
                    return "";
                },
            },
        ],
        [populateData, getCustomerImportId]
    );

    return (
        <>
            {!inIframe() && (
                <p className="title-bar ps-3 pt-2 pb-2">
                    <LinkWrapper path={`/uar`}>UAR Home</LinkWrapper>
                </p>
            )}
            <Row>
                <Col>
                    <Table hover>
                        <thead style={{ borderStyle: "hidden" }}>
                            <tr>
                                <td colSpan={2}>User Access Reports Summary</td>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Commenting out and not taking out completely in case they do want this back 
                            <tr>
                                <td>Total User Account Changes:</td>
                                <td>{finosecSystemStatistics.totalUserChanges}</td>
                            </tr> */}
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Report Count:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {systemsSummary.totalSubReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Reports Reviewed:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {systemsSummary.totalReviewedReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Reports Remaining to Review:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {systemsSummary.totalNotReviewedReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Percent Complete:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {systemsSummary.totalSubReports
                                        ? Math.round(
                                              (systemsSummary.totalReviewedReports / systemsSummary.totalSubReports) *
                                                  100
                                          )
                                        : 0}
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table hover>
                        <thead style={{ borderStyle: "hidden" }}>
                            <tr>
                                <td>Review Progress</td>
                            </tr>
                        </thead>
                    </Table>
                    <Row className="justify-content-center">
                        <Chart
                            width={"500px"}
                            height={"300px"}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ["Task", ""],
                                ["Total Reports Reviewed", systemsSummary.totalReviewedReports],
                                ["Total Reports Not Reviewed", systemsSummary.totalNotReviewedReports],
                            ]}
                            options={{
                                backgroundColor: "transparent",
                                slices: {
                                    0: { color: "#079247" },
                                    1: { color: "#CCCCCC", textStyle: { color: "#000000" } },
                                },
                            }}
                            rootProps={{ "data-testid": "1" }}
                        />
                    </Row>
                </Col>
            </Row>
            <div className="ag-theme-alpine">
                <KeysysGrid getRows={() => selectedSystems} columnDefs={columnDefs} />
            </div>
        </>
    );
};

export default UARList;
