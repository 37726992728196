import { inject, injectable } from "inversify";
import type { Store } from "redux";
import { createPatch } from "rfc6902";
import { TYPES } from "../../dependencyInjection/Types";
import { ReportAttribute } from "../../models/ReportAttribute";
import type { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import type { IHttpClient } from "../HttpClient/IHttpClient";
import { IReportAttributeService } from "./IReportAttributeService";

@injectable()
export class ReportAttributeService implements IReportAttributeService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getReportAttributes(reportId: number): Promise<void | ReportAttribute[]> {
        return this.httpClient
            .get<ReportAttribute[]>(`/reports/${reportId}/report-attributes`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving report attributes. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    createReportAttribute(reportId: number, reportAttribute: ReportAttribute): Promise<void | ReportAttribute> {
        return this.httpClient
            .post<ReportAttribute>(`/reports/${reportId}/report-attributes`, { ...reportAttribute, reportId })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a report attribute. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editReportAttribute(
        reportId: number,
        reportAttribute: ReportAttribute,
        newReportAttribute: ReportAttribute
    ): Promise<void | ReportAttribute> {
        const operations = createPatch({ ...reportAttribute, reportId }, { ...newReportAttribute, reportId });
        if (operations.length === 0) return new Promise((res, rej) => res(reportAttribute));
        return this.httpClient
            .patch<ReportAttribute>(`/reports/${reportId}/report-attributes/${reportAttribute.id}`, operations)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing report attribute  "${reportAttribute.attribute}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteReportAttribute(reportId: number, reportAttribute: ReportAttribute): Promise<void | ReportAttribute> {
        return this.httpClient
            .delete(`/reports/${reportId}/report-attributes/${reportAttribute.id}`)
            .then(() => {
                const returnReportAttribute = { ...reportAttribute, isActive: !reportAttribute.isActive };
                return returnReportAttribute;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the report. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
