import {
    UPSERT_CUSTOMER_REPORT_DISPLAYS,
    UpsertCustomerReportDisplaysAction,
    CustomerReportDisplaysFetchedAction,
    CUSTOMER_REPORT_DISPLAYS_FETCHED,
    FETCHING_CUSTOMER_REPORT_DISPLAYS,
} from "./CustomerReportDisplayAction";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { ICustomerReportDisplayService } from "../../services/customerReportDisplay/ICustomerReportDisplayService";
import { CustomerReportDisplay } from "../../models/CustomerReportDisplay";

export function getCustomerReportDisplays(
    tenantId: number
): KeysysThunkAction<void, CustomerReportDisplaysFetchedAction> {
    return async (dispatch, getState, container) => {
        dispatch<any>({
            type: FETCHING_CUSTOMER_REPORT_DISPLAYS,
        });
        const customerReportDisplayService = container.get<ICustomerReportDisplayService>(
            TYPES.CustomerReportDisplayService
        );
        const customerReportDisplays = await customerReportDisplayService.getCustomerReportDisplays(tenantId);
        if (customerReportDisplays) {
            dispatch<CustomerReportDisplaysFetchedAction>({
                type: CUSTOMER_REPORT_DISPLAYS_FETCHED,
                customerReportDisplays: customerReportDisplays,
            });
        }
    };
}

export function upsertCustomerReportDisplays(
    customerReportDisplays: CustomerReportDisplay[]
): KeysysThunkAction<void, UpsertCustomerReportDisplaysAction> {
    return async (dispatch, getState, container) => {
        const customerReportDisplayService = container.get<ICustomerReportDisplayService>(
            TYPES.CustomerReportDisplayService
        );
        const updatedCustomerReportDisplays = await customerReportDisplayService.upsertCustomerReportDisplays(
            customerReportDisplays
        );
        if (customerReportDisplays) {
            dispatch<UpsertCustomerReportDisplaysAction>({
                type: UPSERT_CUSTOMER_REPORT_DISPLAYS,
                customerReportDisplays: updatedCustomerReportDisplays,
            });
        }
    };
}
