/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { deleteDashboard, getDashboards } from "../actions/dashboard/DasboardActionCreators";
import { selectMappedSystemsWithDashboards } from "../selectors/DashboardSelectors";
import { AppState } from "../state/AppState";

export const useDashboardList = () => {
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState('');

    const systems  = useSelector((state: AppState) => selectMappedSystemsWithDashboards(state, searchString.toLowerCase()));

    useEffect(() => {
        if(!systems || systems.length === 0) {
            // Dispatch
            dispatch(getDashboards());
            dispatch(getFinosecSystems());
        }
    }, []);

    const removeDashboard = (dashboard: any) => {
            // Delete Dashboard
            dispatch(deleteDashboard(dashboard))
    }

    return {
        systems: (systems.length > 0 ? systems : []),
        removeDashboard,
        setSearchString
    };
}
