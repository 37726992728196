import type { Store } from "redux";
import { IntakeSystemFile } from "../../models/IntakeSystemFile";
import type { IHttpClient } from "../HttpClient/IHttpClient";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import {
    ADD_INTAKESYSTEMFILE_SUCCESS,
    DELETE_INTAKESYSTEMFILE_SUCCESS,
    EDIT_INTAKESYSTEMFILE_SUCCESS,
    ERROR,
    RESTORE_INTAKESYSTEMFILE_SUCCESS,
} from "../../constants/ToastConstants";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { showToast } from "../../actions/ToastActionCreators";
import { createPatch } from "rfc6902";
import { ShowToastAction } from "../../actions/ToastActions";
import { IIntakeSystemFileService } from "./IIntakeSystemFileService";
import type { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";

@injectable()
export class IntakeSystemFileService implements IIntakeSystemFileService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    addIntakeSystemFile(intakeSystemFile: IntakeSystemFile): Promise<void | IntakeSystemFile> {
        return this.httpClient
            .post<IntakeSystemFile>("/intake-system-file", intakeSystemFile)
            .then((u) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_INTAKESYSTEMFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeSystemFile "${u.displayFileName}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return u;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while creating intakeSystemFile "${intakeSystemFile.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    deleteIntakeSystemFile(intakeSystemFile: IntakeSystemFile): Promise<void | IntakeSystemFile> {
        return this.httpClient
            .delete(`/intake-system-file/${intakeSystemFile.id}`)
            .then(() => {
                const returnIntakeSystemFile = { ...intakeSystemFile, isActive: false }; // TODO: Should API return the deleted intakeSystemFile so we don't have to do this?
                const toastProps: KeysysToastProps = {
                    name: DELETE_INTAKESYSTEMFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeSystemFile "${intakeSystemFile.displayFileName}" deactivated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnIntakeSystemFile;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while deleting intakeSystemFile "${intakeSystemFile.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    editIntakeSystemFile(
        intakeSystemFile: IntakeSystemFile,
        newIntakeSystemFile: IntakeSystemFile
    ): Promise<void | IntakeSystemFile> {
        const operations = createPatch(intakeSystemFile, newIntakeSystemFile);
        return this.httpClient
            .patch<IntakeSystemFile>(`/intake-system-file/${intakeSystemFile.id}`, operations)
            .then((patchedIntakeSystemFile) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_INTAKESYSTEMFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeSystemFile "${patchedIntakeSystemFile.displayFileName}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedIntakeSystemFile;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while updating intakeSystemFile "${intakeSystemFile.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    getInactiveIntakeSystemFiles(): Promise<void | IntakeSystemFile[]> {
        return this.httpClient
            .get<IntakeSystemFile[]>("/intake-system-file/inactive")
            .catch((exception) =>
                this.handleError(
                    exception,
                    `We encountered an error while retrieving inactive intakeSystemFiles. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getIntakeSystemFiles(): Promise<void | IntakeSystemFile[]> {
        return this.httpClient
            .get<IntakeSystemFile[]>("/intake-system-file")
            .catch((exception) =>
                this.handleError(
                    exception,
                    `We encountered an error while retrieving intakeSystemFiles. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    handleError(exception: any, bodyText: string): void {
        if (exception.statusCode === 401) {
            return;
        }

        const toastProps: KeysysToastProps = {
            name: ERROR,
            theme: "danger",
            titleInHeader: "Error",
            body: bodyText,
            delay: 15000,
        };
        this.store.dispatch(showToast(toastProps));
    }

    restoreIntakeSystemFile(intakeSystemFileToRestore: IntakeSystemFile): Promise<void | IntakeSystemFile> {
        const updatedIntakeSystemFile = { ...intakeSystemFileToRestore, isActive: true };
        const operations = createPatch(intakeSystemFileToRestore, updatedIntakeSystemFile);

        return this.httpClient
            .patch<IntakeSystemFile>(`/intake-system-file/${intakeSystemFileToRestore.id}`, operations)
            .then((restoredIntakeSystemFile) => {
                const toastProps: KeysysToastProps = {
                    name: RESTORE_INTAKESYSTEMFILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `IntakeSystemFile "${restoredIntakeSystemFile.displayFileName}" activated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return restoredIntakeSystemFile;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while restoring intakeSystemFile "${intakeSystemFileToRestore.displayFileName}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }
}
