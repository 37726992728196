import {
    LOGGED_IN,
    LoggedInAction,
    LOGIN_FAIL,
    LoginFailAction,
    LogoutAction,
    UnimpersonateUserAction,
    UNIMPERSONATE_USER,
    ImpersonateUserAction,
    IMPERSONATE_USER,
} from "./AuthenticateActions";
import jwt_decode from "jwt-decode";
import { persistor } from "../store";
import { SsoService } from "../models/SsoService";
import { KeysysThunkAction } from "../store/KeysysThunkAction";
import { UserService } from "../services/UserService";
import { TYPES } from "../dependencyInjection/Types";
import {
    UserImpersonatedAction,
    UserUnimpersonatedAction,
    USER_IMPERSONATED,
    USER_UNIMPERSONATED,
} from "./users/UserActions";
import { getRoles } from "./RoleActionCreators";
import { ResetStateAction, RESET_STATE } from "./KeysysActions";

export function logOut(): KeysysThunkAction<void, LogoutAction> {
    return async (dispatch, getState, container) => {
        await persistor.purge();
        await new SsoService().signOut();
    };
}

export function loginFail(): LoginFailAction {
    return {
        type: LOGIN_FAIL,
    };
}

export const unimpersonateUser = (): KeysysThunkAction => {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const response = await userService.unimpersonate();
        const appState = getState();
        const roles = appState.roleState.roles;
        if (response) {
            dispatch<UnimpersonateUserAction>({
                type: UNIMPERSONATE_USER,
                userId: response.userId,
                email: response.emailAddress,
                tenant_id: response.tenantId,
                roles: roles.find((r) => r.id === response.roleId)?.name || "",
            });
            dispatch<UserUnimpersonatedAction>({
                type: USER_UNIMPERSONATED,
            });
            dispatch<ResetStateAction>({
                type: RESET_STATE,
            });
        }
    };
};

export const impersonateUser = (email: string): KeysysThunkAction => {
    return async (dispatch, getState, container) => {
        const userService = container.get<UserService>(TYPES.UserService);
        const impersonatedResponse = await userService.impersonate(email);
        const appState = getState();
        const roles = appState.roleState.roles;
        if (impersonatedResponse && impersonatedResponse.success) {
            dispatch<ImpersonateUserAction>({
                type: IMPERSONATE_USER,
                userId: impersonatedResponse.userId,
                email: impersonatedResponse.emailAddress,
                tenant_id: impersonatedResponse.tenantId,
                roles: roles.find((r) => r.id === impersonatedResponse.roleId)?.name || "",
                impersonatorId: impersonatedResponse.userId,
            });
            dispatch<UserImpersonatedAction>({
                type: USER_IMPERSONATED,
                impersonatorId: impersonatedResponse.impersonatorId,
            });
        }
    };
};
