/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment";
import DocumentDetailsModal from "./documentDetailsModal";
import { useSelector } from "react-redux";
import { AppState } from "../../state/AppState";

interface IDocumentsTableProps {
    documents: any[];
    pageSize: number;
    searchText: string;
}

export default function DocumentsTable(props: IDocumentsTableProps) {
    const { documents, pageSize, searchText } = props;

    const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
    const [selectedDocumentId, setSelectedDocumentId] = useState<number>(0);
    const selectedDocument = documents.find((x) => x.id === selectedDocumentId);
    const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<string>("");
    const [selectedDocumentProgramReference, setSelectedDocumentProgramReference] = useState<string>("");
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin" || decodedAccessToken.roles === "PartnerAdmin";
    const sortedDocuments = documents.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());

    function viewDetails(data: any): void {
        setSelectedDocumentId(data.id);
        setSelectedDocumentStatus(data.fileStatus);
        setSelectedDocumentProgramReference(data.programReference);
        setShowDetailsModal(true);
    }

    const columnDefs: ColDef[] = [
        {
            headerName: "Description",
            field: "documentDescription",
        },
        {
            headerName: "Program Reference",
            field: "programReference",
        },
        {
            headerName: "Comments",
            field: "comments",
        },
        {
            headerName: "Created By",
            field: "userName",
        },
        {
            headerName: "Date Uploaded",
            field: "createdOn",
            cellRendererFramework: (params: any) => <span>{moment(params.data?.createdOn).format("MM-DD-YYYY")}</span>,
        },
        {
            headerName: "",
            cellRendererFramework: (params: any) => {
                const isRequired = isAdmin ? params.data?.adminReviewRequired : params.data?.customerReviewRequired;
                if (isRequired) {
                    const hasBeenReviewed = isAdmin
                        ? params.data?.adminReviewedBy && params.data?.adminReviewDate
                        : params.data?.customerReviewedBy && params.data?.customerReviewDate;
                    if (hasBeenReviewed) {
                        return <span className="text-success">Reviewed</span>;
                    } else {
                        return <span className="text-danger">Review Required</span>;
                    }
                }
                return null;
            },
        },
        {
            headerName: "",
            cellRendererFramework: (params: any) => {
                return (
                    <Button key={params?.data?.id} variant="link" onClick={() => viewDetails(params?.data)}>
                        View Details
                    </Button>
                );
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 150,
            tooltipValueGetter: () => "View Details",
        },
    ];

    return (
        <>
            <div>
                <Row className={"documnets-table-container margin-0"}>
                    <Col className={"h-100 p-0"}>
                        <div className="ag-theme-alpine">
                            <KeysysGrid
                                searchText={searchText}
                                pageSize={pageSize}
                                pagination={true}
                                data={sortedDocuments}
                                columnDefs={columnDefs}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
            {showDetailsModal && (
                <DocumentDetailsModal
                    customerDocument={selectedDocument}
                    status={selectedDocumentStatus}
                    programReference={selectedDocumentProgramReference}
                    onClose={() => setShowDetailsModal(false)}
                />
            )}
        </>
    );
}
