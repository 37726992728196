/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getParentTenant, getTenants } from "../actions/tenants/TenantActionCreators";
import { AppState } from "../state/AppState";

export const useCurrentTenant = (tenantId: number) => {
    const dispatch = useDispatch();
    const {loaded, parentTenant, tenants} = useSelector((state: AppState) => state.tenantState);
    const currentTenant = useMemo(() => tenants.find((t) => t.id === tenantId), [tenants]);
    const childTenants = useMemo(
        () => tenants.filter(
        (t) => t.parentTenantId === tenantId
    ), [tenants]);

    useEffect(() => {
        if (!loaded) {
            dispatch(getTenants());
        }
        if (currentTenant?.parentTenantId && !parentTenant) {
            dispatch(getParentTenant(currentTenant.parentTenantId));
        }
    }, [currentTenant, childTenants]);

    const currentIsPartnerTenant = !currentTenant?.parentTenantId;

    return {
        currentTenant,
        parentTenant,
        childTenants,
        loading: currentIsPartnerTenant ? !currentTenant : !parentTenant && !currentTenant,
    };
};
