import {KeysysToastProps} from "../components/toast/KeysysToastProps";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export interface ShowToastAction {
    type: typeof SHOW_TOAST;
    toastProps: KeysysToastProps;
}

export interface HideToastAction {
    type: typeof HIDE_TOAST;
    name: string;
}

export type ToastActionTypes = ShowToastAction | HideToastAction;
