export type WorkflowJobFilterToggle = "readyForIntake" | "processing" | "rejected" | "completed";

export type WorkflowJobFilterState = {
    readyForIntake: boolean;
    processing: boolean;
    rejected: boolean;
    completed: boolean;
};

export const initialWorkflowJobFilterState: WorkflowJobFilterState = {
    readyForIntake: true,
    processing: true,
    rejected: true,
    completed: false,
};

export const WorkflowJobFilterReducer = (
    state: WorkflowJobFilterState = initialWorkflowJobFilterState,
    action: WorkflowJobFilterToggle
): WorkflowJobFilterState => {
    switch (action) {
        case "completed":
            return {
                ...state,
                completed: !state.completed,
            };
        case "processing":
            return {
                ...state,
                processing: !state.processing,
            };
        case "readyForIntake":
            return {
                ...state,
                readyForIntake: !state.readyForIntake,
            };
        case "rejected":
            return {
                ...state,
                rejected: !state.rejected,
            };
        default:
            return state;
    }
};
