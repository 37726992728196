import { FileStatus } from "../../models/FileStatus";

export const FILE_STATUSES_FETCHED = "FILE_STATUSES_FETCHED";

export interface FileStatusesFetchedAction {
    type: typeof FILE_STATUSES_FETCHED;
    statuses: FileStatus[];
}

export type FileStatusActions = FileStatusesFetchedAction;
