import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { Row, Col, Alert } from "react-bootstrap";
import KeysysSelect from "../form/select/KeysysSelect";
import { useDispatch, useSelector } from "react-redux";
import { editWorkflowJob } from "../../actions/workflowJob/WorkflowJobActionCreators";
import KeysysInput from "../form/input/KeysysInput";
import { useWorkflowJobs } from "../../hooks/useWorkflowJobs";
import { WorkflowJob } from "../../models/WorkflowJob";
import { propTypes } from "react-bootstrap/esm/Image";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import { AppState } from "../../state/AppState";
import { getWorkflowStatuses } from "../../actions/workflowStatus/WorkflowStatusActionCreators";
import { useFinosecSystemStatisticsByImportId } from "../../hooks/customerFinosecSystemStatisticHook";
import { getCustomerImportsByTenantId } from "../../actions/customerImport/CustomerImportActionCreators";
import { useCustomerImportReportList } from "../../hooks/customerReportsHook";

interface WorkflowJobFormModel {
    statusId: number;
    systemId: number;
    comments: string;
}

const schema = yup.object<WorkflowJobFormModel>().shape({
    statusId: yup.number().min(1, "Status is required").required("Status is required"),
});

interface WorkflowJobFormProps {
    onClose?: VoidFunction;
}

export const WorkflowJobForm = ({ onClose }: WorkflowJobFormProps) => {
    const dispatch = useDispatch();
    const { selectedJob, finosecSystems } = useWorkflowJobs();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";

    const workflowStatuses = useSelector((state: AppState) => state.workflowStatusState.workflowStatuses);
    const intakeSystemFiles = useSelector((state: AppState) => state.intakeSystemFileState.intakeSystemFiles);
    const intakeCustomerFiles = useSelector((state: AppState) => state.intakeCustomerFileState.intakeCustomerFiles);

    const customerImports = useSelector((state: AppState) => state.customerImportState.customerImports);
    useCustomerImportReportList(
        customerImports.find((ci) => ci.finosecSystemId === selectedJob?.systemId && ci.isCurrent)?.id ?? 0
    );
    const { importStatistics } = useFinosecSystemStatisticsByImportId(
        customerImports.find((ci) => ci.finosecSystemId === selectedJob?.systemId && ci.isCurrent)?.id ?? 0
    );

    useEffect(() => {
        if (workflowStatuses.length === 0) {
            dispatch(getWorkflowStatuses());
        }
        if (selectedJob) dispatch(getCustomerImportsByTenantId(selectedJob.customerId, selectedJob?.systemId));
    }, [dispatch, selectedJob, workflowStatuses.length]);

    const percentComplete = useMemo(
        (): number =>
            (intakeCustomerFiles.filter((cf: any) => cf.jobId === selectedJob?.id).length /
                intakeSystemFiles.filter((f: any) => f.systemId === selectedJob?.systemId).length) *
            100,
        [intakeCustomerFiles, intakeSystemFiles, selectedJob]
    );

    const currentImportPercent = useMemo(
        (): number => Math.round((importStatistics.totalReviewedReports / importStatistics.totalSubReports) * 100),
        [importStatistics.totalReviewedReports, importStatistics.totalSubReports]
    );

    const initialValues = useMemo(
        (): WorkflowJobFormModel => ({
            statusId: selectedJob?.statusId ?? 0,
            systemId: selectedJob?.systemId ?? 0,
            comments: selectedJob?.comments ?? "",
        }),
        [selectedJob]
    );

    const workflowStatusOptions: KeysysSelectOption[] = workflowStatuses
        .sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name))
        .map((ws: { id: any; name: any }) => ({
            value: ws.id,
            label: ws.name,
        }));

    const clearFormAndHide = useCallback(
        (formProps: FormikProps<WorkflowJobFormModel> | FormikHelpers<WorkflowJobFormModel>) => {
            formProps.resetForm();
            if (onClose) {
                onClose();
            }
        },
        [onClose]
    );

    const handleSubmit = useCallback(
        (values: WorkflowJobFormModel, helpers: FormikHelpers<WorkflowJobFormModel>) => {
            if (!isGlobalAdmin) {
                values.statusId = workflowStatuses.find((ws: any) => ws.name === "Processing")?.id ?? 4;
            }
            if (selectedJob) {
                dispatch(
                    editWorkflowJob(selectedJob, {
                        ...selectedJob,
                        ...values,
                    })
                );
                clearFormAndHide(helpers);
            }
        },
        [clearFormAndHide, dispatch, isGlobalAdmin, selectedJob, workflowStatuses]
    );

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<WorkflowJobFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditWorkflowJobModal"
                            show={true}
                            title={
                                "Update Intake Status " +
                                finosecSystems.find((fs) => fs.id === selectedJob?.systemId)?.name
                            }
                            closeButtonText={"Close"}
                            okButtonText={"Submit"}
                            showSaveButton={true}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="addEditWorkflowJobForm">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        Are you sure you want to submit your files for processing?
                                    </Col>
                                </Row>
                                {percentComplete < 100 && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <Alert>
                                                Percent of required files uploaded {percentComplete.toFixed(0)}%{"\n"}
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                {currentImportPercent < 100 && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <Alert>
                                                Your current review is only {currentImportPercent.toFixed(0)}% complete,
                                                by submitting, you will complete your current review
                                                {"\n"}
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                {!isGlobalAdmin && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <Alert>
                                                {!isGlobalAdmin &&
                                                    "Submitting will complete your current intake process"}
                                            </Alert>
                                        </Col>
                                    </Row>
                                )}
                                {isGlobalAdmin && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id="statusId"
                                                placeholder="Select Status"
                                                fieldName="statusId"
                                                label="Status"
                                                options={workflowStatusOptions}
                                                multiple={false}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                <Row className={"mb-4"}>
                                    {isGlobalAdmin && (
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            Enter <b>customer visible</b> comments below
                                        </Col>
                                    )}
                                    {!isGlobalAdmin && (
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            Enter comments below
                                        </Col>
                                    )}
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={8} md={{ span: 6, offset: 3 }}>
                                        <Field
                                            label="Enter Comments Below"
                                            style={{ width: "100%" }}
                                            name="comments"
                                            as="textarea"
                                            rows={7}
                                            minLength="2"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
};
