import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import { EmployeeUploadModel } from "./EmployeeUploadModel";
import * as Yup from "yup";
import { UPLOAD_EMPLOYEE_CONFIRM_MODAL } from "../../constants/ModalConstants";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { hideModal } from "../../actions/ModalActionCreators";
import { Card, Col, Row, Form as bsForm } from "react-bootstrap";
import KeysysSelect from "../form/select/KeysysSelect";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { IEmployeeUploadForm } from "./IEmployeeUploadForm";
import { uploadEmployeeFile } from "../../actions/employee/EmployeeActionCreators";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { getPreSignedUrl, uploadFileToS3WithPreSignedUrl } from "../../helper-functions";
import { EMPLOYEE_UPLOAD_BUCKET_NAME } from "../../config/config";
import { PreSignedUrlType } from "../../models/PreSignedUrlType";
import { showToast } from "../../actions/ToastActionCreators";

export default function EmployeeUploadForm(props: IEmployeeUploadForm) {
    const modalShow = useSelector((state: AppState) =>
        state.modalState.activeModals.includes(UPLOAD_EMPLOYEE_CONFIRM_MODAL)
    );
    const dispatch = useDispatch();
    const tenants = useSelector((state: AppState) => state.tenantState).tenants;
    const [employeeFile, setEmployeeFilePath] = useState<File>();
    const { accessToken } = useSelector((state: AppState) => state.authenticationState);

    const tenantOptions: KeysysSelectOption[] = tenants
        .filter((t) => t.parentTenantId !== null)
        .map((t: any) => ({ value: t.id, label: t.name } as KeysysSelectOption));

    function getInitialValues() {
        return { tenantId: 0, filename: "" };
    }

    const schema = Yup.object<EmployeeUploadModel>().shape({
        tenantId: Yup.number().min(1, "Tenant is required"),
    });

    function clearFormAndHide(formProps: FormikProps<EmployeeUploadModel> | FormikHelpers<EmployeeUploadModel>): void {
        formProps.resetForm();
        dispatch(hideModal(UPLOAD_EMPLOYEE_CONFIRM_MODAL));
    }

    function handleSubmit(values: EmployeeUploadModel, FormikHelpers: FormikHelpers<EmployeeUploadModel>) {
        if (employeeFile) {
            validateEmployeeFileHeaders(employeeFile).then(async (isValid) => {
                if (isValid) {
                    const awsFileId = generateFilename(values.tenantId);
                    values.filename = awsFileId;

                    var preSignedUrl = await getPreSignedUrl(
                        accessToken,
                        awsFileId,
                        EMPLOYEE_UPLOAD_BUCKET_NAME!,
                        PreSignedUrlType.Upload
                    );

                    if (preSignedUrl) {
                        //upload to s3
                        const awsResult = await uploadFileToS3WithPreSignedUrl(employeeFile, preSignedUrl);
                        if (awsResult === 200) {
                            //Send to API
                            dispatch(uploadEmployeeFile(values));
                        } else {
                            dispatch(
                                showToast({
                                    name: "UPLOAD_S3_ERROR",
                                    titleInHeader: "Error",
                                    body:
                                        "Upload failed. Please try again. If the problem continues, contact the system administrator.",
                                    theme: "danger",
                                })
                            );
                        }
                    }
                } else {
                    dispatch(
                        showToast({
                            name: "UPLOAD_S3_ERROR",
                            titleInHeader: "Error",
                            body:
                                "Invalid File. Please make sure the header matches the template and try again. If the problem continues, contact the system administrator.",
                            theme: "danger",
                        })
                    );
                }
            });
        }
        dispatch(hideModal(UPLOAD_EMPLOYEE_CONFIRM_MODAL));
    }

    function generateFilename(tenantId: number) {
        const now = new Date();
        const fileTimeEquivalent = now.getTime();
        return `EmployeeUpload_${tenantId}_${fileTimeEquivalent}.xlsx`;
    }

    function validateEmployeeFileHeaders(file: File): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                // Ensure 'result' is not null by adding a check
                const data = e.target?.result;
                if (typeof data !== "string") {
                    reject(new Error("Failed to read file"));
                    return;
                }

                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const headers: string[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0] as string[];
                const expectedHeaders: string[] = [
                    "firstname",
                    "alternatefirstname",
                    "lastname",
                    "email",
                    "title",
                    "department",
                    "manager",
                    "hiredate",
                    "terminationdate",
                ];

                const isValid = validateHeaders(headers, expectedHeaders);
                resolve(isValid);
            };
            reader.onerror = (error: ProgressEvent<FileReader>) => reject(error);
            reader.readAsBinaryString(file);
        });
    }

    function validateHeaders(headers: string[], expectedHeaders: string[]): boolean {
        if (headers.length !== expectedHeaders.length) return false;
        return !expectedHeaders.some(
            (expectedHeader, i) => headers[i].toLowerCase().replace(/\s/g, "") !== expectedHeader.toLowerCase()
        );
    }

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={schema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<EmployeeUploadModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="uploadEmployeeFileModal"
                            show={modalShow}
                            title={"Upload Employee List"}
                            closeButtonText={"Close"}
                            okButtonText={"Upload"}
                            showSaveButton={employeeFile ? true : false}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="userForm">
                                <Card>
                                    <Card.Body>
                                        <Row className={"mb-4"}>
                                            <Col xs={12} lg={6}>
                                                <KeysysSelect
                                                    formProps={formProps}
                                                    id={"tenantId"}
                                                    placeholder={"Tenant..."}
                                                    fieldName={"tenantId"}
                                                    label={"Select Tenant"}
                                                    options={tenantOptions}
                                                    multiple={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <bsForm.Group>
                                                    <bsForm.Label>{employeeFile?.name}</bsForm.Label>
                                                    <bsForm.Control
                                                        type="file"
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            const file: any = e.target.files![0];
                                                            setEmployeeFilePath(file);
                                                        }}
                                                        data-browse={"Upload Employees"}
                                                        id={"UploadEmployees"}
                                                    />
                                                    <img className={"mt-3"} width="200px" alt=""></img>
                                                </bsForm.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <span style={{ color: "red", marginTop: "5px" }}>
                                                    <i className="fas fa-info-circle"></i>
                                                    &nbsp;Please make sure the file matches the template. Template can
                                                    be downloaded &nbsp;
                                                    <Link
                                                        to="EmployeeUpload.xlsx"
                                                        target="_blank"
                                                        download
                                                        title="Download template"
                                                    >
                                                        here
                                                    </Link>{" "}
                                                    or from list page.
                                                </span>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
