import { KeysysAccordionProps } from "./KeysysAccordionProps";
import ReactTooltip from "react-tooltip";
import { Accordion, Button, Card, Container } from "react-bootstrap";
import { IMP0RT_CONFIRM_MODAL } from "../../constants/ModalConstants";
import { showModal } from "../../actions/ModalActionCreators";
import { useDispatch } from "react-redux";
import { useState } from "react";

export default function KeysysAccordion(props: KeysysAccordionProps) {
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const handleAccordionClick = (e: any) => {
        if (!e.target.className.includes("accordion-action-button")) {
            setIsExpanded((x) => !x);
        }
    };

    function showImportForm(): void {
        dispatch(showModal(IMP0RT_CONFIRM_MODAL, { formType: "import" }));
    }

    return (
        <>
            <Accordion
                className={props.className}
                defaultActiveKey={props.defaultExpanded ? "0" : ""}
                style={{ marginBottom: "10px" }}
                activeKey={isExpanded ? "0" : ""}
            >
                <Card>
                    <Card.Header className="accordion-header">
                        <Container
                            className={"ml-0"}
                            style={{ display: "flex", justifyContent: "space-between", maxWidth: "unset" }}
                            onClick={handleAccordionClick}
                        >
                            <span
                                data-tip={props.titleHelpText}
                                data-text-color="#296266"
                                data-background-color="#eedbc7"
                                data-border={true}
                                data-testid="accordion-title"
                                style={{ alignSelf: "center" }}
                            >
                                {props.title}
                            </span>
                            <span>{props.moreInfo}</span>
                            <div>
                                {props.actionButtons &&
                                    props.actionButtons.map((ab, idx) => {
                                        if (ab.icon) {
                                            return (
                                                <i
                                                    className={`accordion-action-button ${ab.icon}`}
                                                    style={{
                                                        marginRight: "10px",
                                                        fontSize: "1.2rem",
                                                        color: `${ab.iconColor}`,
                                                    }}
                                                    onClick={() => ab.cb(props.data)}
                                                    key={`action-button-${idx}`}
                                                />
                                            );
                                        } else {
                                            return (
                                                <Button
                                                    key={`action-button-${idx}`}
                                                    className={"accordion-action-button import-grid-button"}
                                                    data-testid={ab.text}
                                                    onClick={() => ab.cb(props.data)}
                                                >
                                                    {ab.text}
                                                </Button>
                                            );
                                        }
                                    })}
                                {props.count !== undefined && (
                                    <span data-testid="accordion-count" className="count">
                                        {props.count}
                                    </span>
                                )}
                            </div>
                        </Container>
                        <div className="header-end">
                            {props.helpText && (
                                <>
                                    <a
                                        className={"help-text-link"}
                                        href={props.href ?? "http://www.finosec.com"}
                                        data-border={true}
                                        data-testid="help-text"
                                        data-tip={props.helpText}
                                        data-text-color="#296266"
                                        data-background-color="#eedbc7"
                                        target={"_blank"}
                                        rel="noreferrer"
                                    >
                                        <i className="far fa-question-circle" />
                                    </a>
                                </>
                            )}
                            {props.addAction && (
                                <i
                                    data-testid="accordion-add-button"
                                    onClick={props.addAction}
                                    className="fas fa-plus-circle"
                                />
                            )}
                            <Accordion.Button onClick={handleAccordionClick} />
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <>
                            {(props.renderOnExpanded && isExpanded) || !props.renderOnExpanded ? props.children : <></>}
                        </>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <ReactTooltip html={true} place="top" effect="float" />
        </>
    );
}
