import {
    ROLES_FETCHED,
    RolesFetchedAction,
} from "./RoleActions";
import { Role } from "../models/Role";
import { KeysysThunkAction } from "../store/KeysysThunkAction";
import { RoleService } from "../services/role/RoleService";
import { TYPES } from "../dependencyInjection/Types";


export function getRoles(): KeysysThunkAction<Promise<Role[] | void>, RolesFetchedAction> {
    return async (dispatch, getState, container) => {
        const rolesService = container.get<RoleService>(TYPES.RoleService);
        const roles = await rolesService.getRoles();
        if (roles) {
            dispatch<RolesFetchedAction>({
                type: ROLES_FETCHED,
                roles: roles,
            });
        }
    };
}





