import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UPLOAD_EMPLOYEE_CONFIRM_MODAL } from "../../constants/ModalConstants";
import { AppState } from "../../state/AppState";
import { showModal } from "../../actions/ModalActionCreators";
import { useCallback, useEffect, useState } from "react";
import { editEmployee, getEmployeesByTenantId } from "../../actions/employee/EmployeeActionCreators";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import CreatableSelect from "react-select/creatable";
import EditButtonRender from "../grid/EditButtonRender";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";
import { Employee } from "../../models/Employee";
import EmployeeForm from "./EmployeeForm";
import PageHeader from "../header/PageHeader";
import { EmployeeFlagToggle } from "./EmployeeFlagToggle";
//import EmployeeUpload from "../../app-files/EmployeeUpload.xlsx";

export default function EmployeeChangeList() {
    const dispatch = useDispatch();
    const tenants = useSelector((state: AppState) => state.tenantState.tenants.filter((t) => t.parentTenantId != null));
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null);
    const [selectedCustomerName, setSelectedCustomerName] = useState<string | null>(null);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [clickedCustomerFilter, setCustomerFilterClick] = useState<boolean>(false);
    const employees = useSelector((state: AppState) => state.employeeState.employees.filter((emp) => emp.hasChanged));
    const [selectedEmployee, setSelectedEmployee] = useState<Employee | undefined>(undefined);
    const [textSearch, setSearchText] = useState<string>("");
    const [checkedRows, setCheckedRows] = useState<number[]>([]);
    const [flaggedRows, setFlaggedRows] = useState<number[]>([]);

    const selectCustomerId = (tenantId: number | null) => {
        setSelectedCustomerId(tenantId);
        setSelectedCustomerName(tenants.filter((tenant) => tenant.id === tenantId)[0].name);
        setCustomerFilterClick(false);
    };

    useEffect(() => {
        if (!isGlobalAdmin) {
            setSelectedCustomerId(decodedAccessToken.tenant_id);
            setCustomerFilterClick(true);
            dispatch(getEmployeesByTenantId(decodedAccessToken.tenant_id));
        }
    }, [decodedAccessToken.tenant_id, dispatch, isGlobalAdmin]);

    const filterEmployees = () => {
        setCustomerFilterClick(true);
        if (selectedCustomerId) {
            dispatch(getEmployeesByTenantId(selectedCustomerId));
        }
    };

    const handleRowFlag = useCallback(
        (employee: Employee) => {
            setFlaggedRows([...flaggedRows, employee.id]);
        },
        [flaggedRows]
    );

    const handleRowReview = useCallback(
        (employee: Employee) => {
            const editedEmployee: Employee = {
                ...employee,
                hasChanged: false,
            };
            setCheckedRows([...checkedRows, employee.id]);
            dispatch(editEmployee(employee, editedEmployee));
        },
        [checkedRows]
    );

    function showEmployeeUploadForm(): void {
        dispatch(showModal(UPLOAD_EMPLOYEE_CONFIRM_MODAL, { formType: "upload" }));
    }

    const employeesRef = useSelectorAsRef((state: AppState) => state.employeeState.employees);

    const showEditCallback = useCallback(
        (params: Employee) => {
            showEditForm(employeesRef.current.find((employee: Employee) => employee.id === params.id)!);
        },
        [employeesRef]
    );

    function showEditForm(employee: Employee): void {
        setSelectedEmployee(employee);
        setShowEditModal(true);
    }

    const columDefs: ColDef[] = [
        {
            headerName: "First Name",
            field: "firstName",
            cellRendererFramework: (data: any) => data.data?.firstName,
            tooltipField: "First name",
        },
        {
            headerName: "Last Name",
            field: "lastName",
            cellRendererFramework: (data: any) => data.data?.lastName,
            tooltipField: "Last Name",
        },
        {
            headerName: "Email",
            field: "email",
            cellRendererFramework: (data: any) => data.data?.email,
            tooltipField: "Email",
        },
        {
            headerName: "Title",
            field: "title",
            cellRendererFramework: (data: any) => data.data?.title,
            tooltipField: "Title",
        },
        {
            headerName: "Department",
            field: "department",
            cellRendererFramework: (data: any) => data.data?.department,
            tooltipField: "Department",
        },
        {
            headerName: "Manager",
            field: "manager",
            cellRendererFramework: (data: any) => data.data?.manager,
            tooltipField: "Manager",
        },
        {
            headerName: "Hire Date",
            field: "hireDate",
            cellRendererFramework: (data: any) => data.data?.hireDate,
            tooltipField: "Hire Date",
        },
        {
            headerName: "Termination Date",
            field: "terminationDate",
            cellRendererFramework: (data: any) => data.data?.terminationDate,
            tooltipField: "Termination Date",
        },
        /* DO NOT DELETE THIS COMMENTED OUT CODE. WE WILL PUT THIS BACK WHEN WE FINISH EMPLOYEE CHANGES
        {
            headerName: "",
            minWidth: 60,
            maxWidth: 60,
            cellRendererFramework: (data: any) => {
                return (
                    <i
                        className={
                            !checkedRows.some((cr) => cr === data.data.id) ? "far fa-circle" : "fas fa-check-circle"
                        }
                        style={{ color: !checkedRows.some((cr) => cr === data.data.id) ? "gray" : "green" }}
                        onClick={() => {
                            handleRowReview(data.data);
                        }}
                    />
                );
            },
        },
        {
            headerName: "",
            minWidth: 60,
            maxWidth: 60,
            cellRendererFramework: (data: any) => {
                return (
                    <i
                        className="fas fa-flag"
                        style={{ color: !flaggedRows.some((fr) => fr === data.data.id) ? "gray" : "red" }}
                        onClick={() => {
                            handleRowFlag(data.data);
                        }}
                    />
                );
            },
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "Employee",
                showEdit: showEditCallback,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Employee",
        },*/
    ];

    return (
        <>
            <PageHeader>
                <h2>Employee Changes</h2>
            </PageHeader>
            <br />
            <Row className="keysys-action-bar">
                {isGlobalAdmin && (
                    <>
                        <Col lg={2} sm={3}>
                            <CreatableSelect
                                onChange={(v) => selectCustomerId(v?.value || null)}
                                defaultValue={null}
                                placeholder={"Select Customer"}
                                options={[
                                    { value: null, label: "Select Customer" },
                                    ...tenants.map((pt) => ({
                                        value: pt.id,
                                        label: pt.name,
                                    })),
                                ]}
                            />
                        </Col>
                        <Col md={1}>
                            <Button onClick={() => filterEmployees()} className="finosec-button-info">
                                Select
                            </Button>
                        </Col>
                    </>
                )}
            </Row>
            <br />
            {selectedCustomerId && clickedCustomerFilter && (
                <div>
                    <Row className={"employees-grid-container"}>
                        <Col className={"h-100 p-0"}>
                            <div className="ag-theme-alpine">
                                <div style={{ width: "500px", marginBottom: "10px", marginTop: "10px" }}>
                                    <input
                                        className="form-control"
                                        placeholder="Search"
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                </div>
                                <KeysysGrid
                                    getRows={() => employees}
                                    columnDefs={columDefs}
                                    pagination={true}
                                    pageSize={25}
                                    searchText={textSearch}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}

            {showEditModal && (
                <EmployeeForm type="edit" employee={selectedEmployee} onClose={() => setShowEditModal(false)} />
            )}
        </>
    );
}
