import React from "react";
import { ErrorMessageTextProps } from "./ErrorMessageTextProps";

export default function ErrorMessageText(props: ErrorMessageTextProps) {
    return (
        <p className="text-danger" style={{ margin: 0, padding: 0 }}>
            {props.message}
        </p>
    );
}
