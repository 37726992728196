import { useEffect, useState, useMemo } from "react";
import { ErrorMessage, Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
//import { AddWorkflowNotificationTemplateFormModel } from "./AddWorkflowNotificationTemplateFormModel";
import * as Yup from "yup";
import { WorkflowNotificationTemplate } from "../../models/WorkflowNotificationTemplate";
import { editWorkflowNotificationTemplate } from "../../actions/workflowNotificationTemplate/WorkflowNotificationTemplateActionCreators";
import { useDispatch, useSelector } from "react-redux";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { AppState } from "../../state/AppState";
import { Col, Row } from "react-bootstrap";
import KeysysInput from "../form/input/KeysysInput";

interface IWorkflowNotificationTemplateForm {
    onClose: VoidFunction;
}

export default function WorkflowNotificationTemplateForm({ onClose }: IWorkflowNotificationTemplateForm) {
    const dispatch = useDispatch();

    const { selectedWorkflowNotificationTemplate } = useSelector(
        (state: AppState) => state.workflowNotificationTemplateState
    );

    const initalValues = useMemo(
        (): WorkflowNotificationTemplate => ({
            id: selectedWorkflowNotificationTemplate?.id ?? 0,
            name: selectedWorkflowNotificationTemplate?.name ?? "",
            subject: selectedWorkflowNotificationTemplate?.subject ?? "",
            body: selectedWorkflowNotificationTemplate?.body ?? "",
            aggregateBody: selectedWorkflowNotificationTemplate?.aggregateBody ?? "",
        }),
        [selectedWorkflowNotificationTemplate]
    );

    const WorkflowNotificationTemplateSchema = Yup.object<WorkflowNotificationTemplate>().shape({
        subject: Yup.string().required("System is required"),
        body: Yup.string().required("Body is required"),
    });
    function clearFormAndHide(
        formProps: FormikProps<WorkflowNotificationTemplate> | FormikHelpers<WorkflowNotificationTemplate>
    ): void {
        formProps.resetForm();
        onClose();
    }

    function handleSubmit(
        values: WorkflowNotificationTemplate,
        FormikHelpers: FormikHelpers<WorkflowNotificationTemplate>
    ) {
        if (selectedWorkflowNotificationTemplate === undefined) {
            return;
        } else {
            dispatch(
                editWorkflowNotificationTemplate(selectedWorkflowNotificationTemplate, {
                    ...selectedWorkflowNotificationTemplate,
                    ...values,
                })
            );
        }
        clearFormAndHide(FormikHelpers);
    }
    return (
        <>
            <Formik
                initialValues={initalValues}
                validationSchema={WorkflowNotificationTemplateSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<WorkflowNotificationTemplate>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditWorkflowNotificationTemplateModal"
                            show={true}
                            title={"Edit Notification Template"}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={true}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="WorkflowNotificationTemplateForm">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 3, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"name"}
                                            placeholder={""}
                                            fieldName={"name"}
                                            label={"Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={8} md={{ span: 3, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"subject"}
                                            placeholder={""}
                                            fieldName={"subject"}
                                            label={"Subject"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={8} md={{ span: 6, offset: 3 }}>
                                        <Field
                                            style={{ width: "100%" }}
                                            name="body"
                                            as="textarea"
                                            rows={7}
                                            minLength="2"
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={8} md={{ span: 6, offset: 3 }}>
                                        <Field
                                            style={{ width: "100%" }}
                                            name="aggregateBody"
                                            as="textarea"
                                            rows={7}
                                            minLength="2"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
