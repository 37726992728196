import { ICustomerImportService } from "../../services/customerImport/ICustomerImportService";
import {
    CUSTOMER_IMPORTS_FETCHED,
    CustomerImportsFetchedAction,
    CREATE_CUSTOMER_IMPORT,
    CreateCustomerImportAction,
    DELETE_CUSTOMER_IMPORT,
    DeleteCustomerImportAction,
    EDIT_CUSTOMER_IMPORT,
    FetchCustomerImportAction,
    FETCH_CUSTOMER_IMPORT,
} from "./CustomerImportActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { CustomerImport } from "../../models/CustomerImport";

export function getCustomerImports(): KeysysThunkAction<void, CustomerImportsFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerImportService = container.get<ICustomerImportService>(TYPES.CustomerImportService);
        const customerImports = await customerImportService.getCustomerImports();

        if (customerImports) {
            dispatch<CustomerImportsFetchedAction>({
                type: CUSTOMER_IMPORTS_FETCHED,
                customerImports: customerImports,
            });
        }
    };
}

export function getCustomerImportById(customerImportId: number): KeysysThunkAction<void, FetchCustomerImportAction> {
    return async (dispatch, getState, container) => {
        const customerImportService = container.get<ICustomerImportService>(TYPES.CustomerImportService);
        const customerImport = await customerImportService.getCustomerImportById(customerImportId);

        if (customerImport) {
            dispatch<FetchCustomerImportAction>({
                type: FETCH_CUSTOMER_IMPORT,
                customerImport: customerImport,
            });
        }
    };
}

export function getCustomerImportsByTenantId(
    tenantId: number,
    systemId?: number
): KeysysThunkAction<void, CustomerImportsFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerImportService = container.get<ICustomerImportService>(TYPES.CustomerImportService);
        const customerImports = await customerImportService.getCustomerImportsByTenantId(tenantId, systemId);

        if (customerImports) {
            dispatch<CustomerImportsFetchedAction>({
                type: CUSTOMER_IMPORTS_FETCHED,
                customerImports: customerImports,
            });
        }
    };
}

export function createCustomerImport(
    customerImport: CustomerImport
): KeysysThunkAction<void, CreateCustomerImportAction> {
    return async (dispatch, getState, container) => {
        const customerImportService = container.get<ICustomerImportService>(TYPES.CustomerImportService);
        const createdCustomerImport = await customerImportService.createCustomerImport(customerImport);
        if (createdCustomerImport) {
            dispatch<CreateCustomerImportAction>({
                type: CREATE_CUSTOMER_IMPORT,
                customerImport: createdCustomerImport,
            });
        }
        return !!createCustomerImport;
    };
}

export function editCustomerImport(
    originalCustomerImport: CustomerImport,
    newCustomerImport: CustomerImport
): KeysysThunkAction<void, CreateCustomerImportAction> {
    return async (dispatch, getState, container) => {
        const customerImportService = container.get<ICustomerImportService>(TYPES.CustomerImportService);
        const editedCustomerImport = await customerImportService.editCustomerImport(
            originalCustomerImport,
            newCustomerImport
        );
        if (editedCustomerImport) {
            dispatch<any>({
                type: EDIT_CUSTOMER_IMPORT,
                originalCustomerImport: originalCustomerImport,
                newCustomerImport: editedCustomerImport,
            });
        }
    };
}

export function deleteCustomerImport(
    customerImport: CustomerImport
): KeysysThunkAction<void, DeleteCustomerImportAction> {
    return async (dispatch, getState, container) => {
        const customerImportService = container.get<ICustomerImportService>(TYPES.CustomerImportService);
        const deletedCustomerImport = await customerImportService.deleteCustomerImport(customerImport);
        if (deletedCustomerImport) {
            dispatch<DeleteCustomerImportAction>({
                type: DELETE_CUSTOMER_IMPORT,
                customerImport: deletedCustomerImport,
            });
        }
    };
}
