import { inject, injectable } from "inversify";
import { Store } from "redux";
import { createPatch } from "rfc6902";
import { showToast } from "../../actions/ToastActionCreators";
import { ShowToastAction } from "../../actions/ToastActions";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_EMPLOYEE_SYSTEM_SUCCESS, EDIT_EMPLOYEE_SYSTEM_SUCCESS } from "../../constants/ToastConstants";
import { TYPES } from "../../dependencyInjection/Types";
import { EmployeeSystem } from "../../models/EmployeeSystem";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IEmployeeSystemService } from "./IEmployeeSystemService";

@injectable()
export class EmployeeSystemService implements IEmployeeSystemService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createEmployeeSystem(employeeSystem: EmployeeSystem): Promise<void | EmployeeSystem> {
        return this.httpClient
            .post<EmployeeSystem>("/employee-system", employeeSystem)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_EMPLOYEE_SYSTEM_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Employee System created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating an employee system. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editEmployeeSystem(
        employeeSystem: EmployeeSystem,
        editedEmployeeSystem: EmployeeSystem
    ): Promise<void | EmployeeSystem> {
        const operations = createPatch(employeeSystem, editedEmployeeSystem);
        return this.httpClient
            .patch<EmployeeSystem>(`/employee-system/${employeeSystem.id}`, operations)
            .then((patchedEmployeeSystem) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_EMPLOYEE_SYSTEM_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Employee System updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedEmployeeSystem;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing employee system. Please try again. If the problem continues, contact your administrator`
                )
            );
    }
}
