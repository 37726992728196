import React from "react";
import Button from "react-bootstrap/Button";
import { SmallSquareDeleteButtonProps } from "./SmallSquareDeleteButtonProps";

export default function SmallSquareDeleteButton(props: SmallSquareDeleteButtonProps) {
    function getButtonText() {
        return `Delete ${props.entityNameSingular}`;
    }

    return (
        <Button
            className={props.className}
            size={"sm"}
            variant="transparent"
            placeholder={getButtonText()}
            title={getButtonText()}
            onClick={props.onClick}
            disabled={!props.isActive}
        >
            <i style={{ color: "#f44336" }} className={"fas fa-trash-alt"} />
        </Button>
    );
}
