/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { CSSProperties, useEffect } from "react";
import { ErrorMessage, Field, FieldProps } from "formik";
import { nullProperties, redBorder } from "../../../cssProperties/CssProperties";
import ErrorMessageText from "../error-message/ErrorMessageText";
import { KeysysSelectProps } from "./KeysysSelectProps";
import Select from "react-select";
import { ValueType, OptionsType } from "react-select/src/types";
import { KeysysSelectOption } from "./KeysysSelectOption";
import { Styles } from "react-select/src/styles";

export default function KeysysSelect(props: KeysysSelectProps) {
    const [fieldValue, setStateFieldValue] = useState<KeysysSelectOption | KeysysSelectOption[] | null>(null);

    const allOption: KeysysSelectOption = { value: "*", label: "All" };

    useEffect(() => {
        if (props.multiple) {
            setStateFieldValue(
                props.options.filter((p) => props.formProps.values[props.fieldName]?.some((s: any) => s === p.value))
            );
        } else {
            setStateFieldValue(props.options.find((p) => p.value === props.formProps.values[props.fieldName]) ?? null);
        }
    }, [props.formProps.values, props.options]);

    function selectAllBeenChosen(change: OptionsType<KeysysSelectOption>) {
        return !!change && change.length > 0 && change[change.length - 1].value === allOption.value;
    }

    function setFieldValue(change: ValueType<KeysysSelectOption, true> | ValueType<KeysysSelectOption, false>) {
        if (props.multiple) {
            change = change as KeysysSelectOption[];
            if (props.allowSelectAll && selectAllBeenChosen(change)) {
                if (props.excludeFromSelectAll !== undefined) {
                    change = props.options.filter((option) => option.value !== props.excludeFromSelectAll!.value);
                } else {
                    change = props.options;
                }
            }
            props.formProps.setFieldValue(props.fieldName, !!change ? change.map((c) => c.value) : []);
        } else {
            change = change as KeysysSelectOption;
            props.formProps.setFieldValue(props.fieldName, change?.value);
        }
        if (!!props.onChange) {
            props.onChange(change);
        }
    }

    function getStyle(): Partial<Styles<any, any>> {
        const isInvalid =
            props.formProps.errors[props.fieldName] && props.formProps.touched[props.fieldName]
                ? redBorder
                : nullProperties;
        return {
            control: (base: CSSProperties, state: any) => {
                return {
                    ...base,
                    ...isInvalid,
                };
            },
            placeholder: (base: CSSProperties, state: any) => {
                return {
                    ...base,
                    color: "#495057",
                    fontWeight: 400,
                };
            },
        };
    }

    return (
        <div>
            {!!props.fieldName && (
                <React.Fragment>
                    <Field name={props.fieldName}>
                        {({ field }: FieldProps<any>) => (
                            <div>
                                <label htmlFor={props.id}>{props.label}</label>
                                <Select
                                    options={!!props.allowSelectAll ? [allOption, ...props.options] : props.options}
                                    isSearchable={true}
                                    isMulti={props.multiple}
                                    placeholder={props.placeholder}
                                    styles={getStyle()}
                                    onChange={setFieldValue}
                                    onBlur={() => props.formProps.setFieldTouched(props.fieldName, true)}
                                    defaultValue={props.initialValue}
                                    value={props.value ?? fieldValue}
                                    isDisabled={!!props.disabled ? props.disabled : false}
                                    isClearable={props.isClearable}
                                    components={props.components}
                                    captureMenuScroll={false}
                                />
                            </div>
                        )}
                    </Field>
                    <ErrorMessage name={props.fieldName}>{(msg) => <ErrorMessageText message={msg} />}</ErrorMessage>
                </React.Fragment>
            )}
            {!props.fieldName && (
                <React.Fragment>
                    <div>
                        <label htmlFor={props.id}>{props.label}</label>
                        <Select
                            options={!!props.allowSelectAll ? [allOption, ...props.options] : props.options}
                            isSearchable={true}
                            isMulti={props.multiple}
                            placeholder={props.placeholder}
                            styles={getStyle()}
                            onChange={(option: ValueType<any, any>) => (!!props.onChange ? props.onChange(option) : {})}
                            defaultValue={props.initialValue}
                            value={props.value ? props.value : undefined}
                            isDisabled={!!props.disabled ? props.disabled : false}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}
