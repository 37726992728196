import React, { useCallback, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { upsertCustomerReportDisplays } from "../../actions/customerReportDisplay/CustomerReportDisplayActionCreator";
import { MappedCustomerReport } from "../../models/CustomerReport";
import { CustomerReportDisplay } from "../../models/CustomerReportDisplay";
import { selectMappedCustomerReports } from "../../selectors/CustomerReportSelectors";
import { AppState } from "../../state/AppState";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";

export default function CustomerReportSubscriptionsModal() {
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const customerReports = useSelector(selectMappedCustomerReports);
    const subReportCategories = useSelector((state: AppState) => state.subReportCategoryState.subReportCategories);
    const subReports = customerReports.filter((cr) => cr.isSubReport);
    const { impersonator } = useSelector((state: AppState) => state.userState);
    const isReadOnlyCustomer = decodedAccessToken.roles === "ReadOnlyCustomer" || !!impersonator;
    const dispatch = useDispatch();

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [updatedDisplays, setUpdatedDisplays] = useState<CustomerReportDisplay[]>([]);
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    const subReportCategoryNames = useMemo((): string[] => {
        return Array.from(new Set(subReports.map((sr) => sr.categoryName).sort((a, b) => a.localeCompare(b)))).sort(
            (a, b) => {
                const subReportCategory = subReportCategories.find((c) => c.name === a);
                return subReportCategory ? subReportCategory.ordinal : -1;
            }
        );
    }, [subReports, subReportCategories]);

    const toggleRowExpand = (row: string) => {
        setExpandedRows((current) => {
            if (current.includes(row)) {
                return current.filter((x) => x !== row);
            } else {
                return [...current, row];
            }
        });
    };

    const getSubReportsForCategory = useCallback(
        (category: string) => {
            return subReports.filter((sr) => sr.categoryName === category);
        },
        [subReports]
    );

    const isReportHidden = useCallback(
        (subReport: MappedCustomerReport) => {
            const subReportId = subReport?.subReportId;
            if (!subReportId) return false;
            const updatedDisplay = updatedDisplays.find((crd) => crd.subReportId === subReportId);
            if (updatedDisplay) {
                return updatedDisplay.isHidden;
            }
            return !!subReport.isHidden;
        },
        [updatedDisplays]
    );

    const handleCheck = useCallback(
        (subReportId: number | undefined, customerImportId: number | undefined, checked: boolean) => {
            if (!subReportId) return;
            const display: CustomerReportDisplay = {
                tenantId: decodedAccessToken.tenant_id,
                subReportId: subReportId,
                customerImportId: customerImportId!,
                isHidden: !checked,
                isActive: true,
                id: 0,
            };
            setUpdatedDisplays((current) => [...current.filter((x) => x.subReportId !== subReportId), display]);
        },
        [decodedAccessToken.tenant_id]
    );

    const handleModalClose = () => {
        setModalShow(false);
        setUpdatedDisplays([]);
    };

    const handleSave = () => {
        if (updatedDisplays.length) {
            dispatch(upsertCustomerReportDisplays(updatedDisplays));
        }
        handleModalClose();
    };

    return (
        <>
            <Button
                variant="outline-dark"
                disabled={isReadOnlyCustomer}
                className="ms-2"
                onClick={() => setModalShow(true)}
            >
                Report Subscriptions
            </Button>
            <VerticallyCenteredModal
                id="customerReportSubscriptions"
                show={modalShow}
                title="Report Subscriptions"
                closeButtonText={"CANCEL"}
                okButtonText={"SAVE"}
                showSaveButton={true}
                onCloseButtonClick={handleModalClose}
                onOkButtonClick={handleSave}
                type={"success"}
                size="lg"
            >
                <Table>
                    <thead>
                        <tr>
                            <th className="text-start">Category</th>
                            <th className="text-start">Name</th>
                            <th>Preference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subReportCategoryNames.map((c) => (
                            <React.Fragment key={`category-${c}`}>
                                <tr>
                                    <td colSpan={3} className="text-start">
                                        <span onClick={() => toggleRowExpand(c)} style={{ cursor: "pointer" }}>
                                            <i
                                                className={`me-2 fas fa-angle-${
                                                    expandedRows.includes(c) ? "down" : "right"
                                                }`}
                                            />
                                            <span className="fw-bold">{c}</span>{" "}
                                            <small>({getSubReportsForCategory(c).length})</small>
                                        </span>
                                    </td>
                                </tr>
                                {expandedRows.includes(c) &&
                                    getSubReportsForCategory(c).map((cr) => (
                                        <tr key={`category-${c}-${cr.id}`}>
                                            <td></td>
                                            <td className="text-start">
                                                {(cr.subReportName || cr.reportName) ?? "Undefined"}
                                            </td>
                                            <td style={{ width: "120px" }} className="text-start">
                                                <Form.Check
                                                    type="switch"
                                                    id={`cr-switch-${cr.id}`}
                                                    label={isReportHidden(cr) ? "Hide" : "Show"}
                                                    checked={!isReportHidden(cr)}
                                                    disabled={!!cr.isForced}
                                                    onChange={(e) =>
                                                        handleCheck(
                                                            cr.subReportId,
                                                            cr.customerImportId,
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </VerticallyCenteredModal>
        </>
    );
}
