import { inject } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { EmployeeCoreId } from "../../models/EmployeeCoreId";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IEmployeeCoreIdService } from "./IEmployeeCoreIdService";

export class EmployeeCoreIdService implements IEmployeeCoreIdService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getEmployeeCoreIds(employeeId: number): Promise<void | EmployeeCoreId[]> {
        return this.httpClient
            .get<EmployeeCoreId[]>(`/employee-core-id/employee/${employeeId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the employee core ids. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    updateEmployeeCoreIds(employeeId: number, coreIds: string[]): Promise<void | string[]> {
        return this.httpClient
            .post<string[]>(`/employee-core-id/employee/${employeeId}`, coreIds)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while updating Employee Core Ids. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
