import { TYPES } from "../../dependencyInjection/Types";
import { IEmployeeService } from "../../services/employee/IEmployeeService";
import { IEmployeeSamAccountService } from "../../services/employeeSamAccount/IEmployeeSamAccountService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    EmployeeSamAccountActions,
    EmployeeSamAccountsFetchedAction,
    EmployeesFetchedAction,
    EMPLOYEES_FETCHED,
    EMPLOYEE_SAM_ACCOUNTS_FETCHED,
    UpdateEmployeeSamAccountsAction,
    UPDATE_EMPLOYEE_SAM_ACCOUNTS,
} from "./EmployeeSamAccountAction";

export function getEmployeeSamAccounts(employeeId: number): KeysysThunkAction<void, EmployeeSamAccountsFetchedAction> {
    return async (dispatch, getState, container) => {
        const employeeSamAccountService = container.get<IEmployeeSamAccountService>(TYPES.EmployeeSamAccountService);
        const employeeSamAccounts = await employeeSamAccountService.getEmployeeSamAccounts(employeeId);

        if (employeeSamAccounts) {
            dispatch<EmployeeSamAccountsFetchedAction>({
                type: EMPLOYEE_SAM_ACCOUNTS_FETCHED,
                employeeSamAccounts: employeeSamAccounts,
            });
        }
    };
}

export function updateEmployeeSamAccounts(
    employeeId: number,
    tenantId: number,
    samAccounts: string[]
): KeysysThunkAction<void, EmployeeSamAccountActions> {
    return async (dispatch, getState, container) => {
        const employeeSamAccountService = container.get<IEmployeeSamAccountService>(TYPES.EmployeeSamAccountService);
        const updatedSamAccounts = await employeeSamAccountService.updateEmployeeSamAccounts(employeeId, samAccounts);
        if (updatedSamAccounts) {
            dispatch<UpdateEmployeeSamAccountsAction>({
                type: UPDATE_EMPLOYEE_SAM_ACCOUNTS,
                employeeSamAccounts: updatedSamAccounts,
            });
            const employeeService = container.get<IEmployeeService>(TYPES.EmployeeService);
            const employees = await employeeService.getEmployeesByTenantId(tenantId);

            if (employees) {
                dispatch<EmployeesFetchedAction>({
                    type: EMPLOYEES_FETCHED,
                    employees: employees,
                });
            }
        }
        return !!updatedSamAccounts;
    };
}
