import { IntakeCustomerFile } from "../../models/IntakeCustomerFile";

export const INTAKECUSTOMERFILES_FETCHED = "INTAKECUSTOMERFILES_FETCHED";
export const INACTIVE_INTAKECUSTOMERFILES_FETCHED = "INACTIVE_INTAKECUSTOMERFILES_FETCHED";
export const INTAKECUSTOMERFILE_CREATED = "INTAKECUSTOMERFILE_CREATED";
export const INTAKECUSTOMERFILE_DELETED = "INTAKECUSTOMERFILE_DELETED";
export const INTAKECUSTOMERFILE_EDITED = "INTAKECUSTOMERFILE_EDITED";
export const INTAKECUSTOMERFILE_RESTORED = "INTAKECUSTOMERFILE_RESTORED";
export const SELECT_INTAKECUSTOMERFILE = "SELECT_INTAKECUSTOMERFILE";
export const CLEAR_SELECTED_INTAKECUSTOMERFILE = "CLEAR_SELECTED_INTAKECUSTOMERFILE";

export interface IntakeCustomerFilesFetchedAction {
    type: typeof INTAKECUSTOMERFILES_FETCHED;
    intakeCustomerFiles: IntakeCustomerFile[];
}

export interface InactiveIntakeCustomerFilesFetchedAction {
    inactiveIntakeCustomerFiles: IntakeCustomerFile[];
    type: typeof INACTIVE_INTAKECUSTOMERFILES_FETCHED;
}

export interface IntakeCustomerFileCreatedAction {
    type: typeof INTAKECUSTOMERFILE_CREATED;
    intakeCustomerFile: IntakeCustomerFile;
}

export interface IntakeCustomerFileDeletedAction {
    type: typeof INTAKECUSTOMERFILE_DELETED;
    intakeCustomerFile: IntakeCustomerFile;
}

export interface IntakeCustomerFileEditedAction {
    newIntakeCustomerFile: IntakeCustomerFile;
    intakeCustomerFile: IntakeCustomerFile;
    type: typeof INTAKECUSTOMERFILE_EDITED;
}

export interface IntakeCustomerFileRestoredAction {
    type: typeof INTAKECUSTOMERFILE_RESTORED;
    intakeCustomerFile: IntakeCustomerFile;
}

export interface SelectIntakeCustomerFileAction {
    type: typeof SELECT_INTAKECUSTOMERFILE;
    intakeCustomerFile: IntakeCustomerFile;
}

export interface ClearSelectedIntakeCustomerFileAction {
    type: typeof CLEAR_SELECTED_INTAKECUSTOMERFILE;
}

export type IntakeCustomerFileActions =
    | IntakeCustomerFilesFetchedAction
    | InactiveIntakeCustomerFilesFetchedAction
    | IntakeCustomerFileCreatedAction
    | IntakeCustomerFileDeletedAction
    | IntakeCustomerFileEditedAction
    | IntakeCustomerFileRestoredAction
    | SelectIntakeCustomerFileAction
    | ClearSelectedIntakeCustomerFileAction;
