import type { Store } from "redux";
import { ImpersonationResponse, User } from "../models/User";
import type { IHttpClient } from "./HttpClient/IHttpClient";
import { inject, injectable } from "inversify";
import { TYPES } from "../dependencyInjection/Types";
import {
    ADD_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    EDIT_USER_SUCCESS,
    ERROR,
    RESTORE_USER_SUCCESS,
} from "../constants/ToastConstants";
import { KeysysToastProps } from "../components/toast/KeysysToastProps";
import { showToast } from "../actions/ToastActionCreators";
import { createPatch } from "rfc6902";
import { ShowToastAction } from "../actions/ToastActions";

@injectable()
export class UserService {
    constructor(@inject(TYPES.Store) private store: Store, @inject(TYPES.HttpClient) private httpClient: IHttpClient) {}

    refresh(refresh_token: string) {
        return this.httpClient
            .postUrlEncodedNoAuth<any>("/connect/token", {
                client_id: "FinosecIdentity",
                grant_type: "refresh_token",
                refresh_token,
            })
            .then((resp) => {
                return resp;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while logging in". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    addUser(user: User): Promise<void | User> {
        return this.httpClient
            .post<User>("/users", user)
            .then((u) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_USER_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `User "${u.emailAddress}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return u;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while creating user "${user.emailAddress}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    deleteUser(user: User): Promise<void | User> {
        return this.httpClient
            .delete(`/users/${user.id}`)
            .then(() => {
                const returnUser = { ...user, isActive: false }; // TODO: Should API return the deleted user so we don't have to do this?
                const toastProps: KeysysToastProps = {
                    name: DELETE_USER_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `User "${user.emailAddress}" deactivated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnUser;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while deleting user "${user.emailAddress}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    editUser(user: User, newUser: User): Promise<void | User> {
        const operations = createPatch(user, newUser);
        return this.httpClient
            .patch<User>(`/users/${user.id}`, operations)
            .then((patchedUser) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_USER_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `User "${patchedUser.emailAddress}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedUser;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while updating user "${user.emailAddress}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    getInactiveUsers(): Promise<void | User[]> {
        return this.httpClient
            .get<User[]>("/users/inactive")
            .catch((exception) =>
                this.handleError(
                    exception,
                    `We encountered an error while retrieving inactive users. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getUsers(): Promise<void | User[]> {
        return this.httpClient
            .get<User[]>("/users")
            .catch((exception) =>
                this.handleError(
                    exception,
                    `We encountered an error while retrieving users. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    impersonate(userEmail: string): Promise<void | ImpersonationResponse> {
        return this.httpClient
            .post<string, ImpersonationResponse>("/users/impersonate", userEmail)
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while trying to impersonate user with email ${userEmail}. Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    unimpersonate(): Promise<void | ImpersonationResponse> {
        return this.httpClient
            .post<ImpersonationResponse | undefined>("/users/unimpersonate", undefined)
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while trying to unimpersonate user. Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    handleError(exception: any, bodyText: string): void {
        if (exception.statusCode === 401) {
            return;
        }

        const toastProps: KeysysToastProps = {
            name: ERROR,
            theme: "danger",
            titleInHeader: "Error",
            body: bodyText,
            delay: 15000,
        };
        this.store.dispatch(showToast(toastProps));
    }

    restoreUser(userToRestore: User): Promise<void | User> {
        const updatedUser = { ...userToRestore, isActive: true };
        const operations = createPatch(userToRestore, updatedUser);

        return this.httpClient
            .patch<User>(`/users/${userToRestore.id}`, operations)
            .then((restoredUser) => {
                const toastProps: KeysysToastProps = {
                    name: RESTORE_USER_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `User "${restoredUser.emailAddress}" activated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return restoredUser;
            })
            .catch((exception) => {
                this.handleError(
                    exception,
                    `We encountered an error while restoring user "${userToRestore.emailAddress}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }
}
