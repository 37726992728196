import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Store } from "redux";
import { IWorkflowFrequencyService } from "./IWorkflowFrequencyService";
import { WorkflowFrequency } from "../../models/WorkflowFrequency";

@injectable()
export class WorkflowFrequencyService implements IWorkflowFrequencyService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}
    getWorkflowFrequencies(): Promise<void | WorkflowFrequency[]> {
        return this.httpClient
            .get<WorkflowFrequency[]>("/workflow-frequency")
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow frequencies. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }
}
