import { createSelector } from "reselect";
import _ from "lodash";
import { AppState } from "../state/AppState";
import { FinosecSystem, FinosecSystemWithEmployee } from "../models/FinosecSystem";

export const selectEmployees = (state: AppState) => state.employeeState.employees;
export const selectSystems = (state: AppState) => state.finosecSystemState.finosecSystems;

export const selectEmployeeSystems = createSelector([selectEmployees, selectSystems], (employees, systems) => {
    let result: FinosecSystemWithEmployee[] = [];
    for (let system of systems) {
        let employeesWithSystemAccess = employees.filter((e) =>
            e.employeeSystems?.map((es) => es.finosecSystemId).includes(system.id)
        );
        for (let employeeWithSystemAccess of employeesWithSystemAccess) {
            const employee = employees.find((e) => e.id === employeeWithSystemAccess.id)!;
            result.push({
                ...system,
                employeeName: `${employee.firstName} ${employee.lastName}`,
                employee,
            });
        }
    }
    const employeesWithSystems = result.map((r) => r.employee.id);
    const employeesWithoutSystems = employees.filter((e) => !employeesWithSystems.includes(e.id));
    const emptySystem: FinosecSystem = {
        id: 0,
        name: "No System",
    };
    for (let employee of employeesWithoutSystems) {
        result.push({
            ...emptySystem,
            employeeName: `${employee.firstName} ${employee.lastName}`,
            employee,
        });
    }
    return result;
});
