/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppState } from "../state/AppState";
import { selectMasterReportsBySystem, selectReportById, selectSubReportById } from "../selectors/ReportSelectors";
import { createSubReport, editSubReport, getSubReports } from "../actions/subReports/SubReportActionCreators";
import { getSubReportAttributes } from "../actions/subReportAttributes/SubReportAttributeActionCreator";
import { getReportAttributes } from "../actions/reportAttributes/ReportAttributeActionCreator";
import { getReports } from "../actions/reports/ReportActionCreators";
import { getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { selectSystems } from "../selectors/FinosecSystemSelectors";
import { FormikContextType } from "formik";
import {
    createSubReportCategory,
    getSubReportCategories,
} from "../actions/subReportCategories/SubReportCategoryActionCreators";
import { SubReportCategory } from "../models/SubReportCategory";
import { getReportTypes } from "../actions/reportType/ReportTypeActionCreateor";
import StateManager from "react-select";

interface ISubReportColumnForm {
    attribute?: string;
    filterTypeId: number;
    id: number;
    filterValue: string;
    groupBy: boolean;
    useAttributeInColHeader: boolean;
    hide: boolean;
    reportAttributeId: number;
}

interface ISubReportForm {
    name: string;
    id: number;
    system: number | null;
    masterList: number | null;
    columns: ISubReportColumnForm[];
    categoryId: number;
    isReadOnly: boolean;
    filter?: string;
    isForced: boolean;
    reportTypeId?: number;
    tenantIds?: number[];
}

export const useSubReportForm = () => {
    let params = useParams<{ id: any }>();
    const dispatch = useDispatch();
    const subReportInitValues: any = {
        name: "",
        system: 0,
        masterList: 0,
        reportId: 0,
        categoryId: 0,
        columns: [],
        reportTypeId: 0,
    };

    const subReport = useSelector((state: AppState) => selectSubReportById(state, +params.id));
    const finosecSystems = useSelector(selectSystems);
    const categories = useSelector((state: AppState) => state.subReportCategoryState?.subReportCategories || []);
    const reportTypes = useSelector((state: AppState) => state.reportTypeState?.reportTypes || []);
    const tenants = useSelector((state: AppState) => state.tenantState?.tenants || []);
    const [selectedMasterListId, setSelectedMasterListId] = useState<number>();
    const [selectedSystemId, setSelectedSystemId] = useState<number>();
    const masterReports = useSelector((state: AppState) => selectMasterReportsBySystem(state, selectedSystemId || 0));
    const selectedMasterList = useSelector((state: AppState) => selectReportById(state, selectedMasterListId || 0));
    const [subReportName, setSubReportName] = useState<any>(null);
    const [selectedSubReportCategory, setSelectedSubReportCategory] = useState<number>();
    const [selectedTenantIds, setSelectedTenantIds] = useState<number[]>();
    const [selectedReportType, setSelectedReportType] = useState<number>();

    useEffect(() => {
        if (selectedMasterListId || subReport?.reportId) {
            dispatch(getReportAttributes(selectedMasterListId || subReport?.reportId || 0));
        }

        if (!categories || categories.length === 0) {
            dispatch(getSubReportCategories());
        }

        if (!reportTypes || reportTypes.length === 0) {
            dispatch(getReportTypes());
        }

        if (!finosecSystems || finosecSystems.length === 0) {
            dispatch(getFinosecSystems());
            if (!masterReports || masterReports.length === 0) {
                // Dispatch store to load
                dispatch(getReports());
            }
        }
    }, [selectedMasterListId]);

    useEffect(() => {
        dispatch(getSubReports());

        if (params.id) {
            dispatch(getSubReportAttributes(+params.id));
        }
    }, []);

    useEffect(() => {
        if (subReport?.system && !selectedSystemId) {
            setSelectedSystemId(subReport.system);
        }
    }, [subReport]);

    const onCreateCategory = (category: string, ordinal?: number) => {
        dispatch(createSubReportCategory({ name: category, ordinal: ordinal || 0 } as SubReportCategory));
    };

    const selectItem = (value: any, stateCallback: (value: any) => void, formCtx?: FormikContextType<any>) => {
        stateCallback(value);
        if (formCtx) {
            const subReportName = formCtx.values?.name;
            setSubReportName(subReportName);
        }
    };

    let subReportColumns: ISubReportColumnForm[] | null = null;
    if (selectedMasterList) {
        subReportColumns = selectedMasterList?.columns?.map((rc) => {
            const subReportColumn = subReport?.columns?.find((sc: any) => sc.reportAttributeId === rc.id) || {
                attribute: rc.attribute,
                reportAttributeId: rc.id,
                filterValue: "",
                filterTypeId: 1,
                groupBy: false,
                useAttributeInColHeader: false,
                hide: false,
                id: 0,
            };
            return subReportColumn;
        });
    }

    const onSave = (values: ISubReportForm) => {
        const { columns, id, name, filter, isReadOnly, categoryId, isForced, reportTypeId, tenantIds } = values;
        if (params.id && subReport) {
            // Update
            const { columns: originalColumns, system, ...originalSubReport } = subReport;
            dispatch(
                editSubReport(
                    originalSubReport,
                    {
                        ...originalSubReport,
                        reportId: selectedMasterListId || +subReport.reportId,
                        name,
                        filter,
                        isReadOnly,
                        categoryId,
                        isForced,
                        reportTypeId,
                        tenantIds,
                    },
                    originalColumns.map((oc: any) => {
                        const { attribute, ...attributeValues } = oc;
                        return attributeValues;
                    }),
                    columns.map((c) => {
                        const { attribute, ...attributeValues } = c;
                        return {
                            ...attributeValues,
                            subReportId: id,
                        };
                    })
                )
            );
        } else {
            // Create
            if (selectedMasterListId) {
                const reportAttribute = {
                    reportId: selectedMasterListId,
                    name,
                    filter,
                    isReadOnly,
                    categoryId: selectedSubReportCategory,
                    isForced,
                    reportTypeId,
                    tenantIds: tenantIds,
                };
                const mappedColumns = columns.map((c) => {
                    const { attribute, filterTypeId, id, ...attributeValues } = c;
                    return {
                        ...attributeValues,
                        filterTypeId: filterTypeId || 1,
                    };
                });
                dispatch(createSubReport(reportAttribute as any, mappedColumns as any));
            }
        }
    };

    const subReportLoading = params.id ? !subReport : false;
    return {
        subReport: subReport
            ? {
                  ...subReport,
                  name: subReportName || subReport.name,
                  system: selectedSystemId || subReport.system,
                  reportId: selectedMasterListId || subReport.reportId,
                  columns: subReportColumns || subReport.columns,
              }
            : {
                  ...subReportInitValues,
                  name: subReportName || subReportInitValues.name,
                  system: selectedSystemId || subReportInitValues.system,
                  reportId: selectedMasterListId || subReportInitValues.reportId,
                  columns: subReportColumns,
              },
        setSelectedMasterListId,
        setSelectedSystemId,
        setSelectedSubReportCategory,
        setSelectedReportType,
        setSelectedTenantIds,
        onSave,
        selectItem,
        masterListOptions: masterReports.map((mr) => ({
            value: mr.id,
            text: mr.name,
            key: mr.name,
        })),
        systemOptions: finosecSystems.map((fs) => ({
            value: fs.id,
            text: fs.name,
            key: fs.name,
        })),
        categoryOptions: categories.map((c) => ({
            value: c.id,
            text: c.name,
            key: c.name,
        })),
        reportTypeOptions: reportTypes.map((rt) => ({
            value: rt.id,
            text: rt.name,
            key: rt.name,
        })),
        tenantOptions: tenants
            .filter((t) => t.parentTenantId !== undefined && t.parentTenantId > 0)
            .map((t) => ({
                value: t.id,
                label: t.name,
                key: t.name,
            })),
        onCreateCategory,
        loading: subReportLoading || finosecSystems.length === 0,
        isEdit: !!params?.id,
        selectedReportType,
    };
};
