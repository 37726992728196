import { SubReportCategory } from "../../models/SubReportCategory";

export const SUB_REPORT_CATEGORIES_FETCHED = "SUB_REPORT_CATEGORIES_FETCHED";
export const CREATE_SUB_REPORT_CATEGORY = "CREATE_SUB_REPORT_CATEGORY";
export const DELETE_SUB_REPORT_CATEGORY = "DELETE_SUB_REPORT_CATEGORY";
export const EDIT_SUB_REPORT_CATEGORY = "EDIT_SUB_REPORT_CATEGORY";
export const FETCHING_SUB_REPORT_CATEGORIES = "FETCHING_SUB_REPORT_CATEGORIES";

export interface SubReportCategoriesFetchedAction {
    type: typeof SUB_REPORT_CATEGORIES_FETCHED;
    subReportCategories: SubReportCategory[];
}

export interface FetchingSubReportCategoriesAction {
    type: typeof FETCHING_SUB_REPORT_CATEGORIES;
}

export interface CreateSubReportCategoryAction {
    type: typeof CREATE_SUB_REPORT_CATEGORY;
    subReportCategory: SubReportCategory;
}

export interface EditSubReportCategoryAction {
    type: typeof EDIT_SUB_REPORT_CATEGORY;
    originalSubReportCategory: SubReportCategory;
    newSubReportCategory: SubReportCategory;
}

export interface DeleteSubReportCategoryAction {
    type: typeof DELETE_SUB_REPORT_CATEGORY;
    subReportCategory: SubReportCategory;
}

export type SubReportCategoryActions =
    | SubReportCategoriesFetchedAction
    | FetchingSubReportCategoriesAction
    | CreateSubReportCategoryAction
    | DeleteSubReportCategoryAction
    | EditSubReportCategoryAction;
