import { CustomerDocumentStatisticsState } from "../../state/CustomerDocumentState";
import {
    CustomerDocumentsStatisticsAction,
    CUSTOMER_DOCUMENTS_STATISTICS
} from "../../actions/customerDocument/CustomerDocumentActions";

const initialState: CustomerDocumentStatisticsState = {
    customerDocumentStatistics: []
};

export function customerDocumentStatisticsReducer(
    state:CustomerDocumentStatisticsState= initialState,
    action:CustomerDocumentsStatisticsAction
    ): CustomerDocumentStatisticsState{
        switch (action.type) {
            case CUSTOMER_DOCUMENTS_STATISTICS:
                return {
                    ...state,
                    customerDocumentStatistics: action.customerDocumentStatistics,
                };
    
            default:
                return state;
        }
}