import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { AppState } from "../../state/AppState";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { useFinosecSystemStatisticsByImportId } from "../../hooks/customerFinosecSystemStatisticHook";
import LinkWrapper from "../routing/LinkWrapper";
import KeysysSelect from "../form/select/KeysysSelect";
import { customerImportModel } from "./customerImportModel";
import { Form, Formik, FormikProps } from "formik";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import moment from "moment";
import { useCustomerImportReportList } from "../../hooks/customerReportsHook";
import { useCustomerImportsList } from "../../hooks/customerImportsHook";
import PageHeader from "../header/PageHeader";
import { Notes } from "../notes/Notes";
import { useNotes } from "../../hooks/notesHook";
import { Chart } from "react-google-charts";
import { useCurrentUser } from "../../hooks/currentUserHook";
import { useCallback, useEffect, useMemo, useState } from "react";
import CustomerSystemPrintModal from "./customerSystemPrintModal";
import { LoadingSpinner } from "../loading/Loading";
import { getSubReportCategories } from "../../actions/subReportCategories/SubReportCategoryActionCreators";
import { selectMappedCustomerReports } from "../../selectors/CustomerReportSelectors";
import CustomerReportSubscriptionsModal from "./customerReportSubscriptionsModal";
import { getCustomerReportDisplays } from "../../actions/customerReportDisplay/CustomerReportDisplayActionCreator";
import { getCustomerImportSubReportAssignments } from "../../actions/customerImportSubReportAssignment/CustomerImportSubReportAssignmentActionCreator";

const CustomerReportList = () => {
    let params = useParams<{ customerImportId: any }>();

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { impersonator } = useSelector((state: AppState) => state.userState);
    const isReadOnlyCustomer = decodedAccessToken.roles === "ReadOnlyCustomer" || !!impersonator;
    const dispatch = useDispatch();
    const customerImportId = +params.customerImportId;
    const { tenant_id: tenantId, userId, tenant_name } = useSelector(
        (state: AppState) => state.authenticationState.decodedAccessToken
    );
    const user = useCurrentUser(userId);
    useCustomerImportReportList(customerImportId);
    const { customerImports, customerImportFiles } = useCustomerImportsList(tenantId, customerImportId);
    const { importStatistics } = useFinosecSystemStatisticsByImportId(customerImportId);
    const customerImport = useSelector((state: AppState) =>
        state.customerImportState.customerImports.find((ci) => ci.id === customerImportId)
    );
    const history = useHistory();
    const finosecSystemId = customerImport?.finosecSystemId;
    const initialValues = {
        customerImportId,
        finosecSystemId,
    };

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const system = useSelector((state: AppState) => state.finosecSystemState.finosecSystems).find(
        (fs) => fs.id === customerImport?.finosecSystemId
    );

    useEffect(() => {
        dispatch(getSubReportCategories());
        dispatch(getCustomerReportDisplays(tenantId));
        dispatch(getCustomerImportSubReportAssignments(customerImportId));
    }, [customerImportId, dispatch, tenantId]);

    const customerReports = useSelector(selectMappedCustomerReports).filter((x) => !x.isHidden);

    const loading = useSelector((state: AppState) =>
        state.customerReportState ? state.customerReportState.loading : true
    );

    const getReportSummaryLink = useCallback(
        (data: any) => {
            return `/uar/customer-import/${params.customerImportId}/customer-report/${data?.data?.id}`;
        },
        [params.customerImportId]
    );
    const { notes, createNote } = useNotes(
        (r) =>
            r.finosecSystemId === customerImport?.finosecSystemId &&
            r.customerImportId === customerImportId &&
            !r.customerReportId,
        [customerImportId]
    );

    const [textSearch, setSearchText] = useState<string>("");

    const [customerReportExpansion, setCustomerReportExpansion] = useState<
        { key: string; expandedValue: boolean }[] | null
    >(() => {
        const saved = localStorage.getItem("customerReportExpansion");
        return saved && saved !== "undefined" ? JSON.parse(saved) : null;
    });

    const handleRowExpand = (group: { key: string; expandedValue: boolean }) => {
        const customerReportGroup = customerReportExpansion?.find((cue) => cue.key === group.key);
        let groups = [];
        if (customerReportGroup && customerReportExpansion) {
            groups = [...customerReportExpansion?.filter((cue) => cue.key !== group.key), group];
        } else if (!customerReportExpansion) {
            groups = [group];
        } else {
            groups = [...customerReportExpansion, group];
        }
        setCustomerReportExpansion(groups);
    };

    useEffect(() => {
        localStorage.setItem("customerReportExpansion", JSON.stringify(customerReportExpansion));
    }, [customerReportExpansion]);

    const columnDefs: ColDef[] = useMemo(
        () => [
            {
                rowGroup: true,
                hide: true,
                headerName: "Test",
                showRowGroup: false,
                valueGetter: (params: any) => {
                    return params?.data?.categoryName;
                },
                cellRendererFramework: (data: any) => {
                    return <span style={{ fontWeight: "bold" }}>{data.value}</span>;
                },
                // suppressSizeToFit: true,
                // resizable: false,
                // flex: 2,
                // minWidth: 35,
                // maxWidth: 35
            },
            {
                headerName: "Name",
                cellRendererFramework: (data: any) => {
                    return data?.data ? (
                        <LinkWrapper path={getReportSummaryLink(data)}>
                            <span style={{ textDecorationLine: "underline" }}>
                                {data?.data?.subReportName || data?.data?.reportName || "Undefined"}
                            </span>
                        </LinkWrapper>
                    ) : (
                        ""
                    );
                },
                valueGetter: (params: any) => {
                    return params?.data?.subReportName || params?.data?.reportName;
                },
                sort: "asc",
            },
            /* Commenting out and not taking out completely in case they do want this back
            {
                headerName: "User Accounts Changed",
                cellRendererFramework: (data: any) => (
                    <span>
                        {data?.data?.isSubReport && !data?.data?.isReadOnlySubReport ? data?.data?.totalDisplayedRows : ""}
                    </span>
                ),
                valueGetter: (params) => {
                    return params?.data?.totalDisplayedRows;
                },
            },
            {
                headerName: "User Accounts Reviewed",
                cellRendererFramework: (data: any) => (
                    <span>
                        {data?.data?.isSubReport && !data?.data?.isReadOnlySubReport ? data?.data?.rowsReviewed : ""}
                    </span>
                ),
            },*/
            {
                headerName: "Review Complete",
                cellRendererFramework: (data: any) => {
                    const isReviewComplete: boolean = data?.data?.isReviewComplete;

                    return (
                        <span>
                            {data?.data?.isSubReport && !data?.data?.isReadOnlySubReport
                                ? isReviewComplete
                                    ? "Yes"
                                    : "No"
                                : ""}
                        </span>
                    );
                },
                valueGetter: (params) => {
                    return params?.data?.isReviewComplete ? "Yes" : "No";
                },
                maxWidth: 130,
            },
            {
                headerName: "Review Complete Date",
                cellRendererFramework: (data: any) => {
                    const isReviewComplete: boolean = data?.data?.isReviewComplete;

                    return (
                        <span>
                            {data?.data?.isSubReport &&
                            !data?.data?.isReadOnlySubReport &&
                            isReviewComplete &&
                            data?.data?.lastModifiedOn
                                ? moment(data?.data?.lastModifiedOn).format("MM-DD-YYYY")
                                : ""}
                        </span>
                    );
                },
                valueGetter: (params) => {
                    return params?.data?.isReviewComplete
                        ? moment(params?.data?.lastModifiedOn).format("MM-DD-YYYY")
                        : "";
                },
                maxWidth: 175,
            },
            {
                headerName: "Notes/Flags",
                cellRendererFramework: (data: any) => (
                    <span>
                        {data?.data?.hasNotes ? (
                            <i
                                className={"far fa-file-alt"}
                                style={{ fontSize: "1rem", textAlign: "center", color: "#980101" }}
                            />
                        ) : (
                            ""
                        )}
                        &nbsp;
                        {data?.data?.hasFlags ? (
                            <i
                                className={"fas fa-flag"}
                                style={{ fontSize: "1rem", textAlign: "center", color: "#980101" }}
                            />
                        ) : (
                            ""
                        )}
                    </span>
                ),
                maxWidth: 130,
            },
        ],
        [getReportSummaryLink]
    );

    function handleOptionChange(data: KeysysSelectOption) {
        const selectedOption: number = +data?.value;
        history.push(`/uar/customer-import/${selectedOption}`);
    }

    const importOptions = customerImports
        .filter((ci) => ci.finosecSystemId === customerImport?.finosecSystemId)
        .map((customerImport) => {
            return { value: customerImport?.id, label: customerImport?.periodName } as KeysysSelectOption;
        });

    return (
        <>
            <Row className="ps-3 pe-3 customer-report-list-header">
                <Col className="col-6 mb-0">
                    <PageHeader>
                        <h2>{system?.name}</h2>
                    </PageHeader>
                </Col>
                <Col className="col-2 mb-0">
                    <Formik initialValues={initialValues} onSubmit={() => {}} enableReinitialize={true}>
                        {(formProps: FormikProps<customerImportModel>) => {
                            return (
                                <Form id="customerImportForm">
                                    <Col xs={12} lg={6}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"customerImportId"}
                                            placeholder={""}
                                            fieldName={"customerImportId"}
                                            label={""}
                                            onChange={handleOptionChange}
                                            options={importOptions}
                                            multiple={false}
                                        />
                                    </Col>
                                </Form>
                            );
                        }}
                    </Formik>
                </Col>
                <Col className="col-4 text-end">
                    <CustomerSystemPrintModal
                        buttonTitle={"Download System Summary"}
                        tenantName={tenant_name}
                        periodName={customerImport?.periodName}
                        customerImportFiles={customerImportFiles.filter(
                            (cif) => cif.customerImportId === customerImport?.id
                        )}
                        tableData={customerReports}
                        columnDefs={columnDefs}
                        notes={notes}
                        systemName={system?.name}
                        totalStatistics={importStatistics}
                    />
                    <CustomerSystemPrintModal
                        buttonTitle={"Download Full System Report"}
                        tenantName={tenant_name}
                        customerReports={customerReports.filter((cr) => cr.isSubReport)}
                        periodName={customerImport?.periodName}
                        customerImportFiles={customerImportFiles.filter(
                            (cif) => cif.customerImportId === customerImport?.id
                        )}
                        tableData={customerReports}
                        columnDefs={columnDefs}
                        notes={notes}
                        systemName={system?.name}
                        totalStatistics={importStatistics}
                    />
                </Col>
            </Row>
            {!inIframe() && (
                <p className="title-bar ps-3 pt-2 pb-2">
                    <LinkWrapper path={`/uar`}>UAR Home</LinkWrapper> / {system?.name}
                </p>
            )}
            <Row className="ps-3 pe-3">
                <Col>
                    <Table hover>
                        <thead style={{ borderStyle: "hidden" }}>
                            <tr>
                                <td colSpan={2}>User Access Reports Summary</td>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Commenting out and not taking out completely in case they do want this back
                            <tr>
                                <td>Total User Account Changes:</td>
                                <td>{importStatistics.totalUserChanges}</td>
                            </tr> */}
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Report Count:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {importStatistics.totalSubReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Reports Reviewed:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {importStatistics.totalReviewedReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Reports Remaining to Review:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {importStatistics.totalNotReviewedReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Percent Complete:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {importStatistics.totalSubReports
                                        ? Math.round(
                                              (importStatistics.totalReviewedReports /
                                                  importStatistics.totalSubReports) *
                                                  100
                                          )
                                        : 0}
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table hover>
                        <thead style={{ borderStyle: "hidden" }}>
                            <tr>
                                <td>Review Progress</td>
                            </tr>
                        </thead>
                    </Table>
                    <Row className="justify-content-center">
                        <Chart
                            width={"500px"}
                            height={"300px"}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ["Task", ""],
                                ["Total Reports Reviewed", importStatistics.totalReviewedReports],
                                ["Total Reports Not Reviewed", importStatistics.totalNotReviewedReports],
                            ]}
                            options={{
                                backgroundColor: "transparent",
                                slices: {
                                    0: { color: "#079247" },
                                    1: { color: "#CCCCCC", textStyle: { color: "#000000" } },
                                },
                            }}
                            rootProps={{ "data-testid": "1" }}
                        />
                    </Row>
                </Col>
            </Row>
            {customerImport && (
                <div style={{ padding: "5px 20px" }}>
                    <Notes
                        displayOnly={!customerImport.isCurrent || isReadOnlyCustomer}
                        currentUserName={`${user?.firstName} ${user?.lastName}`}
                        finosecSystemId={customerImport.finosecSystemId}
                        customerImportId={params.customerImportId}
                        notes={notes}
                        onSave={(note) => createNote(note)}
                    />
                </div>
            )}
            <div className="ag-theme-alpine">
                <div className="d-flex mb-2 mt-2">
                    <div style={{ width: "500px" }}>
                        <input
                            className="form-control"
                            placeholder="Search"
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </div>
                    {customerImport?.isCurrent && <CustomerReportSubscriptionsModal />}
                </div>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <KeysysGrid
                        expandAll={true}
                        searchText={textSearch}
                        getRows={() => customerReports}
                        columnDefs={columnDefs}
                        onRowGroupOpened={(e) =>
                            handleRowExpand({ key: `${customerImportId}_${e.node?.key}`, expandedValue: e.expanded })
                        }
                        expandedRows={customerReportExpansion
                            ?.filter((cue) => cue.expandedValue && +cue.key.split("_")[0] === customerImportId)
                            .map((cue) => cue.key.split("_")[1])}
                    />
                )}
            </div>
        </>
    );
};

export default CustomerReportList;
