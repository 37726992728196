import { SubReportState } from "../../state/SubReportState";
import {
    SubReportActions,
    SUB_REPORTS_FETCHED,
    CREATE_SUB_REPORT,
    DELETE_SUB_REPORT,
    EDIT_SUB_REPORT,
    FETCHING_SUB_REPORTS,
} from "../../actions/subReports/SubReportActions";
import { replaceInCollection } from "./../ReplaceInCollection";

const initialState: SubReportState = {
    subReports: [],
    loading: false,
    loaded: false,
};

export function subReportReducer(state: SubReportState = initialState, action: SubReportActions): SubReportState {
    switch (action.type) {
        case FETCHING_SUB_REPORTS:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case SUB_REPORTS_FETCHED:
            return {
                ...state,
                subReports: action.subReports,
                loading: false,
                loaded: true,
            };
        case CREATE_SUB_REPORT:
            return {
                ...state,
                subReports: [...state.subReports, action.subReport],
            };
        case DELETE_SUB_REPORT:
            const originalActiveSubReport = state.subReports.find((r) => r.id === action.subReport.id);

            return {
                ...state,
                subReports: replaceInCollection(state.subReports, originalActiveSubReport!, action.subReport),
            };
        case EDIT_SUB_REPORT:
            return {
                ...state,
                subReports: replaceInCollection(
                    state.subReports,
                    action.originalSubReport,
                    action.newSubReport,
                    (a, b) => a.name.localeCompare(b.name)
                ),
            };
        default:
            return state;
    }
}
