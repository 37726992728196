import { Field, Formik, Form } from "formik";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { INote, IPostNote } from "../../models/Note";
import { AppState } from "../../state/AppState";
import moment from "moment";
import { getLocalDateTime } from "../../helper-functions/getLocalDateTime";

interface INotesProps {
    onSave?: (note: IPostNote) => any;
    notes: INote[];
    displayOnly?: boolean;
    finosecSystemId?: number;
    customerImportId?: number;
    customerReportId?: number;
    currentUserName?: string;
    noScroll?: boolean;
}

export const Notes = (props: INotesProps) => {
    const {
        notes,
        onSave,
        finosecSystemId,
        currentUserName,
        customerImportId,
        customerReportId,
        displayOnly,
        noScroll,
    } = props;
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);

    const handleSubmit = (values: any, resetForm: any) => {
        if (!customerImportId || !currentUserName || !finosecSystemId || !onSave) return;
        const noteToCreate: IPostNote = {
            tenantId: decodedAccessToken.tenant_id,
            noteText: values.note,
            finosecSystemId: finosecSystemId,
            customerImportId: customerImportId,
            lastModifiedByName: currentUserName,
            isAutoCreated: false,
        };
        if (customerReportId) {
            noteToCreate.customerReportId = customerReportId;
        }
        resetForm();
        onSave(noteToCreate);
    };

    return displayOnly && !(notes?.length > 0) ? (
        <></>
    ) : (
        <Card>
            <Card.Body>
                <Row>
                    <Col md={noScroll || displayOnly ? 12 : 6}>
                        <h6>List of Notes</h6>
                        <ListGroup
                            style={{
                                maxHeight: noScroll ? "unset" : "230px",
                                overflowY: "auto",
                            }}
                            variant="flush"
                        >
                            {notes?.map((n, idx) => (
                                <ListGroup.Item
                                    key={n.id}
                                    style={{
                                        // color: 'rgb(107, 113, 114)',
                                        fontSize: "14px",
                                        fontWeight: 400,
                                    }}
                                >
                                    {n.noteText}
                                    <div className="mb-2 text-muted" style={{ paddingLeft: "10px" }}>
                                        {n.lastModifiedByName} (
                                        {moment(getLocalDateTime(n.createdOn!) || "").format("MM/DD/YY HH:mm:ss")})
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                    {!displayOnly && (
                        <Col style={{ alignSelf: "center" }} md={{ span: 4, offset: 2 }}>
                            <Formik
                                initialValues={{ note: "" }}
                                onSubmit={(values, { resetForm }) => {
                                    handleSubmit(values, resetForm);
                                }}
                            >
                                <Form>
                                    <Field style={{ width: "100%" }} name="note" as="textarea" rows={7} minLength="2" />
                                    <Button style={{ width: "100%" }} type="submit" className="finosec-button-info">
                                        Add Note
                                    </Button>
                                </Form>
                            </Formik>
                        </Col>
                    )}
                </Row>
            </Card.Body>
        </Card>
    );
};
