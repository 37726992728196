/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import DeleteButtonRender from "../grid/DeleteButtonRender";
import EditButtonRender from "../grid/EditButtonRender";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import KeysysAccordion from "../accordion/KeysysAccordion";
import { Button } from "react-bootstrap";
import PageHeader from "../header/PageHeader";
import LinkWrapper from "../routing/LinkWrapper";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, showModal } from "../../actions/ModalActionCreators";
import { CONFIRM_DELETE_DASHBOARD_MODAL } from "../../constants/ModalConstants";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import { AppState } from "../../state/AppState";
import { useDashboardList } from "../../hooks/dashboardListHook";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";

const DashboardsList = () => {
    const dispatch = useDispatch();
    const { systems, removeDashboard, setSearchString } = useDashboardList();
    const confirmDeleteDashboardModalIsVisible = useSelector((state: AppState) =>
        state.modalState.activeModals.includes(CONFIRM_DELETE_DASHBOARD_MODAL)
    );

    const dashboardToDelete = useSelector((state: AppState) =>
        state.modalState.modalValues.get("CONFIRM_DELETE_DASHBOARD_MODAL")
    );

    const dashboardsRef = useSelectorAsRef((state: AppState) => {
        const dashboards = state.dashboardState?.dashboards?.map((r: any) => ({ ...r })) || [];
        return dashboards;
    });

    const showConfirmDeleteDashboardModal = (dashboard: any) => {
        dispatch(showModal(CONFIRM_DELETE_DASHBOARD_MODAL, dashboard));
    };

    const showDeleteCallback = useCallback((params: any) => {
        // Retrieve Dashboard from redux store
        const id =  params;
         showConfirmDeleteDashboardModal(
                  dashboardsRef.current.find((dashboard: any) => id === dashboard.id)
              )
    }, []);  

    const closeDeleteDashboardConfirmationModal = () => {
        dispatch(hideModal(CONFIRM_DELETE_DASHBOARD_MODAL));
    };

    const handleDeleteDashboard = () => {
        // Delete dashboard based
        if (dashboardToDelete) {
            const dashboard = dashboardToDelete;
            removeDashboard(dashboard);
            closeDeleteDashboardConfirmationModal();
        }
    };
    const columnDefs: ColDef[] = [
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "Customers",
            field: "customerCount",
        },
        {
            headerName: "Status",
            field: "isActive",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: (data: any) => {
                return (
                    <LinkWrapper
                        path={`/dashboards/${data.data.id}/edit`}
                    >
                        <EditButtonRender {...data} />
                    </LinkWrapper>
                );
            },
            cellRendererParams: {
                entityName: "Dashboard",
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Dashboard",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: DeleteButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "Dashboard",
                    showDelete: showDeleteCallback,
                    isActive: data.data.isActive,
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Delete Dashboard",
        },
    ];
    const onSearch = (searchString: string) => {
        setSearchString(searchString);
    };
    return (
        <>
            <PageHeader search={true} onSearchChange={onSearch}>
                <LinkWrapper path={"/dashboards/add"}>
                    <Button className={"add-user-button finosec-button-info"} data-testid={"add-dashboard-button"}>
                        Add Dashboard
                    </Button>
                </LinkWrapper>
            </PageHeader>
            {systems.map((s) => (
                <KeysysAccordion key={s.name} title={s.name} count={s.dashboards.length}>
                    <div className="ag-theme-alpine">
                        <KeysysGrid getRows={() => s.dashboards} columnDefs={columnDefs} />
                    </div>
                </KeysysAccordion>
            ))}
            <div>
                <ConfirmationModal
                    id="confirmDeleteMasterDashboard"
                    show={confirmDeleteDashboardModalIsVisible}
                    title="Deactivate Dashboard?"
                    message={"Do you really want to deactivate this dashboard?"}
                    onCloseButtonClick={closeDeleteDashboardConfirmationModal}
                    onOkButtonClick={() => handleDeleteDashboard()}
                    theme={"danger"}
                />
            </div>
        </>
    );
};

export default DashboardsList;
