import { WorkflowStatus } from "../../models/WorkflowStatus";

export const WORKFLOW_STATUSES_FETCHED = "WORKFLOW_STAUTUSES_FETCHED";

export interface WorkflowStatusesFetched {
    type: typeof WORKFLOW_STATUSES_FETCHED;
    workflowStatuses: WorkflowStatus[];
}

export type WorkflowStatusActions = WorkflowStatusesFetched;
