import {KeysysToastProps} from "../components/toast/KeysysToastProps";
import {HIDE_TOAST, HideToastAction, SHOW_TOAST, ShowToastAction} from "../actions/ToastActions";

export function showToast(toastProps: KeysysToastProps): ShowToastAction {
    return {
        type: SHOW_TOAST,
        toastProps: toastProps
    };
}

export function hideToast(toastName: string): HideToastAction {
    return {
        type: HIDE_TOAST,
        name: toastName
    };
}
