import { AuthenticationState } from "../state/AuthenticationState";
import {
    AuthenticateActions,
    IMPERSONATE_USER,
    LOGGED_IN,
    LOGIN_FAIL,
    LOGOUT,
    UNIMPERSONATE_USER,
} from "../actions/AuthenticateActions";

const initialState: AuthenticationState = {
    login: false,
    isAuthenticated: false,
    decodedAccessToken: {
        roles: "",
        userId: -1,
        sub: "",
        tenant_name: "",
        tenant_id: -1,
        family_name: "",
        given_name: "",
        email: "",
        qr_code_image: "",
        manual_entry_code: "",
        nbf: -1,
        exp: -1,
        auth_time: -1,
        impersonating_id: "",
    },
    accessToken: "",
    refreshToken: "",
    failedLoginAttempts: 0,
    expiresIn: 0,
};

export function authenticationReducer(
    state: AuthenticationState = initialState,
    action: AuthenticateActions
): AuthenticationState {
    switch (action.type) {
        case LOGGED_IN:
            return {
                ...state,
                isAuthenticated: true,
                decodedAccessToken: action.decodedAccessToken,
                accessToken: action.accessToken,
                refreshToken: action.refreshToken,
                failedLoginAttempts: 0,
                expiresIn: action.expiresIn,
            };
        case LOGOUT:
            return {
                ...initialState,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: "",
                refreshToken: "",
                failedLoginAttempts: state.failedLoginAttempts + 1,
            };
        case IMPERSONATE_USER:
            return {
                ...state,
                decodedAccessToken: {
                    ...state.decodedAccessToken,
                    userId: action.userId,
                    email: action.email,
                    tenant_id: action.tenant_id,
                    roles: action.roles,
                    impersonating_id: action.impersonatorId.toString(),
                },
            };
        case UNIMPERSONATE_USER:
            return {
                ...state,
                decodedAccessToken: {
                    ...state.decodedAccessToken,
                    userId: action.userId,
                    email: action.email,
                    tenant_id: action.tenant_id,
                    roles: action.roles,
                    impersonating_id: "",
                },
            };
        default:
            return state;
    }
}
