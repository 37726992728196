import { SystemAccountType } from "../../models/SystemAccountType";

export const SYSTEM_ACCOUNT_TYPES_FETCHED = "SYSTEM_ACCOUNT_TYPES_FETCHED";

export interface SystemAccountTypeFetchedAction {
    type: typeof SYSTEM_ACCOUNT_TYPES_FETCHED;
    systemAccountTypes: SystemAccountType[];
}

export type SystemAccountTypeActions = SystemAccountTypeFetchedAction;
