import React from "react";
import _ from 'lodash'

const PageHeader = (props: { title?: string; search?: boolean; onSearchChange?: (search: string) => void, children?: React.ReactChild | React.ReactChild[] }) => {
    const {onSearchChange, search, children } = props;
    const debouncedSearch = _.debounce((search: string) => {
        if(onSearchChange) {
            onSearchChange(search)
        }
    }, 300);
    return (
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            {search && (
                <div style={{ width: "500px" }}>
                    <input className="form-control" placeholder="Search" onChange={(e) => debouncedSearch(e.target.value)} />
                </div>
            )}
            <div>{children}</div>
        </div>
    );
};

export default PageHeader;
