import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { AppState } from "../state/AppState";

export const useWorkflowJobs = () => {
    const dispatch = useDispatch();

    const { selectedWorkflowJob: selectedJob, workflowJobs } = useSelector((state: AppState) => state.workflowJobState);

    const tenants = useSelector((state: AppState) => state.tenantState.tenants);
    const finosecSystems = useSelector((state: AppState) => state.finosecSystemState.finosecSystems);

    const findTenant = useCallback(
        (id?: number) => {
            if (!id) return;
            return tenants.find((t) => t.id === id);
        },
        [tenants]
    );

    return useMemo(
        () => ({
            findTenant,
            selectedJob,
            workflowJobs,
            finosecSystems,
        }),
        [findTenant, selectedJob, workflowJobs, finosecSystems]
    );
};
