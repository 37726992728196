import { TYPES } from "../../dependencyInjection/Types";
import { ICustomerReportAttributeService } from "../../services/customerReportAttribute/ICustomerReportAttributeService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    CustomerReportAttributesFetchedAction,
    CUSTOMER_REPORT_ATTRIBUTES_FETCHED,
} from "./CustomerReportAttributeAction";

export function getCustomerReportAttributes(
    customerReportId: number
): KeysysThunkAction<void, CustomerReportAttributesFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerReportAttributeService = container.get<ICustomerReportAttributeService>(
            TYPES.CustomerReportAttributeService
        );
        const customerReportAttributes = await customerReportAttributeService.getCustomerReportAttributes(
            customerReportId
        );

        if (customerReportAttributes) {
            dispatch<CustomerReportAttributesFetchedAction>({
                type: CUSTOMER_REPORT_ATTRIBUTES_FETCHED,
                customerReportAttributes: customerReportAttributes,
            });
        }
    };
}
