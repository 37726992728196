/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../actions/users/UserActionCreators";
import { AppState } from "../state/AppState";

export const useCurrentUser = (userId: number) => {
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.userState.users);

    useEffect(() => {
        if (!user || user.length === 0) {
            dispatch(getUsers());
        }
    }, []);
    return user.find((u) => u.id === userId);
};
