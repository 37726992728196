import { TYPES } from "../../dependencyInjection/Types";
import { WorkflowJobSchedule } from "../../models/WorkflowJobSchedule";
import { IWorkflowJobScheduleService } from "../../services/workflowJobSchedule/IWorkflowJobScheduleService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    WorkflowJobScheduleCleared,
    WorkflowJobScheduleCreated,
    WorkflowJobScheduleDeleted,
    WorkflowJobScheduleEdited,
    WorkflowJobScheduleFetched,
    WorkflowJobScheduleSelected,
    WorkflowJobSchedulesFetched,
    WORKFLOW_JOB_SCHEDULES_FETCHED,
    WORKFLOW_JOB_SCHEDULE_CLEARED,
    WORKFLOW_JOB_SCHEDULE_CREATED,
    WORKFLOW_JOB_SCHEDULE_DELETED,
    WORKFLOW_JOB_SCHEDULE_EDITED,
    WORKFLOW_JOB_SCHEDULE_FETCHED,
    WORKFLOW_JOB_SCHEDULE_SELECTED,
} from "./WorkflowJobScheduleActions";

export function getWorkflowJobSchedules(): KeysysThunkAction<void, WorkflowJobSchedulesFetched> {
    return async (dispatch, getState, container) => {
        const workflowJobScheduleService = container.get<IWorkflowJobScheduleService>(TYPES.WorkflowJobScheduleService);
        const workflowJobSchedules = await workflowJobScheduleService.getWorkflowJobSchedules();
        if (workflowJobSchedules) {
            dispatch<WorkflowJobSchedulesFetched>({
                type: WORKFLOW_JOB_SCHEDULES_FETCHED,
                workflowJobSchedules,
            });
        }
    };
}

export function getWorkflowJobSchedulesByTenantId(
    tenantId: number
): KeysysThunkAction<void, WorkflowJobSchedulesFetched> {
    return async (dispatch, getState, container) => {
        const workflowJobScheduleService = container.get<IWorkflowJobScheduleService>(TYPES.WorkflowJobScheduleService);
        const workflowJobSchedules = await workflowJobScheduleService.getWorkflowJobSchedulesByTenantId(tenantId);
        if (workflowJobSchedules) {
            dispatch<WorkflowJobSchedulesFetched>({
                type: WORKFLOW_JOB_SCHEDULES_FETCHED,
                workflowJobSchedules,
            });
        }
    };
}

export function getWorkflowJobSchedulesByDate(date: string): KeysysThunkAction<void, WorkflowJobSchedulesFetched> {
    return async (dispatch, getState, container) => {
        const workflowJobScheduleService = container.get<IWorkflowJobScheduleService>(TYPES.WorkflowJobScheduleService);
        const workflowJobSchedules = await workflowJobScheduleService.getWorkflowJobSchedulesByDate(date);
        if (workflowJobSchedules) {
            dispatch<WorkflowJobSchedulesFetched>({
                type: WORKFLOW_JOB_SCHEDULES_FETCHED,
                workflowJobSchedules,
            });
        }
    };
}

export function getWorkflowJobSchedule(id: number): KeysysThunkAction<void, WorkflowJobScheduleFetched> {
    return async (dispatch, getState, container) => {
        const workflowJobScheduleService = container.get<IWorkflowJobScheduleService>(TYPES.WorkflowJobScheduleService);
        const workflowJobSchedule = await workflowJobScheduleService.getWorkflowJobScheduleById(id);
        if (workflowJobSchedule) {
            dispatch<WorkflowJobScheduleFetched>({
                type: WORKFLOW_JOB_SCHEDULE_FETCHED,
                workflowJobSchedule,
            });
        }
    };
}

export function createWorkflowJobSchedule(
    _workflowJobSchedule: WorkflowJobSchedule
): KeysysThunkAction<void, WorkflowJobScheduleCreated> {
    return async (dispatch, getState, container) => {
        const workflowJobScheduleService = container.get<IWorkflowJobScheduleService>(TYPES.WorkflowJobScheduleService);
        const workflowJobSchedule = await workflowJobScheduleService.createWorkflowJobSchedule(_workflowJobSchedule);
        if (workflowJobSchedule) {
            dispatch<WorkflowJobScheduleCreated>({
                type: WORKFLOW_JOB_SCHEDULE_CREATED,
                workflowJobSchedule,
            });
        }
    };
}

export function editWorkflowJobSchedule(
    workflowJobSchedule: WorkflowJobSchedule,
    newWorkflowJobSchedule: WorkflowJobSchedule
): KeysysThunkAction<void, WorkflowJobScheduleEdited> {
    return async (dispatch, getState, container) => {
        const workflowJobScheduleService = container.get<IWorkflowJobScheduleService>(TYPES.WorkflowJobScheduleService);
        const patched = await workflowJobScheduleService.editWorkflowJobSchedule(
            workflowJobSchedule,
            newWorkflowJobSchedule
        );
        if (patched) {
            dispatch<WorkflowJobScheduleEdited>({
                type: WORKFLOW_JOB_SCHEDULE_EDITED,
                workflowJobSchedule,
                newWorkflowJobSchedule: patched,
            });
        }
    };
}

export function deleteWorkflowJobSchedule(
    _workflowJobSchedule: WorkflowJobSchedule
): KeysysThunkAction<void, WorkflowJobScheduleDeleted> {
    return async (dispatch, getState, container) => {
        const workflowJobScheduleService = container.get<IWorkflowJobScheduleService>(TYPES.WorkflowJobScheduleService);
        const workflowJobSchedule = await workflowJobScheduleService.deleteWorkflowJobSchedule(_workflowJobSchedule);
        if (workflowJobSchedule) {
            dispatch<WorkflowJobScheduleDeleted>({
                type: WORKFLOW_JOB_SCHEDULE_DELETED,
                workflowJobSchedule,
            });
        }
    };
}

export function selectWorkflowJobSchedule(workflowJobSchedule: WorkflowJobSchedule): WorkflowJobScheduleSelected {
    return {
        type: WORKFLOW_JOB_SCHEDULE_SELECTED,
        workflowJobSchedule,
    };
}

export function clearWorkflowJobSchedule(): WorkflowJobScheduleCleared {
    return {
        type: WORKFLOW_JOB_SCHEDULE_CLEARED,
    };
}
