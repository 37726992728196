import {
    FinosecSystemStatisticAction,
    FINOSEC_SYSTEM_STATISTICS_DATA_FETCHED,
    FINOSEC_SYSTEM_STATISTICS_PARTNER_DATA_FETCHED,
} from "../../actions/finosecSystemStatistic/FinosecSystemStatisticAction";
import { FinosecSystemStatisticsState } from "../../state/FinosecSystemStatisticsState";

const initialState: FinosecSystemStatisticsState = {
    systemStatisticsData: {
        statisticsDate: "",
        customerImports: [],
        customerReports: [],
    },
    partnerSystemStatisticsData: [],
};

export function finosecSystemStatisticReducer(
    state: FinosecSystemStatisticsState = initialState,
    action: FinosecSystemStatisticAction
): FinosecSystemStatisticsState {
    switch (action.type) {
        case FINOSEC_SYSTEM_STATISTICS_DATA_FETCHED:
            return {
                ...state,
                systemStatisticsData: action.systemStatisticsData,
            };
        case FINOSEC_SYSTEM_STATISTICS_PARTNER_DATA_FETCHED:
            return {
                ...state,
                partnerSystemStatisticsData: action.partnerStatisticsData,
            };

        default:
            return state;
    }
}
