import { CREATE_FINOSEC_SYSTEM, FinosecSystemActions, FINOSEC_SYSTEMS_FETCHED } from "../../actions/finosecSystem/FinosecSystemAction";
import { FinosecSystemState } from "../../state/FinosecSystemState";

const initialState: FinosecSystemState ={
    finosecSystems:[]
};

export function finosecSystemReducer(state: FinosecSystemState = initialState, action: FinosecSystemActions): FinosecSystemState{

    switch(action.type){
        case FINOSEC_SYSTEMS_FETCHED:
            return {
                ...state,
                finosecSystems: action.finosecSystems,
            };        

        case CREATE_FINOSEC_SYSTEM:
            return {
                ...state,
                finosecSystems: [...state.finosecSystems, action.finosecSystem],
            };

        default:
            return state;
        

    }
}