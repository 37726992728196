import React from "react";
import { useHistory } from "react-router-dom";

export type LinkWrapperProps = {
    path: string;
    children: React.ReactChild | React.ReactChild[] | undefined;
    className?: string;
    style?: any,
    onClick?: (e: React.MouseEvent) => void;
};

export default function LinkWrapper(props: LinkWrapperProps) {
    const leftMouseButton = 0;
    const middleMouseButton = 1;

    const history = useHistory();
    const handleMouseDown = (e: React.MouseEvent) => {
        if ((e.ctrlKey && e.button === leftMouseButton) || e.button === middleMouseButton) {
            window.open(props.path, "_blank");
        } else if (e.button === 0) {
            history.push(props.path);
        }
        props.onClick && props.onClick(e);
    };
    const additionalStyle = props.style || {};

    return (
        <span
            data-testid="link-wrapper"
            style={{ cursor: "pointer", ...additionalStyle }}
            onMouseDown={handleMouseDown}
            className={props.className}
        >
            {props.children}
        </span>
    );
}
