import { IntakeSystemFile } from "../../models/IntakeSystemFile";

export const INTAKESYSTEMFILES_FETCHED = "INTAKESYSTEMFILES_FETCHED";
export const INACTIVE_INTAKESYSTEMFILES_FETCHED = "INACTIVE_INTAKESYSTEMFILES_FETCHED";
export const INTAKESYSTEMFILE_CREATED = "INTAKESYSTEMFILE_CREATED";
export const INTAKESYSTEMFILE_DELETED = "INTAKESYSTEMFILE_DELETED";
export const INTAKESYSTEMFILE_EDITED = "INTAKESYSTEMFILE_EDITED";
export const INTAKESYSTEMFILE_RESTORED = "INTAKESYSTEMFILE_RESTORED";
export const SELECT_INTAKESYSTEMFILE = "SELECT_INTAKESYSTEMFILE";
export const CLEAR_SELECTED_INTAKESYSTEMFILE = "CLEAR_SELECTED_INTAKESYSTEMFILE";

export interface IntakeSystemFilesFetchedAction {
    type: typeof INTAKESYSTEMFILES_FETCHED;
    intakeSystemFiles: IntakeSystemFile[];
}

export interface InactiveIntakeSystemFilesFetchedAction {
    inactiveIntakeSystemFiles: IntakeSystemFile[];
    type: typeof INACTIVE_INTAKESYSTEMFILES_FETCHED;
}

export interface IntakeSystemFileCreatedAction {
    type: typeof INTAKESYSTEMFILE_CREATED;
    intakeSystemFile: IntakeSystemFile;
}

export interface IntakeSystemFileDeletedAction {
    type: typeof INTAKESYSTEMFILE_DELETED;
    intakeSystemFile: IntakeSystemFile;
}

export interface IntakeSystemFileEditedAction {
    newIntakeSystemFile: IntakeSystemFile;
    intakeSystemFile: IntakeSystemFile;
    type: typeof INTAKESYSTEMFILE_EDITED;
}

export interface IntakeSystemFileRestoredAction {
    type: typeof INTAKESYSTEMFILE_RESTORED;
    intakeSystemFile: IntakeSystemFile;
}

export interface SelectIntakeSystemFileAction {
    type: typeof SELECT_INTAKESYSTEMFILE;
    intakeSystemFile: IntakeSystemFile;
}

export interface ClearSelectedIntakeSystemFileAction {
    type: typeof CLEAR_SELECTED_INTAKESYSTEMFILE;
}

export type IntakeSystemFileActions =
    | IntakeSystemFilesFetchedAction
    | InactiveIntakeSystemFilesFetchedAction
    | IntakeSystemFileCreatedAction
    | IntakeSystemFileDeletedAction
    | IntakeSystemFileEditedAction
    | IntakeSystemFileRestoredAction
    | SelectIntakeSystemFileAction
    | ClearSelectedIntakeSystemFileAction;
