import { ISubReportCategoryService } from "../../services/subReportCategory/ISubReportCategoryService";
import {
    SUB_REPORT_CATEGORIES_FETCHED,
    SubReportCategoriesFetchedAction,
    CREATE_SUB_REPORT_CATEGORY,
    CreateSubReportCategoryAction,
    DELETE_SUB_REPORT_CATEGORY,
    DeleteSubReportCategoryAction,
    EditSubReportCategoryAction,
    EDIT_SUB_REPORT_CATEGORY,
    FETCHING_SUB_REPORT_CATEGORIES,
} from "./SubReportCategoryActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { SubReportCategory } from "../../models/SubReportCategory";

export function getSubReportCategories(): KeysysThunkAction<void, SubReportCategoriesFetchedAction> {
    return async (dispatch, getState, container) => {
        dispatch<any>({
            type: FETCHING_SUB_REPORT_CATEGORIES,
        });
        const subReportCategoryService = container.get<ISubReportCategoryService>(TYPES.SubReportCategoryService);
        const subReportCategories = await subReportCategoryService.getSubReportCategories();

        if (subReportCategories) {
            dispatch<SubReportCategoriesFetchedAction>({
                type: SUB_REPORT_CATEGORIES_FETCHED,
                subReportCategories: subReportCategories,
            });
        }
    };
}

export function createSubReportCategory(
    subReportCategory: SubReportCategory
): KeysysThunkAction<void, CreateSubReportCategoryAction> {
    return async (dispatch, getState, container) => {
        const subReportCategoryService = container.get<ISubReportCategoryService>(TYPES.SubReportCategoryService);
        const createdSubReportCategory = await subReportCategoryService.createSubReportCategory(subReportCategory);
        if (createdSubReportCategory) {
            dispatch<CreateSubReportCategoryAction>({
                type: CREATE_SUB_REPORT_CATEGORY,
                subReportCategory: createdSubReportCategory,
            });
        }
        return !!createSubReportCategory;
    };
}

export function editSubReportCategory(
    originalSubReportCategory: SubReportCategory,
    newSubReportCategory: SubReportCategory
): KeysysThunkAction<void, EditSubReportCategoryAction> {
    return async (dispatch, getState, container) => {
        const subReportCategoryService = container.get<ISubReportCategoryService>(TYPES.SubReportCategoryService);
        const patchedSubReportCategory = await subReportCategoryService.editSubReportCategory(
            originalSubReportCategory,
            newSubReportCategory
        );
        if (patchedSubReportCategory) {
            dispatch<EditSubReportCategoryAction>({
                type: EDIT_SUB_REPORT_CATEGORY,
                originalSubReportCategory,
                newSubReportCategory,
            });
        }
    };
}

export function deleteSubReportCategory(
    subReportCategory: SubReportCategory
): KeysysThunkAction<void, DeleteSubReportCategoryAction> {
    return async (dispatch, getState, container) => {
        const subReportCategoryService = container.get<ISubReportCategoryService>(TYPES.SubReportCategoryService);
        const deletedSubReportCategory = await subReportCategoryService.deleteSubReportCategory(subReportCategory);
        if (deletedSubReportCategory) {
            dispatch<DeleteSubReportCategoryAction>({
                type: DELETE_SUB_REPORT_CATEGORY,
                subReportCategory: deletedSubReportCategory,
            });
        }
    };
}
