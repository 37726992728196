import React from "react";
import { SmallRoundCheckboxProps } from "./SmallRoundCheckboxProps";

export default function SmallRoundCheckbox(props: SmallRoundCheckboxProps) {
    function getCheckboxText(): string {
        return `Complete ${props.entityNameSingular}`;
    }

    return (
        <div className={"justify-content-center align-items-center d-flex"}>
            <input
                type="checkbox"
                onChange={props.onClick}
                checked={props.checked}
                placeholder={getCheckboxText()}
                title={getCheckboxText()}
                className="checkbox-round"
                id={props.id}
                disabled={props.disabled}
            />
        </div>
    );
}
