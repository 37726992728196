import { Dashboard } from "../../models/Dashboard";

export const DASHBOARDS_FETCHED = "DASHBOARDS_FETCHED";
export const FETCH_DASHBOARD = "FETCH_DASHBOARD";
export const CREATE_DASHBOARD = "CREATE_DASHBOARD";
export const EDIT_DASHBOARD = "EDIT_DASHBOARD";
export const DELETE_DASHBOARD = "DELETE_DASHBOARD";

export interface DashboardsFetchedAction {
    type: typeof DASHBOARDS_FETCHED;
    dashboards: Dashboard[];
}

export interface FetchDashboardAction {
    type: typeof FETCH_DASHBOARD;
    dashboard: Dashboard;
}


export interface CreateDashboardAction {
    type: typeof CREATE_DASHBOARD;
    dashboard: Dashboard;
}

export interface EditDashboardAction{
    type: typeof EDIT_DASHBOARD;
    orininalRport: Dashboard;
    newDashboard:Dashboard;
}

export interface DeleteDashboardAction {
    type: typeof DELETE_DASHBOARD;
    dashboard: Dashboard;
}


export type DashboardActions =
    | DashboardsFetchedAction
    | FetchDashboardAction
    | CreateDashboardAction
    | EditDashboardAction
    | DeleteDashboardAction;
