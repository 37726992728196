import { IReportService } from "../../services/report/IReportService";
import {
    REPORTS_FETCHED,
    ReportsFetchedAction,
    CREATE_REPORT,
    CreateReportAction,
    DELETE_REPORT,
    DeleteReportAction,
    EDIT_REPORT,
    FetchReportAction,
    FETCH_REPORT,
} from "./ReportActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { Report } from "../../models/Report";
import { ReportAttribute } from "../../models/ReportAttribute";
import { IReportAttributeService } from "../../services/reportAttribute/IReportAttributeService";
import { CREATE_REPORT_ATTRIBUTE, 
         DELETE_REPORT_ATTRIBUTE,
         EDIT_REPORT_ATTRIBUTE } from "../reportAttributes/ReportAttributeAction";

export function getReports(): KeysysThunkAction<void, ReportsFetchedAction> {
    return async (dispatch, getState, container) => {
        const reportService = container.get<IReportService>(TYPES.ReportService);
        const reports = await reportService.getReports();

        if (reports) {
            dispatch<ReportsFetchedAction>({
                type: REPORTS_FETCHED,
                reports: reports,
            });
        }
    };
}

export function getReportById(reportId: number): KeysysThunkAction<void, FetchReportAction>{
    return async (dispatch, getState, container) =>{
        const reportService = container.get<IReportService>(TYPES.ReportService);
        const report = await reportService.getReportById(reportId);
        
        if(report){
            dispatch<FetchReportAction>({
                type: FETCH_REPORT,
                report: report
            })
        }
    }
}


export function createReport(report: Report, attributesToCreate:ReportAttribute[]): KeysysThunkAction<void, CreateReportAction> {
    return async (dispatch, getState, container) => {
        const reportService = container.get<IReportService>(TYPES.ReportService);
        const createdReport = await reportService.createReport(report);
        const attributeService = container.get<IReportAttributeService>(TYPES.ReportAttributeService);
        if (createdReport) {
            dispatch<CreateReportAction>({
                type: CREATE_REPORT,
                report: createdReport,
            });

            for(let i=0; i < attributesToCreate.length;i++){
                const createdAttribute = await attributeService.createReportAttribute(createdReport.id,attributesToCreate[i] )
                if(createdAttribute)
                {
                    dispatch<any>({
                        type: CREATE_REPORT_ATTRIBUTE,
                        reportAttribute:createdAttribute
                    });                    
                }
            
            }

        }
        return !!createReport;
    };
}

export function editReport(
        originalReport:Report,
        newReport: Report,         
        attributesToCreate?:ReportAttribute[], 
        attributesToUpdate?:ReportAttribute[], 
        originalAttributes?:ReportAttribute[],
        attributesToDelete?:ReportAttribute[]
        ): KeysysThunkAction<void, CreateReportAction> {
    return async (dispatch, getState, container) => {
        const reportService = container.get<IReportService>(TYPES.ReportService);
        const attributeService = container.get<IReportAttributeService>(TYPES.ReportAttributeService);
        const editedReport = await reportService.editReport(originalReport,newReport);
        if (editedReport) {

            dispatch<any>({
                type: EDIT_REPORT,
                originalReport:originalReport,
                newReport: editedReport,
            });

            if(attributesToCreate)
            {
                for(let i=0; i < attributesToCreate.length;i++){
                    const createdAttribute = await attributeService.createReportAttribute(originalReport.id,attributesToCreate[i] )
                    if(createdAttribute)
                    {
                        dispatch<any>({
                            type: CREATE_REPORT_ATTRIBUTE,
                            reportAttribute:createdAttribute
                        }); 
                    }
                }
            }

            if(attributesToUpdate && originalAttributes)
            {
                for(let i=0; i<attributesToUpdate.length;i++){
                    const originalAttribute  = originalAttributes.find(a=>a.id===attributesToUpdate[i].id);
                    if (originalAttribute) {
                        const editedAttribute = await attributeService.editReportAttribute(originalReport.id, originalAttribute!!,attributesToUpdate[i] )
                        if(editedAttribute)
                        {
                            dispatch<any>({
                                type: EDIT_REPORT_ATTRIBUTE,                            
                                originalReportAttribute: originalAttribute,
                                newReportAttribute:editedAttribute
    
                            }); 
                        }
                    }
                }
            }
              if(attributesToDelete)
            {
                for(let i=0; i < attributesToDelete.length;i++){
                    const deletedAttribute = await attributeService.deleteReportAttribute(originalReport.id,attributesToDelete[i] )
                    if(deletedAttribute)
                    {
                        dispatch<any>({
                            type: DELETE_REPORT_ATTRIBUTE,
                            reportAttribute:deletedAttribute
                        }); 
                    }
                }
            }
        
        }
        
    };
}

export function deleteReport(report: Report): KeysysThunkAction<void, DeleteReportAction> {
    return async (dispatch, getState, container) => {
        const reportService = container.get<IReportService>(TYPES.ReportService);
        const deletedReport = await reportService.deleteReport(report);
        if (deletedReport) {
            dispatch<DeleteReportAction>({
                type: DELETE_REPORT,
                report: deletedReport,
            });
        }
    };
}
