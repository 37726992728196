import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ICustomerImportFileStatusService } from "./ICustomerImportFileStatusService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Store } from "redux";
import { CustomerImportFileStatus } from "../../models/CustomerImportFileStatus";

@injectable()
export class CustomerImportFileStatusService implements ICustomerImportFileStatusService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    upsertCustomerImportFileStatus(customerImportFileStatus: CustomerImportFileStatus): Promise<void> {
        return this.httpClient
            .putUniqueBody<CustomerImportFileStatus, void>(
                `/customer-import-file-status-check/`,
                customerImportFileStatus
            )
            .then(() => {})
            .catch(() => {});
    }
}
