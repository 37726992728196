import { CSSProperties } from "react";

export const nullProperties: CSSProperties = {};

export const vh100: CSSProperties = {
    height: "100vh"
};

export const vh75: CSSProperties = {
    height: "75vh"
};

export const redBorder: CSSProperties = {
    border: "1px solid red"
};
