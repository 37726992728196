import { SubReport } from "../../models/SubReport";

export const SUB_REPORTS_FETCHED = "SUB_REPORTS_FETCHED";
export const CREATE_SUB_REPORT = "CREATE_SUB_REPORT";
export const DELETE_SUB_REPORT = "DELETE_SUB_REPORT";
export const EDIT_SUB_REPORT = "EDIT_SUB_REPORT";
export const FETCHING_SUB_REPORTS = "FETCHING_SUB_REPORTS";

export interface SubReportsFetchedAction {
    type: typeof SUB_REPORTS_FETCHED;
    subReports: SubReport[];
}

export interface FetchingSubReportAction {
    type: typeof FETCHING_SUB_REPORTS;
}
export interface CreateSubReportAction {
    type: typeof CREATE_SUB_REPORT;
    subReport: SubReport;
}

export interface EditSubReportAction {
    type: typeof EDIT_SUB_REPORT;
    originalSubReport: SubReport;
    newSubReport: SubReport;
}

export interface DeleteSubReportAction {
    type: typeof DELETE_SUB_REPORT;
    subReport: SubReport;
}

export type SubReportActions =
    | SubReportsFetchedAction
    | FetchingSubReportAction
    | CreateSubReportAction
    | DeleteSubReportAction
    | EditSubReportAction;
