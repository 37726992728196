import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useCallback, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editEmployeeSystemException } from "../../actions/employeeSystemException/EmployeeSystemExceptionActionCreator";
import { EmployeeSystemException } from "../../models/EmployeeSystemException";
import { AppState } from "../../state/AppState";
import KeysysInput from "../form/input/KeysysInput";
import KeysysSelect from "../form/select/KeysysSelect";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { EditEmployeeSystemExceptionFormModel } from "./EditEmployeeSystemExceptionFormModel";
import { IEmployeeSystemExceptionForm } from "./IEmployeeSystemExceptionForm";

export default function EditEmployeeSystemExceptionForm(props: IEmployeeSystemExceptionForm) {
    const dispatch = useDispatch();
    const formikRef = useRef<FormikProps<any>>(null);

    const systemAccessTypes = useSelector((state: AppState) => state.systemAccountTypeState).systemAccountTypes;
    const systemAccessTypeOptions: KeysysSelectOption[] = systemAccessTypes.map(
        (sat: any) => ({ value: sat.id, label: sat.name } as KeysysSelectOption)
    );

    const getInitialValues = useCallback(() => {
        return {
            userId: props.employeeSystemException?.userId,
            name: props.employeeSystemException?.name,
            finosecSystem: props.employeeSystemException?.finosecSystem?.name,
            systemAccountTypeId: 0,
        };
    }, [props]);

    function handleSubmit(
        values: EditEmployeeSystemExceptionFormModel,
        formikHelpers: FormikHelpers<EditEmployeeSystemExceptionFormModel>
    ) {
        if (props.employeeSystemException?.id) {
            if (values.systemAccountTypeId) {
                const editedEmployeeSystemException: EmployeeSystemException = {
                    ...props.employeeSystemException!,
                    systemAccountTypeId: values.systemAccountTypeId,
                };
                dispatch(editEmployeeSystemException(props.employeeSystemException!, editedEmployeeSystemException));
            }
        }
        clearFormAndHide(formikHelpers);
    }

    function clearFormAndHide(
        formProps:
            | FormikProps<EditEmployeeSystemExceptionFormModel>
            | FormikHelpers<EditEmployeeSystemExceptionFormModel>
    ): void {
        formProps.resetForm();
        props.onClose();
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<EditEmployeeSystemExceptionFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="editEmployeeSystemExceptionModal"
                            show={true}
                            title={"Assign System Account"}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                            showSaveButton={true}
                        >
                            <Form id="employee-system-exception-form">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"userId"}
                                            placeholder={""}
                                            fieldName={"userId"}
                                            label={"User Id"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"name"}
                                            placeholder={""}
                                            fieldName={"name"}
                                            label={"Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"finosecSystem"}
                                            placeholder={""}
                                            fieldName={"finosecSystem"}
                                            label={"Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"systemAccountTypeId"}
                                            placeholder={"System Account Type..."}
                                            fieldName={"systemAccountTypeId"}
                                            label={"Select System Account Type"}
                                            options={systemAccessTypeOptions}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
