import {
    CLEAR_SELECTED_INTAKECUSTOMERFILE,
    ClearSelectedIntakeCustomerFileAction,
    INACTIVE_INTAKECUSTOMERFILES_FETCHED,
    InactiveIntakeCustomerFilesFetchedAction,
    SELECT_INTAKECUSTOMERFILE,
    SelectIntakeCustomerFileAction,
    INTAKECUSTOMERFILE_CREATED,
    INTAKECUSTOMERFILE_DELETED,
    INTAKECUSTOMERFILE_EDITED,
    INTAKECUSTOMERFILE_RESTORED,
    IntakeCustomerFileCreatedAction,
    IntakeCustomerFileDeletedAction,
    IntakeCustomerFileEditedAction,
    IntakeCustomerFileRestoredAction,
    INTAKECUSTOMERFILES_FETCHED,
    IntakeCustomerFilesFetchedAction,
} from "./IntakeCustomerFilesActions";
import { IntakeCustomerFile } from "../../models/IntakeCustomerFile";
import { IIntakeCustomerFileService } from "../../services/intakeCustomerFile/IIntakeCustomerFileService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { LoggedInAction, LOGGED_IN } from "../AuthenticateActions";
import jwt_decode from "jwt-decode";

export function clearSelectedIntakeCustomerFile(): ClearSelectedIntakeCustomerFileAction {
    return {
        type: CLEAR_SELECTED_INTAKECUSTOMERFILE,
    };
}

export function createIntakeCustomerFile(
    intakeCustomerFile: IntakeCustomerFile
): KeysysThunkAction<void, IntakeCustomerFileCreatedAction> {
    return async (dispatch, getState, container) => {
        const intakeCustomerFileService = container.get<IIntakeCustomerFileService>(TYPES.IntakeCustomerFileService);
        const createdIntakeCustomerFile = await intakeCustomerFileService.addIntakeCustomerFile(intakeCustomerFile);
        if (createdIntakeCustomerFile) {
            dispatch<IntakeCustomerFileCreatedAction>({
                type: INTAKECUSTOMERFILE_CREATED,
                intakeCustomerFile: createdIntakeCustomerFile,
            });
        }
    };
}

export function deleteIntakeCustomerFile(
    intakeCustomerFile: IntakeCustomerFile
): KeysysThunkAction<void, IntakeCustomerFileDeletedAction> {
    return async (dispatch, getState, container) => {
        const intakeCustomerFileService = container.get<IIntakeCustomerFileService>(TYPES.IntakeCustomerFileService);
        const deletedIntakeCustomerFile = await intakeCustomerFileService.deleteIntakeCustomerFile(intakeCustomerFile);
        if (deletedIntakeCustomerFile) {
            dispatch<IntakeCustomerFileDeletedAction>({
                type: INTAKECUSTOMERFILE_DELETED,
                intakeCustomerFile: deletedIntakeCustomerFile,
            });
        }
    };
}

export function editIntakeCustomerFile(
    intakeCustomerFile: IntakeCustomerFile,
    newIntakeCustomerFile: IntakeCustomerFile
): KeysysThunkAction<void, IntakeCustomerFileEditedAction> {
    return async (dispatch, getState, container) => {
        const intakeCustomerFileService = container.get<IIntakeCustomerFileService>(TYPES.IntakeCustomerFileService);
        const patched = await intakeCustomerFileService.editIntakeCustomerFile(
            intakeCustomerFile,
            newIntakeCustomerFile
        );
        if (patched) {
            dispatch<IntakeCustomerFileEditedAction>({
                type: INTAKECUSTOMERFILE_EDITED,
                intakeCustomerFile,
                newIntakeCustomerFile: patched,
            });
        }
    };
}

export function getInactiveIntakeCustomerFiles(): KeysysThunkAction<void, InactiveIntakeCustomerFilesFetchedAction> {
    return async (dispatch, getState, container) => {
        const intakeCustomerFileService = container.get<IIntakeCustomerFileService>(TYPES.IntakeCustomerFileService);
        const intakeCustomerFiles = await intakeCustomerFileService.getInactiveIntakeCustomerFiles();
        if (intakeCustomerFiles) {
            dispatch<InactiveIntakeCustomerFilesFetchedAction>({
                type: INACTIVE_INTAKECUSTOMERFILES_FETCHED,
                inactiveIntakeCustomerFiles: intakeCustomerFiles,
            });
        }
    };
}

export function getIntakeCustomerFiles(): KeysysThunkAction<void, IntakeCustomerFilesFetchedAction> {
    return async (dispatch, getState, container) => {
        const intakeCustomerFileService = container.get<IIntakeCustomerFileService>(TYPES.IntakeCustomerFileService);
        const intakeCustomerFiles = await intakeCustomerFileService.getIntakeCustomerFiles();
        if (intakeCustomerFiles) {
            dispatch<IntakeCustomerFilesFetchedAction>({
                type: INTAKECUSTOMERFILES_FETCHED,
                intakeCustomerFiles: intakeCustomerFiles,
            });
        }
    };
}

export function restoreIntakeCustomerFile(
    intakeCustomerFile: IntakeCustomerFile
): KeysysThunkAction<void, IntakeCustomerFileRestoredAction> {
    return async (dispatch, getState, container) => {
        const intakeCustomerFileService = container.get<IIntakeCustomerFileService>(TYPES.IntakeCustomerFileService);
        const restoredIntakeCustomerFile = await intakeCustomerFileService.restoreIntakeCustomerFile(
            intakeCustomerFile
        );
        if (restoredIntakeCustomerFile) {
            dispatch<IntakeCustomerFileRestoredAction>({
                type: INTAKECUSTOMERFILE_RESTORED,
                intakeCustomerFile: restoredIntakeCustomerFile,
            });
        }
    };
}

export function selectIntakeCustomerFile(intakeCustomerFile: IntakeCustomerFile): SelectIntakeCustomerFileAction {
    return {
        type: SELECT_INTAKECUSTOMERFILE,
        intakeCustomerFile: intakeCustomerFile,
    };
}
