import { servicesContainer } from "../services/IOC/ServicesContainer";
import { INoteService } from "../services/note/INoteService";
import { TYPES } from "../dependencyInjection/Types";
import { useEffect, useState } from "react";
import { INote, IPostNote } from "../models/Note";

export const useNotes = (notesFilter: (note: INote) => boolean, filterVars?: any[]) => {
    const noteService = servicesContainer.get<INoteService>(TYPES.NoteService);

    const [notes, setNotes] = useState<INote[]>([]);
    const [notesLoading, setNotesLoading] = useState<boolean>(false);

    const effectDep = filterVars || [];

    useEffect(() => {
        setNotesLoading(true);
        noteService.getNotes().then((res) => {
            if (res) {
                setNotes(res.filter((r) => (notesFilter ? notesFilter(r) : true)));
            }
            setNotesLoading(false);
        });
    }, [...effectDep]);

    const createNote = (note: IPostNote) => {
        noteService.createNote(note).then((res) => {
            if (res) {
                setNotes([...notes, res]);
            }
        });
    };

    return {
        createNote,
        notes,
        notesLoading,
    };
};
