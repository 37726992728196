/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import DeleteButtonRender from "../grid/DeleteButtonRender";
import EditButtonRender from "../grid/EditButtonRender";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import KeysysAccordion from "../accordion/KeysysAccordion";
import { Button } from "react-bootstrap";
import PageHeader from "../header/PageHeader";
import LinkWrapper from "../routing/LinkWrapper";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, showModal } from "../../actions/ModalActionCreators";
import { CONFIRM_DELETE_REPORT_MODAL } from "../../constants/ModalConstants";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import { AppState } from "../../state/AppState";
import { useReportList } from "../../hooks/reportListHook";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";

const ReportsList = () => {
    const dispatch = useDispatch();
    const { systems, removeReport, setSearchString } = useReportList();
    const confirmDeleteReportModalIsVisible = useSelector((state: AppState) =>
        state.modalState.activeModals.includes(CONFIRM_DELETE_REPORT_MODAL)
    );

    const reportToDelete = useSelector((state: AppState) =>
        state.modalState.modalValues.get("CONFIRM_DELETE_REPORT_MODAL")
    );

    const reportsRef = useSelectorAsRef((state: AppState) => {
        const reports = state.reportState?.reports?.map((r: any) => ({ ...r, reportType: "Master Report" })) || [];
        const subReports =
            state.subReportState?.subReports
                .filter((sr) => sr.isActive === true)
                .map((sr: any) => ({ ...sr, reportType: "Sub Report" })) || [];
        return [...reports, ...subReports];
    });

    const showConfirmDeleteReportModal = (report: any) => {
        dispatch(showModal(CONFIRM_DELETE_REPORT_MODAL, report));
    };

    const showDeleteCallback = useCallback((params: any) => {
        // Retrieve Report from redux store
        const id = typeof params === "string" ? +params.split("-")[2] : params;
        const reportType = typeof params === "string" ? params.split("-")[0] : params;
        reportType === "sub"
            ? showConfirmDeleteReportModal(
                  reportsRef.current.find((report: any) => id === report.id && report.reportType === "Sub Report")
              )
            : showConfirmDeleteReportModal(
                  reportsRef.current.find((report: any) => id === report.id && report.reportType === "Master Report")
              );
    }, []);

    const closeDeleteReportConfirmationModal = () => {
        dispatch(hideModal(CONFIRM_DELETE_REPORT_MODAL));
    };

    const handleDeleteReport = () => {
        // Delete report based on reportType
        if (reportToDelete) {
            const { reportType, ...report } = reportToDelete;
            removeReport(report, reportType);
            closeDeleteReportConfirmationModal();
        }
    };
    const columnDefs: ColDef[] = [
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "Report Type",
            field: "reportType",
        },
        {
            headerName: "Columns",
            field: "attributeCount",
        },
        {
            headerName: "Status",
            field: "active",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: (data: any) => {
                return (
                    <LinkWrapper
                        path={`/reports/${
                            data.data.reportType === "Master Report"
                                ? `master-list/${data.data.id}`
                                : `sub-reports/${data.data.id.split("-")[2]}`
                        }/edit`}
                    >
                        <EditButtonRender {...data} />
                    </LinkWrapper>
                );
            },
            cellRendererParams: {
                entityName: "Report",
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Report",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: DeleteButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "Report",
                    showDelete: showDeleteCallback,
                    isActive: data.data.reportType === "Sub Report" && data.data.isActive === true,
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Delete Report",
        },
    ];
    const onSearch = (searchString: string) => {
        setSearchString(searchString);
    };
    return (
        <>
            <PageHeader search={true} onSearchChange={onSearch}>
                <LinkWrapper path={"/reports/master-list/add"}>
                    <Button className={"add-user-button finosec-button-info"} data-testid={"add-master-report-button"}>
                        Add Master List
                    </Button>
                </LinkWrapper>
                <LinkWrapper path={"/reports/sub-reports/add"}>
                    <Button className={"add-user-button finosec-button-info"} data-testid={"add-sub-report-button"}>
                        Add Sub Report
                    </Button>
                </LinkWrapper>
            </PageHeader>
            {systems.map((s) => (
                <KeysysAccordion renderOnExpanded key={s.name} title={s.name} count={s.reports.length}>
                    <div className="ag-theme-alpine">
                        <KeysysGrid pageSize={15} pagination={true} getRows={() => s.reports} columnDefs={columnDefs} />
                    </div>
                </KeysysAccordion>
            ))}
            <div>
                <ConfirmationModal
                    id="confirmDeleteMasterReport"
                    show={confirmDeleteReportModalIsVisible}
                    title="Deactivate Report?"
                    message={"Do you really want to deactivate this report?"}
                    onCloseButtonClick={closeDeleteReportConfirmationModal}
                    onOkButtonClick={() => handleDeleteReport()}
                    theme={"danger"}
                />
            </div>
        </>
    );
};

export default ReportsList;
