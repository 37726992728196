import { TYPES } from "../../dependencyInjection/Types";
import { IReportTypeService } from "../../services/reportType/IReportTypeService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { ReportTypesFetchedAction, REPORT_TYPES_FETCHED } from "./ReportTypeAction";

export function getReportTypes(): KeysysThunkAction<void, ReportTypesFetchedAction> {
    return async (dispatch, getState, container) => {
        const reportTypeService = container.get<IReportTypeService>(TYPES.ReportTypeService);
        const reportTypes = await reportTypeService.getReportTypes();

        if (reportTypes) {
            dispatch<ReportTypesFetchedAction>({
                type: REPORT_TYPES_FETCHED,
                reportTypes: reportTypes,
            });
        }
    };
}
