import { ErrorMessage, Field, FieldProps, FormikProps } from "formik";
import ErrorMessageText from "../error-message/ErrorMessageText";
import DatePicker from "react-datepicker";
import "./KeysysDatetime.css";

interface MonthPickerProps {
    formProps: FormikProps<any>;
    id: string;
    placeholder: string;
    fieldName: string;
    label: string;
    disabled?: boolean;
}

export default function MonthPicker(props: MonthPickerProps) {
    const getSafeDateValue = (input: any) => {
        if (!input) {
            return undefined;
        }

        const inputAsDate = new Date(input);
        return isNaN(inputAsDate as any) ? undefined : inputAsDate;
    };

    function setFieldValue(change: Date | null) {
        props.formProps.setFieldValue(props.fieldName, getSafeDateValue(change));
    }

    return (
        <div>
            <Field name={props.fieldName}>
                {({ field }: FieldProps<any>) => (
                    <div>
                        <label htmlFor={props.id}>{props.label} </label>
                        <div>
                            <DatePicker
                                className={
                                    props.formProps.errors[props.fieldName] && props.formProps.touched[props.fieldName]
                                        ? "form-control invalid"
                                        : "form-control"
                                }
                                onChange={setFieldValue}
                                selected={getSafeDateValue(field.value)}
                                disabled={!!props.disabled ? props.disabled : false}
                                placeholderText={props.placeholder}
                                showMonthYearPicker
                                dateFormat={"MM/yyyy"}
                            />
                        </div>
                    </div>
                )}
            </Field>
            <ErrorMessage name={props.fieldName}>{(msg) => <ErrorMessageText message={msg} />}</ErrorMessage>
        </div>
    );
}
