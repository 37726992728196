import FormBuilder, { ApiViewFields, FormControlOption } from "../form-builder/FormBuilder";
import { Form, Formik } from "formik";
import { Button, Card } from "react-bootstrap";
import { useDashboardForm } from "../../hooks/dashboardFormHook";
import { LoadingSpinner } from "../loading/Loading";

const DashboardForm = () => {
    const { dashboard, systems, onSave, onCreateSystem, isEdit } = useDashboardForm();
    if (!dashboard) {
        return <LoadingSpinner />;
    }

    const systemOptions: FormControlOption[] = systems
        ? systems.map((s) => ({ value: `${s.id}`, text: `${s.name}`, key: `${s.id}` } as FormControlOption))
        : [];
    const meta: ApiViewFields[] = [
        {
            key: "name",
            label: "Dashboard Name",
            type: "input",
        },
        {
            key: "finosecSystemId",
            label: "Select System",
            type: "dropdown",
            canAddValuesToOptions: true,
            onAddValueToOptions: (system) => onCreateSystem(system),
            options: systemOptions,
        },
        {
            key: "templateId",
            label: "Template Id",
            type: "input",
        },
    ];
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Formik enableReinitialize initialValues={dashboard} onSubmit={(v) => onSave(v)}>
                <Card style={{ width: "75%", alignSelf: "center" }}>
                    <Form className="keysys-form">
                        <Card.Header className="finosec-button-info" style={{ color: "white" }}>
                            <Card.Title>Create Master List</Card.Title>
                        </Card.Header>
                        <Card.Body
                            style={{ display: "flex", flexDirection: "column" }}
                            className="justify-content-md-center"
                        >
                            <FormBuilder meta={meta} chunkNumber={2} initialValues={dashboard}></FormBuilder>
                        </Card.Body>
                        <Card.Footer className="text-right" style={{ backgroundColor: "white" }}>
                            <Button type="submit" className="finosec-button-info">
                                {isEdit ? "Save Changes" : "Create Dashboard"}
                            </Button>
                        </Card.Footer>
                    </Form>
                </Card>
            </Formik>
        </div>
    );
};

export default DashboardForm;
