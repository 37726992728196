import { TYPES } from "../../dependencyInjection/Types";
import { WorkflowJob } from "../../models/WorkflowJob";
import { IWorkflowJobService } from "../../services/workflowJob/IWorkflowJobService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    WorkflowJobCreated,
    WorkflowJobDeleted,
    WorkflowJobEdited,
    WorkflowJobFetched,
    WorkflowJobSelected,
    WorkflowJobsFetched,
    WORKFLOW_JOBS_FETCHED,
    WORKFLOW_JOB_CREATED,
    WORKFLOW_JOB_DELETED,
    WORKFLOW_JOB_EDITED,
    WORKFLOW_JOB_FETCHED,
    WORKFLOW_JOB_SELECTED,
} from "./WorkflowJobActions";

export function getWorkflowJobs(): KeysysThunkAction<void, WorkflowJobsFetched> {
    return async (dispatch, getState, container) => {
        const workflowJobService = container.get<IWorkflowJobService>(TYPES.WorkflowJobService);
        const workflowJobs = await workflowJobService.getWorkflowJobs();
        if (workflowJobs) {
            dispatch<WorkflowJobsFetched>({
                type: WORKFLOW_JOBS_FETCHED,
                workflowJobs,
            });
        }
    };
}

export function getWorkflowJobsByTenantId(tenantId: number): KeysysThunkAction<void, WorkflowJobsFetched> {
    return async (dispatch, getState, container) => {
        const workflowJobService = container.get<IWorkflowJobService>(TYPES.WorkflowJobService);
        const workflowJobs = await workflowJobService.getWorkflowJobsByTenantId(tenantId);
        if (workflowJobs) {
            dispatch<WorkflowJobsFetched>({
                type: WORKFLOW_JOBS_FETCHED,
                workflowJobs,
            });
        }
    };
}

export function getWorkflowJob(id: number): KeysysThunkAction<void, WorkflowJobFetched> {
    return async (dispatch, getState, container) => {
        const workflowJobService = container.get<IWorkflowJobService>(TYPES.WorkflowJobService);
        const workflowJob = await workflowJobService.getWorkflowJob(id);
        if (workflowJob) {
            dispatch<WorkflowJobFetched>({
                type: WORKFLOW_JOB_FETCHED,
                workflowJob,
            });
        }
    };
}

export function createWorkflowJob(_workflowJob: WorkflowJob): KeysysThunkAction<void, WorkflowJobCreated> {
    return async (dispatch, getState, container) => {
        const workflowJobService = container.get<IWorkflowJobService>(TYPES.WorkflowJobService);
        const workflowJob = await workflowJobService.createWorkflowJob(_workflowJob);
        if (workflowJob) {
            dispatch<WorkflowJobCreated>({
                type: WORKFLOW_JOB_CREATED,
                workflowJob,
            });
        }
    };
}

export function editWorkflowJob(
    workflowJob: WorkflowJob,
    newWorkflowJob: WorkflowJob
): KeysysThunkAction<void, WorkflowJobEdited> {
    return async (dispatch, getState, container) => {
        const workflowJobService = container.get<IWorkflowJobService>(TYPES.WorkflowJobService);
        const patched = await workflowJobService.editWorkflowJob(workflowJob, newWorkflowJob);
        if (patched) {
            dispatch<WorkflowJobEdited>({
                type: WORKFLOW_JOB_EDITED,
                workflowJob,
                newWorkflowJob: patched,
            });
        }
    };
}

export function deleteWorkflowJob(_workflowJob: WorkflowJob): KeysysThunkAction<void, WorkflowJobDeleted> {
    return async (dispatch, getState, container) => {
        const workflowJobService = container.get<IWorkflowJobService>(TYPES.WorkflowJobService);
        const workflowJob = await workflowJobService.deleteWorkflowJob(_workflowJob);
        if (workflowJob) {
            dispatch<WorkflowJobDeleted>({
                type: WORKFLOW_JOB_DELETED,
                workflowJob,
            });
        }
    };
}

export function selectWorkflowJob(workflowJob: WorkflowJob): WorkflowJobSelected {
    return {
        type: WORKFLOW_JOB_SELECTED,
        workflowJob,
    };
}
