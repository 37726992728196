import { Identifiable } from "../models/Identifiable";

export function replaceInCollection<TModel extends Identifiable>(
    collection: TModel[],
    original: TModel,
    replacement: TModel,
    sortFn?: (a: TModel, b: TModel) => number
): TModel[] {
    const newCollection = collection.filter((m) => m.id !== original.id);
    newCollection.push(replacement);
    return !!sortFn ? newCollection.sort(sortFn) : newCollection;
}
