import { AccessToken } from "../models/AccessToken";

export const LOGGED_IN = "LOGGED_IN";
export const LOGOUT = "LOGOUT";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const UNIMPERSONATE_USER = "Unimpersonate User";
export const IMPERSONATE_USER = "Impersonate User";

export interface LoggedInAction {
    type: typeof LOGGED_IN;
    accessToken: string;
    decodedAccessToken: AccessToken;
    refreshToken: string;
    expiresIn: number;
}

export interface LogoutAction {
    type: typeof LOGOUT;
}

export interface LoginFailAction {
    type: typeof LOGIN_FAIL;
}

export interface ImpersonateUserAction {
    type: typeof IMPERSONATE_USER;
    userId: number;
    email: string;
    tenant_id: number;
    roles: string;
    impersonatorId: number;
}

export interface UnimpersonateUserAction {
    type: typeof UNIMPERSONATE_USER;
    userId: number;
    email: string;
    tenant_id: number;
    roles: string;
}

export type AuthenticateActions =
    | LoggedInAction
    | LogoutAction
    | LoginFailAction
    | UnimpersonateUserAction
    | ImpersonateUserAction;
