import { inject, injectable } from "inversify";
import * as redux from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { CustomerReportAttribute } from "../../models/CustomerReportAttribute";
import * as IExceptionHandler from "../exceptionHandlers/IExceptionHandler";
import { ICustomerReportAttributeService } from "./ICustomerReportAttributeService";
import * as IHttpClient from "../HttpClient/IHttpClient";

@injectable()
export class CustomerReportAttributeService implements ICustomerReportAttributeService {
    constructor(
        @inject(TYPES.Store) private store: redux.Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient.IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler.IExceptionHandler
    ) {}

    getCustomerReportAttributes(customerReportId: number): Promise<void | CustomerReportAttribute[]> {
        return this.httpClient
            .get<CustomerReportAttribute[]>(`/customer-report/${customerReportId}/customer-report-attributes`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving Customer Report attributes. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
