import { FileTypeActions, FILE_TYPES_FETCHED } from "../../actions/fileType/FileTypeActions";
import { FileTypeState } from "../../state/FileTypeState";

const initialState: FileTypeState = {
    fileTypes: [],
};

export function fileTypeReducer(state: FileTypeState = initialState, action: FileTypeActions): FileTypeState {
    switch (action.type) {
        case FILE_TYPES_FETCHED: {
            return {
                ...state,
                fileTypes: action.fileTypes,
            };
        }
        default:
            return state;
    }
}
