import { createSelector } from "reselect";
import { AppState } from "../state/AppState";

export const selectReportAttributes = (state: AppState) => state.reportAttributeState?.reportAttributes;
export const selectId = (state: any, id: number) => id;
const selectSubReportAttributes = (state: AppState) => state.subReportAttributeState.subReportAttributes;
const selectCustomerReportAttributes = (state: AppState) => state.customerReportAttributeState.customerReportAttributes;

export const selectedReportAttributes = createSelector(
    [selectReportAttributes, selectId],
    (reportAttributes, reportId) => {
        return reportAttributes.filter((ra) => ra.reportId === reportId);
    }
);

export const selectAttributesForReportList = createSelector(
    [
        selectReportAttributes,
        selectSubReportAttributes,
        selectCustomerReportAttributes,
        (state: AppState, id: any) => id,
    ],
    (
        reportAttributes,
        subReportAttributes,
        customerReportAttributes,
        { reportId, subReportId, customerReportId, isCurrent }
    ) => {
        const customerReportAttributesFiltered = customerReportAttributes.filter(
            (cra) => cra.customerReportId === customerReportId
        );
        const isSubReport = !!reportId && !!subReportId;
        if (isSubReport && subReportAttributes.length === 0) {
            return [];
        }
        return reportId
            ? reportAttributes
                  .filter((a) => a.reportId === reportId)
                  .map((ra) => ({
                      ...ra,
                      subReport: subReportId
                          ? !isCurrent && customerReportAttributesFiltered.length !== 0
                              ? customerReportAttributes.find(
                                    (cra) =>
                                        cra.customerReportId === customerReportId && cra.reportAttributeId === ra.id
                                )
                              : subReportAttributes.find(
                                    (sra) => sra.subReportId === subReportId && sra.reportAttributeId === ra.id
                                )
                          : null,
                  }))
            : [];
    }
);
