import { inject, injectable } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { CustomerImportSubReportAssignment } from "../../models/CustomerImportSubReportAssignment";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { ICustomerImportSubReportAssignmentService } from "./ICustomerImportSubReportAssignmentService";

@injectable()
export class CustomerImportSubReportAssignmentService implements ICustomerImportSubReportAssignmentService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getCustomerImportSubReportAssignments(
        customerImportId: number
    ): Promise<void | CustomerImportSubReportAssignment[]> {
        return this.httpClient
            .get<CustomerImportSubReportAssignment[]>(
                `/customer-import-sub-report-assignment/customer-import-id/${customerImportId}`
            )
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the customer import sub report assignments. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
