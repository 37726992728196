import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Store } from "redux";
import { IWorkflowStatusService } from "./IWorkflowStatusService";
import { WorkflowStatus } from "../../models/WorkflowStatus";

@injectable()
export class WorkflowStatusService implements IWorkflowStatusService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}
    getWorkflowStatuses(): Promise<void | WorkflowStatus[]> {
        return this.httpClient
            .get<WorkflowStatus[]>("/workflow-status")
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow statuses. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }
}
