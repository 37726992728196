import { CustomerImportState } from "../../state/CustomerImportState";
import {
    CustomerImportActions,
    CUSTOMER_IMPORTS_FETCHED,
    CREATE_CUSTOMER_IMPORT,
    EDIT_CUSTOMER_IMPORT,
    DELETE_CUSTOMER_IMPORT,
} from "../../actions/customerImport/CustomerImportActions";
import { replaceInCollection } from "./../ReplaceInCollection";

const initialState: CustomerImportState = {
    customerImports: [],
};

export function customerImportReducer(
    state: CustomerImportState = initialState,
    action: CustomerImportActions
): CustomerImportState {
    switch (action.type) {
        case CUSTOMER_IMPORTS_FETCHED: {
            return {
                ...state,
                customerImports: action.customerImports,
            };
        }

        case CREATE_CUSTOMER_IMPORT:
            return {
                ...state,
                customerImports: [...state.customerImports, action.customerImport],
            };

        case EDIT_CUSTOMER_IMPORT:
            return {
                ...state,
                customerImports: replaceInCollection(
                    state.customerImports,
                    action.originalCustomerImport,
                    action.newCustomerImport,
                    (a, b) => `${a.id}`.localeCompare(`${b.id}`)
                ),
            };
        case DELETE_CUSTOMER_IMPORT:
            const originalActiveCustomerImport = state.customerImports.find((r) => r.id === action.customerImport.id);

            return {
                ...state,
                customerImports: replaceInCollection(
                    state.customerImports,
                    originalActiveCustomerImport!,
                    action.customerImport
                ),
            };
        default:
            return state;
    }
}
