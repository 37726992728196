import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";

import PathFinder from "../../images/Pathfinder-Logo-(Green).jpg";
import Sysorec from "../../images/Sysorec-Logo(White).jpg";
import Academy from "../../images/FinosecAcademy.jpg";

import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { useCurrentUser } from "../../hooks/currentUserHook";

const Home = () => {
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant, loading } = useCurrentTenant(decodedAccessToken.tenant_id);
    const loggedInUser = useCurrentUser(decodedAccessToken.userId);
    const [showIframe ] = useState<boolean>(false);

    if (loading || !decodedAccessToken) {
        return <h3>Loading...</h3>;
    }

    // function handleNeedHelpClick() :void {
    //     setShowIframe(true);

    // }

return ( 
    <>       
       {/* { showIframe && 
            <div style={{flexGrow:1}} className="justify-content-md-center">
                <div style={{float:"right"}} onClick={()=> setShowIframe(false)}> <i className="far fa-window-close"/></div>
                <iframe style={{height:'100%', width:'100%'}} src="https://support.finosec.com/support/home" id="iframe" name="iframe"></iframe>
            </div>
        } */}

        { !showIframe && 
            <Row style={{flexGrow:1}} className="justify-content-md-center">
                <Col sm={12} md={11} lg={8}>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            {loggedInUser?.hasPathfinderAccess && (
                                <a
                                    className="card-link"
                                    style={{ height: "150px" }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={currentTenant?.pathfinderUrl}
                                >
                                    <Card style={{ height: "150px" }}>
                                        <Card.Img style={{ height: "100%" }} variant="top" src={PathFinder} />
                                    </Card>
                                </a>
                            )}
                        </Col>
                        <Col md={6}>
                            {loggedInUser?.hasSysorecAccess && (
                                <a
                                    className="card-link"
                                    style={{ height: "150px" }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href={currentTenant?.sysorecUrl}
                                >
                                    <Card style={{ height: "150px" }}>
                                        <Card.Img style={{ height: "100%" }} variant="top" src={Sysorec} />
                                    </Card>
                                </a>
                            )}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={6}>
                            {currentTenant?.finosecAcademy && loggedInUser?.hasFinosecAcademyAccess && (
                                <a
                                    className="card-link"
                                    style={{ height: "150px" }}
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.finosec.academy/feed"
                                >
                                    <Card style={{ height: "150px" }}>
                                        <Card.Img style={{ height: "100%" }} variant="top" src={Academy} />
                                    </Card>
                                </a>
                            )}
                        </Col>
                        <Col md={6}>
                            <a
                                className="card-link"
                                style={{ height: "150px" }}
                                target="iframe"
                                rel="noreferrer"
                                //onClick={()=>handleNeedHelpClick()}
                                href="https://support.finosec.com/support/home"
                            >
                                <Card style={{ height: "150px" }}>
                                    <div
                                        style={{
                                            height: "100%",
                                            fontSize: "2rem",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <span>
                                            <i
                                                className="fas fa-exclamation-triangle sidebar-image-icon"
                                                style={{ color: "#eaf2ee", textAlign: "center", marginRight: "5px" }}
                                            />
                                                Need Help
                                        </span>
                                    </div>
                                </Card>
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
    }
</>
);
};

export default Home;
