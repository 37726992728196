import { CustomerImport } from "../../models/CustomerImport";

export const CUSTOMER_IMPORTS_FETCHED = "CUSTOMER_IMPORTS_FETCHED";
export const FETCH_CUSTOMER_IMPORT = "FETCH_CUSTOMER_IMPORT";
export const CREATE_CUSTOMER_IMPORT = "CREATE_CUSTOMER_IMPORT";
export const EDIT_CUSTOMER_IMPORT = "EDIT_CUSTOMER_IMPORT";
export const DELETE_CUSTOMER_IMPORT = "DELETE_CUSTOMER_IMPORT";

export interface CustomerImportsFetchedAction {
    type: typeof CUSTOMER_IMPORTS_FETCHED;
    customerImports: CustomerImport[];
}

export interface FetchCustomerImportAction {
    type: typeof FETCH_CUSTOMER_IMPORT;
    customerImport: CustomerImport;
}

export interface CreateCustomerImportAction {
    type: typeof CREATE_CUSTOMER_IMPORT;
    customerImport: CustomerImport;
}

export interface EditCustomerImportAction {
    type: typeof EDIT_CUSTOMER_IMPORT;
    originalCustomerImport: CustomerImport;
    newCustomerImport: CustomerImport;
}

export interface DeleteCustomerImportAction {
    type: typeof DELETE_CUSTOMER_IMPORT;
    customerImport: CustomerImport;
}

export type CustomerImportActions =
    | CustomerImportsFetchedAction
    | FetchCustomerImportAction
    | CreateCustomerImportAction
    | EditCustomerImportAction
    | DeleteCustomerImportAction;
