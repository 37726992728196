import {
    WorkflowJobScheduleActions,
    WORKFLOW_JOB_SCHEDULES_FETCHED,
    WORKFLOW_JOB_SCHEDULE_CLEARED,
    WORKFLOW_JOB_SCHEDULE_CREATED,
    WORKFLOW_JOB_SCHEDULE_DELETED,
    WORKFLOW_JOB_SCHEDULE_EDITED,
    WORKFLOW_JOB_SCHEDULE_FETCHED,
    WORKFLOW_JOB_SCHEDULE_SELECTED,
} from "../../actions/workflowJobSchedule/WorkflowJobScheduleActions";
import { WorkflowJobScheduleState } from "../../state/WorkflowJobScheduleState";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: WorkflowJobScheduleState = {
    workflowJobSchedules: [],
};

export function workflowJobScheduleReducer(
    state: WorkflowJobScheduleState = initialState,
    action: WorkflowJobScheduleActions
): WorkflowJobScheduleState {
    switch (action.type) {
        case WORKFLOW_JOB_SCHEDULES_FETCHED:
            return {
                ...state,
                workflowJobSchedules: [...action.workflowJobSchedules].sort((a, b) => a.name.localeCompare(b.name)),
            };
        case WORKFLOW_JOB_SCHEDULE_FETCHED:
            const existing = state.workflowJobSchedules.find((s) => s.id === action.workflowJobSchedule.id);
            const workflowJobSchedules = existing
                ? replaceInCollection(state.workflowJobSchedules, existing, action.workflowJobSchedule, (a, b) =>
                      a.name.localeCompare(b.name)
                  )
                : [...state.workflowJobSchedules, action.workflowJobSchedule].sort((a, b) =>
                      a.name.localeCompare(b.name)
                  );
            return {
                ...state,
                workflowJobSchedules,
                selectedWorkflowJobSchedule: action.workflowJobSchedule,
            };
        case WORKFLOW_JOB_SCHEDULE_CREATED:
            return {
                ...state,
                workflowJobSchedules: [...state.workflowJobSchedules, action.workflowJobSchedule].sort((a, b) =>
                    a.name.localeCompare(b.name)
                ),
            };
        case WORKFLOW_JOB_SCHEDULE_EDITED:
            return {
                ...state,
                workflowJobSchedules: replaceInCollection(
                    state.workflowJobSchedules,
                    action.workflowJobSchedule,
                    action.newWorkflowJobSchedule,
                    (a, b) => a.name.localeCompare(b.name)
                ),
            };
        case WORKFLOW_JOB_SCHEDULE_DELETED:
            const original = state.workflowJobSchedules.find((s) => s.id === action.workflowJobSchedule.id);
            return {
                ...state,
                workflowJobSchedules: replaceInCollection(
                    state.workflowJobSchedules,
                    original!,
                    action.workflowJobSchedule
                ),
            };
        case WORKFLOW_JOB_SCHEDULE_SELECTED:
            return { ...state, selectedWorkflowJobSchedule: action.workflowJobSchedule };
        case WORKFLOW_JOB_SCHEDULE_CLEARED:
            return { ...state, selectedWorkflowJobSchedule: undefined };
        default:
            return state;
    }
}
