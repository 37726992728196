import {
    SystemAccountTypeActions,
    SYSTEM_ACCOUNT_TYPES_FETCHED,
} from "../../actions/systemAccountType/SystemAccountTypeAction";
import { SystemAccountTypeState } from "../../state/SystemAccountTypeState";

const initialState: SystemAccountTypeState = {
    systemAccountTypes: [],
};
export function systemAccountTypeReducer(
    state: SystemAccountTypeState = initialState,
    action: SystemAccountTypeActions
): SystemAccountTypeState {
    switch (action.type) {
        case SYSTEM_ACCOUNT_TYPES_FETCHED: {
            return {
                ...state,
                systemAccountTypes: action.systemAccountTypes,
            };
        }
        default:
            return state;
    }
}
