import { TYPES } from "../../dependencyInjection/Types";
import { WorkflowNotificationTemplate } from "../../models/WorkflowNotificationTemplate";
import { IWorkflowNotificationTemplateService } from "../../services/workflowNotificationTemplate/IWorkflowNotificationTemplateService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    WorkflowNotificationTemplateCreated,
    WorkflowNotificationTemplateDeleted,
    WorkflowNotificationTemplateEdited,
    WorkflowNotificationTemplateFetched,
    WorkflowNotificationTemplateSelected,
    WorkflowNotificationTemplatesFetched,
    WORKFLOW_NOTIFICATION_TEMPLATES_FETCHED,
    WORKFLOW_NOTIFICATION_TEMPLATE_CREATED,
    WORKFLOW_NOTIFICATION_TEMPLATE_DELETED,
    WORKFLOW_NOTIFICATION_TEMPLATE_EDITED,
    WORKFLOW_NOTIFICATION_TEMPLATE_FETCHED,
    WORKFLOW_NOTIFICATION_TEMPLATE_SELECTED,
} from "./WorkflowNotificationTemplateActions";

export function getWorkflowNotificationTemplates(): KeysysThunkAction<void, WorkflowNotificationTemplatesFetched> {
    return async (dispatch, getState, container) => {
        const workflowNotificationTemplateService = container.get<IWorkflowNotificationTemplateService>(
            TYPES.WorkflowNotificationTemplateService
        );
        const workflowNotificationTemplates = await workflowNotificationTemplateService.getWorkflowNotificationTemplates();
        if (workflowNotificationTemplates) {
            dispatch<WorkflowNotificationTemplatesFetched>({
                type: WORKFLOW_NOTIFICATION_TEMPLATES_FETCHED,
                workflowNotificationTemplates,
            });
        }
    };
}

export function getWorkflowNotificationTemplate(
    id: number
): KeysysThunkAction<void, WorkflowNotificationTemplateFetched> {
    return async (dispatch, getState, container) => {
        const workflowNotificationTemplateService = container.get<IWorkflowNotificationTemplateService>(
            TYPES.WorkflowNotificationTemplateService
        );
        const workflowNotificationTemplate = await workflowNotificationTemplateService.getWorkflowNotificationTemplate(
            id
        );
        if (workflowNotificationTemplate) {
            dispatch<WorkflowNotificationTemplateFetched>({
                type: WORKFLOW_NOTIFICATION_TEMPLATE_FETCHED,
                workflowNotificationTemplate,
            });
        }
    };
}

export function createWorkflowNotificationTemplate(
    _workflowNotificationTemplate: WorkflowNotificationTemplate
): KeysysThunkAction<void, WorkflowNotificationTemplateCreated> {
    return async (dispatch, getState, container) => {
        const workflowNotificationTemplateService = container.get<IWorkflowNotificationTemplateService>(
            TYPES.WorkflowNotificationTemplateService
        );
        const workflowNotificationTemplate = await workflowNotificationTemplateService.createWorkflowNotificationTemplate(
            _workflowNotificationTemplate
        );
        if (workflowNotificationTemplate) {
            dispatch<WorkflowNotificationTemplateCreated>({
                type: WORKFLOW_NOTIFICATION_TEMPLATE_CREATED,
                workflowNotificationTemplate,
            });
        }
    };
}

export function editWorkflowNotificationTemplate(
    workflowNotificationTemplate: WorkflowNotificationTemplate,
    newWorkflowNotificationTemplate: WorkflowNotificationTemplate
): KeysysThunkAction<void, WorkflowNotificationTemplateEdited> {
    return async (dispatch, getState, container) => {
        const workflowNotificationTemplateService = container.get<IWorkflowNotificationTemplateService>(
            TYPES.WorkflowNotificationTemplateService
        );
        const patched = await workflowNotificationTemplateService.editWorkflowNotificationTemplate(
            workflowNotificationTemplate,
            newWorkflowNotificationTemplate
        );
        if (patched) {
            dispatch<WorkflowNotificationTemplateEdited>({
                type: WORKFLOW_NOTIFICATION_TEMPLATE_EDITED,
                workflowNotificationTemplate,
                newWorkflowNotificationTemplate: patched,
            });
        }
    };
}

export function deleteWorkflowNotificationTemplate(
    _workflowNotificationTemplate: WorkflowNotificationTemplate
): KeysysThunkAction<void, WorkflowNotificationTemplateDeleted> {
    return async (dispatch, getState, container) => {
        const workflowNotificationTemplateService = container.get<IWorkflowNotificationTemplateService>(
            TYPES.WorkflowNotificationTemplateService
        );
        const workflowNotificationTemplate = await workflowNotificationTemplateService.deleteWorkflowNotificationTemplate(
            _workflowNotificationTemplate
        );
        if (workflowNotificationTemplate) {
            dispatch<WorkflowNotificationTemplateDeleted>({
                type: WORKFLOW_NOTIFICATION_TEMPLATE_DELETED,
                workflowNotificationTemplate,
            });
        }
    };
}

export function selectWorkflowNotificationTemplate(
    workflowNotificationTemplate: WorkflowNotificationTemplate
): WorkflowNotificationTemplateSelected {
    return {
        type: WORKFLOW_NOTIFICATION_TEMPLATE_SELECTED,
        workflowNotificationTemplate,
    };
}
