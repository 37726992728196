/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import KeysysAccordion from "../accordion/KeysysAccordion";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import { getFinosecSystemStatisticsByPartnerId } from "../../actions/finosecSystemStatistic/FinosecSystemStatisticActionCreator";
import moment from "moment";
import { Accordion, Col } from "react-bootstrap";

const UAROverview = () => {
    const dispatch = useDispatch();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant, childTenants } = useCurrentTenant(decodedAccessToken.tenant_id);
    const partnerStatisticsData = useSelector(
        (state: AppState) => state.finosecSystemStatisticsState.partnerSystemStatisticsData
    );

    useEffect(() => {
        dispatch(getFinosecSystemStatisticsByPartnerId(decodedAccessToken.tenant_id));
    }, [decodedAccessToken.tenant_id]);

    const getTotalCustomerReports = useCallback((customerId: number) => {
        const totalCustomerReports = partnerStatisticsData
            .filter((ps) => ps.tenantId === customerId)
            .reduce((totalCustomerReports, current) => totalCustomerReports + current.totalSubReports, 0);

        return totalCustomerReports;
    }, []);

    const getTotalCompletedCustomerReports = useCallback((customerId: number) => {
        const totalCompletedCustomerReports = partnerStatisticsData
            .filter((ps) => ps.tenantId === customerId)
            .reduce((totalCustomerReports, current) => totalCustomerReports + current.totalReviewedReports, 0);
        return totalCompletedCustomerReports;
    }, []);

    const getTotalCustomerPercentComplete = useCallback((customerId: number) => {
        const totalCustomerReports = partnerStatisticsData
            .filter((ps) => ps.tenantId === customerId)
            .reduce((totalCustomerReports, current) => totalCustomerReports + current.totalSubReports, 0);
        const totalCompletedCustomerReports = partnerStatisticsData
            .filter((ps) => ps.tenantId === customerId)
            .reduce((totalCustomerReports, current) => totalCustomerReports + current.totalReviewedReports, 0);

        return (totalCompletedCustomerReports / totalCustomerReports) * 100;
    }, []);

    const [textSearch, setSearchText] = useState<string>("");

    const columnDefs: ColDef[] = [
        {
            headerName: "Name",
            field: "finosecSystemName",
        },
        {
            headerName: "Report Count",
            field: "totalSubReports",
        },
        {
            headerName: "Reports Reviewed",
            field: "totalReviewedReports",
        },
        {
            headerName: "Reports Remaining to Review",
            field: "totalNotReviewedReports",
        },
        {
            headerName: "Percentage Complete",
            cellRendererFramework: (data: any) => {
                const statisticsData = data?.data;

                if (!statisticsData?.totalSubReports || statisticsData?.totalReviewedReports === 0) {
                    return "0%";
                }

                if (statisticsData && statisticsData.totalReviewedReports && statisticsData.totalSubReports)
                    return (
                        <span>
                            {" "}
                            {Math.round((statisticsData.totalReviewedReports / statisticsData.totalSubReports) * 100)}%
                        </span>
                    );
                return null;
            },
        },
        {
            headerName: "Review Complete Date",
            field: "reviewCompletedDate",
            cellRendererFramework: (data: any) => {
                const date = data?.data?.reviewCompletedDate;
                if (date) {
                    return <span>{moment(date).format("MM-DD-YYYY")}</span>;
                }
                return "";
            },
        },
        {
            headerName: "Import Data Date",
            field: "importDate",
            cellRendererFramework: (data: any) => {
                const date = data?.data?.importDate;
                if (date) {
                    return <span>{moment(date).format("MM-DD-YYYY")}</span>;
                }
                return "";
            },
        },
    ];

    const moreInfo = () => {
        <div>test</div>;
    };

    return (
        <>
            {childTenants?.map((c) => (
                <Accordion key={c.name} defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <Col className="col-2">
                                <h2>{c.name}</h2>
                            </Col>
                            <Col className="col-3">
                                <dl>
                                    <dt>Report Count: </dt>
                                    <dd>
                                        {partnerStatisticsData
                                            .filter((ps) => ps.tenantId === c.id)
                                            .reduce(
                                                (totalCustomerReports, current) =>
                                                    totalCustomerReports + current.totalSubReports,
                                                0
                                            )}
                                    </dd>
                                </dl>
                            </Col>
                            <Col className="col-3">
                                <dl>
                                    <dt>Report Complete: </dt>
                                    <dd>
                                        {partnerStatisticsData
                                            .filter((ps) => ps.tenantId === c.id)
                                            .reduce(
                                                (totalCustomerReports, current) =>
                                                    totalCustomerReports + current.totalReviewedReports,
                                                0
                                            )}
                                    </dd>
                                </dl>
                            </Col>
                            <Col className="col-3">
                                <dl>
                                    <dt>Percent Complete: </dt>
                                    <dd>
                                        {Math.round(
                                            (partnerStatisticsData
                                                .filter((ps) => ps.tenantId === c.id)
                                                .reduce(
                                                    (totalCustomerReports, current) =>
                                                        totalCustomerReports + current.totalReviewedReports,
                                                    0
                                                ) /
                                                partnerStatisticsData
                                                    .filter((ps) => ps.tenantId === c.id)
                                                    .reduce(
                                                        (totalCustomerReports, current) =>
                                                            totalCustomerReports + current.totalSubReports,
                                                        0
                                                    )) *
                                                100
                                        ) + "%"}
                                    </dd>
                                </dl>
                            </Col>
                            <Col className="col-2">
                                <span data-testid="accordion-count" className="count">
                                    {partnerStatisticsData?.filter((ps: any) => ps.tenantId === c.id).length}
                                </span>
                            </Col>
                        </Accordion.Header>
                        <Accordion.Body className="margin-0 padding-0">
                            <Accordion key={c.name} defaultActiveKey="0">
                                <Accordion.Collapse eventKey="0">
                                    <div className="ag-theme-alpine">
                                        <KeysysGrid
                                            getRowId={(data) => data.finosecSystemId}
                                            getRows={() =>
                                                partnerStatisticsData?.filter((ps: any) => ps.tenantId === c.id)
                                            }
                                            columnDefs={columnDefs}
                                        />
                                    </div>
                                </Accordion.Collapse>
                            </Accordion>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ))}
        </>
    );
};

export default UAROverview;
