import { FILE_TYPES_FETCHED, FileTypesFetchedAction } from "./FileTypeActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { IFileTypeService } from "../../services/fileType/IFileTypeService";

export function getFileTypes(): KeysysThunkAction<void, FileTypesFetchedAction> {
    return async (dispatch, getState, container) => {
        const fileTypeService = container.get<IFileTypeService>(TYPES.FileTypeService);
        const fileTypes = await fileTypeService.getFileTypes();

        if (fileTypes) {
            dispatch<FileTypesFetchedAction>({
                type: FILE_TYPES_FETCHED,
                fileTypes: fileTypes,
            });
        }
    };
}
