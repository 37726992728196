import { ModalState } from "../state/ModalState";
import { HIDE_MODAL, ModalActionTypes, SHOW_MODAL } from "../actions/ModalActions";
import { reject } from "lodash-es";
import { Map } from "immutable";

const initialState: ModalState = {
    activeModals: [],
    modalValues: Map<string, any>(),
};

export function modalReducer(state: ModalState = initialState, action: ModalActionTypes): ModalState {
    switch (action.type) {
        case SHOW_MODAL: {
            let newModalValues: Map<string, any> | undefined = undefined;
            if (action.modalValues) {
                newModalValues = state.modalValues.set(action.modal, action.modalValues);
            }
            return {
                ...state,
                activeModals: [...state.activeModals, action.modal],
                modalValues: newModalValues ?? state.modalValues,
            };
        }
        case HIDE_MODAL:
            return {
                ...state,
                activeModals: reject(state.activeModals, (modal: string) => modal === action.modal),
                modalValues: state.modalValues.delete(action.modal),
            };
        default:
            return state;
    }
}
