import { useEffect, useState, useMemo } from "react";
import { IIntakeSystemFileForm } from "./IIntakeSystemFileForm";
import { IIntakeSystemFileModal } from "./IIntakeSystemFileForm";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
//import { AddIntakeSystemFileFormModel } from "./AddIntakeSystemFileFormModel";
import * as Yup from "yup";
import { IntakeSystemFile } from "../../models/IntakeSystemFile";
import {
    createIntakeSystemFile,
    deleteIntakeSystemFile,
    editIntakeSystemFile,
} from "../../actions/intakeSystemFiles/IntakeSystemFilesActionCreators";
import { useDispatch, useSelector } from "react-redux";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { AppState } from "../../state/AppState";
import { Col, Row } from "react-bootstrap";
import KeysysInput from "../form/input/KeysysInput";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import { Tenant } from "../../models/Tenant";
import KeysysSelect from "../form/select/KeysysSelect";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import { useImportList } from "../../hooks/importListHook";
import { useIntakeSystemFiles } from "../../hooks/intakeSystemFileHook";

export default function IntakeSystemFileForm(props: IIntakeSystemFileModal) {
    const dispatch = useDispatch();
    const {
        finosecSystems,
        selectedPartnerId,
        selectPartnerId,
        partnerTenants,
        fileTypes,
        customerTenants,
    } = useImportList();

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);

    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const claimTenantName = decodedAccessToken.tenant_name;

    const tenants = useSelector<AppState, Tenant[]>((state) => state.tenantState.tenants);

    const { selectedFile } = useIntakeSystemFiles();
    const partnertenants = useSelector<AppState, Tenant[]>((s) =>
        s.tenantState.tenants
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((x) => x.parentTenantId === null || x.parentTenantId === undefined)
    );

    const customertenants = useSelector<AppState, Tenant[]>((s) =>
        s.tenantState.tenants
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((x) => x.parentTenantId === selectedPartnerId)
    );
    const partnerTenantOptions: KeysysSelectOption[] = partnerTenants
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((pt) => ({
            value: pt.id,
            label: pt.name,
        }));

    const customerTenantOptions: KeysysSelectOption[] = customerTenants
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((ct) => ({
            value: ct.id,
            label: ct.name,
        }));

    const fileTypeOptions: KeysysSelectOption[] = fileTypes
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((ft) => ({
            value: ft.id,
            label: ft.name,
        }));

    const systemOptions: KeysysSelectOption[] = finosecSystems
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((sys) => ({
            value: sys.id,
            label: sys.name,
        }));

    const initalValues = useMemo(
        (): IIntakeSystemFileForm => ({
            displayFileName: selectedFile?.displayFileName ?? "",
            processingFileName: selectedFile?.processingFileName ?? "",
            fileTypeId: selectedFile?.fileTypeId ?? 0,
            systemId: selectedFile?.systemId ?? props.systemId,
            customerId: selectedFile?.customerId ?? 0,
            isRequired: selectedFile?.isRequired ?? true,
        }),
        [selectedFile]
    );

    const intakeSystemfileSchema = Yup.object<IIntakeSystemFileForm>().shape({
        systemId: Yup.number().min(1, "System is required").required("System is required"),
        displayFileName: Yup.string()
            .max(50, "Display File Name must be 50 characters or less")
            .required("Display File Name is required"),
        processingFileName: Yup.string()
            .max(50, "Processing Name must be 50 characters or less")
            .required("Processing Name is required"),
        fileTypeId: Yup.string().nullable().required("File Type is required"),
    });
    function clearFormAndHide(
        formProps: FormikProps<IIntakeSystemFileForm> | FormikHelpers<IIntakeSystemFileForm>
    ): void {
        formProps.resetForm();
        props.onClose();
    }

    const handleDeleteIntakeSystemFile = (intakeSystemFile: IntakeSystemFile) => {
        if (selectedFile === undefined) {
            return;
        } else {
            dispatch(deleteIntakeSystemFile(selectedFile));
            setShowConfirmDeleteModal(false);
            props.onClose();
        }
    };

    function getTitle() {
        if (props.type === "add") {
            return "Add Intake System File";
        }
        if (props.type === "edit") {
            return "Edit Intake System File";
        }
        return "Intake System File";
    }

    function handleSubmit(values: IIntakeSystemFileForm, FormikHelpers: FormikHelpers<IIntakeSystemFileForm>) {
        if (props.type === "add") {
            const intakeSystemFileToCreate: IntakeSystemFile = {
                id: 0,
                systemId: values.systemId,
                displayFileName: values.displayFileName,
                processingFileName: values.processingFileName,
                fileTypeId: values.fileTypeId, //values.filetypeselected,
                isRequired: true,
                customerId: values.customerId, //values.customerIdselected
            };
            dispatch(createIntakeSystemFile(intakeSystemFileToCreate));
        } else {
            if (selectedFile === undefined) {
                return;
            } else {
                dispatch(
                    editIntakeSystemFile(selectedFile, {
                        ...selectedFile,
                        ...values,
                    })
                );
            }
        }
        clearFormAndHide(FormikHelpers);
    }
    return (
        <>
            <Formik
                initialValues={initalValues}
                validationSchema={intakeSystemfileSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<IIntakeSystemFileForm>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditIntakeSystemFileModal"
                            show={true}
                            title={getTitle()}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={true}
                            showDeleteButton={props.type === "edit"}
                            deleteButtonText={"Delete"}
                            onDeleteButtonClick={() => setShowConfirmDeleteModal(true)}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="intakeSystemFileForm">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"systemId"}
                                            placeholder={""}
                                            fieldName={"systemId"}
                                            label={"Select a System"}
                                            options={systemOptions}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 3, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"displayFileName"}
                                            placeholder={""}
                                            fieldName={"displayFileName"}
                                            label={"Display File Name"}
                                        />
                                    </Col>
                                    <Col xs={12} md={{ span: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"processingFileName"}
                                            placeholder={""}
                                            fieldName={"processingFileName"}
                                            label={"Processing Name"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"fileTypeId"}
                                            placeholder={""}
                                            fieldName={"fileTypeId"}
                                            label={"Select a File Type"}
                                            options={fileTypeOptions}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col xs={12} md={{ span: 3, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"partnerId"}
                                            placeholder={"Partner"}
                                            fieldName={"partnerId"}
                                            label={"Select a Partner"}
                                            options={partnerTenantOptions}
                                            multiple={false}
                                            onChange={(value: any) => {
                                                selectPartnerId(value?.value);
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} md={{ span: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"customerTenant"}
                                            placeholder={"Customer"}
                                            fieldName={"customerId"}
                                            label={"Select a Customer"}
                                            options={customerTenantOptions}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
            <ConfirmationModal
                id="confirmDeleteIntakeSystemFileModal"
                show={showConfirmDeleteModal}
                title="Deactivate IntakeSystemFile?"
                message={"Do you really want to delete this file requirement?"}
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={() => handleDeleteIntakeSystemFile({} as IntakeSystemFile)}
                theme={"danger"}
            />
        </>
    );
}
