import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerImportsByTenantId } from "../actions/customerImport/CustomerImportActionCreators";
import { getCustomerImportFiles } from "../actions/customerImportFile/CustomerImportFileActionCreators";
import { selectCustomerImportFilesWithReportsByImportId } from "../selectors/CustomerImportFileSelectors";
import { AppState } from "../state/AppState";

export const useCustomerImportsList = (tenantId: number, customerImportId?: any, systemId?: number) => {
    const dispatch = useDispatch();
    const customerImports = useSelector((state: AppState) => state.customerImportState.customerImports);
    const customerImportFiles = useSelector((state: AppState) =>
        selectCustomerImportFilesWithReportsByImportId(state, customerImportId)
    );
    useEffect(() => {
        dispatch(getCustomerImportsByTenantId(tenantId, systemId));
        dispatch(getCustomerImportFiles());
    }, [tenantId, systemId]);

    return { customerImports, customerImportFiles };
};
