import { CustomerReport, CustomerReportFlag } from "../../models/CustomerReport";
import { CustomerReportReviewCreateBulk, ICustomerReportReview } from "../../models/CustomerReportReview";

export const FETCH_CUSTOMER_REPORTS = "FETCH_CUSTOMER_REPORTS";
export const CUSTOMER_REPORTS_FETCHED = "CUSTOMER_REPORTS_FETCHED";
export const EDIT_CUSTOMER_REPORT_SUCCESS = "EDIT_CUSTOMER_REPORT_SUCCESS";
export const CUSTOMER_REPORTS_LOADING = "CUSTOMER_REPORTS_LOADING";

export const FETCH_CUSTOMER_REPORTS_REVIEW = "FETCH_CUSTOMER_REPORT_REVIEWS";
export const CUSTOMER_REPORTS_REVIEW_FETCHED = "CUSTOMER_REPORT_REVIEWS_FETCHED";
export const ALL_CUSTOMER_REPORTS_REVIEW_FETCHED = "ALL_CUSTOMER_REPORT_REVIEWS_FETCHED";
export const ADD_CUSTOMER_REPORT_REVIEW_SUCCESS = "ADD_CUSTOMER_REPORT_REVIEW_SUCCESS";
export const BULK_CUSTOMER_REPORT_REVIEW_UPSERT = "BULK_CUSTOMER_REPORT_REVIEW_UPSERT";
export const CUSTOMER_REPORT_REVIEWS_LOADING = "CUSTOMER_REPORT_REVIEWS_LOADING";

export const PDF_REQUEST_SUBMITTED = "PDF_REQUEST_SUBMITTED";
export const CUSTOMER_REPORT_FLAG = "CUSTOMER_REPORT_FLAG";

export interface CustomerReportsLoadingAction {
    type: typeof CUSTOMER_REPORTS_LOADING;
}

export interface FetchCustomerReportsAction {
    type: typeof FETCH_CUSTOMER_REPORTS;
}

export interface CustomerReportsFetchedAction {
    type: typeof CUSTOMER_REPORTS_FETCHED;
    customerReports: CustomerReport[];
}

export interface EditCustomerReportAction {
    type: typeof EDIT_CUSTOMER_REPORT_SUCCESS;
    customerReport: CustomerReport;
    newCustomerReport: CustomerReport;
}

export interface CustomerReportReviewsLoadingAction {
    type: typeof CUSTOMER_REPORT_REVIEWS_LOADING;
}

export interface FetchCustomerReportReviewsAction {
    type: typeof FETCH_CUSTOMER_REPORTS_REVIEW;
}

export interface CustomerReportReviewsFetchedAction {
    type: typeof CUSTOMER_REPORTS_REVIEW_FETCHED;
    customerReportReviews: ICustomerReportReview[];
}

export interface AllCustomerReportReviewsFetchedAction {
    type: typeof ALL_CUSTOMER_REPORTS_REVIEW_FETCHED;
    customerReportReviews: ICustomerReportReview[];
}

export interface AddCustomerReportReviewAction {
    type: typeof ADD_CUSTOMER_REPORT_REVIEW_SUCCESS;
    customerReportReview: ICustomerReportReview;
}

export interface PdfRequestSubmittedAction {
    type: typeof PDF_REQUEST_SUBMITTED;
}

export interface BulkCustomerReportReviewUpsertAction {
    type: typeof BULK_CUSTOMER_REPORT_REVIEW_UPSERT;
    bulkRequest: CustomerReportReviewCreateBulk;
}

export interface CustomerReportFlagAction {
    type: typeof CUSTOMER_REPORT_FLAG;
}

export type CustomerReportActions =
    | CustomerReportsLoadingAction
    | CustomerReportsFetchedAction
    | CustomerReportReviewsLoadingAction
    | CustomerReportReviewsFetchedAction
    | AllCustomerReportReviewsFetchedAction
    | AddCustomerReportReviewAction
    | EditCustomerReportAction
    | PdfRequestSubmittedAction
    | BulkCustomerReportReviewUpsertAction
    | FetchCustomerReportsAction
    | FetchCustomerReportReviewsAction
    | CustomerReportFlagAction;
