import { EDIT_EMPLOYEE_SYSTEM, EmployeeSystemActions } from "../../actions/employeeSystem/EmployeeSystemAction";
import { EmployeeSystemState } from "../../state/EmployeeSystemState";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: EmployeeSystemState = {
    employeeSystems: [],
};

export function employeeSystemReducer(
    state: EmployeeSystemState = initialState,
    action: EmployeeSystemActions
): EmployeeSystemState {
    switch (action.type) {
        case EDIT_EMPLOYEE_SYSTEM:
            return {
                ...state,
                employeeSystems: replaceInCollection(
                    state.employeeSystems,
                    action.originalEmployeeSystem!,
                    action.editedEmployeeSystem
                ),
            };
        default:
            return state;
    }
}
