import { WorkflowJob } from "../../models/WorkflowJob";

export const WORKFLOW_JOBS_FETCHED = "WORKFLOW_JOBS_FETCHED";
export const WORKFLOW_JOB_FETCHED = "WORKFLOW_JOB_FETCHED";
export const WORKFLOW_JOB_CREATED = "WORKFLOW_JOB_CREATED";
export const WORKFLOW_JOB_EDITED = "WORKFLOW_JOB_EDITED";
export const WORKFLOW_JOB_DELETED = "WORKFLOW_JOB_DELETED";
export const WORKFLOW_JOB_SELECTED = "WORKFLOW_JOB_SELECTED";

export interface WorkflowJobsFetched {
    type: typeof WORKFLOW_JOBS_FETCHED;
    workflowJobs: WorkflowJob[];
}

export interface WorkflowJobFetched {
    type: typeof WORKFLOW_JOB_FETCHED;
    workflowJob: WorkflowJob;
}

export interface WorkflowJobCreated {
    type: typeof WORKFLOW_JOB_CREATED;
    workflowJob: WorkflowJob;
}

export interface WorkflowJobEdited {
    type: typeof WORKFLOW_JOB_EDITED;
    workflowJob: WorkflowJob;
    newWorkflowJob: WorkflowJob;
}

export interface WorkflowJobDeleted {
    type: typeof WORKFLOW_JOB_DELETED;
    workflowJob: WorkflowJob;
}

export interface WorkflowJobSelected {
    type: typeof WORKFLOW_JOB_SELECTED;
    workflowJob: WorkflowJob;
}

export type WorkflowJobActions =
    | WorkflowJobsFetched
    | WorkflowJobFetched
    | WorkflowJobCreated
    | WorkflowJobEdited
    | WorkflowJobDeleted
    | WorkflowJobSelected;
