import {
    CLEAR_SELECTED_INTAKESYSTEMFILE,
    ClearSelectedIntakeSystemFileAction,
    INACTIVE_INTAKESYSTEMFILES_FETCHED,
    InactiveIntakeSystemFilesFetchedAction,
    SELECT_INTAKESYSTEMFILE,
    SelectIntakeSystemFileAction,
    INTAKESYSTEMFILE_CREATED,
    INTAKESYSTEMFILE_DELETED,
    INTAKESYSTEMFILE_EDITED,
    INTAKESYSTEMFILE_RESTORED,
    IntakeSystemFileCreatedAction,
    IntakeSystemFileDeletedAction,
    IntakeSystemFileEditedAction,
    IntakeSystemFileRestoredAction,
    INTAKESYSTEMFILES_FETCHED,
    IntakeSystemFilesFetchedAction,
} from "./IntakeSystemFilesActions";
import { IntakeSystemFile } from "../../models/IntakeSystemFile";
import { IIntakeSystemFileService } from "../../services/intakeSystemFile/IIntakeSystemFileService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { LoggedInAction, LOGGED_IN } from "../AuthenticateActions";
import jwt_decode from "jwt-decode";

export function clearSelectedIntakeSystemFile(): ClearSelectedIntakeSystemFileAction {
    return {
        type: CLEAR_SELECTED_INTAKESYSTEMFILE,
    };
}

export function createIntakeSystemFile(
    intakeSystemFile: IntakeSystemFile
): KeysysThunkAction<void, IntakeSystemFileCreatedAction> {
    return async (dispatch, getState, container) => {
        const intakeSystemFileService = container.get<IIntakeSystemFileService>(TYPES.IntakeSystemFileService);
        const createdIntakeSystemFile = await intakeSystemFileService.addIntakeSystemFile(intakeSystemFile);
        if (createdIntakeSystemFile) {
            dispatch<IntakeSystemFileCreatedAction>({
                type: INTAKESYSTEMFILE_CREATED,
                intakeSystemFile: createdIntakeSystemFile,
            });
        }
    };
}

export function deleteIntakeSystemFile(
    intakeSystemFile: IntakeSystemFile
): KeysysThunkAction<void, IntakeSystemFileDeletedAction> {
    return async (dispatch, getState, container) => {
        const intakeSystemFileService = container.get<IIntakeSystemFileService>(TYPES.IntakeSystemFileService);
        const deletedIntakeSystemFile = await intakeSystemFileService.deleteIntakeSystemFile(intakeSystemFile);
        if (deletedIntakeSystemFile) {
            dispatch<IntakeSystemFileDeletedAction>({
                type: INTAKESYSTEMFILE_DELETED,
                intakeSystemFile: deletedIntakeSystemFile,
            });
        }
    };
}

export function editIntakeSystemFile(
    intakeSystemFile: IntakeSystemFile,
    newIntakeSystemFile: IntakeSystemFile
): KeysysThunkAction<void, IntakeSystemFileEditedAction> {
    return async (dispatch, getState, container) => {
        const intakeSystemFileService = container.get<IIntakeSystemFileService>(TYPES.IntakeSystemFileService);
        const patched = await intakeSystemFileService.editIntakeSystemFile(intakeSystemFile, newIntakeSystemFile);
        if (patched) {
            dispatch<IntakeSystemFileEditedAction>({
                type: INTAKESYSTEMFILE_EDITED,
                intakeSystemFile,
                newIntakeSystemFile: patched,
            });
        }
    };
}

export function getInactiveIntakeSystemFiles(): KeysysThunkAction<void, InactiveIntakeSystemFilesFetchedAction> {
    return async (dispatch, getState, container) => {
        const intakeSystemFileService = container.get<IIntakeSystemFileService>(TYPES.IntakeSystemFileService);
        const intakeSystemFiles = await intakeSystemFileService.getInactiveIntakeSystemFiles();
        if (intakeSystemFiles) {
            dispatch<InactiveIntakeSystemFilesFetchedAction>({
                type: INACTIVE_INTAKESYSTEMFILES_FETCHED,
                inactiveIntakeSystemFiles: intakeSystemFiles,
            });
        }
    };
}

export function getIntakeSystemFiles(): KeysysThunkAction<void, IntakeSystemFilesFetchedAction> {
    return async (dispatch, getState, container) => {
        const intakeSystemFileService = container.get<IIntakeSystemFileService>(TYPES.IntakeSystemFileService);
        const intakeSystemFiles = await intakeSystemFileService.getIntakeSystemFiles();
        if (intakeSystemFiles) {
            dispatch<IntakeSystemFilesFetchedAction>({
                type: INTAKESYSTEMFILES_FETCHED,
                intakeSystemFiles: intakeSystemFiles,
            });
        }
    };
}

export function restoreIntakeSystemFile(
    intakeSystemFile: IntakeSystemFile
): KeysysThunkAction<void, IntakeSystemFileRestoredAction> {
    return async (dispatch, getState, container) => {
        const intakeSystemFileService = container.get<IIntakeSystemFileService>(TYPES.IntakeSystemFileService);
        const restoredIntakeSystemFile = await intakeSystemFileService.restoreIntakeSystemFile(intakeSystemFile);
        if (restoredIntakeSystemFile) {
            dispatch<IntakeSystemFileRestoredAction>({
                type: INTAKESYSTEMFILE_RESTORED,
                intakeSystemFile: restoredIntakeSystemFile,
            });
        }
    };
}

export function selectIntakeSystemFile(intakeSystemFile: IntakeSystemFile): SelectIntakeSystemFileAction {
    return {
        type: SELECT_INTAKESYSTEMFILE,
        intakeSystemFile: intakeSystemFile,
    };
}
