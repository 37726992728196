import { SubReportCategoryState } from "../../state/SubReportCategoryState";
import {
    SubReportCategoryActions,
    SUB_REPORT_CATEGORIES_FETCHED,
    CREATE_SUB_REPORT_CATEGORY,
    DELETE_SUB_REPORT_CATEGORY,
    EDIT_SUB_REPORT_CATEGORY,
    FETCHING_SUB_REPORT_CATEGORIES,
} from "../../actions/subReportCategories/SubReportCategoryActions";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: SubReportCategoryState = {
    subReportCategories: [],
    loading: false,
    loaded: false,
};

export function subReportCategoryReducer(
    state: SubReportCategoryState = initialState,
    action: SubReportCategoryActions
): SubReportCategoryState {
    switch (action.type) {
        case FETCHING_SUB_REPORT_CATEGORIES:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case SUB_REPORT_CATEGORIES_FETCHED:
            return {
                ...state,
                subReportCategories: action.subReportCategories,
                loading: false,
                loaded: true,
            };
        case CREATE_SUB_REPORT_CATEGORY:
            return {
                ...state,
                subReportCategories: [...state.subReportCategories, action.subReportCategory],
            };
        case DELETE_SUB_REPORT_CATEGORY:
            const originalActiveSubReportCategory = state.subReportCategories.find(
                (r) => r.id === action.subReportCategory.id
            );

            return {
                ...state,
                subReportCategories: replaceInCollection(
                    state.subReportCategories,
                    originalActiveSubReportCategory!,
                    action.subReportCategory
                ),
            };
        case EDIT_SUB_REPORT_CATEGORY:
            return {
                ...state,
                subReportCategories: replaceInCollection(
                    state.subReportCategories,
                    action.originalSubReportCategory,
                    action.newSubReportCategory,
                    (a, b) => a.name.localeCompare(b.name)
                ),
            };
        default:
            return state;
    }
}
