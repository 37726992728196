import { inject, injectable } from "inversify";
import { Store } from "redux";
import { TYPES } from "../../dependencyInjection/Types";
import { FinosecSystemStatistics, SystemStatisticsData } from "../../models/FinosecSystemStatistics";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IFinosecSystemStatisticService } from "./IFinosecSystemStatisticService";

@injectable()
export class FinosecSystemStatisticService implements IFinosecSystemStatisticService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getFinosecSystemStatisticsDataByTenantId(tenantId: Number): Promise<void | SystemStatisticsData> {
        return this.httpClient
            .get<SystemStatisticsData>(`/finosec-system-statistics/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving system statistics. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getFinosecSystemStatisticsDataByPartnerId(partnerId: Number): Promise<void | FinosecSystemStatistics[]> {
        return this.httpClient
            .get<FinosecSystemStatistics[]>(`/finosec-system-statistics/partner/${partnerId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving system statistics. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
