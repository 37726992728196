import React, { useCallback, useEffect, useState } from "react";
import { GridOptions, GridSizeChangedEvent, GridApi } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { IKeysysGrid } from "./IKeysysGrid";

export default function KeysysGrid(props: IKeysysGrid) {
    const propsGridOptions = props.gridOptions || {};
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    function headerHeightGetter() {
        var columnHeaderTexts = [...document.querySelectorAll(".ag-header-cell-text")];
        var clientHeights = columnHeaderTexts.map((headerText) => headerText.clientHeight);
        var tallestHeaderTextHeight = Math.max(...clientHeights);

        return tallestHeaderTextHeight;
    }

    function headerHeightSetter() {
        var padding = 20;
        var height = headerHeightGetter() + padding;
        gridOptions?.api?.setHeaderHeight(height);
    }
    let gridOptions: GridOptions = {
        onGridReady: (params) => {
            setGridApi(params.api);
        },
        groupDefaultExpanded: props.expandAll ? 1 : 0,
        onFirstDataRendered: headerHeightSetter,
        onColumnResized: headerHeightSetter,
        autoGroupColumnDef: {
            showRowGroup: true,
            minWidth: 250,
            maxWidth: 250,
            headerName: props.initialHeader || "",
            // sortable: true
            // comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
            //     return (valueA === valueB) ? 0 : (valueA > valueB) ? 1 : -1;
            // },
        },
        // showOpenedGroup: true,
        ...propsGridOptions,
        suppressAggFuncInHeader: true,
    };

    if (props.externalFilter) {
        gridOptions = {
            doesExternalFilterPass: props.externalFilter,
            isExternalFilterPresent: () => true,
        };
    }

    const onSearch = useCallback(
        (searchString: string | undefined) => {
            gridApi?.setQuickFilter(searchString);
        },
        [gridApi]
    );

    useEffect(() => {
        if (gridApi && props.pageSize) {
            gridApi.paginationSetPageSize(props.pageSize);
        }
    }, [props.pageSize, gridApi]);

    useEffect(() => {
        if (gridApi) {
            onSearch(props.searchText);
        }
    }, [props.searchText, gridApi, onSearch]);

    return (
        <div>
            <AgGridReact
                colResizeDefault={"shift"}
                paginateChildRows
                onGridSizeChanged={(event: GridSizeChangedEvent) => {
                    event.api.sizeColumnsToFit();
                }}
                {...gridOptions}
                immutableData
                pagination={props.pagination}
                paginationPageSize={props.pageSize}
                columnDefs={props.columnDefs}
                getRowNodeId={(data) => {
                    if (props.getRowId) {
                        return props.getRowId(data);
                    }
                    return data?.id;
                }}
                defaultColDef={{
                    sortable: true,
                    resizable: true,
                    wrapText: true,
                    autoHeight: true,
                    suppressMenu: true,
                    filter: false,
                    headerComponentParams: {
                        template:
                            '<div class="ag-cell-label-container" role="presentation">' +
                            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                            "  </div>" +
                            "</div>",
                    },
                    cellStyle: {
                        lineHeight: "2",
                        alignItems: "center",
                        display: "flex",
                        paddingRight: "16",
                        paddingLeft: "16",
                    },
                }}
                rowData={props.getRows ? props.getRows() : props.data || []}
                onRowGroupOpened={props.onRowGroupOpened || undefined}
                isGroupOpenByDefault={(e) => {
                    if (props.expandAll) {
                        return true;
                    }
                    if (props.expandedRows) {
                        return props.expandedRows.includes(e.key);
                    } else {
                        return false;
                    }
                }}
            />
        </div>
    );
}
