import { TYPES } from "../../dependencyInjection/Types";
import { ReportAttribute } from "../../models/ReportAttribute";
import { IReportAttributeService } from "../../services/reportAttribute/IReportAttributeService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { CreateReportAttributeAction, 
        ReportAttributesFetchedAction, 
        REPORT_ATTRIBUTES_FETCHED,
        CREATE_REPORT_ATTRIBUTE, 
        EditReportAttributeAction,
        DeleteReportAttributeAction,
        DELETE_REPORT_ATTRIBUTE} from "./ReportAttributeAction";

export function getReportAttributes(reportId: number):KeysysThunkAction<void,ReportAttributesFetchedAction> {
    return async (dispatch, getState, container) =>{
        const reportAttributeService = container.get<IReportAttributeService>(TYPES.ReportAttributeService);
        const reportAttributes = await reportAttributeService.getReportAttributes(reportId);
    
        if(reportAttributes)
        {
            dispatch<ReportAttributesFetchedAction>({
                type:REPORT_ATTRIBUTES_FETCHED,
                reportAttributes:reportAttributes
            });
        }
    }
}

export function createReportAttribute(reportId:number, reportAttribute:ReportAttribute): KeysysThunkAction<void,CreateReportAttributeAction> {
        return async (dispatch, getState, container) => {
            const reportAttributeService = container.get<IReportAttributeService>(TYPES.ReportAttributeService);
            const createdReportAttribute = await reportAttributeService.createReportAttribute(reportId, reportAttribute);
            
            if(createdReportAttribute)
            {
                dispatch<CreateReportAttributeAction>({
                    type: CREATE_REPORT_ATTRIBUTE,
                    reportAttribute:createdReportAttribute
                });
                
            }
            return !!createdReportAttribute;
           
        }

    }

    export function editReportAttribute(reportId:number, originalReportAttribute: ReportAttribute, newReportAttribute: ReportAttribute): KeysysThunkAction<void, EditReportAttributeAction> {
        return async (dispatch, getState, container) => {
            const attributeService = container.get<IReportAttributeService>(TYPES.ReportAttributeService);
            const patchedAttribute = await attributeService.editReportAttribute(reportId,originalReportAttribute, newReportAttribute);
            if (patchedAttribute) {
                dispatch<EditReportAttributeAction>({
                    type: "EDIT_REPORT_ATTRIBUTE",
                    originalReportAttribute: originalReportAttribute,
                    newReportAttribute: patchedAttribute,
                });
            }
        };
    }

    export function deleteReportAttribute(reportId:number, reportAttribute: ReportAttribute): KeysysThunkAction<void, DeleteReportAttributeAction> {
        return async (dispatch, getState, container) => {
            const attributeService = container.get<IReportAttributeService>(TYPES.ReportAttributeService);
            const deletedAttribute = await attributeService.deleteReportAttribute(reportId,reportAttribute);
            if (deletedAttribute) {
                dispatch<DeleteReportAttributeAction>({
                    type: DELETE_REPORT_ATTRIBUTE,
                    reportAttribute: deletedAttribute,
                });
            }
        };
    }
