import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { Field, Formik, Form, FormikProps } from "formik";

interface ICustomeReportMarkReviewModalProps {
    subReportName: string;
    onOk: (note: string) => void;
    triggerTableUpdate: () => void;
}

export default function CustomerReportReportMarkReviewModal(props: ICustomeReportMarkReviewModalProps) {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const { subReportName, onOk, triggerTableUpdate } = props;

    const handleSubmit = (values: any, resetForm: any) => {
        onOk(`Selected Mark all as Reviewed (${subReportName})${values.note ? `: ${values.note}` : ""}`);
        resetForm();
        setModalShow(false);
    };

    useEffect(() => {
        if (modalShow) {
            triggerTableUpdate();
        }
    }, [modalShow]);

    return (
        <>
            <Button onClick={() => setModalShow(true)} className="finosec-button-info">
                Mark All Reviewed
            </Button>
            <Formik
                initialValues={{ note: "" }}
                onSubmit={(values, { resetForm }) => {
                    handleSubmit(values, resetForm);
                }}
            >
                {(formProps: FormikProps<any>) => (
                    <VerticallyCenteredModal
                        id="markAllReview"
                        show={modalShow}
                        size="lg"
                        title="Are you sure you want to mark all as complete?"
                        closeButtonText={"NO - CANCEL"}
                        okButtonText={"Mark All Reviewed"}
                        dynamicHeight={true}
                        showSaveButton={true}
                        // deleteButtonText={"Deactivate"}
                        onCloseButtonClick={() => setModalShow(false)}
                        onOkButtonClick={formProps.handleSubmit}
                        type={"success"}
                    >
                        <Form>
                            <label htmlFor="note">Review Completed ({subReportName})</label>
                            <Field
                                placeholder="Explanation:"
                                style={{ width: "100%" }}
                                name="note"
                                as="textarea"
                                rows={7}
                            />
                        </Form>
                    </VerticallyCenteredModal>
                )}
            </Formik>
        </>
    );
}
