import { Options } from "request";
import { IHttpClient } from "./IHttpClient";
import type { Store } from "redux";
import { AppState } from "../../state/AppState";
import type { RequestPromiseAPI } from "request-promise-native";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { Operation } from "rfc6902";
import { API_BASE_URL } from "../../config/config";
import { Token } from "../../models/Token";
import { SsoService } from "../../models/SsoService";
import axios, { AxiosResponse } from "axios";

@injectable()
export class HttpClient implements IHttpClient {
    private token: Token = {
        access_token: "",
        refresh_token: "",
    };
    private isAuthenticated: boolean = false;

    constructor(
        @inject(TYPES.Store) private readonly store: Store,
        @inject(TYPES.RequestPromiseApi) private request: RequestPromiseAPI
    ) {
        this.store.subscribe(() => {
            const state = this.store.getState() as AppState;
            this.token.access_token = state.authenticationState.accessToken;
            this.token.refresh_token = state.authenticationState.refreshToken;
            this.isAuthenticated = state.authenticationState.isAuthenticated;
        });
    }

    public get<T>(relativePath: string): Promise<T> {
        const options = this.getDefaultOptions(relativePath);
        return this.request
            .get(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.get(relativePath)));
    }

    post<T>(relativePath: string, body: T, contentType?: string): Promise<T> {
        const options = this.getDefaultOptions(relativePath, contentType);
        options.body = body;
        return this.request
            .post(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.post(relativePath, body)));
    }

    postUrlEncoded<T>(relativePath: string, body: any): Promise<T> {
        const options = this.getDefaultOptions(relativePath, "application/x-www-form-urlencoded");
        let formBody: any = [];
        for (var property in body) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(body[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        options.body = formBody;
        return this.request
            .post(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.post(relativePath, body)));
    }

    postUrlEncodedNoAuth<T>(relativePath: string, body: any): Promise<T> {
        let formBody: any = [];
        for (var property in body) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(body[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const headers: any = { "Content-Type": "application/x-www-form-urlencoded" };

        const options = {
            baseUrl: API_BASE_URL,
            headers,
            url: relativePath,
            body: formBody,
        };
        return this.request
            .post(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.post(relativePath, body)));
    }

    postUniqueBody<TBody, TReturn>(relativePath: string, body: TBody): Promise<TReturn> {
        const options = this.getDefaultOptions(relativePath);
        options.body = body;
        return this.request
            .post(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.postUniqueBody(relativePath, body)));
    }

    postMultipartBody<TBody, TReturn>(relativePath: string, body: TBody): Promise<TReturn> {
        const options = this.getMultipartOptions(relativePath);
        options.formData = body as any;
        options.body = body;
        return this.request
            .post(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.postMultipartBody(relativePath, body)));
    }

    postFormData(formData: FormData, path: string): Promise<AxiosResponse<any>> {
        return axios
            .post(`${API_BASE_URL}${path}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${this.token.access_token}`,
                },
            })
            .catch((e) => this.handleErrors(e, () => this.postFormData(formData, path)));
    }

    put<T>(relativePath: string, body: T): Promise<T> {
        const options = this.getDefaultOptions(relativePath);
        options.body = body;
        return this.request
            .put(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.put(relativePath, body)));
    }

    putUniqueBody<TBody, TReturn>(relativePath: string, body: TBody): Promise<TReturn> {
        const options = this.getDefaultOptions(relativePath);
        options.body = body;
        return this.request
            .put(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.putUniqueBody(relativePath, body)));
    }

    patch<T>(relativePath: string, body: Operation[]): Promise<T> {
        const options = this.getDefaultOptions(relativePath);
        options.body = body;
        return this.request
            .patch(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.patch(relativePath, body)));
    }

    delete(relativePath: string): Promise<boolean> {
        const options = this.getDefaultOptions(relativePath);
        return this.request
            .delete(options)
            .then((_) => true)
            .catch((e) => this.handleErrors(e, () => this.delete(relativePath)));
    }

    deleteWithRes<TReturn>(relativePath: string): Promise<TReturn> {
        const options = this.getDefaultOptions(relativePath);
        return this.request
            .delete(options)
            .promise()
            .catch((e) => this.handleErrors(e, () => this.delete(relativePath)));
    }

    private getDefaultOptions = (relativePath: string, contentType?: string): Options => {
        const headers: any = { Authorization: `Bearer ${this.token.access_token}` };
        if (contentType) {
            headers["Content-Type"] = contentType;
        }
        return {
            baseUrl: API_BASE_URL,
            headers,
            url: relativePath,
            json: true,
        };
    };

    private getMultipartOptions = (relativePath: string): Options => {
        return {
            baseUrl: API_BASE_URL,
            headers: {
                Authorization: `Bearer ${this.token.access_token}`,
            },
            url: relativePath,
            json: true,
        };
    };

    private handleErrors = async (errorResponse: any, retry: () => Promise<any>) => {
        if (
            errorResponse.statusCode === 401 &&
            this.isAuthenticated &&
            !errorResponse.message.includes("current user does not have access")
        ) {
            await new SsoService().signIn();
        }
        throw errorResponse;
    };
}
