import {
    CustomerImportSubReportAssignmentActions,
    CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED,
} from "../../actions/customerImportSubReportAssignment/CustomerImportSubReportAssignmentAction";
import { CustomerImportSubReportAssignmentState } from "../../state/CustomerImportSubReportAssignmentState";

const initialState: CustomerImportSubReportAssignmentState = {
    customerImportSubReportAssignments: [],
};

export function customerImportSubReportAssignmentReducer(
    state: CustomerImportSubReportAssignmentState = initialState,
    action: CustomerImportSubReportAssignmentActions
): CustomerImportSubReportAssignmentState {
    switch (action.type) {
        case CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED:
            return {
                ...state,
                customerImportSubReportAssignments: action.customerImportSubReportAssignments,
            };
        default:
            return state;
    }
}
