import { servicesContainer } from "../services/IOC/ServicesContainer";
import { INoteService } from "../services/note/INoteService";
import { TYPES } from "../dependencyInjection/Types";
import { useEffect, useState, useCallback } from "react";
import { INote } from "../models/Note";

export const usePdfNotes = (customerImportId: number | undefined) => {
    const noteService = servicesContainer.get<INoteService>(TYPES.NoteService);

    const [notes, setNotes] = useState<INote[]>([]);
    const [notesLoading, setNotesLoading] = useState<boolean>(false);

    const loadNotes = useCallback(async () => {
        if (!customerImportId) return;
        setNotesLoading(true);
        try {
            const response = await noteService.getNotesByCustomerImportId(customerImportId);
            if (response) {
                setNotes(response);
            }
        } catch (err) {
            console.log("loadNotes error", JSON.stringify(err));
        }
        setNotesLoading(false);
    }, [customerImportId, noteService]);

    useEffect(() => {
        loadNotes();
    }, [loadNotes]);

    return {
        notes,
        notesLoading,
    };
};
