import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFinosecSystemStatisticsByTenantId } from "../actions/finosecSystemStatistic/FinosecSystemStatisticActionCreator";
import { MappedCustomerReport } from "../models/CustomerReport";
import { CustomerReportDisplay } from "../models/CustomerReportDisplay";
import { FinosecSystemStatistics } from "../models/FinosecSystemStatistics";
import { SubReport } from "../models/SubReport";
import { selectMappedCustomerReports } from "../selectors/CustomerReportSelectors";
import { selectCustomerSystems } from "../selectors/FinosecSystemSelectors";
import { AppState } from "../state/AppState";
import _ from "lodash";

export const useFinosecSystemStatisticsByTenantId = () => {
    const dispatch = useDispatch();

    const { tenant_id } = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const subreports = useSelector((state: AppState) => state.subReportState.subReports);
    const customerReportDisplays = useSelector(
        (state: AppState) => state.customerReportDisplayState.customerReportDisplays
    );
    const customerSystems = useSelector((state: AppState) => selectCustomerSystems(state, tenant_id));
    const subReportCategories = useSelector((state: AppState) => state.subReportCategoryState.subReportCategories);
    const {
        systemStatisticsData: { customerReports, customerImports },
    } = useSelector((state: AppState) => state.finosecSystemStatisticsState);

    useEffect(() => {
        dispatch(getFinosecSystemStatisticsByTenantId(tenant_id));
    }, [dispatch, tenant_id]);

    const getSystemName = useCallback(
        (systemId: number): string => {
            const system = customerSystems.find((s) => s.id === systemId);
            if (system) {
                return system.name;
            }
            return "";
        },
        [customerSystems]
    );

    const isSubReportHidden = (
        isSubReport: boolean,
        subReport: SubReport | undefined,
        display: CustomerReportDisplay | undefined
    ): boolean => {
        if (!isSubReport || !subReport || !display) return false;
        if (subReport.isForced) return false;
        return display.isHidden;
    };

    const mappedCustomerReports = useMemo((): MappedCustomerReport[] => {
        let result = customerReports.map((cr) => {
            const subReport = cr.isSubReport ? subreports.find((sr) => sr.id === cr.subReportId) : undefined;
            const subReportCategory = subReport
                ? subReportCategories.find((sc) => sc.id === subReport.categoryId)
                : null;
            const maxOrdinal = _.max(subReportCategories.map((sc) => sc.ordinal)) || 0;
            const display = cr.isSubReport
                ? customerReportDisplays.find(
                      (crd) => crd.subReportId === cr.subReportId && crd.customerImportId === cr.customerImportId
                  )
                : undefined;
            const isHidden = isSubReportHidden(cr.isSubReport, subReport, display);

            return {
                ...cr,
                categoryName: subReportCategory ? subReportCategory.name : subReport ? "Access Report" : "Master List",
                categoryOrdinal: subReportCategory ? subReportCategory.ordinal : subReport ? -1 : maxOrdinal + 1,
                isHidden: isHidden,
                isForced: cr.isSubReport && subReport?.isForced,
                tenantIds: cr.isSubReport ? subReport?.tenantIds : null,
            } as MappedCustomerReport;
        });

        return result.filter(
            (cr) => cr.reportTypeId !== 2 || (cr.reportTypeId === 2 && cr.tenantIds?.includes(tenant_id))
        );
    }, [customerReports, subreports, subReportCategories, customerReportDisplays, tenant_id]);

    const getSystemsSummary = useCallback(() => {
        let totalSubReports = 0;
        let totalReviewedReports = 0;

        for (let customerReport of mappedCustomerReports) {
            if (customerReport.isSubReport && !customerReport.isReadOnlySubReport && !customerReport.isHidden) {
                totalSubReports++;
                if (customerReport.isReviewComplete) {
                    totalReviewedReports++;
                }
            }
        }

        const systemsSummary: FinosecSystemStatistics = {
            finosecSystemId: mappedCustomerReports.length ? mappedCustomerReports[0].finosecSystemId : 0,
            tenantId: mappedCustomerReports.length ? mappedCustomerReports[0].tenantId : tenant_id,
            importId: 0,
            totalUserChanges: 0,
            totalSubReports: totalSubReports,
            totalReviewedReports: totalReviewedReports,
            totalNotReviewedReports: totalSubReports - totalReviewedReports,
            statisticsDate: new Date().toLocaleDateString(),
        };

        return systemsSummary;
    }, [mappedCustomerReports, tenant_id]);

    const getSystemStatistics = useCallback((): FinosecSystemStatistics[] => {
        let result: FinosecSystemStatistics[] = [];
        for (let customerImport of customerImports) {
            let totalSubReports = 0;
            let totalReviewedReports = 0;
            let reviewDate: string = "";

            const customerReportsForImport = mappedCustomerReports.filter(
                (cr) => cr.customerImportId === customerImport.id
            );
            for (let customerReport of customerReportsForImport) {
                if (customerReport.isSubReport && !customerReport.isReadOnlySubReport && !customerReport.isHidden) {
                    totalSubReports++;
                    if (customerReport.isReviewComplete) {
                        totalReviewedReports++;
                        if (customerReport.lastModifiedOn) {
                            const lastModifiedTime = new Date(customerReport.lastModifiedOn).getTime();
                            if (!reviewDate || new Date(reviewDate).getTime() < lastModifiedTime) {
                                reviewDate = customerReport.lastModifiedOn;
                            }
                        }
                    }
                }
            }
            const systemStatistics: FinosecSystemStatistics = {
                finosecSystemId: customerImport.finosecSystemId,
                tenantId: customerImport.tenantId,
                finosecSystemName: getSystemName(customerImport.finosecSystemId),
                importId: customerImport.id,
                totalUserChanges: 0,
                totalSubReports: totalSubReports,
                totalReviewedReports: totalReviewedReports,
                totalNotReviewedReports: totalSubReports - totalReviewedReports,
                reviewCompletedDate:
                    totalReviewedReports > 0 && totalSubReports - totalReviewedReports === 0 ? reviewDate : undefined,
                statisticsDate: new Date().toLocaleDateString(),
            };
            result.push(systemStatistics);
        }
        return result;
    }, [customerImports, mappedCustomerReports, getSystemName]);

    return useMemo(
        () => ({
            systemStatistics: getSystemStatistics(),
            systemsSummary: getSystemsSummary(),
        }),
        [getSystemStatistics, getSystemsSummary]
    );
};

export const useFinosecSystemStatisticsByImportId = (customerImportId: number) => {
    const { tenant_id } = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const customerReports = useSelector(selectMappedCustomerReports);

    const getImportStatistics = useCallback((): FinosecSystemStatistics => {
        let totalSubReports = 0;
        let totalReviewedReports = 0;
        const customerReportsForImport = customerReports.filter((cr) => cr.customerImportId === customerImportId);
        for (let customerReport of customerReportsForImport) {
            if (customerReport.isSubReport && !customerReport.isReadOnlySubReport && !customerReport.isHidden) {
                totalSubReports++;
                if (customerReport.isReviewComplete) {
                    totalReviewedReports++;
                }
            }
        }

        const importStatistics: FinosecSystemStatistics = {
            finosecSystemId: customerReports.length ? customerReports[0].finosecSystemId : 0,
            tenantId: customerReports.length ? customerReports[0].tenantId : tenant_id,
            importId: customerImportId,
            totalUserChanges: 0,
            totalSubReports: totalSubReports,
            totalReviewedReports: totalReviewedReports,
            totalNotReviewedReports: totalSubReports - totalReviewedReports,
            statisticsDate: new Date().toLocaleDateString(),
        };

        return importStatistics;
    }, [customerImportId, customerReports, tenant_id]);

    return useMemo(
        () => ({
            importStatistics: getImportStatistics(),
        }),
        [getImportStatistics]
    );
};
