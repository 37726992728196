/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectImportData } from "../selectors/ReportSelectors";
import { selectFilteredPartnerCustomerTenants } from "../selectors/TenantSelectors";
import { getCustomerImports, deleteCustomerImport } from "../actions/customerImport/CustomerImportActionCreators";
import {
    getCustomerImportFiles,
    deleteCustomerImportFile,
} from "../actions/customerImportFile/CustomerImportFileActionCreators";
import { AppState } from "../state/AppState";
import { getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { getFileTypes } from "../actions/fileType/FileTypeActionCreators";

import { getReports } from "../actions/reports/ReportActionCreators";
import { CustomerImportFile } from "../models/CustomerImportFile";
import { CustomerImport } from "../models/CustomerImport";

export const useImportList = () => {
    const dispatch = useDispatch();
    const [selectedCustomerOption, setSelectedCustomerOption] = useState<{
        value: number | null;
        label: string;
    } | null>(null);
    const [selectedPartnerId, setSelectedPartnerId] = useState<number | null>(null);

    const [selectedTenants, selectSelectedTenants] = useState<{
        customerId: number | null;
        partnerId: number | null;
    }>({
        customerId: null,
        partnerId: null,
    });

    const systems = useSelector((state: AppState) => selectImportData(state, selectedTenants?.customerId));
    const { partnerTenants, customerTenants } = useSelector((state: AppState) =>
        selectFilteredPartnerCustomerTenants(state, selectedPartnerId)
    );

    const fileTypes = useSelector((state: AppState) => state.fileTypeState.fileTypes);

    const finosecSystems = useSelector((state: AppState) => state.finosecSystemState.finosecSystems);

    const selectPartnerId = (tenantId: number | null) => {
        setSelectedCustomerOption({ value: null, label: "Select Customer" });
        setSelectedPartnerId(tenantId);
    };
    useEffect(() => {
        if (!systems || systems.length === 0) {
            // Dispatch
            dispatch(getFinosecSystems());
        }
        dispatch(getReports());
        dispatch(getCustomerImports());
        dispatch(getCustomerImportFiles());
        dispatch(getFileTypes());
    }, []);

    const deactivateCustomerImportFile = (customerImportFile: CustomerImportFile) => {
        dispatch(deleteCustomerImportFile(customerImportFile));
    };

    const deactivateCustomerImport = (customerImport: CustomerImport) => {
        dispatch(deleteCustomerImport(customerImport));
    };

    const [selectedFileType, setSelectedFileTypeId] = useState<number>();

    const [selectedSystemId, setSelectedSystemId] = useState<number>();

    const filterImports = () => {
        selectSelectedTenants({
            partnerId: selectedPartnerId,
            customerId: selectedCustomerOption?.value || null,
        });
    };

    return {
        systems,
        partnerTenants,
        customerTenants,
        fileTypes,
        finosecSystems,
        setSelectedCustomerOption,
        setSelectedFileTypeId,
        setSelectedSystemId,
        selectPartnerId,
        deactivateCustomerImportFile,
        deactivateCustomerImport,
        filterImports,
        selectedTenants,
        selectedCustomerOption,
        selectedPartnerId,
    };
};
