export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const EDIT_CONTACT_SUCCESS = "EDIT_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const RESTORE_CONTACT_SUCCESS = "RESTORE_CONTACT_SUCCESS";

export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const RESTORE_ROLE_SUCCESS = "RESTORE_ROLE_SUCCESS";

export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const RESTORE_USER_SUCCESS = "RESTORE_USER_SUCCESS";

export const ADD_INTAKESYSTEMFILE_SUCCESS = "ADD_INTAKESYSTEMFILE_SUCCESS";
export const EDIT_INTAKESYSTEMFILE_SUCCESS = "EDIT_INTAKESYSTEMFILE_SUCCESS";
export const DELETE_INTAKESYSTEMFILE_SUCCESS = "DELETE_INTAKESYSTEMFILE_SUCCESS";
export const RESTORE_INTAKESYSTEMFILE_SUCCESS = "RESTORE_INTAKESYSTEMFILE_SUCCESS";

export const ADD_INTAKECUSTOMERFILE_SUCCESS = "ADD_INTAKECUSTOMERFILE_SUCCESS";
export const EDIT_INTAKECUSTOMERFILE_SUCCESS = "EDIT_INTAKECUSTOMERFILE_SUCCESS";
export const DELETE_INTAKECUSTOMERFILE_SUCCESS = "DELETE_INTAKECUSTOMERFILE_SUCCESS";
export const RESTORE_INTAKECUSTOMERFILE_SUCCESS = "RESTORE_INTAKECUSTOMERFILE_SUCCESS";

export const ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS";
export const EDIT_TENANT_SUCCESS = "EDIT_TENANT_SUCCESS";
export const DEACTIVATE_TENANT_SUCCESS = "DEACTIVATE_TENANT_SUCCESS";

export const ADD_REPORT_SUCCESS = "ADD_REPORT_SUCCESS";
export const EDIT_REPORT_SUCCESS = "EDIT_REPORT_SUCCESS";
export const DEACTIVATE_REPORT_SUCCESS = "DEACTIVATE_REPORT_SUCCESS";

export const ADD_DASHBOARD_SUCCESS = "ADD_DASHBOARD_SUCCESS";
export const EDIT_DASHBOARD_SUCCESS = "EDIT_DASHBOARD_SUCCESS";
export const DEACTIVATE_DASHBOARD_SUCCESS = "DEACTIVATE_DASHBOARD_SUCCESS";

export const ADD_SUB_REPORT_SUCCESS = "ADD_SUB_REPORT_SUCCESS";
export const EDIT_SUB_REPORT_SUCCESS = "EDIT_SUB_REPORT_SUCCESS";
export const DEACTIVATE_SUB_REPORT_SUCCESS = "DEACTIVATE_SUB_REPORT_SUCCESS";

export const EDIT_SUB_REPORT_CATEGORY_SUCCESS = "EDIT_SUB_REPORT_CATEGORY_SUCCESS";

export const ADD_SUB_REPORT_ATTRIBUTE_SUCCESS = "ADD_SUB_REPORT_ATTRIBUTE_SUCCESS";
export const EDIT_SUB_REPORT_ATTRIBUTE_SUCCESS = "EDIT_SUB_REPORT_ATTRIBUTE_SUCCESS";
export const DEACTIVATE_SUB_REPORT_ATTRIBUTE_SUCCESS = "DEACTIVATE_SUB_REPORT_ATTRIBUTE_SUCCESS";

export const ADD_CUSTOMER_IMPORT_SUCCESS = "ADD_CUSTOMER_IMPORT_SUCCESS";
export const EDIT_CUSTOMER_IMPORT_SUCCESS = "EDIT_CUSTOMER_IMPORT_SUCCESS";
export const DEACTIVATE_CUSTOMER_IMPORT_SUCCESS = "DEACTIVATE_CUSTOMER_IMPORT_SUCCESS";

export const ADD_CUSTOMER_IMPORT_FILE_SUCCESS = "ADD_CUSTOMER_IMPORT_FILE_SUCCESS";
export const EDIT_CUSTOMER_IMPORT_FILE_SUCCESS = "EDIT_CUSTOMER_IMPORT_FILE_SUCCESS";
export const DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS = "DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS";

export const ADD_EMPLOYEE_FILE_SUCCESS = "ADD_EMPLOYEE_FILE_SUCCESS";
export const EDIT_EMPLOYEE_FILE_SUCCESS = "EDIT_EMPLOYEE_FILE_SUCCESS";
export const EDIT_EMPLOYEE_SUCCESS = "EDIT_EMPLOYEE_SUCCESS";
export const MERGE_EMPLOYEE_SUCCESS = "MERGE_EMPLOYEE_SUCCESS";

export const ADD_EMPLOYEE_SYSTEM_SUCCESS = "AD_EMPLOYEE_SYSTEM_SUCCESS";
export const EDIT_EMPLOYEE_SYSTEM_SUCCESS = "EDIT_EMPLOYEE_SYSTEM_SUCCESS";

export const EDIT_EMPLOYEE_SYSTEM_EXCEPTION_SUCCESS = "EDIT_EMPLOYEE_SYSTEM_EXCEPTION_SUCCESS";

export const ADD_SYSTEM_SUCCESS = "ADD_SYSTEM_SUCCESS";

export const ADD_WORKFLOW_JOB_SCHEDULE_SUCCESS = "ADD_WORKFLOW_JOB_SCHEDULE_SUCCESS";
export const EDIT_WORKFLOW_JOB_SCHEDULE_SUCCESS = "EDIT_WORKFLOW_JOB_SCHEDULE_SUCCESS";
export const DELETE_WORKFLOW_JOB_SCHEDULE_SUCCESS = "DELETE_WORKFLOW_JOB_SCHEDULE_SUCCESS";

export const ADD_WORKFLOW_JOB_SUCCESS = "ADD_WORKFLOW_JOB_SUCCESS";
export const EDIT_WORKFLOW_JOB_SUCCESS = "EDIT_WORKFLOW_JOB_SUCCESS";
export const DELETE_WORKFLOW_JOB_SUCCESS = "DELETE_WORKFLOW_JOB_SUCCESS";

export const ADD_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS = "ADD_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS";
export const EDIT_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS = "EDIT_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS";
export const DELETE_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS = "DELETE_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS";

export const ERROR = "ERROR";
