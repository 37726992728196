import { TYPES } from "../../dependencyInjection/Types";
import { EmployeeSystemException } from "../../models/EmployeeSystemException";
import { IEmployeeSystemExceptionService } from "../../services/employeeSystemException/IEmployeeSystemExceptionService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    EditEmployeeSystemExceptionAction,
    EDIT_EMPLOYEE_SYSTEM_EXCEPTION,
    EmployeeSystemExceptionsFetchedAction,
    EMPLOYEE_SYSTEM_EXCEPTIONS_FETCHED,
} from "./EmployeeSystemExceptionAction";

export function getEmployeeSystemExceptionssByTenantId(
    tenantId: number
): KeysysThunkAction<void, EmployeeSystemExceptionsFetchedAction> {
    return async (dispatch, getState, container) => {
        const employeeSystemExceptionService = container.get<IEmployeeSystemExceptionService>(
            TYPES.EmployeeSystemExceptionService
        );
        const employeeSystemExceptions = await employeeSystemExceptionService.getEmployeeSystemExceptionsByTenantId(
            tenantId
        );

        if (employeeSystemExceptions) {
            dispatch<EmployeeSystemExceptionsFetchedAction>({
                type: EMPLOYEE_SYSTEM_EXCEPTIONS_FETCHED,
                employeeSystemExceptions: employeeSystemExceptions,
            });
        }
    };
}

export function editEmployeeSystemException(
    originalEmployeeSystemException: EmployeeSystemException,
    editedEmployeeSystemException: EmployeeSystemException
): KeysysThunkAction<void, EditEmployeeSystemExceptionAction> {
    return async (dispatch, getState, container) => {
        const employeeSystemExceptionService = container.get<IEmployeeSystemExceptionService>(
            TYPES.EmployeeSystemExceptionService
        );
        const patchedEmployeeSystemException = await employeeSystemExceptionService.editEmployeeSystemException(
            originalEmployeeSystemException,
            editedEmployeeSystemException
        );
        if (patchedEmployeeSystemException) {
            dispatch<EditEmployeeSystemExceptionAction>({
                type: EDIT_EMPLOYEE_SYSTEM_EXCEPTION,
                originalEmployeeSystemException: originalEmployeeSystemException,
                editedEmployeeSystemException: patchedEmployeeSystemException,
            });
        }
    };
}
