import { useEffect, useState } from "react";
import { IUserForm } from "./IUserForm";
import { ErrorMessage, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { AddUserFormModel } from "./AddUserFormModel";
import * as Yup from "yup";
import { User } from "../../../models/User";
import { createUser, deleteUser, editUser, restoreUser } from "../../../actions/users/UserActionCreators";
import { useDispatch, useSelector } from "react-redux";
import VerticallyCenteredModal from "../../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { AppState } from "../../../state/AppState";
import { Col, Row } from "react-bootstrap";
import KeysysInput from "../../form/input/KeysysInput";
import { KeysysSelectOption } from "../../form/select/KeysysSelectOption";
import { Role } from "../../../models/Role";
import ErrorMessageText from "../../form/error-message/ErrorMessageText";
import SmallRoundCheckbox from "../../checkbox/small-round-checkbox/SmallRoundCheckbox";
import { Tenant } from "../../../models/Tenant";
import KeysysSelect from "../../form/select/KeysysSelect";
import ConfirmationModal from "../../modal/ConfirmationModal/ConfirmationModal";
import { useCurrentTenant } from "../../../hooks/currentTenantHook";
import { values } from "lodash";

export default function UserForm(props: IUserForm) {
    const dispatch = useDispatch();
    const [pathfinderAccessValue, setPathfinderAccessValue] = useState<boolean>(false);
    const [sysorecAccessValue, setSysorecAccessValue] = useState<boolean>(false);
    const [finosecAcademyAccessValue, setFinosecAcademyAccessValue] = useState<boolean>(false);
    const [documentMgmtValue, setDocumentMgmtValue] = useState<boolean>(false);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false);

    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const claimTenantName = decodedAccessToken.tenant_name;
    const claimTenantId = decodedAccessToken.tenant_id;
    const loggedInUserRole = decodedAccessToken.roles;
    const qrCodeImage = decodedAccessToken.qr_code_image;

    const roles = useSelector<AppState, Role[]>((state) => state.roleState.roles);
    const tenants = useSelector<AppState, Tenant[]>((state) => state.tenantState.tenants);

    useEffect(() => {
        if (props.user?.hasPathfinderAccess) {
            setPathfinderAccessValue(props.user?.hasPathfinderAccess);
        }
        if (props.user?.hasSysorecAccess) {
            setSysorecAccessValue(props.user?.hasSysorecAccess);
        }
        if (props.user?.hasFinosecAcademyAccess) {
            setFinosecAcademyAccessValue(props.user?.hasFinosecAcademyAccess);
        }
        if (props.user?.hasDocumentManagement) {
            setDocumentMgmtValue(props.user?.hasDocumentManagement);
        }
    }, [props]);

    function getInitialValues() {
        return {
            firstName: props.user?.firstName ?? "",
            lastName: props.user?.lastName ?? "",
            emailAddress: props.user?.emailAddress ?? "",
            phoneNumber: props.user?.phoneNumber ?? "",
            isActive: props.user?.isActive ?? true,
            hasPathfinderAccess: props.user?.hasPathfinderAccess ?? true,
            hasSysorecAccess: props.user?.hasSysorecAccess ?? true,
            hasFinosecAcademyAccess: props.user?.hasFinosecAcademyAccess ?? true,
            hasDocumentManagement: props.user?.hasDocumentManagement ?? true,
            tenantName: props.user?.tenantName ?? "",
            tenantId: loggedInUserRole === "CustomerAdmin" ? claimTenantId : props.user?.tenantId ?? 0,
            roleId: props.user?.roleId ?? 0,
            mfaOptionId: props.user?.mfaOptionId ?? 0,
            mfaEnabled: props.user?.mfaEnabled ?? true,
            mfaReset: false,
        };
    }
    const userSchema = Yup.object<AddUserFormModel>().shape({
        tenantId: Yup.number().min(1, "Tenant is required").required("Tenant is required"),
        firstName: Yup.string().max(50, "First Name must be 50 characters or less").required("First Name is required"),
        lastName: Yup.string().max(50, "Last Name must be 50 characters or less").required("Last Name is required"),
        emailAddress: Yup.string().max(100, "Email must be 100 characters or less").required("Email is required"),
        roleId: Yup.string().nullable().required("Role is required"),
        //mfaOptionId: Yup.number().min(1, "MFA Option is required").required("MFA Option is required"),
        phoneNumber: Yup.number()
            .min(10, "Phone Number must be 10 characters")
            .when("mfaOptionId", {
                is: (mfaOptionId) => mfaOptionId === 2,
                then: Yup.number().required("Phone Number is required for SMS MFA Type"),
            }),
    });
    function clearFormAndHide(formProps: FormikProps<AddUserFormModel> | FormikHelpers<AddUserFormModel>): void {
        formProps.resetForm();
        props.onClose();
    }

    const handleDeleteUser = (user: User) => {
        dispatch(deleteUser(user));
        setShowConfirmDeleteModal(false);
        props.onClose();
    };

    const handleRestoreUser = (user: User) => {
        dispatch(restoreUser(user));
        setShowConfirmRestoreModal(false);
        props.onClose();
    };

    function getTitle() {
        if (props.type === "add") {
            return "Add User";
        }
        if (props.type === "edit") {
            return "Edit User";
        }
        return "User";
    }

    // const getRoleOptions = (): KeysysSelectOption[] => {
    //     const roleOptions =
    //         loggedInUserRole === "CustomerAdmin"
    //             ? roles.filter((r) => r.name === "CustomerAdmin")
    //             : roles.filter((r) => r.name === "CustomerAdmin" || r.name === "PartnerAdmin");
    //     return roleOptions.map((r) => ({ value: r.id, label: r.name } as KeysysSelectOption));
    // };

    const getTenantOptions = (): KeysysSelectOption[] => {
        const loggedInUser = decodedAccessToken.roles;
        const tenantOptions =
            loggedInUser === "CustomerAdmin"
                ? tenants.filter((t) => t.name === claimTenantName)
                : loggedInUser === "PartnerAdmin"
                ? tenants.filter((t) => t.name !== "RootTenant")
                : tenants;
        return tenantOptions.map((t) => ({ value: t.id, label: t.name } as KeysysSelectOption));
    };

    const getCustomerOptions = (): KeysysSelectOption[] => {
        const filteredCustomerRoles = roles.filter((t) => t.name === "CustomerAdmin" || t.name === "ReadOnlyCustomer");
        return filteredCustomerRoles.map((t) => ({ value: t.id, label: t.name } as KeysysSelectOption));
    };

    const getMFAOptions = (): KeysysSelectOption[] => {
        return [
            /*{ value: 1, label: "Email" } as KeysysSelectOption,
            { value: 2, label: "SMS" } as KeysysSelectOption,*/
            { value: 3, label: "Authenticator App" } as KeysysSelectOption,
        ];
    };

    const getMFAEnabledOptions = (): KeysysSelectOption[] => {
        return [
            { value: true, label: "Yes" } as KeysysSelectOption,
            { value: false, label: "No" } as KeysysSelectOption,
        ];
    };

    function getRoleIdToSave(selectedTenantId: number, customerRoleId: number): number {
        const tenantSelected = selectedTenantId
            ? tenants.filter((t) => t.id === selectedTenantId)[0]
            : tenants.filter((t) => t.name === claimTenantName)[0];

        let roleToSave = tenantSelected.parentTenantId ? "CustomerAdmin" : "PartnerAdmin";
        if (tenantSelected.name === "RootTenant") {
            roleToSave = "GlobalAdmin";
        }

        if (customerRoleId === 0) {
            return roles.filter((r) => r.name === roleToSave)[0].id;
        } else {
            return customerRoleId;
        }
    }

    function getTenantNameToSave(selectedTenantId: number): string {
        return selectedTenantId ? tenants.filter((t) => t.id === selectedTenantId)[0].name : claimTenantName;
    }

    function getTenantIdToSave(): number {
        return tenants.filter((t) => t.name === claimTenantName)[0].id;
    }

    function handleSubmit(values: AddUserFormModel, FormikHelpers: FormikHelpers<AddUserFormModel>) {
        if (props.type === "add") {
            const userToCreate: User = {
                id: 0,
                firstName: values.firstName,
                lastName: values.lastName,
                emailAddress: values.emailAddress,
                phoneNumber: values.phoneNumber,
                isActive: true,
                hasPathfinderAccess: pathfinderAccessValue,
                hasSysorecAccess: sysorecAccessValue,
                hasFinosecAcademyAccess: finosecAcademyAccessValue,
                hasDocumentManagement: documentMgmtValue,
                roleId: getRoleIdToSave(values.tenantId, values.roleId),
                tenantId: loggedInUserRole === "CustomerAdmin" ? getTenantIdToSave() : values.tenantId,
                tenantName: getTenantNameToSave(values.tenantId),
                mfaOptionId: values.mfaOptionId,
                mfaEnabled: values.mfaEnabled,
                mfaSetupComplete: false,
            };
            dispatch(createUser(userToCreate));
        } else {
            const editedUser: User = {
                ...props.user!,
                id: props.user!.id,
                firstName: values.firstName,
                lastName: values.lastName,
                emailAddress: values.emailAddress,
                phoneNumber: values.phoneNumber,
                hasPathfinderAccess: pathfinderAccessValue,
                hasSysorecAccess: sysorecAccessValue,
                hasFinosecAcademyAccess: finosecAcademyAccessValue,
                hasDocumentManagement: documentMgmtValue,
                roleId: getRoleIdToSave(values.tenantId, values.roleId),
                tenantId: loggedInUserRole === "CustomerAdmin" ? getTenantIdToSave() : values.tenantId,
                tenantName: getTenantNameToSave(values.tenantId),
                mfaOptionId: values.mfaOptionId,
                mfaEnabled: values.mfaEnabled,
                mfaSetupComplete: !values.mfaReset,
            };
            dispatch(editUser(props.user!, editedUser));
        }
        clearFormAndHide(FormikHelpers);
    }
    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={userSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<AddUserFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditPartnerModal"
                            show={true}
                            title={getTitle()}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={!props.user || props.user?.isActive}
                            showDeleteButton={props.type === "edit" && props.user?.isActive}
                            deleteButtonText={"Deactivate"}
                            showRestoreButton={props.type === "edit" && !props.user?.isActive}
                            restoreButtonText={"Activate"}
                            onDeleteButtonClick={() => setShowConfirmDeleteModal(true)}
                            onRestoreButtonClick={() => setShowConfirmRestoreModal(true)}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="userForm">
                                {loggedInUserRole === "CustomerAdmin" ? (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <h3>{claimTenantName}</h3>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id={"tenant"}
                                                placeholder={"Select Tenant"}
                                                fieldName={"tenantId"}
                                                label={"Tenant"}
                                                options={getTenantOptions()}
                                                multiple={false}
                                                disabled={props.type === "edit" ? true : false}
                                            />
                                            <ErrorMessage name={"roleId"}>
                                                {(msg) => <ErrorMessageText message={msg} />}
                                            </ErrorMessage>
                                        </Col>
                                    </Row>
                                )}

                                {tenants?.find((t: any) => t.id === formProps?.values?.tenantId)?.parentTenantId !==
                                    null && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id={"roleId"}
                                                placeholder={"Select Customer Role"}
                                                fieldName={"roleId"}
                                                label={"Customer Role"}
                                                options={getCustomerOptions()}
                                                multiple={false}
                                                disabled={props.type === "edit" ? true : false}
                                            />
                                        </Col>
                                    </Row>
                                )}

                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"emailAddress"}
                                            placeholder={"Email"}
                                            fieldName={"emailAddress"}
                                            label={"Email"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"firstName"}
                                            placeholder={""}
                                            fieldName={"firstName"}
                                            label={"First Name"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"lastName"}
                                            placeholder={""}
                                            fieldName={"lastName"}
                                            label={"Last Name"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"phoneNumber"}
                                            placeholder={""}
                                            fieldName={"phoneNumber"}
                                            label={"Phone Number"}
                                        />
                                    </Col>
                                </Row>
                                {loggedInUserRole === "GlobalAdmin" && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id={"mfaEnabled"}
                                                placeholder={"Enable MFA"}
                                                fieldName={"mfaEnabled"}
                                                label={"Enable MFA"}
                                                options={getMFAEnabledOptions()}
                                                multiple={false}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {loggedInUserRole === "GlobalAdmin" && props.type === "edit" && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id={"mfaReset"}
                                                placeholder={"Reset MFA"}
                                                fieldName={"mfaReset"}
                                                label={"Reset MFA"}
                                                options={getMFAEnabledOptions()}
                                                multiple={false}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {(props.user?.mfaEnabled || formProps.values.mfaEnabled) &&
                                    props.user?.id === decodedAccessToken.userId && (
                                        <>
                                            <Row className={"mb-4"}>
                                                <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                    <KeysysSelect
                                                        formProps={formProps}
                                                        id={"mfaOption"}
                                                        placeholder={"Select MFA Option"}
                                                        fieldName={"mfaOptionId"}
                                                        label={"MFA (2FA) Options"}
                                                        options={getMFAOptions()}
                                                        multiple={false}
                                                    />
                                                </Col>
                                            </Row>
                                            {formProps.values.mfaOptionId === 2 && (
                                                <Row className={"mb-4"}>
                                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                        <strong>
                                                            By clicking Save, you are opting in to receive MFA one-time
                                                            passcode text message from Finosec UAR App. The Finosec UAR
                                                            App cannot be accessed without this code. Carrier Message
                                                            and Data rates may apply.
                                                        </strong>
                                                    </Col>
                                                </Row>
                                            )}
                                            {formProps.values.mfaOptionId === 3 && (
                                                <>
                                                    <Row className={"mb-4"}>
                                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                            <strong>
                                                                Please download either Goolge Authenticator or Microsoft
                                                                Authenticator. Open the app and scan the code below to
                                                                set up MFA.
                                                            </strong>
                                                        </Col>
                                                    </Row>
                                                    <Row className={"mb-4"}>
                                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                            <img src={qrCodeImage} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </>
                                    )}
                                {/* <Row>
                                    <Col xs={12} md={{ span: 6, offset: 3 }} className={"d-inline-flex"}>
                                        <SmallRoundCheckbox
                                            entityNameSingular="PathfinderAccess"
                                            id={"pathfinderAccessCheckbox"}
                                            checked={pathfinderAccessValue}
                                            onClick={() => {
                                                setPathfinderAccessValue(!pathfinderAccessValue);
                                            }}
                                        />
                                        <label htmlFor="pathfinderAccessCheckbox">Pathfinder Access</label>
                                    </Col>
                                </Row> 
                                <Row>
                                    <Col xs={12} md={{ span: 6, offset: 3 }} className={"d-inline-flex"}>
                                        <SmallRoundCheckbox
                                            entityNameSingular="SysorecAccess"
                                            id={"sysorecAccessCheckbox"}
                                            checked={sysorecAccessValue}
                                            onClick={() => {
                                                setSysorecAccessValue(!sysorecAccessValue);
                                            }}
                                        />
                                        <label htmlFor="sysorecAccessCheckbox">UAR Access</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={{ span: 6, offset: 3 }} className={"d-inline-flex"}>
                                        <SmallRoundCheckbox
                                            entityNameSingular="FinosecAcademyAccess"
                                            id={"finosecAcademyAccessCheckbox"}
                                            checked={finosecAcademyAccessValue}
                                            onClick={() => {
                                                setFinosecAcademyAccessValue(!finosecAcademyAccessValue);
                                            }}
                                        />
                                        <label htmlFor="finosecAcademyAccessCheckbox">Finosec Academy Access</label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={{ span: 6, offset: 3 }} className={"d-inline-flex"}>
                                        <SmallRoundCheckbox
                                            entityNameSingular="DocumentMgmt"
                                            id={"documentMgmtCheckbox"}
                                            checked={documentMgmtValue}
                                            onClick={() => {
                                                setDocumentMgmtValue(!documentMgmtValue);
                                            }}
                                        />
                                        <label htmlFor="documentMgmtCheckbox">Document MGMT</label>
                                    </Col>
                                </Row> */}
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
            <ConfirmationModal
                id="confirmDeleteUserModal"
                show={showConfirmDeleteModal}
                title="Deactivate User?"
                message={"Do you really want to deactivate this user?"}
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={() => handleDeleteUser(props.user!)}
                theme={"danger"}
            />
            <ConfirmationModal
                id="confirmRestoreUserModal"
                show={showConfirmRestoreModal}
                title="Activate User?"
                message={"Do you really want to activate this user?"}
                onCloseButtonClick={() => setShowConfirmRestoreModal(false)}
                onOkButtonClick={() => handleRestoreUser(props.user!)}
                theme={"danger"}
            />
        </>
    );
}
