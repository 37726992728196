import { useLocation } from "react-router-dom";
import LinkWrapper from "../routing/LinkWrapper";

export interface SidebarLinkProps {
    isExpanded: boolean;
    onClick: VoidFunction;
    path: string;
    iconClass: string;
    hoverText?: string;
    children?: React.ReactChild | React.ReactChild[];
}

export default function SidebarLink(props: SidebarLinkProps) {
    const { isExpanded, onClick, path, iconClass, hoverText, children } = props;
    const location = useLocation();

    return (
        <li className={location.pathname === path ? "current-page" : ""} title={hoverText || ""}>
            <LinkWrapper onClick={onClick} path={path}>
                <div className="d-flex align-items-center">
                    <span className={`icon-container ${isExpanded ? "expanded" : ""}`}>
                        <i
                            className={`sidebar-image-icon ${iconClass || ""}`}
                            style={{ color: "#eaf2ee", fontSize: "1.3rem", textAlign: "center" }}
                        />
                    </span>
                    {isExpanded && <div className="menu-item-with-plus-icon">{children}</div>}
                </div>
            </LinkWrapper>
        </li>
    );
}
