import { WorkflowFrequency } from "../../models/WorkflowFrequency";

export const WORKFLOW_FREQUENCIES_FETCHED = "WORKFLOW_FREQUENCIES_FETCHED";

export interface WorkflowFrequenciesFetched {
    type: typeof WORKFLOW_FREQUENCIES_FETCHED;
    workflowFrequencies: WorkflowFrequency[];
}

export type WorkflowFrequencyActions = WorkflowFrequenciesFetched;
