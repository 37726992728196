/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createFinosecSystem, getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { createDashboard, editDashboard, getDashboardById } from "../actions/dashboard/DasboardActionCreators";
import { FinosecSystem } from "../models/FinosecSystem";
import { Dashboard } from "../models/Dashboard";
import { selectDashboardById } from "../selectors/DashboardSelectors";
import { AppState } from "../state/AppState";

export const useDashboardForm = () => {
    let params = useParams<{ id: any }>();
    const dispatch = useDispatch();
    const dashboardInitValues: any = {
        name: "",
        system: "",
        templateId: ""
    };
    const dashboard = useSelector((state:AppState) =>
        selectDashboardById(state, +params.id)
    );

    const systems = useSelector<AppState, FinosecSystem[]>((s) =>
        s.finosecSystemState?.finosecSystems?.sort((a, b) => a.name.localeCompare(b.name))
    );

    useEffect(() => {
        if (params.id && !dashboard) {
            dispatch(getDashboardById(params.id));
        }
        if (!systems || systems.length === 0)  {
            dispatch(getFinosecSystems());
        }
    }, []);

    const onSave = (values: any) => {
        let dashboardToAdd = { name: values.name, finosecSystemId: values.finosecSystemId, id: params?.id || 0} as Dashboard;
        if (params?.id && dashboard) {
            const originalDashboard = { name: dashboard.name, finosecSystemId: dashboard.finosecSystemId, id: +params.id} as Dashboard;            
            dispatch(editDashboard(originalDashboard, dashboardToAdd));

        } else {
            
           dispatch(createDashboard(dashboardToAdd));
        }
    };

    const onCreateSystem = (system: string) => {        
        dispatch(createFinosecSystem({name:system} as FinosecSystem));
    };

    return {
        dashboard: dashboard
            ? dashboard : {
                 ...dashboardInitValues,
              },
        systems,
        onSave,
        onCreateSystem,
        isEdit: !!params?.id
    };
};
