import { FileStatusActions, FILE_STATUSES_FETCHED } from "../../actions/fileStatus/FileStatusActions";
import { FileStatusState } from "../../state/FileStatusState";

const initialState: FileStatusState = {
    statuses: [],
};

export function fileStatusReducer(state: FileStatusState = initialState, action: FileStatusActions): FileStatusState {
    switch (action.type) {
        case FILE_STATUSES_FETCHED: {
            return {
                ...state,
                statuses: action.statuses,
            };
        }
        default:
            return state;
    }
}
