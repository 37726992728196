import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFileTypes } from "../actions/fileType/FileTypeActionCreators";
import { AppState } from "../state/AppState";

export const useIntakeSystemFiles = () => {
    const dispatch = useDispatch();
    const { selectedIntakeSystemFile: selectedFile, intakeSystemFiles } = useSelector(
        (state: AppState) => state.intakeSystemFileState
    );
    const fileTypes = useSelector((state: AppState) => state.fileTypeState.fileTypes);
    const systems = useSelector((state: AppState) => state.finosecSystemState.finosecSystems);
    const tenants = useSelector((state: AppState) => state.tenantState.tenants);
    useEffect(() => {
        if (fileTypes.length === 0) {
            dispatch(getFileTypes());
        }
    }, []);
    const findTenant = useCallback(
        (id?: number) => {
            if (!id) return;
            return tenants.find((t) => t.id === id);
        },
        [tenants]
    );
    const findSystem = useCallback(
        (id?: number) => {
            if (!id) return;
            return systems.find((s) => s.id === id);
        },
        [systems]
    );
    return useMemo(
        () => ({
            findSystem,
            findTenant,
            selectedFile,
            intakeSystemFiles,
        }),
        [findSystem, findTenant, selectedFile, intakeSystemFiles]
    );
};
