import {
    WorkflowJobActions,
    WORKFLOW_JOBS_FETCHED,
    WORKFLOW_JOB_CREATED,
    WORKFLOW_JOB_DELETED,
    WORKFLOW_JOB_EDITED,
    WORKFLOW_JOB_FETCHED,
    WORKFLOW_JOB_SELECTED,
} from "../../actions/workflowJob/WorkflowJobActions";
import { WorkflowJob } from "../../models/WorkflowJob";
import { WorkflowJobState } from "../../state/WorkflowJobState";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: WorkflowJobState = {
    workflowJobs: [],
};

export function workflowJobReducer(
    state: WorkflowJobState = initialState,
    action: WorkflowJobActions
): WorkflowJobState {
    switch (action.type) {
        case WORKFLOW_JOBS_FETCHED:
            return {
                ...state,
                workflowJobs: [...action.workflowJobs].sort(sortWorkflowJobs),
            };
        case WORKFLOW_JOB_FETCHED:
            const existing = state.workflowJobs.find((j) => j.id === action.workflowJob.id);
            const workflowJobs = existing
                ? replaceInCollection(state.workflowJobs, existing, action.workflowJob, sortWorkflowJobs)
                : [...state.workflowJobs, action.workflowJob].sort(sortWorkflowJobs);
            return {
                ...state,
                workflowJobs,
            };
        case WORKFLOW_JOB_CREATED:
            return {
                ...state,
                workflowJobs: [...state.workflowJobs, action.workflowJob].sort(sortWorkflowJobs),
            };
        case WORKFLOW_JOB_DELETED:
            const original = state.workflowJobs.find((j) => j.id === action.workflowJob.id);
            return {
                ...state,
                workflowJobs: replaceInCollection(state.workflowJobs, original!, action.workflowJob),
            };
        case WORKFLOW_JOB_EDITED:
            return {
                ...state,
                workflowJobs: replaceInCollection(
                    state.workflowJobs,
                    action.workflowJob,
                    action.newWorkflowJob,
                    sortWorkflowJobs
                ),
            };
        case WORKFLOW_JOB_SELECTED:
            return { ...state, selectedWorkflowJob: action.workflowJob };

        default:
            return state;
    }
}

function sortWorkflowJobs(a: WorkflowJob, b: WorkflowJob) {
    return a.name.localeCompare(b.name);
}
