import { RoleState } from "../state/RoleState";
import {
    ROLES_FETCHED,
} from "../actions/RoleActions";

const initialState: RoleState = {
    roles: []
};

export function roleReducer(state: RoleState = initialState, action: any): RoleState {
    switch (action.type) {
        case ROLES_FETCHED:
            const sortedRoles = [...action.roles];
            sortedRoles.sort((a, b) => a.name.localeCompare(b.name));

            return {
                ...state,
                roles: sortedRoles,
            };
        

        default:
            return state;
    }
}

