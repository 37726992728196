import { ReportTypeActions, REPORT_TYPES_FETCHED } from "../../actions/reportType/ReportTypeAction";
import { ReportTypeState } from "../../state/ReportTypeState";

const initialState: ReportTypeState = {
    reportTypes: [],
};

export function reportTypeReducer(state: ReportTypeState = initialState, action: ReportTypeActions): ReportTypeState {
    switch (action.type) {
        case REPORT_TYPES_FETCHED: {
            return {
                ...state,
                reportTypes: action.reportTypes,
            };
        }
        default:
            return state;
    }
}
