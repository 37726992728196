import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ERROR } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { inject } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { Store } from "redux";
import { IExceptionHandler } from "./IExceptionHandler";

export class DefaultExceptionHandler implements IExceptionHandler {
    constructor(@inject(TYPES.Store) private store: Store) {}

    handleError(exception: any, bodyText: string): void {
        if (exception.statusCode === 401 && !exception.message?.includes("current user does not have access")) {
            return;
        }

        if (exception.statusCode === 400 && !!exception.error.Error) {
            bodyText = exception.error.Error;
        }

        const toastProps: KeysysToastProps = {
            name: ERROR,
            theme: "danger",
            titleInHeader: "Error",
            body: bodyText,
            delay: 15000,
        };
        this.store.dispatch(showToast(toastProps));
    }
}
