import {
    WorkflowFrequencyActions,
    WORKFLOW_FREQUENCIES_FETCHED,
} from "../../actions/workflowFrequency/WorkflowFrequencyActions";
import { WorkflowFrequencyState } from "../../state/WorkflowFrequencyState";

const initialState: WorkflowFrequencyState = {
    workflowFrequencies: [],
};

export function workflowFrequencyReducer(
    state: WorkflowFrequencyState = initialState,
    action: WorkflowFrequencyActions
): WorkflowFrequencyState {
    switch (action.type) {
        case WORKFLOW_FREQUENCIES_FETCHED:
            return {
                ...state,
                workflowFrequencies: [...action.workflowFrequencies].sort((a, b) => a.name.localeCompare(b.name)),
            };
        default:
            return state;
    }
}
