import { Tenant } from "../../models/Tenant";
import { CustomerMenuItemCreate, ICustomerMenuItem } from "../../models/CustomerMenuItem";

export const TENANTS_FETCHED = "TENANTS_FETCHED";
export const PARENT_TENANT_FETCHED = "PARENT_TENANT_FETCHED";
export const CREATE_TENANT = "CREATE_TENANT";
export const DELETE_TENANT = "DELETE_TENANT";
export const EDIT_TENANT = "EDIT_TENANT";
export const CUSTOMER_MENU_ITEMS_FETCHED = "CUSTOMER_MENU_ITEMS_FETCHED";
export const CREATE_CUSTOMER_MENU_ITEM = "CREATE_CUSTOMER_MENU_ITEM";
export const EDIT_CUSTOMER_MENU_ITEM = "EDIT_CUSTOMER_MENU_ITEM";
export const DELETE_CUSTOMER_MENU_ITEM = "DELETE_CUSTOMER_MENU_ITEM";

export interface TenantsFetchedAction {
    type: typeof TENANTS_FETCHED;
    tenants: Tenant[];
}

export interface CustomerMenuItemsFetchedAction {
    type: typeof CUSTOMER_MENU_ITEMS_FETCHED;
    customerMenuItems: ICustomerMenuItem[];
}

export interface CreateCustomerMenuItemAction {
    type: typeof CREATE_CUSTOMER_MENU_ITEM;
    customerMenuItem: ICustomerMenuItem;
}

export interface EditCustomerMenuItemAction {
    type: typeof EDIT_CUSTOMER_MENU_ITEM;
    customerMenuItem: ICustomerMenuItem;
    newCustomerMenuItem: ICustomerMenuItem;
}

export interface DeleteCustomerMenuItemAction {
    type: typeof DELETE_CUSTOMER_MENU_ITEM;
    customerMenuItem: ICustomerMenuItem;
}

export interface ParentTenantFetchedAction {
    type: typeof PARENT_TENANT_FETCHED;
    parentTenant: Tenant;
}

export interface CreateTenantAction {
    type: typeof CREATE_TENANT;
    tenant: Tenant;
}

export interface DeleteTenantAction {
    type: typeof DELETE_TENANT;
    tenant: Tenant;
}

export interface EditTenantAction {
    type: typeof EDIT_TENANT;
    originalTenant: Tenant;
    newTenant: Tenant;
}

export type TenantActions =
    | TenantsFetchedAction
    | CreateTenantAction
    | EditTenantAction
    | DeleteTenantAction
    | ParentTenantFetchedAction
    | CustomerMenuItemsFetchedAction
    | CreateCustomerMenuItemAction
    | EditCustomerMenuItemAction
    | DeleteCustomerMenuItemAction;
