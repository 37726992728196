import { EmployeeSystem } from "../../models/EmployeeSystem";

export const CREATE_EMPLOYEE_SYSTEM = "CREATE_EMPLOYEE_SYSTEM";
export const EDIT_EMPLOYEE_SYSTEM = "EDIT_EMPLOYEE_SYSTEM";

export interface CreateEmployeeSystemAction {
    type: typeof CREATE_EMPLOYEE_SYSTEM;
    employeeSystem: EmployeeSystem;
}

export interface EditEmployeeSystemAction {
    type: typeof EDIT_EMPLOYEE_SYSTEM;
    originalEmployeeSystem: EmployeeSystem;
    editedEmployeeSystem: EmployeeSystem;
}

export type EmployeeSystemActions = CreateEmployeeSystemAction | EditEmployeeSystemAction;
