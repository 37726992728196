import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import SidebarLink, { SidebarLinkProps } from "./SidebarLink";
import { useFloating, offset, shift, flip, autoPlacement } from "@floating-ui/react-dom";
import { relative } from "path";
import { start } from "repl";

export type LinkGroupItem = Omit<SidebarLinkProps, "isExpanded">;

interface SidebarLinkBottomGroupProps {
    isSidebarExpanded: boolean;
    isGroupExpanded: (groupName: string) => boolean;
    onClick: (groupName: string) => void;
    iconClass: string;
    groupName: string;
    links: LinkGroupItem[];
    hoverText?: string;
}

const SidebarLinkBottomGroup = ({
    isSidebarExpanded,
    isGroupExpanded,
    onClick,
    iconClass,
    groupName,
    links,
    hoverText,
}: SidebarLinkBottomGroupProps) => {
    const { x, y, reference, floating, strategy } = useFloating({
        placement: "right",
        middleware: [offset(10), shift({ padding: { bottom: 70 } })],
    });
    const location = useLocation();
    const shouldGroupExpand = useMemo(() => isGroupExpanded(groupName), [isGroupExpanded, groupName]);
    const className = useMemo(() => {
        if (links.map(({ path }) => path).includes(location.pathname)) {
            return "current-page";
        }
    }, [links, location]);
    return (
        <>
            <li className={className} title={hoverText}>
                <div
                    ref={reference}
                    onClick={() => onClick(groupName)}
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                >
                    <span className={`icon-container ${isSidebarExpanded ? "expanded" : ""}`}>
                        <i
                            className={`sidebar-image-icon ${iconClass || ""}`}
                            style={{ color: "#eaf2ee", fontSize: "1.3rem", textAlign: "center" }}
                        />
                    </span>
                    {isSidebarExpanded && (
                        <>
                            <div className="menu-item-with-plus-icon">
                                <strong>{groupName}</strong>
                            </div>
                            <i
                                className="fas fa-caret-down"
                                style={{ color: "#eaf2ee", fontSize: "1.3rem", textAlign: "center" }}
                            />
                        </>
                    )}
                </div>
            </li>
            {isSidebarExpanded && shouldGroupExpand && (
                <ul className="group-item-list">
                    {links.map((link) => (
                        <SidebarLink key={link.path} isExpanded {...link} />
                    ))}
                </ul>
            )}
            {!isSidebarExpanded && shouldGroupExpand && (
                <ul
                    className="floating-group-item-list"
                    ref={floating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                    }}
                >
                    {links.map((link) => (
                        <SidebarLink key={link.path} isExpanded {...link} />
                    ))}
                </ul>
            )}
        </>
    );
};

export default SidebarLinkBottomGroup;
