/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Col, Navbar, Row, NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import logo from "../../images/finosec_logo.png";
import { HeaderState } from "../../state/HeaderState";
import LinkWrapper from "../routing/LinkWrapper";
import Select from "react-select";
import { TenantState } from "../../state/TenantState";
import { logOut, unimpersonateUser } from "../../actions/AuthenticationActionCreators";
import { useCurrentUser } from "../../hooks/currentUserHook";
import { useHistory, useLocation, useParams } from "react-router-dom";

const Header = (props: { partnerLogo?: string }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { partnerLogo } = props;
    const headerState = useSelector<AppState, HeaderState>((state) => state.headerState);
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { impersonator } = useSelector((state: AppState) => state.userState);
    const currentUser = useCurrentUser(decodedAccessToken.userId);
    let [boxShadowRef, setBoxShadowRef] = useState<HTMLDivElement | null>();
    const userName = impersonator
        ? `${impersonator.firstName} as ${currentUser?.firstName} ${currentUser?.lastName}`
        : `${currentUser?.firstName} ${currentUser?.lastName}`;

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }
    let shadowAdded = false;
    useEffect(() => {
        if (boxShadowRef) {
            window.addEventListener("scroll", () => {
                handleBoxShadow();
            });
        }
        return () => window.removeEventListener("scroll", handleBoxShadow);
    }, [boxShadowRef]);

    const dispatchUnimpersonateUser = () => {
        dispatch(unimpersonateUser());
    };

    const location = useLocation();
    const locationArray = location.pathname.toString().split("/");

    const customerImport = useSelector((state: AppState) =>
        state.customerImportState.customerImports.find((ci) => ci.id === parseInt(locationArray[3]))
    );

    const customerReport = useSelector((state: AppState) =>
        state.customerReportState.customerReports.find((cr) => cr.id === parseInt(locationArray[5]))
    );

    const system = useSelector((state: AppState) => state.finosecSystemState.finosecSystems).find(
        (fs) => fs.id === customerImport?.finosecSystemId
    );

    function handleBoxShadow() {
        if (!shadowAdded && window.pageYOffset > 0) {
            shadowAdded = true;
            boxShadowRef?.classList.add("add-shadow");
        } else if (shadowAdded && window.pageYOffset === 0) {
            shadowAdded = false;
            boxShadowRef?.classList.remove("add-shadow");
        }
    }

    function handleTitleFontSize(title: string): string {
        if (title.length > 50 && title.length <= 75) {
            return "shrinkTitle50";
        }
        if (title.length > 75) {
            return "shrinkTitle75";
        }
        return "";
    }

    function shouldRenderDropDownRow(): boolean {
        let shouldRender = false;
        if (headerState.dropDown?.options && headerState.dropDown2?.options) {
            shouldRender = headerState.dropDown.options.length > 0 || headerState.dropDown2.options.length > 0;
        }
        return shouldRender;
    }
    if (inIframe()) {
        return (
            <>
                {locationArray[1] === "uar" && (
                    <div className="iFrameContainer">
                        <Navbar className={"navbarIFrame"}>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <div>
                                    <p className="ps-3 pe-3">
                                        <LinkWrapper path={`/uar`}>UAR Home</LinkWrapper>
                                        {locationArray[2] === "customer-import" && (
                                            <LinkWrapper path={`/uar/customer-import/${locationArray[3]}`}>
                                                {` `}/ {`${system?.name}`}
                                            </LinkWrapper>
                                        )}
                                        {locationArray[4] === "customer-report" && (
                                            <span>
                                                {` `}/{" "}
                                                {`${customerReport?.subReportName || customerReport?.reportName}`}
                                            </span>
                                        )}
                                    </p>
                                </div>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                )}
            </>
        );
    }
    return (
        <>
            <Navbar>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div
                        id={handleTitleFontSize(headerState.title)}
                        className={window.outerWidth < 450 && headerState.dropDown ? "d-none" : "navbar-text"}
                    >
                        {decodedAccessToken.tenant_name}
                    </div>

                    {shouldRenderDropDownRow() && (
                        <Row data-testid={"dropDownRow"} className={"w-100 justify-content-end mr-1 ml-auto"}>
                            {headerState.dropDown!.options && headerState.dropDown!.options.length > 0 && (
                                <Col lg={6} className={"dropdown-column"}>
                                    <Select
                                        options={headerState.dropDown?.options}
                                        isSearchable={true}
                                        placeholder={headerState.dropDown?.placeHolder}
                                        id={"headerDropdown"}
                                        className={"header-dropdown"}
                                        onChange={headerState.dropDown?.onChanged}
                                        defaultValue={headerState.dropDown?.initialValue}
                                    />
                                </Col>
                            )}
                            {headerState.dropDown2!.options && headerState.dropDown2!.options.length > 0 && (
                                <Col lg={6} className={"dropdown-column"}>
                                    <Select
                                        options={headerState.dropDown2?.options}
                                        isSearchable={true}
                                        placeholder={headerState.dropDown2?.placeHolder}
                                        id={"headerDropdown2"}
                                        className={"header-dropdown"}
                                        onChange={headerState.dropDown2?.onChanged}
                                        defaultValue={headerState.dropDown2?.initialValue}
                                    />
                                </Col>
                            )}
                        </Row>
                    )}
                    <NavDropdown title={userName} className="header-item d-none d-lg-block">
                        {!!impersonator && (
                            <NavDropdown.Item onClick={dispatchUnimpersonateUser}>
                                Cancel Impersonation
                            </NavDropdown.Item>
                        )}
                        {/* <NavDropdown.Item as={Link} to="/app-settings">
                        App Settings
                    </NavDropdown.Item> */}
                        <NavDropdown.Item
                            onClick={() => {
                                if (!!impersonator) {
                                    dispatch(unimpersonateUser());
                                }
                                dispatch(logOut());
                            }}
                        >
                            Log Out
                        </NavDropdown.Item>
                    </NavDropdown>
                </Navbar.Collapse>
            </Navbar>
            <div data-testid={"shadowElement"} id={"shadow"} ref={(el: any) => setBoxShadowRef(el)}>
                {document.querySelector(".breadcrumb")?.textContent}
            </div>
        </>
    );
};

export default Header;
