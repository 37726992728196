import { Formik, FormikHelpers, FormikProps } from "formik";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";
import * as Yup from "yup";
import logo from "../../images/finosec_logo.png";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import KeysysSelect from "../form/select/KeysysSelect";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import { OTPSetupCredentials } from "../../models/OTPSetupCredentials";
import { useCurrentUser } from "../../hooks/currentUserHook";
import KeysysInput from "../form/input/KeysysInput";
import { useHistory } from "react-router-dom";
import { useInjection } from "../../dependencyInjection/DependencyInjectionProvider";
import { MFAUpdateModel } from "../../models/MFAUpdateModel";
import { IMFAService } from "../../services/mfaService/IMFAService";
import { TYPES } from "../../dependencyInjection/Types";
import { KeysysToastProps } from "../toast/KeysysToastProps";
import { showToast } from "../../actions/ToastActionCreators";

export default function MfaSetup() {
    const dispatch = useDispatch();
    const history = useHistory();
    let [showMFAUpdateErrorMessage, setMFAUpdateErrorMessage] = useState<boolean>(false);
    let [loginErrorStatusCode, setLoginErrorStatusCode] = useState<number>();
    let [loginErrorStatusMessage, setLoginErrorStatusMessage] = useState<string>();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin" || decodedAccessToken.roles === "PartnerAdmin";
    const user = useCurrentUser(decodedAccessToken.userId);
    const mfaService = useInjection<IMFAService>(TYPES.MFAService);

    const getInitialValues: () => OTPSetupCredentials = () => ({
        mfaOptionId: 3,
        phoneNumber: user?.phoneNumber,
        email: user?.emailAddress,
        verificationCode: undefined,
    });

    const getMFAOptions = (): KeysysSelectOption[] => {
        return [
            /*{ value: 1, label: "Email" } as KeysysSelectOption,
            { value: 2, label: "SMS" } as KeysysSelectOption,*/
            { value: 3, label: "Authenticator App" } as KeysysSelectOption,
        ];
    };

    const redirectTo = () => {
        if (!isAdmin) {
            return "/uar";
        }
        return "/users";
    };

    const handleSubmit = (values: OTPSetupCredentials, formikHelpers: FormikHelpers<OTPSetupCredentials>) => {
        const updateMFAModel: MFAUpdateModel = {
            mfaOptionId: values.mfaOptionId,
            mfaSetupComplete: true,
            subjectId: decodedAccessToken.sub,
            verificationCode: values.verificationCode,
        };
        mfaService
            .updateMFA(updateMFAModel)
            .then(() => {
                const toastProps: KeysysToastProps = {
                    name: "MFA Update",
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `MFA Updated successfully for ${user?.emailAddress}.`,
                };
                history.push(redirectTo());
                dispatch(showToast(toastProps));
            })
            .catch((e) => {
                setMFAUpdateErrorMessage(true);
            });
    };

    const mfaSetupSchema = Yup.object<OTPSetupCredentials>().shape({
        mfaOptionId: Yup.number().required("Mfa Option is required"),
        verificationCode: Yup.number().required("Verification code is required"),
    });

    return (
        <Container>
            <div className="text-center">
                <img src={logo} alt="Finosec Logo" id="loginLogo" className="mt-5" />
            </div>
            <h5 className="text-center mt-4 mb-4 font-weight-bold">Multi-factor Authentication setup.</h5>
            <Formik initialValues={getInitialValues()} validationSchema={mfaSetupSchema} onSubmit={handleSubmit}>
                {(formProps: FormikProps<OTPSetupCredentials>) => {
                    return (
                        <form id="setupMFAForm" onSubmit={formProps.handleSubmit}>
                            <Form.Group as={Col} xl="6" className="mx-auto">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"mfaOption"}
                                            placeholder={"Select MFA Option"}
                                            fieldName={"mfaOptionId"}
                                            label={"MFA (2FA) Options"}
                                            options={getMFAOptions()}
                                            multiple={false}
                                        />
                                    </Col>
                                </Row>
                                {formProps.values.mfaOptionId === 1 && (
                                    <Row className={"mb-4"}>
                                        <Col xs={12} md={{ span: 6, offset: 3 }}>
                                            <KeysysInput
                                                formProps={formProps}
                                                id={"email"}
                                                placeholder={""}
                                                fieldName={"email"}
                                                disabled={true}
                                                label={"Email Address"}
                                            />
                                        </Col>
                                    </Row>
                                )}
                                {formProps.values.mfaOptionId === 2 && (
                                    <>
                                        <Row className={"mb-4"}>
                                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"phoneNumber"}
                                                    placeholder={""}
                                                    fieldName={"phoneNumber"}
                                                    label={"Phone Number"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                <strong>
                                                    By clicking Save, you are opting in to receive MFA one-time passcode
                                                    text message from Finosec UAR App. The Finosec UAR App cannot be
                                                    accessed without this code. Carrier Message and Data rates may
                                                    apply.
                                                </strong>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                                {formProps.values.mfaOptionId === 3 && (
                                    <>
                                        <Row className={"mb-4"}>
                                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                <strong>
                                                    Please download either Goolge Authenticator or Microsoft
                                                    Authenticator. Scan the code below to set up MFA. You will need to
                                                    provide the code for verification.
                                                </strong>
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                <img src={decodedAccessToken.qr_code_image} />
                                            </Col>
                                        </Row>
                                        {/*<Row className={"mb-4"}>
                                            <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"verifyCode"}
                                                    placeholder={""}
                                                    fieldName={"verifyCode"}
                                                    label={"Verify Code"}
                                                />
                                            </Col>
                                        </Row>*/}
                                    </>
                                )}
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"verificationCode"}
                                            placeholder={""}
                                            fieldName={"verificationCode"}
                                            label={"Verification Code"}
                                        />
                                    </Col>
                                </Row>
                                <Row className="justify-content-center m-0">
                                    <Col xs="1" className="d-flex">
                                        <Button
                                            style={{ marginLeft: "120px" }}
                                            className="btn-primary ml-auto"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                                <Col xl="6" className="mx-auto">
                                    <Alert className="mt-4" variant={"danger"} hidden={!showMFAUpdateErrorMessage}>
                                        {loginErrorStatusCode === 406 ? (
                                            <p>{loginErrorStatusMessage}</p>
                                        ) : (
                                            "Invalid verification code."
                                        )}
                                    </Alert>
                                </Col>
                            </Form.Group>
                        </form>
                    );
                }}
            </Formik>
        </Container>
    );
}
