import { useRef } from "react";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { Col, Row } from "react-bootstrap";
import KeysysInput from "../form/input/KeysysInput";
import * as Yup from "yup";
import { hideModal } from "../../actions/ModalActionCreators";
import { COMPLETE_SYSTEM_REPORT_MODAL } from "../../constants/ModalConstants";
import moment from "moment";
import { editCustomerImport } from "../../actions/customerImport/CustomerImportActionCreators";
import { ICompleteSystemReportForm } from "./forms/ICompleteSystemReportForm";

export default function CompleteSystemReportForm(props: ICompleteSystemReportForm) {
    const customerImport = props.customerImport;
    const modalShow = useSelector((state: AppState) =>
        state.modalState.activeModals.includes(COMPLETE_SYSTEM_REPORT_MODAL)
    );

    const dispatch = useDispatch();

    const formikRef = useRef<FormikProps<any>>(null);

    function getInitialValues() {
        return {
            periodName: "",
        };
    }
    const masterListCount = useSelector(
        (state: AppState) => state.reportState.reports.filter((ml) => ml.finosecSystemId === props.systemId).length
    );

    const importCount = useSelector(
        (state: AppState) =>
            state.customerImportFileState.customerImportFiles.filter((f) => f.customerImportId === customerImport?.id)
                .length
    );

    const partnerSchema = Yup.object<any>().shape({
        periodName: Yup.string().max(50, "Name must be 50 characters or less").required("Period Name is required"),
        // logoUri: Yup.string().required("Logo is required"),
    });

    function clearFormAndHide(formProps: FormikProps<any> | FormikHelpers<any>): void {
        formProps.resetForm();
        dispatch(hideModal(COMPLETE_SYSTEM_REPORT_MODAL));
    }

    function handleSubmit(values: any, FormikHelpers: FormikHelpers<any>) {
        const updatedCustomerImport: any = {
            ...customerImport,
            id: customerImport?.id,
            tenantId: customerImport?.tenantId,
            finosecSystemId: customerImport?.finosecSystemId,
            periodName: values.periodName,
            completedDate: moment(),
            isComplete: true,
            isActive: true,
        };
        // Dispatch complete system report
        dispatch(editCustomerImport(customerImport, updatedCustomerImport));
        clearFormAndHide(FormikHelpers);
    }

    function getImportListCount() {
        return `${importCount}/${masterListCount}`;
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                validationSchema={partnerSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<any>) => {
                    return (
                        <VerticallyCenteredModal
                            id="completeSystemImportModal"
                            show={modalShow}
                            title="Complete System Import"
                            closeButtonText={"NO - CANCEL"}
                            okButtonText={"YES - COMPLETE"}
                            dynamicHeight={true}
                            size="sm"
                            showSaveButton={true}
                            showDeleteButton={props.type === "edit"}
                            deleteButtonText={"Deactivate"}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                            actionButtonsVertical={true}
                            type={masterListCount === importCount ? "success" : "danger"}
                        >
                            <Form id="completeSystemImportForm" data-testid={"complete-system-import-form"}>
                                {/* <Card>
                                    <Card.Body> */}
                                <div style={{ textAlign: "center" }}>
                                    <h1>{getImportListCount()}</h1>
                                </div>
                                <p>
                                    Master Lists have been imported. Completing this list will make all of the imported
                                    Master List Available to Customer Name
                                </p>
                                <Row className={"mb-4"}>
                                    <Col>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"periodName"}
                                            placeholder={"Time Period Name"}
                                            fieldName={"periodName"}
                                            label={""}
                                        />
                                    </Col>
                                </Row>
                                {/* </Card.Body>
                                </Card> */}
                                <div style={{ color: "red" }}>Are you sure you want to complete?</div>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
