import { NumberLocale } from "yup";
import { TYPES } from "../../dependencyInjection/Types";
import { Employee } from "../../models/Employee";
import { EmployeeMerge } from "../../models/EmployeeMerge";
import { IEmployeeService } from "../../services/employee/IEmployeeService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import {
    EditEmployeeAction,
    EDIT_EMPLOYEE,
    EmployeeActions,
    EmployeeFetchedAction,
    EmployeesFetchedAction,
    EMPLOYEES_FETCHED,
    EMPLOYEE_FETCHED,
    MergeEmployeeAction,
    MERGE_EMPLOYEE,
    UploadEmployeeFileAction,
    UPLOAD_EMPLOYEE_FILE,
} from "./EmployeeAction";
import { EmployeeUploadModel } from "../../components/employee/EmployeeUploadModel";

export function getEmployeesByTenantId(tenantId: number): KeysysThunkAction<void, EmployeesFetchedAction> {
    return async (dispatch, getState, container) => {
        const employeeService = container.get<IEmployeeService>(TYPES.EmployeeService);
        const employees = await employeeService.getEmployeesByTenantId(tenantId);

        if (employees) {
            dispatch<EmployeesFetchedAction>({
                type: EMPLOYEES_FETCHED,
                employees: employees,
            });
        }
    };
}

export function getEmployeeById(id: number): KeysysThunkAction<void, EmployeeFetchedAction> {
    return async (dispatch, getState, container) => {
        const employeeService = container.get<IEmployeeService>(TYPES.EmployeeService);
        const employee = await employeeService.getEmployeeById(id);

        if (employee) {
            dispatch<EmployeeFetchedAction>({
                type: EMPLOYEE_FETCHED,
                employee: employee,
            });
        }
    };
}

export function uploadEmployeeFile(
    employeeFile: EmployeeUploadModel
): KeysysThunkAction<void, UploadEmployeeFileAction> {
    return async (dispatch, getState, container) => {
        const employeeService = container.get<IEmployeeService>(TYPES.EmployeeService);
        const createdEmployeeFile = await employeeService.uploadEmployees(employeeFile);
        if (createdEmployeeFile) {
            dispatch<UploadEmployeeFileAction>({
                type: UPLOAD_EMPLOYEE_FILE,
                employees: createdEmployeeFile,
            });
        }
        return !!createdEmployeeFile;
    };
}

export function editEmployee(
    originalEmployee: Employee,
    editedEmployee: Employee
): KeysysThunkAction<void, EditEmployeeAction> {
    return async (dispatch, getState, container) => {
        const employeeService = container.get<IEmployeeService>(TYPES.EmployeeService);
        const patchedEmployee = await employeeService.editEmployee(originalEmployee, editedEmployee);
        if (patchedEmployee) {
            dispatch<EditEmployeeAction>({
                type: EDIT_EMPLOYEE,
                originalEmployee: originalEmployee,
                editedEmployee: patchedEmployee,
            });
        }
    };
}

export function mergeEmployee(
    employeeMerge: EmployeeMerge,
    tenantId: number
): KeysysThunkAction<void, EmployeeActions> {
    return async (dispatch, getState, container) => {
        const employeeService = container.get<IEmployeeService>(TYPES.EmployeeService);
        const mergedEmployee = await employeeService.mergeEmployee(employeeMerge);
        if (mergedEmployee) {
            dispatch<MergeEmployeeAction>({
                type: MERGE_EMPLOYEE,
                employeeMerge: mergedEmployee,
            });
            const employees = await employeeService.getEmployeesByTenantId(tenantId);

            if (employees) {
                dispatch<EmployeesFetchedAction>({
                    type: EMPLOYEES_FETCHED,
                    employees: employees,
                });
            }
        }
        return !!mergedEmployee;
    };
}
