import { IntakeCustomerFileState } from "../state/IntakeCustomerFileState";
import {
    CLEAR_SELECTED_INTAKECUSTOMERFILE,
    INACTIVE_INTAKECUSTOMERFILES_FETCHED,
    SELECT_INTAKECUSTOMERFILE,
    INTAKECUSTOMERFILE_CREATED,
    INTAKECUSTOMERFILE_DELETED,
    INTAKECUSTOMERFILE_EDITED,
    INTAKECUSTOMERFILE_RESTORED,
    IntakeCustomerFileActions,
    INTAKECUSTOMERFILES_FETCHED,
} from "../actions/intakeCustomerFiles/IntakeCustomerFilesActions";
import { IntakeCustomerFile } from "../models/IntakeCustomerFile";
import { replaceInCollection } from "./ReplaceInCollection";

const initialState: IntakeCustomerFileState = {
    intakeCustomerFiles: [],
    selectedIntakeCustomerFile: undefined,
};

export function intakeCustomerFileReducer(
    state: IntakeCustomerFileState = initialState,
    action: IntakeCustomerFileActions
): IntakeCustomerFileState {
    switch (action.type) {
        case INTAKECUSTOMERFILES_FETCHED:
            const sortedIntakeCustomerFiles = [...action.intakeCustomerFiles];

            return {
                ...state,
                intakeCustomerFiles: sortedIntakeCustomerFiles,
            };
        case INTAKECUSTOMERFILE_CREATED:
            return {
                ...state,
                intakeCustomerFiles: addIntakeCustomerFileAndGet(state.intakeCustomerFiles, action.intakeCustomerFile),
            };
        case INTAKECUSTOMERFILE_DELETED:
            const originalActiveIntakeCustomerFile = state.intakeCustomerFiles.find(
                (u) => u.id === action.intakeCustomerFile.id
            );

            return {
                ...state,
                intakeCustomerFiles: removeOldIntakeCustomerFileAndReplaceWithNew(
                    state.intakeCustomerFiles,
                    originalActiveIntakeCustomerFile!,
                    action.intakeCustomerFile
                ),
            };
        case INTAKECUSTOMERFILE_EDITED:
            return {
                ...state,
                intakeCustomerFiles: removeOldIntakeCustomerFileAndReplaceWithNew(
                    state.intakeCustomerFiles,
                    action.intakeCustomerFile,
                    action.newIntakeCustomerFile
                ),
            };
        case INTAKECUSTOMERFILE_RESTORED:
            const originalInactiveIntakeCustomerFile = state.intakeCustomerFiles.find(
                (u) => u.id === action.intakeCustomerFile.id
            );

            return {
                ...state,
                intakeCustomerFiles: removeOldIntakeCustomerFileAndReplaceWithNew(
                    state.intakeCustomerFiles,
                    originalInactiveIntakeCustomerFile!,
                    action.intakeCustomerFile
                ),
            };
        case SELECT_INTAKECUSTOMERFILE:
            return {
                ...state,
                selectedIntakeCustomerFile: action.intakeCustomerFile,
            };
        case CLEAR_SELECTED_INTAKECUSTOMERFILE:
            return {
                ...state,
                selectedIntakeCustomerFile: undefined,
            };
        default:
            return state;
    }
}

function removeOldIntakeCustomerFileAndReplaceWithNew(
    intakeCustomerFiles: IntakeCustomerFile[],
    originalIntakeCustomerFile: IntakeCustomerFile,
    newIntakeCustomerFile: IntakeCustomerFile
): IntakeCustomerFile[] {
    return replaceInCollection(intakeCustomerFiles, originalIntakeCustomerFile, newIntakeCustomerFile);
}

function addIntakeCustomerFileAndGet(
    intakeCustomerFiles: IntakeCustomerFile[],
    intakeCustomerFile: IntakeCustomerFile
): IntakeCustomerFile[] {
    return [...intakeCustomerFiles, intakeCustomerFile];
}
