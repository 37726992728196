import { ReportType } from "../../models/ReportType";

export const REPORT_TYPES_FETCHED = "REPORT_TYPES_FETCHED";

export interface ReportTypesFetchedAction {
    type: typeof REPORT_TYPES_FETCHED;
    reportTypes: ReportType[];
}

export type ReportTypeActions = ReportTypesFetchedAction;
