/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import Sidebar from "react-sidebar";
import { Badge, Button } from "react-bootstrap";
import arrowRight from "../../images/arrow_encircled_right.png";
import arrowLeft from "../../images/arrow_encircled_left.png";
import logout from "../../images/logout_button_icon.png";
import { useDispatch, useSelector } from "react-redux";
import { logOut, unimpersonateUser } from "../../actions/AuthenticationActionCreators";
import { AppState } from "../../state/AppState";
import { getCustomerMenuItems } from "../../actions/tenants/TenantActionCreators";
import * as FontIcons from "react-icons/fa";
import logo from "../../images/finosec_sidebar_logo.png";
import _ from "lodash";
import SidebarLink from "./SidebarLink";
import SidebarLinkGroup from "./SidebarLinkGroup";
import SidebarLinkBottomGroup from "./SidebarLinkBottomGroup";

export default function KeysysSidebar() {
    const [shouldSidebarDisplay, setShouldSidebarDisplay] = useState<boolean>(false);
    const [expandedGroup, setExpandedGroup] = useState<string | undefined>();
    const isGroupExpanded = useCallback((groupName: string) => expandedGroup === groupName, [expandedGroup]);
    const [sidebarRootIndex, setSidebarRootIndex] = useState<
        "sidebar-root-under" | "sidebar-root-group-expanded" | "sidebar-root-above"
    >("sidebar-root-under");
    const dispatch = useDispatch();

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin" || decodedAccessToken.roles === "PartnerAdmin";
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const isPartnerAdmin = decodedAccessToken.roles === "PartnerAdmin";
    const isCustomer = decodedAccessToken.roles === "CustomerAdmin";
    const { impersonator } = useSelector((state: AppState) => state.userState);
    const isReadOnlyCustomer = decodedAccessToken.roles === "ReadOnlyCustomer";
    const hasUARAccess = true;

    const customerMenuItems = useSelector((state: AppState) =>
        isCustomer
            ? _.orderBy(
                  state.tenantState.customerMenuItems.filter((item) => item.tenantId === decodedAccessToken.tenant_id),
                  (item) => item.ordinal
              )
            : []
    );
    const customerDocumentStatistics = useSelector((state: AppState) =>
        state.customerDocumentStatisticsState.customerDocumentStatistics.filter((cds) =>
            state.tenantState.tenants.map((t) => t.id).includes(cds.customerTenantId)
        )
    );
    const reduceStatistics = customerDocumentStatistics.reduce(
        (result: any, statistic: any) => {
            return {
                totalDocumentsAdminReview: result.totalDocumentsAdminReview + statistic.totalDocumentsAdminReview,
                totalDocumentsCustomerReview:
                    result.totalDocumentsCustomerReview + statistic.totalDocumentsCustomerReview,
            };
        },
        {
            totalDocumentsAdminReview: 0,
            totalDocumentsCustomerReview: 0,
        }
    );

    useEffect(() => {
        if (shouldSidebarDisplay && sidebarRootIndex !== "sidebar-root-above") {
            setSidebarRootIndex("sidebar-root-above");
        }
        if (sidebarRootIndex === "sidebar-root-above") {
            setTimeout(() => setSidebarRootIndex("sidebar-root-under"), 300);
        }
    }, [shouldSidebarDisplay]);

    useEffect(() => {
        if (decodedAccessToken && isCustomer) {
            dispatch(getCustomerMenuItems(decodedAccessToken.tenant_id));
        }
    }, [isCustomer]);

    function onSetSidebarOpen(): void {
        setExpandedGroup(undefined);
        setShouldSidebarDisplay((x) => !x);
    }

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const Icon = (props: any) => {
        const icons: any = FontIcons;
        const FontIcon = icons[props.icon];
        return FontIcon ? <FontIcon /> : <></>;
    };

    const onSidebarLinkClick = useCallback(() => {
        if (shouldSidebarDisplay) {
            setShouldSidebarDisplay(false);
        }
        if (expandedGroup) {
            setExpandedGroup(undefined);
        }
        if (sidebarRootIndex !== "sidebar-root-under") {
            setSidebarRootIndex("sidebar-root-under");
        }
    }, [expandedGroup, shouldSidebarDisplay, sidebarRootIndex]);

    const onSidebarGroupClick = useCallback(
        (groupName: string) => {
            if (!expandedGroup || expandedGroup !== groupName) {
                if (!shouldSidebarDisplay) {
                    setSidebarRootIndex("sidebar-root-group-expanded");
                }
                setExpandedGroup(groupName);
            } else {
                setExpandedGroup(undefined);
            }
        },
        [expandedGroup, shouldSidebarDisplay]
    );

    const onSubmenuOverlayClick = () => {
        setExpandedGroup(undefined);
        setSidebarRootIndex("sidebar-root-under");
    };

    const SidebarContent = ({ isExpanded = true }: any) => {
        return (
            <>
                <div style={{ width: "100%" }}>
                    <ul className={"menu-item-list"}>
                        {/*<SidebarLink
                            isExpanded={isExpanded}
                            onClick={onSidebarLinkClick}
                            path="/users"
                            iconClass="fas fa-user"
                            hoverText="User Management"
                        >
                            User MGMT
                        </SidebarLink>*/}

                        {/*isGlobalAdmin && (
                            <>
                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/reports"
                                    iconClass="fas fa-file-alt"
                                    hoverText="Report Builder"
                                >
                                    Report Builder
                                </SidebarLink>

                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/dashboards"
                                    iconClass="fas fa-file-alt"
                                    hoverText="Dashboard Builder"
                                >
                                    Dashboard Builder
                                </SidebarLink>

                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/import"
                                    iconClass="fas fa-file-import"
                                    hoverText="Import Manager"
                                >
                                    Import MGR
                                </SidebarLink>
                            </>
                        )*/}
                        {(isCustomer || isGlobalAdmin) && (
                            <SidebarLinkGroup
                                isSidebarExpanded={isExpanded}
                                isGroupExpanded={isGroupExpanded}
                                onClick={onSidebarGroupClick}
                                groupName="Account Management"
                                links={[
                                    {
                                        onClick: onSidebarLinkClick,
                                        path: "/employees",
                                        iconClass: "fas fa-users",
                                        children: "Employees",
                                        hoverText: "Employees",
                                    },
                                    /* This will be added at a later time
                                {
                                    onClick: onSidebarLinkClick,
                                    path: "/employeechangereport",
                                    iconClass: "fas fa-users",
                                    children: "Employee Changes",
                                    hoverText: "Employee Changes",
                                }, */
                                    {
                                        onClick: onSidebarLinkClick,
                                        path: "/systemaccessreport",
                                        iconClass: "fas fa-clipboard-list",
                                        children: "Employee System List",
                                        hoverText: "Employee System List",
                                    },
                                    {
                                        onClick: onSidebarLinkClick,
                                        path: "/employeesystemexception",
                                        iconClass: "fas fa-exclamation-circle",
                                        children: "System Account Exceptions",
                                        hoverText: "System Account Exceptions",
                                    },
                                ]}
                                iconClass="fas fa-user-cog"
                                hoverText="Employees"
                            />
                        )}
                        {isAdmin && (
                            <>
                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/documents"
                                    iconClass="fas fa-exchange-alt"
                                    hoverText="Document Exchange"
                                >
                                    <strong>
                                        Document
                                        <br /> Exchange{" "}
                                    </strong>
                                    <Badge className="document-exchange-count-badge" bg="primary">
                                        {reduceStatistics.totalDocumentsAdminReview}
                                    </Badge>
                                </SidebarLink>
                            </>
                        )}
                        {(isCustomer || isReadOnlyCustomer) && (
                            <>
                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={() => setShouldSidebarDisplay(false)}
                                    path={`/document-exchange/customer-documents/${decodedAccessToken.tenant_id}`}
                                    iconClass="fas fa-exchange-alt"
                                    hoverText="Document Exchange"
                                >
                                    <strong>Document Exchange</strong>
                                </SidebarLink>
                            </>
                        )}
                        {isCustomer && (
                            <>
                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/customerintakereview"
                                    iconClass="fas fa-file-contract"
                                    hoverText="System Report Intake"
                                >
                                    <strong>System Report Intake</strong>
                                </SidebarLink>
                            </>
                        )}

                        {(isCustomer || isReadOnlyCustomer) && hasUARAccess && (
                            <>
                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/uar"
                                    iconClass="fas fa-chart-line"
                                    hoverText="User Access Reports"
                                >
                                    <strong>User Access Reports</strong>
                                </SidebarLink>
                            </>
                        )}
                        {isPartnerAdmin && (
                            <>
                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/uar-overview"
                                    iconClass="fas fa-chart-line"
                                    hoverText="User Access Reports Overview"
                                >
                                    <strong>User Access Reports Overview</strong>
                                </SidebarLink>
                                <SidebarLink
                                    isExpanded={isExpanded}
                                    onClick={onSidebarLinkClick}
                                    path="/admincustomerintakereview"
                                    iconClass="fas fa-file-contract"
                                    hoverText="Customer Intake Manager"
                                >
                                    <strong>Customer Intake Manager</strong>
                                </SidebarLink>
                            </>
                        )}
                        {isCustomer &&
                            customerMenuItems.map((item) => (
                                <li key={item.id}>
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={item.url.includes("//") ? item.url : `//${item.url}`}
                                        style={{ textDecoration: "none" }}
                                        title={item.name || ""}
                                    >
                                        <div className="d-flex align-items-center">
                                            <span className={`icon-container ${isExpanded ? "expanded" : ""}`}>
                                                <i
                                                    className="fas sidebar-image-icon"
                                                    style={{
                                                        color: "#eaf2ee",
                                                        fontSize: "1.3rem",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {" "}
                                                    <Icon icon={item.icon} />{" "}
                                                </i>
                                            </span>
                                            {isExpanded && <div className="menu-item-with-plus-icon">{item.name}</div>}
                                        </div>
                                    </a>
                                </li>
                            ))}
                    </ul>
                    <ul className={"menu-item-list menu-item-list-bottom"}>
                        {isGlobalAdmin && (
                            <>
                                <SidebarLinkBottomGroup
                                    isSidebarExpanded={isExpanded}
                                    isGroupExpanded={isGroupExpanded}
                                    onClick={onSidebarGroupClick}
                                    groupName="System Admin"
                                    links={[
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/users",
                                            iconClass: "fas fa-users",
                                            children: "Users",
                                            hoverText: "Users",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/tenants",
                                            iconClass: "fas fa-clipboard-list",
                                            children: "Tenants",
                                            hoverText: "Tenants",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/uar-overview",
                                            iconClass: "fas fa-file-alt",
                                            children: "User Access Reports Overview",
                                            hoverText: "User Access Reports Overview",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/reports",
                                            iconClass: "fas fa-file-alt",
                                            children: "Report Builder",
                                            hoverText: "Report Builder",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/dashboards",
                                            iconClass: "fas fa-clipboard-list",
                                            children: "Dashboard Builder",
                                            hoverText: "Dashboard Builder",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/import",
                                            iconClass: "fas fa-file-import",
                                            children: "Import Manager",
                                            hoverText: "Import Manager",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/intakesystemfile",
                                            iconClass: "fas fa-file-invoice",
                                            children: "Intake File Builder",
                                            hoverText: "Intake File Builder",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/admincustomerintakereview",
                                            iconClass: "fas fa-file-contract",
                                            children: "Customer Intake Manager",
                                            hoverText: "Customer Intake Manager",
                                        },
                                    ]}
                                    iconClass="fas fa-cogs"
                                    hoverText="Admin"
                                />
                            </>
                        )}
                        {isPartnerAdmin && (
                            <>
                                <SidebarLinkBottomGroup
                                    isSidebarExpanded={isExpanded}
                                    isGroupExpanded={isGroupExpanded}
                                    onClick={onSidebarGroupClick}
                                    groupName="System Admin"
                                    links={[
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/users",
                                            iconClass: "fas fa-users",
                                            children: "Users",
                                            hoverText: "Users",
                                        },
                                        {
                                            onClick: onSidebarLinkClick,
                                            path: "/tenants",
                                            iconClass: "fas fa-clipboard-list",
                                            children: "Tenants",
                                            hoverText: "Tenants",
                                        },
                                    ]}
                                    iconClass="fas fa-cogs"
                                    hoverText="Admin"
                                />
                            </>
                        )}
                        {isCustomer && (
                            <SidebarLinkGroup
                                isSidebarExpanded={isExpanded}
                                isGroupExpanded={isGroupExpanded}
                                onClick={onSidebarGroupClick}
                                groupName="Admin"
                                links={[
                                    {
                                        onClick: onSidebarLinkClick,
                                        path: "/users",
                                        iconClass: "fas fa-users",
                                        children: "Users",
                                        hoverText: "Users",
                                    },
                                ]}
                                iconClass="fas fa-user-cog"
                                hoverText="Users"
                            />
                        )}
                    </ul>
                </div>
                {isExpanded && (
                    <>
                        <Button
                            className={"sidebar-menu-logout-button"}
                            onClick={() => {
                                if (!!impersonator) {
                                    dispatch(unimpersonateUser());
                                }
                                dispatch(logOut());
                            }}
                        >
                            <img src={logout} className={"logout-icon"} alt={"Box with arrow pointing up and out"} />
                            Log Out
                        </Button>
                        <Button className={"sidebar-menu-arrow-button"} onClick={onSetSidebarOpen} title={"Close Menu"}>
                            <img src={arrowLeft} alt={"Encircled arrow facing left"} />
                        </Button>
                        <div className={"sidebar-logo-border"}></div>
                        <div className={"sidebar-menu-logo-button"}>
                            <div id="keysys-menu-footer-logo">
                                <div>
                                    <img
                                        alt={"Company logo"}
                                        src={logo}
                                        style={{ maxWidth: "165px", marginBottom: "5px" }}
                                    />
                                </div>
                                <p className={"sidebar-logo-align"}>
                                    {"\u00A9"} {new Date().getFullYear()} FINOSEC, INC.
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };
    if (inIframe()) {
        return null;
    }
    return (
        <Sidebar
            rootId={"Sidebar"}
            rootClassName={sidebarRootIndex}
            contentClassName={"sidebar-dock"}
            overlayClassName={"sidebar-overlay"}
            sidebarClassName={"sidebar-menu"}
            open={shouldSidebarDisplay}
            onSetOpen={onSetSidebarOpen}
            sidebar={<SidebarContent isExpanded={shouldSidebarDisplay} />}
            touch={false}
        >
            {!shouldSidebarDisplay && expandedGroup && (
                <div className="submenu-overlay" onClick={onSubmenuOverlayClick} />
            )}
            <div className={"dock-container"}>
                <Button className={"sidebar-dock-hamburger-button"} onClick={onSetSidebarOpen} title={"Open Menu"}>
                    <i style={{ color: "#cccccc" }} className={"fas fa-bars"} />
                </Button>
                <div className="collapsed-content">
                    <SidebarContent isExpanded={shouldSidebarDisplay} />
                </div>
                <Button className={"sidebar-dock-arrow-button"} onClick={onSetSidebarOpen} title={"Open Menu"}>
                    <img src={arrowRight} alt={"Encircled arrow facing right"} />
                </Button>
            </div>
        </Sidebar>
    );
}
