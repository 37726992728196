import { AuthenticateActions } from "./AuthenticateActions";
import { CustomerReportActions } from "./customerReport/CustomerReportAction";
import { ModalActionTypes } from "./ModalActions";
import { ReportAttributeActions } from "./reportAttributes/ReportAttributeAction";
import { ReportActions } from "./reports/ReportActions";
import { RoleActions } from "./RoleActions";
import { TabActionTypes } from "./TabActions";
import { TenantActions } from "./tenants/TenantActions";
import { ToastActionTypes } from "./ToastActions";
import { UserActions } from "./users/UserActions";
import { WorkflowFrequencyActions } from "./workflowFrequency/WorkflowFrequencyActions";
import { WorkflowJobActions } from "./workflowJob/WorkflowJobActions";
import { WorkflowJobScheduleActions } from "./workflowJobSchedule/WorkflowJobScheduleActions";

export const RESET_STATE = "Reset State";

export interface ResetStateAction {
    type: typeof RESET_STATE;
}

export type KeysysActions =
    | AuthenticateActions
    | ModalActionTypes
    | RoleActions
    | TabActionTypes
    | TenantActions
    | ToastActionTypes
    | UserActions
    | ReportActions
    | ReportAttributeActions
    | CustomerReportActions
    | WorkflowFrequencyActions
    | WorkflowJobActions
    | WorkflowJobScheduleActions
    | ResetStateAction;
