import { FILE_STATUSES_FETCHED, FileStatusesFetchedAction } from "./FileStatusActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { IFileStatusService } from "../../services/fileStatus/IFileStatusService";

export function getFileStatuses(): KeysysThunkAction<void, FileStatusesFetchedAction> {
    return async (dispatch, getState, container) => {
        const fileStatusService = container.get<IFileStatusService>(TYPES.FileStatusService);
        const statuses = await fileStatusService.getFileStatuses();

        if (statuses) {
            dispatch<FileStatusesFetchedAction>({
                type: FILE_STATUSES_FETCHED,
                statuses: statuses,
            });
        }
    };
}
