import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { authenticationReducer } from "../reducers/AuthenticationReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { headerReducer } from "../reducers/HeaderReducer";
import { modalReducer } from "../reducers/ModalReducer";
import { roleReducer } from "../reducers/RoleReducer";
import { servicesContainer } from "../services/IOC/ServicesContainer";
import { tabReducer } from "../reducers/TabReducer";
import { toastReducer } from "../reducers/ToastReducer";
import { userReducer } from "../reducers/UserReducer";
import { tenantReducer } from "../reducers/tenant/TenantReducer";
import { reportReducer } from "../reducers/report/ReportReducer";
import { reportAttributeReducer } from "../reducers/reportAttribute/ReportAttributeReducer";
import { subReportReducer } from "../reducers/subReport/SubReportReducer";
import { subReportAttributeReducer } from "../reducers/subReportAttribute/SubReportAttributeReducer";
import { awsFileReducer } from "../reducers/AwsFileReducer";
import { finosecSystemReducer } from "../reducers/finosecSystem/FinosecSystemReducer";
import { finosecSystemStatisticReducer } from "../reducers/finosecSystemStatistic/FinosecSystemStatisticReducer";
import { customerImportReducer } from "../reducers/customerImport/CustomerImportReducer";
import { customerImportFileReducer } from "../reducers/customerImportFile/CustomerImportFileReducer";
import { customerReportReducer } from "../reducers/customerReport/CustomerReportReducer";
import { customerDocumentReducer } from "../reducers/customerDocument/CustomerDocumentReducer";
import { fileStatusReducer } from "../reducers/fileStatus/FileStatusReducer";
import { fileTypeReducer } from "../reducers/fileType/FileTypeReducer";
import { customerDocumentStatisticsReducer } from "../reducers/customerDocument/CustomerDocumentStatisticsReducer";
import { subReportCategoryReducer } from "../reducers/subReportCategory/SubReportCategoryReducer";
import { customerReportAttributeReducer } from "../reducers/customerReportAttribute/customerReportAttributeReducer";
import { reportTypeReducer } from "../reducers/reportType/reportTypeReducer";
import { employeeReducer } from "../reducers/employee/employeeReducer";
import { employeeCoreIdReducer } from "../reducers/employeeCoreId/employeeCoreIdReducer";
import { employeeSamAccountReducer } from "../reducers/employeeSamAccount/employeeSamAccountReducer";
import { customerReportDisplayReducer } from "../reducers/customerReportDisplay/CustomerReportDisplayReducer";
import { systemAccountTypeReducer } from "../reducers/systemAccountType/systemAccountTypeReducer";
import { employeeSystemReducer } from "../reducers/employeeSystem/employeeSystemReducer";
import { employeeSystemExceptionReducer } from "../reducers/employeeSystemException/employeeSystemExceptionReducer";
import { customerImportSubReportAssignmentReducer } from "../reducers/customerImportSubReportAssignment/customerImportSubReportAssignmentReducer";
import { intakeSystemFileReducer } from "../reducers/IntakeSystemFileReducer";
import { intakeCustomerFileReducer } from "../reducers/IntakeCustomerFileReducer";
import { workflowJobScheduleReducer } from "../reducers/workflowJobSchedule/WorkflowJobScheduleReducer";
import { workflowFrequencyReducer } from "../reducers/workflowFrequency/WorkflowFrequencyReducer";
import { workflowJobReducer } from "../reducers/workflowJob/WorkflowJobReducer";
import { workflowStatusReducer } from "../reducers/workflowStatus/WorkflowStatusReducer";
import { workflowNotificationTemplateReducer } from "../reducers/workflowNotificationTemplate/WorkflowNotificationTemplateReducer";

const mapTransformer = (config) => {
    return createTransform(
        (map) => JSON.stringify(Array.from(map)),
        (arrayString) => new Map(JSON.parse(arrayString)),
        config
    );
};

const persistConfig = {
    key: "root",
    storage: storage,
    stateReconciler: autoMergeLevel2, // need to investigate this more
    transforms: [mapTransformer({ whitelist: ["roleState", "userState", "modalState", "tenantState"] })],
    // we can whitelist or blacklist substates
};

const combinedReducer = combineReducers({
    authenticationState: authenticationReducer,
    awsFileState: awsFileReducer,
    headerState: headerReducer,
    modalState: modalReducer,
    reportState: reportReducer,
    reportAttributeState: reportAttributeReducer,
    roleState: roleReducer,
    tabState: tabReducer,
    tenantState: tenantReducer,
    toastState: toastReducer,
    userState: userReducer,
    subReportState: subReportReducer,
    subReportCategoryState: subReportCategoryReducer,
    subReportAttributeState: subReportAttributeReducer,
    finosecSystemState: finosecSystemReducer,
    finosecSystemStatisticsState: finosecSystemStatisticReducer,
    customerImportState: customerImportReducer,
    customerImportFileState: customerImportFileReducer,
    customerReportState: customerReportReducer,
    customerDocumentState: customerDocumentReducer,
    customerDocumentStatisticsState: customerDocumentStatisticsReducer,
    fileStatusState: fileStatusReducer,
    fileTypeState: fileTypeReducer,
    customerReportAttributeState: customerReportAttributeReducer,
    reportTypeState: reportTypeReducer,
    customerReportDisplayState: customerReportDisplayReducer,
    employeeState: employeeReducer,
    employeeCoreIdState: employeeCoreIdReducer,
    employeeSamAccountState: employeeSamAccountReducer,
    systemAccountTypeState: systemAccountTypeReducer,
    employeeSystemState: employeeSystemReducer,
    employeeSystemExceptionState: employeeSystemExceptionReducer,
    customerImportSubReportAssignmentState: customerImportSubReportAssignmentReducer,
    intakeSystemFileState: intakeSystemFileReducer,
    intakeCustomerFileState: intakeCustomerFileReducer,
    workflowJobScheduleState: workflowJobScheduleReducer,
    workflowFrequencyState: workflowFrequencyReducer,
    workflowJobState: workflowJobReducer,
    workflowStatusState: workflowStatusReducer,
    workflowNotificationTemplateState: workflowNotificationTemplateReducer,
});

const rootReducer = (state, action) => {
    if (action.type === "LOGOUT" || action.type === "Reset State") {
        state = undefined;
    }
    return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducer,
    undefined,
    composeWithDevTools(applyMiddleware(thunk.withExtraArgument(servicesContainer)))
);

export const persistor = persistStore(store);
