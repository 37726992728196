/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFinosecSystems } from "../actions/finosecSystem/FinosecSystemActionCreator";
import { deleteReport, getReports } from "../actions/reports/ReportActionCreators";
import { deleteSubReport, getSubReports } from "../actions/subReports/SubReportActionCreators";
import { selectMappedSystemsWithReports } from "../selectors/ReportSelectors";
import { AppState } from "../state/AppState";

export const useReportList = () => {
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState('');

    const systems  = useSelector((state: AppState) => selectMappedSystemsWithReports(state, searchString.toLowerCase()));

    useEffect(() => {
        if(!systems || systems.length === 0) {
            // Dispatch
            dispatch(getReports());
            dispatch(getSubReports());
            dispatch(getFinosecSystems());
        }
    }, []);

    const removeReport = (report: any, reportType: string) => {
        if(reportType === 'Sub Report') {
            // Delete Sub Report
            dispatch(deleteSubReport(report))
        }
        if (reportType === 'Master Report') {
            // Delete Master Report
            dispatch(deleteReport(report))
        }
    }

    return {
        systems: (systems.length > 0 ? systems : []),
        removeReport,
        setSearchString
    };
}
