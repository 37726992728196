import { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { Col, Row, Form as BootstrapForm, Button } from "react-bootstrap";
import KeysysInput from "../form/input/KeysysInput";
import { IDocumentDetailsModal } from "./forms/IDocumentDetailsModal";
import { CustomerDocument } from "../../models/CustomerDocument";
import {
    deleteCustomerDocument,
    editCustomerDocument,
    pushDocumentToMonarch,
} from "../../actions/customerDocument/CustomerDocumentActionCreators";
import { getFileStatuses } from "../../actions/fileStatus/FileStatusActionCreators";
import { showToast } from "../../actions/ToastActionCreators";
import { getPreSignedUrl, downloadFileFromS3WithPreSignedUrl } from "../../helper-functions";
import { DOCUMENT_EXCHANGE_BUCKET_NAME } from "../../config/config";
import { PreSignedUrlType } from "../../models/PreSignedUrlType";

export default function DocumentDetailsModal(props: IDocumentDetailsModal) {
    const { customerDocument, status, programReference, onClose } = props;
    const { accessToken } = useSelector((state: AppState) => state.authenticationState);
    const [commentsInput, setCommentsInput] = useState<string>("");
    const [monarchFileNameInput, setMonarchFileNameInput] = useState<string>("");
    const [statusInput, setStatusInput] = useState<string>("");

    useEffect(() => {
        if (status) {
            setStatusInput(status);
        }
    }, [status]);

    useEffect(() => {
        dispatch(getFileStatuses());
    }, []);

    useEffect(() => {
        if (customerDocument) {
            setCommentsInput(customerDocument.comments || "");
        }
    }, [customerDocument]);

    const dispatch = useDispatch();
    const selectFileStatuses = (state: AppState) => state.fileStatusState.statuses;
    const fileStatuses = useSelector(selectFileStatuses);

    const formikRef = useRef<FormikProps<any>>(null);

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const user = `${decodedAccessToken.given_name} ${decodedAccessToken.family_name}`;
    const loggedInUserRole = decodedAccessToken.roles;
    const isCustomerAdmin = loggedInUserRole === "CustomerAdmin";
    const isGlobalAdmin = loggedInUserRole === "GlobalAdmin";
    const isUserAccessReport = customerDocument && programReference === "User Access Report";
    const approvedStatus = fileStatuses.find((status) => status.name === "Approved");
    const deniedStatus = fileStatuses.find((status) => status.name === "Denied");

    const getInitialValues = useCallback((): any => {
        if (customerDocument)
            return {
                description: customerDocument.documentDescription,
                status,
                programReference,
                adminReviewedBy: customerDocument.adminReviewedBy,
                adminReviewDate: customerDocument.adminReviewDate,
                customerReviewedBy: customerDocument.customerReviewedBy,
                customerReviewDate: customerDocument.customerReviewDate,
                fileName: customerDocument.fileName,
            };
        return {
            description: "",
            status: "",
            programReference: "",
            adminReviewedBy: "",
            adminReviewDate: "",
            customerReviewedBy: "",
            customerReviewDate: "",
            fileName: "",
        };
    }, [customerDocument, status, programReference]);

    function reviewIsRequired(): boolean {
        if (!customerDocument) {
            return false;
        }
        if (isCustomerAdmin && customerDocument.customerReviewRequired && !customerDocument.customerReviewDate) {
            return true;
        }
        if (!isCustomerAdmin && customerDocument.adminReviewRequired && !customerDocument.adminReviewDate) {
            return true;
        }
        return false;
    }

    function markFileAsReviewed(): void {
        if (isCustomerAdmin) {
            const updatedDocument: CustomerDocument = {
                ...customerDocument,
                customerReviewedBy: user,
                customerReviewDate: moment().format("MM/DD/YYYY HH:mm:ss"),
                comments: commentsInput.trim(),
            };
            dispatch(editCustomerDocument(customerDocument, updatedDocument));
        } else {
            const updatedDocument: CustomerDocument = {
                ...customerDocument,
                adminReviewedBy: user,
                adminReviewDate: moment().format("MM/DD/YYYY HH:mm:ss"),
                comments: commentsInput.trim(),
            };
            dispatch(editCustomerDocument(customerDocument, updatedDocument));
        }
    }

    function approvePushToMonarch(): void {
        if (customerDocument?.awsFileId) {
            dispatch(
                pushDocumentToMonarch(
                    customerDocument.awsFileId,
                    monarchFileNameInput.trim(),
                    customerDocument.tenantId
                )
            );
        }
        if (approvedStatus) {
            const updatedDocument: CustomerDocument = {
                ...customerDocument,
                monarchPushBy: user,
                monarchPushDate: moment().format("MM/DD/YYYY HH:mm:ss"),
                fileStatusId: approvedStatus.id,
                comments: commentsInput.trim(),
            };
            dispatch(editCustomerDocument(customerDocument, updatedDocument));
            setStatusInput("Approved");
        }
    }

    function denyToMonarch(): void {
        if (deniedStatus) {
            const updatedDocument: CustomerDocument = {
                ...customerDocument,
                monarchPushBy: user,
                monarchPushDate: moment().format("MM/DD/YYYY HH:mm:ss"),
                fileStatusId: deniedStatus.id,
                comments: commentsInput.trim(),
            };
            dispatch(editCustomerDocument(customerDocument, updatedDocument));
            setStatusInput("Denied");
        }
    }

    function handleDelete(): void {
        dispatch(deleteCustomerDocument(customerDocument));
        onClose();
    }

    async function handleDownload(): Promise<void> {
        if (customerDocument?.awsFileId && customerDocument?.fileName) {
            try {
                const preSignedUrlType = PreSignedUrlType.Download;

                const preSignedUrl = await getPreSignedUrl(
                    accessToken,
                    customerDocument.awsFileId,
                    DOCUMENT_EXCHANGE_BUCKET_NAME!,
                    preSignedUrlType
                );
                await downloadFileFromS3WithPreSignedUrl(
                    customerDocument.fileName,
                    preSignedUrl,
                    customerDocument.contentHash
                );
            } catch (error) {
                dispatch(
                    showToast({
                        name: "DOWNLOAD_S3_ERROR",
                        titleInHeader: "Error",
                        body:
                            "Download failed. Please try again. If the problem continues, contact the system administrator.",
                        theme: "danger",
                    })
                );
            }
        }
    }

    function saveComments(): void {
        const updatedDocument: CustomerDocument = {
            ...customerDocument,
            comments: commentsInput.trim(),
        };
        dispatch(editCustomerDocument(customerDocument, updatedDocument));
        onClose();
    }

    if (customerDocument)
        return (
            <>
                <Formik
                    innerRef={formikRef}
                    initialValues={getInitialValues()}
                    onSubmit={saveComments}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<any>) => {
                        return (
                            <VerticallyCenteredModal
                                id="documentDetailsModal"
                                show={true}
                                title=""
                                closeButtonText={"Close"}
                                okButtonText={"Save Comments"}
                                dynamicHeight={true}
                                size="lg"
                                showSaveButton={true}
                                onCloseButtonClick={onClose}
                                onOkButtonClick={formProps.submitForm}
                                actionButtonsVertical={false}
                            >
                                <Form id="documentDetailsModal" data-testid={"document-details-modal"}>
                                    <div style={{ textAlign: "center" }}>
                                        <h4>Document Details</h4>
                                    </div>
                                    <Row>
                                        <Col>
                                            <Row className="mb-2">
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"description"}
                                                    placeholder={""}
                                                    fieldName={"description"}
                                                    label={"Description"}
                                                    disabled={true}
                                                />
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <BootstrapForm.Label>Status</BootstrapForm.Label>
                                                    <BootstrapForm.Control value={statusInput} readOnly disabled />
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"programReference"}
                                                    placeholder={""}
                                                    fieldName={"programReference"}
                                                    label={"Program Reference"}
                                                    disabled={true}
                                                />
                                            </Row>
                                        </Col>
                                        {isGlobalAdmin && (
                                            <Col>
                                                <BootstrapForm.Label>Comments</BootstrapForm.Label>
                                                <BootstrapForm.Control
                                                    as="textarea"
                                                    rows={6}
                                                    value={commentsInput}
                                                    onChange={(e) => setCommentsInput(e.target.value)}
                                                />
                                            </Col>
                                        )}
                                    </Row>
                                    {!isGlobalAdmin && (
                                        <Row className="mb-1">
                                            <Col>
                                                <BootstrapForm.Label>Comments</BootstrapForm.Label>
                                                <BootstrapForm.Control
                                                    as="textarea"
                                                    rows={3}
                                                    value={commentsInput}
                                                    onChange={(e) => setCommentsInput(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className={"mb-1"}>
                                        <Col>
                                            {!isCustomerAdmin && (
                                                <Row className="mb-1">
                                                    <Col>
                                                        <BootstrapForm.Label>Admin Reviewed By</BootstrapForm.Label>
                                                        <BootstrapForm.Control
                                                            value={`${customerDocument.adminReviewedBy || ""} ${
                                                                customerDocument.adminReviewDate
                                                                    ? `(${moment(
                                                                          customerDocument.adminReviewDate
                                                                      ).format("MM/DD/YYYY hh:mm a")})`
                                                                    : ""
                                                            }`}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                            <Row className="mb-1">
                                                <Col>
                                                    <BootstrapForm.Label>Customer Reviewed By</BootstrapForm.Label>
                                                    <BootstrapForm.Control
                                                        value={`${customerDocument.customerReviewedBy || ""} ${
                                                            customerDocument.customerReviewDate
                                                                ? `(${moment(
                                                                      customerDocument.customerReviewDate
                                                                  ).format("MM/DD/YYYY hh:mm a")})`
                                                                : ""
                                                        }`}
                                                        disabled
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        {isGlobalAdmin && isUserAccessReport && (
                                            <Col>
                                                {customerDocument.monarchPushDate ? (
                                                    <>
                                                        <BootstrapForm.Label>Monarch Decision</BootstrapForm.Label>
                                                        <BootstrapForm.Control
                                                            value={`${customerDocument.monarchPushBy} ${moment(
                                                                customerDocument.monarchPushDate
                                                            ).format("MM/DD/YYYY hh:mm a")}`}
                                                            disabled
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <BootstrapForm.Label>Monarch File Name</BootstrapForm.Label>
                                                        <BootstrapForm.Control
                                                            value={monarchFileNameInput}
                                                            onChange={(e) => setMonarchFileNameInput(e.target.value)}
                                                        />
                                                        {monarchFileNameInput.trim() === "" && (
                                                            <div>
                                                                <BootstrapForm.Text className="text-danger">
                                                                    File name required to approve
                                                                </BootstrapForm.Text>
                                                            </div>
                                                        )}
                                                        <Button
                                                            className="mt-2 finosec-button-info w-50"
                                                            onClick={approvePushToMonarch}
                                                            disabled={monarchFileNameInput.trim() === ""}
                                                        >
                                                            Approve
                                                        </Button>
                                                        <Button
                                                            className="mt-2 w-50"
                                                            variant="danger"
                                                            style={{ color: "white" }}
                                                            onClick={denyToMonarch}
                                                        >
                                                            Deny
                                                        </Button>
                                                    </>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <KeysysInput
                                                formProps={formProps}
                                                id={"fileName"}
                                                placeholder={""}
                                                fieldName={"fileName"}
                                                label={"File Name"}
                                                disabled={true}
                                            />
                                        </Col>
                                        {isGlobalAdmin && <Col />}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="success"
                                                className="w-100"
                                                onClick={markFileAsReviewed}
                                                disabled={!reviewIsRequired()}
                                            >
                                                Mark File As Reviewed
                                            </Button>
                                            {reviewIsRequired() && (
                                                <div className="text-danger text-center">Review Required</div>
                                            )}

                                            <Button className="w-50 mt-2 finosec-button-info" onClick={handleDownload}>
                                                Download File
                                            </Button>
                                            {(isGlobalAdmin || isCustomerAdmin) && (
                                                <Button className="w-50 mt-2" variant="danger" onClick={handleDelete}>
                                                    Delete File
                                                </Button>
                                            )}
                                        </Col>
                                        {isGlobalAdmin && <Col />}
                                    </Row>
                                </Form>
                            </VerticallyCenteredModal>
                        );
                    }}
                </Formik>
            </>
        );

    return null;
}
