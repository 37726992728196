import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { IHttpClient } from "../HttpClient/IHttpClient";
import { IDashboardService } from "./IDashboardService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { Dashboard } from "../../models/Dashboard";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import { ADD_DASHBOARD_SUCCESS, DEACTIVATE_DASHBOARD_SUCCESS, EDIT_DASHBOARD_SUCCESS } from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";

@injectable()
export class DashboardService implements IDashboardService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createDashboard(dashboard: Dashboard): Promise<void | Dashboard> {
        return this.httpClient
            .post<Dashboard>("/dashboards", dashboard)
            .then((t) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_DASHBOARD_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Dashboard "${t.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a dashboard. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editDashboard(dashboard:Dashboard, newDashboard: Dashboard): Promise<void|Dashboard>{
        const operations = createPatch(dashboard, newDashboard);
        return this.httpClient
            .patch<Dashboard>(`/dashboards/${dashboard.id}`, operations)
            .then((patchedDashboard) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_DASHBOARD_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedDashboard.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedDashboard;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing dashboard "${dashboard.name}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteDashboard(dashboard: Dashboard): Promise<void | Dashboard> {
        return this.httpClient
            .delete(`/dashboards/${dashboard.id}`)
            .then(() => {
                const returnDashboard = { ...dashboard, isActive: !dashboard.isActive }; 
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_DASHBOARD_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Dashboard "${dashboard.name}" ${dashboard.isActive?" deactivated":" reactivated"} successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnDashboard;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the dashboard. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getDashboards(): Promise<void | Dashboard[]> {
        return this.httpClient
            .get<Dashboard[]>(`/dashboards`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving dashboards. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getDashboardById(id: number): Promise<void | Dashboard> {
        return this.httpClient
            .get<Dashboard>(`/dashboards/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving the dashboard. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}
