import { useParams } from "react-router-dom";
import { Col, Row, Table, ListGroup } from "react-bootstrap";
import { Notes } from "../notes/Notes";
import { forwardRef, useEffect, useRef } from "react";
import _ from "lodash";
import { ColDef } from "ag-grid-community";
import finosecPDFLogo from "../../images/User Access Reporting Powered By FINOSEC.png";
import { useDispatch, useSelector } from "react-redux";
import { useCustomerImportReportList } from "../../hooks/customerReportsHook";
import { AppState } from "../../state/AppState";
import { Chart } from "react-google-charts";
import { useCustomerImportsList } from "../../hooks/customerImportsHook";
import { useFinosecSystemStatisticsByImportId } from "../../hooks/customerFinosecSystemStatisticHook";
import { getAllCustomerReportReviews } from "../../actions/customerReport/CustomerReportActionCreator";
import LinkWrapper from "../routing/LinkWrapper";
import moment from "moment";
import { CustomerImportFile } from "../../models/CustomerImportFile";
import { getTenants } from "../../actions/tenants/TenantActionCreators";
import { getFinosecSystems } from "../../actions/finosecSystem/FinosecSystemActionCreator";
import { usePdfNotes } from "../../hooks/notesPdfHook";
import "../customerReport/customerPrintModal.scss";
import "../customerReport/printModal.scss";
import "./pdfReport.scss";
import { getSubReports } from "../../actions/subReports/SubReportActionCreators";
import { getSubReportCategories } from "../../actions/subReportCategories/SubReportCategoryActionCreators";
import { getCustomerReportDisplays } from "../../actions/customerReportDisplay/CustomerReportDisplayActionCreator";

export const CustomerImportSummary = () => {
    let params = useParams<{ customerImportId: any; tenantId: any }>();
    const customerImportId = +params.customerImportId;
    const ref = useRef();
    const dispatch = useDispatch();
    useCustomerImportReportList(customerImportId);
    const { customerImportFiles } = useCustomerImportsList(+params.tenantId, customerImportId);
    const filteredCustomerImportFiles = customerImportFiles.filter(
        (cif) => cif.customerImportId === customerImport?.id
    ) as any[];
    const { importStatistics } = useFinosecSystemStatisticsByImportId(customerImportId);
    const customerImport = useSelector((state: AppState) =>
        state.customerImportState.customerImports.find((ci) => ci.id === customerImportId)
    );
    const tenants = useSelector((state: AppState) => state.tenantState.tenants);
    const periodName = customerImport?.periodName;
    const tenantName = tenants.find((t) => t.id === +params.tenantId)?.name;
    const systems = useSelector((state: AppState) => state.finosecSystemState.finosecSystems);
    const system = systems.find((fs) => fs.id === customerImport?.finosecSystemId);
    var customerReports = useSelector((state: AppState) =>
        _.orderBy(state.customerReportState?.customerReports, (cr) => cr.subReportName || cr.reportName)
    );
    const tableData = customerReports as any[];
    const systemName = system?.name;
    const { customerReportReviews, customerReportReviewsLoading, loading } = useSelector(
        (state: AppState) => state.customerReportState
    );
    const isLoadingCustomerReportDisplays = useSelector((state: AppState) => state.customerReportDisplayState.loading);
    const isLoadingSubReportCategories = useSelector((state: AppState) => state.subReportCategoryState.loading);
    const isLoadingSubReports = useSelector((state: AppState) => state.subReportState.loading);
    const isLoadingCustomerReports = useSelector((state: AppState) => state.customerReportState?.loading);
    const { notes, notesLoading } = usePdfNotes(customerImportId);

    /*var isLoading =
        notesLoading ||
        isLoadingCustomerReports ||
        customerReportReviewsLoading ||
        loading ||
        isLoadingCustomerReportDisplays ||
        isLoadingSubReportCategories ||
        isLoadingSubReports;*/
    const isLoading = isLoadingCustomerReports || notesLoading;

    useEffect(() => {
        if (!tenants || tenants.length === 0) {
            dispatch(getTenants());
        }
    }, [tenants, dispatch]);

    useEffect(() => {
        if (!systems || systems.length === 0) {
            dispatch(getFinosecSystems());
        }
    }, [systems, dispatch]);

    const getReportSummaryLink = (data: any) => {
        return `/uar/customer-import/${params.customerImportId}/customer-report/${data?.data?.id}`;
    };

    useEffect(() => {
        dispatch(getAllCustomerReportReviews());
        dispatch(getSubReports());
        dispatch(getSubReportCategories());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCustomerReportDisplays(+params.tenantId));
    }, [params.tenantId, dispatch]);

    const columnDefs: ColDef[] = [
        {
            headerName: "Name",
            cellRendererFramework: (data: any) => {
                return (
                    <LinkWrapper path={getReportSummaryLink(data)}>
                        <span style={{ textDecorationLine: "underline" }}>
                            {data?.data?.subReportName || data?.data?.reportName || "Undefined"}
                        </span>
                    </LinkWrapper>
                );
            },
            valueGetter: (params) => {
                return params?.data?.subReportName || params?.data?.reportName;
            },
            sort: "asc",
        },
        /* Commenting out and not taking out completely in case they do want this back
        {
            headerName: "User Accounts Changed",
            cellRendererFramework: (data: any) => (
                <span>
                    {data?.data?.isSubReport && !data?.data?.isReadOnlySubReport ? data?.data?.totalDisplayedRows : ""}
                </span>
            ),
            valueGetter: (params) => {
                return params?.data?.totalDisplayedRows;
            },
        },
        {
            headerName: "User Accounts Reviewed",
            cellRendererFramework: (data: any) => (
                <span>
                    {data?.data?.isSubReport && !data?.data?.isReadOnlySubReport ? data?.data?.rowsReviewed : ""}
                </span>
            ),
        },*/
        {
            headerName: "Review Complete",
            cellRendererFramework: (data: any) => {
                const crrsForReport = customerReportReviews.filter((x) => x.customerReportId === data?.data?.id);
                const reviewedCRRs = crrsForReport.filter((x) => x.isReviewed);
                const unreviewedCRRs = crrsForReport.filter((x) => !x.isReviewed);
                const isReviewComplete: boolean =
                    (data?.data.isReviewComplete && unreviewedCRRs.length < 1) ||
                    (data?.data.totalDisplayedRows === reviewedCRRs.length && data?.data.totalDisplayedRows > 0);

                return (
                    <span>
                        {data?.data?.isSubReport && !data?.data?.isReadOnlySubReport
                            ? isReviewComplete
                                ? "Yes"
                                : "No"
                            : ""}
                    </span>
                );
            },
            valueGetter: (params) => {
                return params?.data?.isReviewComplete ? "Yes" : "No";
            },
            maxWidth: 130,
        },
        {
            headerName: "Review Complete Date",
            cellRendererFramework: (data: any) => {
                const crrsForReport = customerReportReviews.filter((x) => x.customerReportId === data?.data?.id);
                const reviewedCRRs = crrsForReport.filter((x) => x.isReviewed);
                const unreviewedCRRs = crrsForReport.filter((x) => !x.isReviewed);
                const isReviewComplete: boolean =
                    (data?.data.isReviewComplete && unreviewedCRRs.length < 1) ||
                    (data?.data.totalDisplayedRows === reviewedCRRs.length && data?.data.totalDisplayedRows > 0);

                return (
                    <span>
                        {data?.data?.isSubReport &&
                        !data?.data?.isReadOnlySubReport &&
                        isReviewComplete &&
                        data?.data?.lastModifiedOn
                            ? moment(data?.data?.lastModifiedOn).format("MM-DD-YYYY")
                            : ""}
                    </span>
                );
            },
            valueGetter: (params) => {
                return params?.data?.isReviewComplete ? moment(params?.data?.lastModifiedOn).format("MM-DD-YYYY") : "";
            },
            maxWidth: 175,
        },
        /* Not removing it in case they want it back
        {
            headerName: "Review Complete By",
            cellRendererFramework: (data: any) => {
                const crrsForReport = customerReportReviews.filter((x) => x.customerReportId === data?.data?.id);
                const reviewedCRRs = crrsForReport.filter((x) => x.isReviewed);
                const unreviewedCRRs = crrsForReport.filter((x) => !x.isReviewed);
                const isReviewComplete: boolean =
                    (data?.data.isReviewComplete && unreviewedCRRs.length < 1) ||
                    (data?.data.totalDisplayedRows === reviewedCRRs.length && data?.data.totalDisplayedRows > 0);

                return (
                    <span>
                        {data?.data?.isSubReport && !data?.data?.isReadOnlySubReport && isReviewComplete
                            ? data?.data?.reviewCompleteByName
                            : ""}
                    </span>
                );
            },
            maxWidth: 250,
        },*/
        {
            headerName: "Notes/Flags",
            cellRendererFramework: (data: any) => (
                <span>
                    {data?.data?.hasNotes ? (
                        <i
                            className={"far fa-file-alt"}
                            style={{ fontSize: "1rem", textAlign: "center", color: "#980101" }}
                        />
                    ) : (
                        ""
                    )}
                    &nbsp;
                    {data?.data?.hasFlags ? (
                        <i
                            className={"fas fa-flag"}
                            style={{ fontSize: "1rem", textAlign: "center", color: "#980101" }}
                        />
                    ) : (
                        ""
                    )}
                </span>
            ),
            maxWidth: 130,
        },
    ];
    const ReferingComponent = forwardRef((props: any, ref: any) => {
        return (
            <div>
                <table
                    style={{
                        width: "100%",
                        tableLayout: "fixed",
                        overflowWrap: "break-word",
                    }}
                >
                    <thead>
                        <tr>
                            {columnDefs.map((cd) => (
                                <th key={cd.headerName} style={{ backgroundColor: "grey" }}>
                                    {cd.headerName}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((td, idx) => (
                            <tr key={idx}>
                                {columnDefs.map((cd) => (
                                    <td
                                        key={`${idx}-${cd.headerName}`}
                                        style={cd.headerName === "Name" ? { textAlign: "start" } : {}}
                                    >
                                        {cd.cellRendererFramework
                                            ? cd.cellRendererFramework({ data: td })
                                            : cd.valueGetter
                                            ? (cd.valueGetter as any)({ data: td })
                                            : td[cd.field as any]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    });
    return (
        <div ref={ref as any} className="print-modal-content pdf-page">
            <Row>
                <Col>
                    <h6>System: {systemName}</h6>
                    <h6>Customer: {tenantName}</h6>
                    <h6>Time Frame: {periodName}</h6>
                    <h6>Export Date: {new Date().toLocaleDateString()}</h6>
                    <h6>User Access Reviews Approved By:</h6>
                    <h6>Approved Date: </h6>
                </Col>
                <Col>
                    <img src={finosecPDFLogo} style={{ width: "50%", marginLeft: "260px" }} alt="Finosec logo" />
                </Col>
            </Row>
            <Row className="ps-3 pe-3">
                <Col>
                    <Table hover>
                        <thead style={{ borderStyle: "hidden" }}>
                            <tr>
                                <td colSpan={2}>User Access Reports Summary</td>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Commenting out and not taking out completely in case they do want this back
                <tr>
                    <td>Total User Account Changes:</td>
                    <td>{importStatistics.totalUserChanges}</td>
                </tr> */}
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Report Count:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {importStatistics.totalSubReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Reports Reviewed:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {importStatistics.totalReviewedReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Reports Remaining to Review:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {importStatistics.totalNotReviewedReports}
                                </td>
                            </tr>
                            <tr>
                                <td className="tr-user-access-reports-summary-left">Percent Complete:</td>
                                <td className="tr-user-access-reports-summary-right">
                                    {Math.round(
                                        (importStatistics.totalReviewedReports / importStatistics.totalSubReports) * 100
                                    )}
                                    %
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <Table hover>
                        <thead style={{ borderStyle: "hidden" }}>
                            <tr>
                                <td>Review Progress</td>
                            </tr>
                        </thead>
                    </Table>
                    <Row className="justify-content-center">
                        <Chart
                            width={"500px"}
                            height={"300px"}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ["Task", ""],
                                ["Total Reports Reviewed", importStatistics.totalReviewedReports],
                                ["Total Reports Not Reviewed", importStatistics.totalNotReviewedReports],
                            ]}
                            options={{
                                backgroundColor: "transparent",
                                slices: {
                                    0: { color: "#079247" },
                                    1: { color: "#CCCCCC", textStyle: { color: "#000000" } },
                                },
                            }}
                            rootProps={{ "data-testid": "1" }}
                        />
                    </Row>
                </Col>
            </Row>
            <h6>
                System Report Generated:
                <ListGroup variant="flush">
                    {filteredCustomerImportFiles.map((cif: CustomerImportFile & { reportName: string }) => (
                        <ListGroup.Item key={cif.id}>
                            <span style={{ fontSize: "14px" }}>
                                {cif.reportName} ({moment(cif.exportDate).format("MM/DD/YYYY")})
                            </span>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </h6>
            <div className="pdfPageBreak" />
            <p className="title-bar ps-3 pt-2 pb-2"> </p>
            <Notes noScroll={true} displayOnly={true} notes={notes.filter((n) => n.customerReportId === 0)} />
            <ReferingComponent
                getRowId={(data: any) => data.Row}
                data={tableData}
                columnDefs={columnDefs}
                style={{ fontSize: "3rem" }}
            />
            {!isLoading && (
                <div id="Loaded" style={{ display: "none" }}>
                    Loaded
                </div>
            )}
        </div>
    );
};
