import { EmployeeCoreIdActions, EMPLOYEE_CORE_IDS_FETCHED } from "../../actions/employeeCoreId/EmployeeCoreIdAction";
import { EmployeeCoreId } from "../../models/EmployeeCoreId";
import { EmployeeCoreIdState } from "../../state/EmployeeCoreIdState";

const initialState: EmployeeCoreIdState = {
    employeeCoreIds: [],
};

export function employeeCoreIdReducer(
    state: EmployeeCoreIdState = initialState,
    action: EmployeeCoreIdActions
): EmployeeCoreIdState {
    switch (action.type) {
        case EMPLOYEE_CORE_IDS_FETCHED: {
            return {
                ...state,
                employeeCoreIds: action.employeeCoreIds as EmployeeCoreId[],
            };
        }
        default:
            return state;
    }
}
