import React from "react";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { VerticallyCenteredModalProps } from "./VerticallyCenteredModalProps";

export default function VerticallyCenteredModal(props: VerticallyCenteredModalProps) {
    let actionButtonStyle = {};
    if (props.actionButtonsVertical) {
        actionButtonStyle = {
            width: "100%",
        };
    }

    const colorMap: any = {
        danger: {
            background: "#f8d7da",
            buttonHeader: "red",
            line: "red",
        },
    };
    const type = props.type || "defualt";
    const colorScheme = colorMap[type];

    const getSize = () => {
        if (!props.size) return "xl";
        if (props.size === "md") return undefined;
        return props.size;
    };

    return (
        <Modal
            id={props.id}
            size={getSize()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={props.show}
            backdrop={"static"}
        >
            <Modal.Header style={colorScheme ? { backgroundColor: colorScheme.buttonHeader } : {}}>
                <Modal.Title id="contained-modal-title-vcenter modal-title">{props.title}</Modal.Title>
                {props.showButtonsInHeader && (
                    <div style={{ margin: "-0.5rem -0.5rem -0.5rem auto" }}>
                        <OverlayTrigger
                            key={props.okButtonText}
                            placement={"top"}
                            overlay={
                                props.okButtonText ? (
                                    <Tooltip id={`tooltip-${props.okButtonText}`}>{props.okButtonText}</Tooltip>
                                ) : (
                                    <span />
                                )
                            }
                        >
                            <i
                                onClick={() => props.onOkButtonClick()}
                                className={props.showButtonsInHeader.okButtonIcon}
                                style={
                                    props.disableUpdate
                                        ? { marginRight: "10px", fontSize: "20px", color: "grey" }
                                        : { marginRight: "10px", cursor: "pointer", fontSize: "20px" }
                                }
                            ></i>
                        </OverlayTrigger>

                        <button
                            onClick={() => props.onCloseButtonClick()}
                            type="button"
                            className="btn-close btn-close-white"
                            aria-label="Close"
                        ></button>
                    </div>
                )}
            </Modal.Header>
            <Modal.Body
                className={!props.dynamicHeight ? "fixed-height-body" : ""}
                style={colorScheme ? { backgroundColor: colorScheme.background } : {}}
            >
                {props.children}
            </Modal.Body>
            <Modal.Footer
                style={
                    colorScheme
                        ? { borderTop: `1px solid ${colorScheme.line}`, backgroundColor: colorScheme.background }
                        : {}
                }
            >
                {/* <ButtonToolbar className="w-100"> */}
                {!!props.showDeleteButton && props.showDeleteButton && (
                    <Button
                        variant={"danger"}
                        size={"lg"}
                        onClick={props.onDeleteButtonClick}
                        style={actionButtonStyle}
                        // style={{ backgroundColor: "#6b7172", color: "white" }}
                    >
                        {props.deleteButtonText}
                    </Button>
                )}
                {!!props.showRestoreButton && props.showRestoreButton && (
                    <Button
                        variant={"primary"}
                        size={"lg"}
                        onClick={props.onRestoreButtonClick}
                        style={actionButtonStyle}
                    >
                        {props.restoreButtonText}
                    </Button>
                )}
                {props.okButtonComponent}
                {!!props.showSaveButton && props.showSaveButton && !props.okButtonComponent && (
                    <Button
                        size={"lg"}
                        onClick={props.onOkButtonClick}
                        disabled={!!props.disableUpdate ? props.disableUpdate : false}
                        className="finosec-button-info"
                        style={{
                            ...actionButtonStyle,
                            backgroundColor: colorScheme ? colorScheme.buttonHeader : "#204ea0",
                        }}
                    >
                        {props.okButtonText}
                    </Button>
                )}
                {/* &nbsp; */}
                <Button
                    variant={"dark"}
                    size={"lg"}
                    onClick={props.onCloseButtonClick}
                    style={{ backgroundColor: "#6b7172", ...actionButtonStyle }}
                >
                    {props.closeButtonText}
                </Button>
                {/* </ButtonToolbar> */}
            </Modal.Footer>
        </Modal>
    );
}
