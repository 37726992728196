export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SSO_URL = process.env.REACT_APP_SSO_URL;
export const SSO_REDIRECT_URL = process.env.REACT_APP_SSO_REDIRECT_URL;
export const SSO_POST_LOGOUT_URL = process.env.REACT_APP_SSO_POST_LOGOUT_URL;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const S3_URL = process.env.REACT_APP_S3_URL;
export const DOCUMENT_EXCHANGE_BUCKET_NAME = process.env.REACT_APP_DOCUMENT_EXCHANGE_BUCKET_NAME;
export const DOCUMENT_EXCHANGE_S3_URL = process.env.REACT_APP_DOCUMENT_EXCHANGE_S3_URL;
export const CUSTOMER_INTAKE_BUCKET_NAME = process.env.REACT_APP_CUSTOMER_INTAKE_BUCKET_NAME;
export const CUSTOMER_INTAKE_S3_URL = process.env.REACT_APP_CUSTOMER_INTAKE_S3_URL;
export const CUSTOMER_IMPORT_BUCKET_NAME = process.env.REACT_APP_CUSTOMER_IMPORT_BUCKET_NAME;
export const CUSTOMER_IMPORT_S3_URL = process.env.REACT_APP_CUSTOMER_IMPORT_S3_URL;
export const CUSTOMER_IMPORT_FILE_PAGE_SIZE = process.env.REACT_APP_CUSTOMER_IMPORT_FILE_PAGE_SIZE;
export const EMPLOYEE_UPLOAD_BUCKET_NAME = process.env.REACT_APP_EMPLOYEE_UPLOAD_BUCKET_NAME;
