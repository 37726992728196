import { TYPES } from "../../dependencyInjection/Types";
import { ISystemAccountTypeService } from "../../services/systemAccountType/ISystemAccountTypeService";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { SystemAccountTypeFetchedAction, SYSTEM_ACCOUNT_TYPES_FETCHED } from "./SystemAccountTypeAction";

export function getSystemAccountTypes(): KeysysThunkAction<void, SystemAccountTypeFetchedAction> {
    return async (dispatch, getState, container) => {
        const systemAccountTypeService = container.get<ISystemAccountTypeService>(TYPES.SystemAccountTypeService);
        const systemAccountTypes = await systemAccountTypeService.getSystemAccountTypes();

        if (systemAccountTypes) {
            dispatch<SystemAccountTypeFetchedAction>({
                type: SYSTEM_ACCOUNT_TYPES_FETCHED,
                systemAccountTypes: systemAccountTypes,
            });
        }
    };
}
