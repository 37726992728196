export const ADD_OR_EDIT_ACCOUNT_MODAL = "ADD_OR_EDIT_ACCOUNT_MODAL";
export const ADD_OR_EDIT_ALTERNATIVE_MODAL = "ADD_OR_EDIT_ALTERNATIVE_MODAL";
export const ADD_OR_EDIT_BUSINESS_STATE_MODAL = "ADD_OR_EDIT_BUSINESS_STATE_MODAL";
export const ADD_OR_EDIT_COMMONALITY_MODAL = "ADD_OR_EDIT_COMMONALITY_MODAL";
export const ADD_OR_EDIT_COMPANY_CRITERIA_MODAL = "ADD_OR_EDIT_COMPANY_CRITERIA_MODAL";
export const ADD_OR_EDIT_CONTACT_MODAL = "ADD_OR_EDIT_CONTACT_MODAL";
export const ADD_OR_EDIT_EVALUATOR_MODAL = "ADD_OR_EDIT_EVALUATOR_MODAL";
export const ADD_OR_EDIT_DECISION_HISTORY_MODAL = "ADD_OR_EDIT_DECISION_HISTORY_MODAL";
export const ADD_OR_EDIT_DECISION_STAGE_MODAL = "ADD_OR_EDIT_DECISION_STAGE_MODAL";
export const ADD_OR_EDIT_OPPORTUNITY_MODAL = "ADD_OR_EDIT_OPPORTUNITY_MODAL";
export const ADD_OR_EDIT_PRODUCT_CRITERIA_MODAL = "ADD_OR_EDIT_PRODUCT_CRITERIA_MODAL";
export const ADD_OR_EDIT_PRODUCT_SUPPORT_COMPANY_EXPENSE_MODAL = "ADD_OR_EDIT_PRODUCT_SUPPORT_COMPANY_EXPENSE_MODAL";
export const ADD_OR_EDIT_ROLE_MODAL = "ADD_OR_EDIT_ROLE_MODAL";
export const ADD_OR_EDIT_SUPPORT_CRITERIA_MODAL = "ADD_OR_EDIT_SUPPORT_CRITERIA_MODAL";
export const ADD_OR_EDIT_TASK_MODAL = "ADD_OR_EDIT_TASK_MODAL";
export const ADD_OR_EDIT_TENANT_MODAL = "ADD_OR_EDIT_TENANT_MODAL";
export const COMPLETE_SYSTEM_REPORT_MODAL = "COMPLETE_SYSTEM_REPORT_MODAL";
export const ADD_OR_EDIT_EXPENSE_CRITERIA_MODAL = "ADD_OR_EDIT_EXPENSE_CRITERIA_MODAL";
export const CONFIRM_DELETE_ACCOUNT_MODAL = "CONFIRM_DELETE_ACCOUNT_MODAL";
export const CONFIRM_DELETE_ALTERNATIVE_MODAL = "CONFIRM_DELETE_ALTERNATIVE_MODAL";
export const CONFIRM_DELETE_BUSINESS_STATE_MODAL = "CONFIRM_DELETE_BUSINESS_STATE_MODAL";
export const CONFIRM_DELETE_COMMONALITY_MODAL = "CONFIRM_DELETE_COMMONALITY_MODAL";
export const CONFIRM_DELETE_COMPANY_CRITERIA_MODAL = "CONFIRM_DELETE_COMPANY_CRITERIA_MODAL";
export const CONFIRM_DELETE_CONTACT_MODAL = "CONFIRM_DELETE_CONTACT_MODAL";
export const CONFIRM_DELETE_DECISION_HISTORY_MODAL = "CONFIRM_DELETE_DECISION_HISTORY_MODAL";
export const CONFIRM_DELETE_DECISION_STAGE_MODAL = "CONFIRM_DELETE_DECISION_STAGE_MODAL";
export const CONFIRM_DELETE_EVALUATOR_MODAL = "CONFIRM_DELETE_EVALUATOR_MODAL";
export const CONFIRM_DELETE_EXPENSE_CRITERIA_MODAL = "CONFIRM_DELETE_EXPENSE_CRITERIA_MODAL";
export const CONFIRM_DELETE_OPPORTUNITY_MODAL = "CONFIRM_DELETE_OPPORTUNITY_MODAL";
export const CONFIRM_DELETE_PRODUCT_CRITERIA_MODAL = "CONFIRM_DELETE_PRODUCT_CRITERIA_MODAL";
export const CONFIRM_DELETE_PRODUCT_SUPPORT_COMPANY_EXPENSE_MODAL =
    "CONFIRM_DELETE_PRODUCT_SUPPORT_COMPANY_EXPENSE_MODAL";
export const CONFIRM_DELETE_ROLE_MODAL = "CONFIRM_DELETE_ROLE_MODAL";
export const CONFIRM_DELETE_SUPPORT_CRITERIA_MODAL = "CONFIRM_DELETE_SUPPORT_CRITERIA_MODAL";
export const CONFIRM_DELETE_TASK_MODAL = "CONFIRM_DELETE_TASK_MODAL";
export const CONFIRM_DELETE_TENANT_MODAL = "CONFIRM_DELETE_TENANT_MODAL";
export const CONFIRM_DELETE_CUSTOMER_IMPORT_FILE_MODAL = "CONFIRM_DELETE_CUSTOMER_IMPORT_FILE_MODAL";
export const CONFIRM_RESTORE_CONTACT_MODAL = "CONFIRM_RESTORE_CONTACT_MODAL";
export const CONFIRM_RESTORE_ROLE_MODAL = "CONFIRM_RESTORE_ROLE_MODAL";
export const CONFIRM_DELETE_REPORT_MODAL = "CONFIRM_DELETE_REPORT_MODAL";
export const IMP0RT_CONFIRM_MODAL = "IMP0RT_CONFIRM_MODAL";
export const IMPORT_DETAILS_MODAL = "IMPORT_DETAILS_MODAL";
export const CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL = "CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL";
export const CONFIRM_DELETE_DASHBOARD_MODAL = "CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL";
export const UPLOAD_EMPLOYEE_CONFIRM_MODAL = "UPLOAD_EMPLOYEE_CONFIRM_MODAL";
