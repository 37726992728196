import { CustomerImportFileState } from "../../state/CustomerImportFileState";
import {
    CustomerImportFileActions,
    CUSTOMER_IMPORT_FILES_FETCHED,
    CREATE_CUSTOMER_IMPORT_FILE,
    DELETE_CUSTOMER_IMPORT_FILE,
    FETCH_CUSTOMER_IMPORT_FILE,
    FETCH_CUSTOMER_IMPORT_JSON_FILE,
} from "../../actions/customerImportFile/CustomerImportFileActions";
import { replaceInCollection } from "./../ReplaceInCollection";

const initialState: CustomerImportFileState = {
    customerImportFiles: [],
    customerImportJsonFile: [],
};

export function customerImportFileReducer(
    state: CustomerImportFileState = initialState,
    action: CustomerImportFileActions
): CustomerImportFileState {
    switch (action.type) {
        case CUSTOMER_IMPORT_FILES_FETCHED: {
            return {
                ...state,
                customerImportFiles: action.customerImportFiles,
            };
        }

        case CREATE_CUSTOMER_IMPORT_FILE:
            return {
                ...state,
                customerImportFiles: [...state.customerImportFiles, action.customerImportFile],
            };

        case FETCH_CUSTOMER_IMPORT_FILE:
            return {
                ...state,
                customerImportFiles: [...state.customerImportFiles, action.customerImportFile],
            };

        case DELETE_CUSTOMER_IMPORT_FILE:
            const originalActiveCustomerImportFile = state.customerImportFiles.find(
                (r) => r.id === action.customerImportFile.id
            );

            return {
                ...state,
                customerImportFiles: replaceInCollection(
                    state.customerImportFiles,
                    originalActiveCustomerImportFile!,
                    action.customerImportFile
                ),
            };
        case FETCH_CUSTOMER_IMPORT_JSON_FILE:
            return {
                ...state,
                customerImportJsonFile: action.customerImportJsonFile,
            };
        default:
            return state;
    }
}
