import { createSelector } from "reselect";
import { AppState } from "../state/AppState";

export const selectCustomerImports = (state: AppState) => state.customerImportState?.customerImports || [];
export const selectCustomerImportFiles = (state: AppState) => state.customerImportFileState?.customerImportFiles || [];
const selectId = (state: any, reportId: number | null) => reportId;

export const selectCustomerImportsByCustomerId = createSelector(
    [selectCustomerImports, selectId],
    (customerImports, customerId) => {
        return customerImports.filter((ci) => ci.tenantId === customerId && ci.isCurrent);
    }
);
