import { ITenantService } from "../../services/tenant/ITenantService";
import {
    TENANTS_FETCHED,
    TenantsFetchedAction,
    CREATE_TENANT,
    CreateTenantAction,
    DELETE_TENANT,
    DeleteTenantAction,
    EDIT_TENANT,
    EditTenantAction,
    ParentTenantFetchedAction,
    PARENT_TENANT_FETCHED,
    CustomerMenuItemsFetchedAction,
    CUSTOMER_MENU_ITEMS_FETCHED,
    CreateCustomerMenuItemAction,
    CREATE_CUSTOMER_MENU_ITEM,
    EDIT_CUSTOMER_MENU_ITEM,
    EditCustomerMenuItemAction,
    DELETE_CUSTOMER_MENU_ITEM,
} from "./TenantActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { Tenant } from "../../models/Tenant";
import { CustomerMenuItemCreate, ICustomerMenuItem } from "../../models/CustomerMenuItem";

export function getTenants(): KeysysThunkAction<void, TenantsFetchedAction> {
    return async (dispatch, getState, container) => {
        const tenantService = container.get<ITenantService>(TYPES.TenantService);
        const tenants = await tenantService.getTenants();

        if (tenants) {
            dispatch<TenantsFetchedAction>({
                type: TENANTS_FETCHED,
                tenants: tenants,
            });
        }
    };
}

export function getParentTenant(id: number): KeysysThunkAction<void, ParentTenantFetchedAction> {
    return async (dispatch, getState, container) => {
        const tenantService = container.get<ITenantService>(TYPES.TenantService);
        const tenant = await tenantService.getTenantById(id);

        if (tenant) {
            dispatch<ParentTenantFetchedAction>({
                type: PARENT_TENANT_FETCHED,
                parentTenant: tenant,
            });
        }
    };
}

export function createTenant(tenant: Tenant, customerMenuItems?: ICustomerMenuItem[]): KeysysThunkAction<void, CreateTenantAction> {
    return async (dispatch, getState, container) => {
        const tenantService = container.get<ITenantService>(TYPES.TenantService);
        const createdTenant = await tenantService.createTenant(tenant);
        if (createdTenant) {
            dispatch<CreateTenantAction>({
                type: CREATE_TENANT,
                tenant: createdTenant,
            });
            if (customerMenuItems && customerMenuItems?.length > 0) {
                for (let i = 0; i < customerMenuItems.length; i++) {
                    const customerMenuItem = await tenantService.createCustomerMenuItem({
                        ...customerMenuItems[i],
                        tenantId: createdTenant.id,
                    });
                    if (customerMenuItems) {
                        dispatch<any>({
                            type: CREATE_CUSTOMER_MENU_ITEM,
                            customerMenuItem,
                        });
                    }
                }
            }
        }
        return !!createTenant;
    };
}

export function deleteTenant(tenant: Tenant): KeysysThunkAction<void, DeleteTenantAction> {
    return async (dispatch, getState, container) => {
        const tenantService = container.get<ITenantService>(TYPES.TenantService);
        const deletedTenant = await tenantService.deleteTenant(tenant);
        if (deletedTenant) {
            dispatch<DeleteTenantAction>({
                type: DELETE_TENANT,
                tenant: deletedTenant,
            });
        }
    };
}

export function editTenant(
    originalTenant: Tenant,
    newTenant: Tenant,
    customerMenuItemsToCreate?: CustomerMenuItemCreate[],
    customerMenuItemsToEdit?: ICustomerMenuItem[],
    customerMenuItemsToDelete?: ICustomerMenuItem[],
    originalCustomerMenuItems?: ICustomerMenuItem[]
): KeysysThunkAction<void, EditTenantAction> {
    return async (dispatch, getState, container) => {
        const tenantService = container.get<ITenantService>(TYPES.TenantService);
        const patchedTenant = await tenantService.editTenant(originalTenant, newTenant);
        if (patchedTenant) {
            dispatch<EditTenantAction>({
                type: EDIT_TENANT,
                originalTenant: originalTenant,
                newTenant: patchedTenant,
            });
            if (customerMenuItemsToCreate && customerMenuItemsToCreate?.length > 0) {
                for (let i = 0; i < customerMenuItemsToCreate.length; i++) {
                    const customerMenuItem = await tenantService.createCustomerMenuItem({
                        ...customerMenuItemsToCreate[i],
                        tenantId: patchedTenant.id,
                    });
                    if (customerMenuItemsToCreate) {
                        dispatch<any>({
                            type: CREATE_CUSTOMER_MENU_ITEM,
                            customerMenuItem,
                        });
                    }
                }
            }
            if (customerMenuItemsToEdit && customerMenuItemsToEdit?.length > 0 && originalCustomerMenuItems) {
                for (let i = 0; i < customerMenuItemsToEdit.length; i++) {
                    const originalCustomerMenuItem = originalCustomerMenuItems?.find(
                        (item) => item.id === customerMenuItemsToEdit[i].id
                    );
                    if (originalCustomerMenuItem) {
                        const customerMenuItem = await tenantService.editCustomerMenuItem(
                            originalCustomerMenuItem,
                            customerMenuItemsToEdit[i]
                        );
                        if (customerMenuItemsToCreate) {
                            dispatch<any>({
                                type: EDIT_CUSTOMER_MENU_ITEM,
                                newCustomerMenuItem: customerMenuItem,
                                customerMenuItem: originalCustomerMenuItem,
                            });
                        }
                    }
                }
            }
            if (customerMenuItemsToDelete && customerMenuItemsToDelete?.length > 0) {
                for (let i = 0; i < customerMenuItemsToDelete.length; i++) {
                    const customerMenuItem = await tenantService.deleteCustomerMenuItem(customerMenuItemsToDelete[i]);
                    if (customerMenuItem) {
                        dispatch<any>({
                            type: DELETE_CUSTOMER_MENU_ITEM,
                            customerMenuItem: customerMenuItemsToDelete[i],
                        });
                    }
                }
            }
        }
    };
}

export function getCustomerMenuItems(tenantId: number): KeysysThunkAction<void, CustomerMenuItemsFetchedAction> {
    return async (dispatch, getState, container) => {
        const tenantService = container.get<ITenantService>(TYPES.TenantService);
        const customerMenuItems = await tenantService.getCustomerMenuItems(tenantId);

        if (customerMenuItems) {
            dispatch<CustomerMenuItemsFetchedAction>({
                type: CUSTOMER_MENU_ITEMS_FETCHED,
                customerMenuItems: customerMenuItems,
            });
        }
    };
}
