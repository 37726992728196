import { SubReportAttributeState } from "../../state/SubReportAttributeState";
import {
    CREATE_SUB_REPORT_ATTRIBUTE,
    DELETE_SUB_REPORT_ATTRIBUTE,
    EDIT_SUB_REPORT_ATTRIBUTE,
    SubReportAttributeActions,
    SUB_REPORT_ATTRIBUTES_FETCHED,
} from "../../actions/subReportAttributes/SubReportAttributeAction";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: SubReportAttributeState = {
    subReportAttributes: [],
};

export function subReportAttributeReducer(
    state: SubReportAttributeState = initialState,
    action: SubReportAttributeActions
): SubReportAttributeState {
    switch (action.type) {
        case SUB_REPORT_ATTRIBUTES_FETCHED: {
            return {
                ...state,
                subReportAttributes: [
                    ...state.subReportAttributes.filter(
                        (sra) => !(action.subReportAttributes || []).map((asra) => asra.id).includes(sra.id)
                    ),
                    ...action.subReportAttributes,
                ],
            };
        }

        case CREATE_SUB_REPORT_ATTRIBUTE: {
            return {
                ...state,
                subReportAttributes: [...state.subReportAttributes, action.subReportAttribute],
            };
        }

        case DELETE_SUB_REPORT_ATTRIBUTE:
            const originalActiveSubReportAttribute = state.subReportAttributes.find(
                (ra) => ra.id === action.subReportAttribute.id
            );

            return {
                ...state,
                subReportAttributes: replaceInCollection(
                    state.subReportAttributes,
                    originalActiveSubReportAttribute!,
                    action.subReportAttribute
                ),
            };
        case EDIT_SUB_REPORT_ATTRIBUTE:
            return {
                ...state,
                subReportAttributes: replaceInCollection(
                    state.subReportAttributes,
                    action.originalAttribute,
                    action.newAttribute,
                    (a, b) => `${a.id}`.localeCompare(`${b.id}`)
                ),
            };

        default:
            return state;
    }
}
