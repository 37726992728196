/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { AppState } from "../../state/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getIntakeSystemFiles,
    deleteIntakeSystemFile,
    selectIntakeSystemFile,
    clearSelectedIntakeSystemFile,
} from "../../actions/intakeSystemFiles/IntakeSystemFilesActionCreators";
import { IntakeSystemFile } from "../../models/IntakeSystemFile";
import { getRoles } from "../../actions/RoleActionCreators";
import EditButtonRender from "../grid/EditButtonRender";
import { getTenants } from "../../actions/tenants/TenantActionCreators";
import DeleteButtonRender from "../grid/DeleteButtonRender";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import PageHeader from "../header/PageHeader";
import IntakeSystemFileFormModal from "./IntakeSystemFileForm";
import KeysysAccordion from "../accordion/KeysysAccordion";
import { getFinosecSystems } from "../../actions/finosecSystem/FinosecSystemActionCreator";
import { getFileTypes } from "../../actions/fileType/FileTypeActionCreators";
import LinkWrapper from "../routing/LinkWrapper";

export default function IntakeSystemFiles() {
    const dispatch = useDispatch();
    const [showAddIntakeSystemFileFormModal, setShowAddIntakeSystemFileFormModal] = useState<boolean>(false);
    const [selectedIntakeSystemId, setselectedIntakeSystemId] = useState<number>(0);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
    const [selectedIntakeSystemFile, setSelectedIntakeSystemFile] = useState<IntakeSystemFile | undefined>(undefined);
    const [intakeSystemFileFormType, setIntakeSystemFileFormType] = useState<"add" | "edit">("add");
    const selectedFile = useSelector((state: AppState) => state.intakeSystemFileState.selectedIntakeSystemFile);
    const intakeSystemFiles = useSelector((state: AppState) => state.intakeSystemFileState.intakeSystemFiles);
    const fileTypes = useSelector((state: AppState) => state.fileTypeState.fileTypes);
    const Customers = useSelector((state: AppState) => state.tenantState.tenants);
    const systems = useSelector((state: AppState) => state.finosecSystemState.finosecSystems);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getIntakeSystemFiles());
        dispatch(getFinosecSystems());
        dispatch(getFileTypes());
        if (!intakeSystemFiles || intakeSystemFiles.length === 0) {
            dispatch(getTenants());
        }
    }, []);

    function showAddFileModal(systemId: number): void {
        setselectedIntakeSystemId(systemId);
        dispatch(clearSelectedIntakeSystemFile());
        setSelectedIntakeSystemFile(undefined);
        setIntakeSystemFileFormType("add");
        setShowAddIntakeSystemFileFormModal(true);
    }

    const handleDeleteIntakeSystemFile = () => {
        if (selectedFile === undefined) {
            return;
        } else {
            dispatch(deleteIntakeSystemFile(selectedFile));
            setShowConfirmDeleteModal(false);
        }
    };

    const showEditFileModal = useCallback(
        (params: IntakeSystemFile) => {
            dispatch(selectIntakeSystemFile(params));
            setIntakeSystemFileFormType("edit");
            setShowAddIntakeSystemFileFormModal(true);
        },
        [intakeSystemFiles]
    );

    const showDeleteCallback = useCallback(
        (params: number) => {
            const deleteFile = intakeSystemFiles.find((f) => f.id === params);
            if (deleteFile === undefined) {
                return;
            }

            dispatch(selectIntakeSystemFile(deleteFile));
            setShowConfirmDeleteModal(true);
        },
        [intakeSystemFiles]
    );

    const columnDefs: ColDef[] = [
        {
            headerName: "Name",
            field: "displayFileName",
        },
        {
            headerName: "Processing File Name",
            field: "processingFileName",
        },
        {
            headerName: "File Type",
            valueGetter: ({ data }: ValueGetterParams) => fileTypes.find((ft) => ft.id === data.fileTypeId)?.name,
        },
        {
            headerName: "Required",
            field: "isRequired",
        },
        {
            headerName: "Customer Name",
            valueGetter: ({ data }: ValueGetterParams) => Customers.find((cn) => cn.id === data.customerId)?.name,
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "IntakeSystemFile",
                showEdit: showEditFileModal,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Intake System File",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: DeleteButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "IntakeSystemFile",
                    showDelete: showDeleteCallback,
                    isActive: true,
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Delete Intake System File",
        },
    ];

    return (
        <>
            <PageHeader>
                <h2>Intake File Builder</h2>
                <LinkWrapper path={`workflownotificationtemplate`}>
                    <span style={{ textDecorationLine: "underline" }}>Notification Templates</span>
                </LinkWrapper>
            </PageHeader>
            {systems.map((s) => (
                <KeysysAccordion
                    actionButtons={[
                        {
                            text: "Add File",
                            icon: undefined,
                            iconColor: undefined,
                            cb: () => showAddFileModal(s.id),
                        },
                    ]}
                    renderOnExpanded
                    key={s.name}
                    title={s.name}
                    count={intakeSystemFiles.filter((file) => file.systemId === s.id).length}
                >
                    <div className="ag-theme-alpine">
                        <KeysysGrid
                            getRows={() => intakeSystemFiles.filter((file) => file.systemId === s.id && file.isActive)}
                            columnDefs={columnDefs}
                        />{" "}
                    </div>
                </KeysysAccordion>
            ))}
            <div>
                {showAddIntakeSystemFileFormModal && (
                    <IntakeSystemFileFormModal
                        //show={showAddIntakeSystemFileFormModal}
                        onClose={() => setShowAddIntakeSystemFileFormModal(false)}
                        type={intakeSystemFileFormType}
                        systemId={selectedIntakeSystemId}
                    />
                )}
            </div>
            <div>
                <ConfirmationModal
                    id="confirmDeleteFileModal"
                    show={showConfirmDeleteModal}
                    title="Delete File?"
                    message={"Do you really want to delete this file?"}
                    onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                    onOkButtonClick={handleDeleteIntakeSystemFile}
                    theme={"danger"}
                />
            </div>
        </>
    );
}
