import { UserState } from "../state/UserState";
import {
    CLEAR_SELECTED_USER,
    INACTIVE_USERS_FETCHED,
    SELECT_USER,
    USER_CREATED,
    USER_DELETED,
    USER_EDITED,
    USER_RESTORED,
    UserActions,
    USERS_FETCHED,
    USER_IMPERSONATED,
    USER_UNIMPERSONATED,
} from "../actions/users/UserActions";
import { User } from "../models/User";
import { replaceInCollection } from "./ReplaceInCollection";

const initialState: UserState = {
    users: [],
    selectedUser: undefined,
};

export function userReducer(state: UserState = initialState, action: UserActions): UserState {
    switch (action.type) {
        case USERS_FETCHED:
            const sortedUsers = [...action.users];
            sortedUsers.sort((a, b) => a.emailAddress.localeCompare(b.emailAddress));

            return {
                ...state,
                users: sortedUsers,
            };
        case INACTIVE_USERS_FETCHED:
            return {
                ...state,
                users: [...state.users.filter((u) => u.isActive), ...action.inactiveUsers],
            };
        case USER_CREATED:
            return {
                ...state,
                users: addUserAndGet(state.users, action.user),
            };
        case USER_DELETED:
            const originalActiveUser = state.users.find((u) => u.id === action.user.id);

            return {
                ...state,
                users: removeOldUserAndReplaceWithNew(state.users, originalActiveUser!, action.user),
            };
        case USER_EDITED:
            return {
                ...state,
                users: removeOldUserAndReplaceWithNew(state.users, action.originalUser, action.newUser),
            };
        case USER_RESTORED:
            const originalInactiveUser = state.users.find((u) => u.id === action.user.id);

            return {
                ...state,
                users: removeOldUserAndReplaceWithNew(state.users, originalInactiveUser!, action.user),
            };
        case SELECT_USER:
            return {
                ...state,
                selectedUser: action.user,
            };
        case USER_IMPERSONATED:
            return {
                ...state,
                impersonator: state.users.find((u) => u.id === action.impersonatorId)
            };
        case USER_UNIMPERSONATED:
            return {
                ...state,
                impersonator: undefined
            }

        case CLEAR_SELECTED_USER:
            return {
                ...state,
                selectedUser: undefined,
            };
        default:
            return state;
    }
}

function removeOldUserAndReplaceWithNew(users: User[], originalUser: User, newUser: User): User[] {
    return replaceInCollection(users, originalUser, newUser, (a, b) => a.emailAddress.localeCompare(b.emailAddress));
}

function addUserAndGet(users: User[], user: User): User[] {
    return [...users, user].sort((a, b) => a.emailAddress.localeCompare(b.emailAddress));
}
