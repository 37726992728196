import { ICustomerImportFileService } from "../../services/customerImportFile/ICustomerImportFileService";
import {
    CUSTOMER_IMPORT_FILES_FETCHED,
    CustomerImportFilesFetchedAction,
    CREATE_CUSTOMER_IMPORT_FILE,
    CreateCustomerImportFileAction,
    DELETE_CUSTOMER_IMPORT_FILE,
    DeleteCustomerImportFileAction,
    EDIT_CUSTOMER_IMPORT_FILE,
    FetchCustomerImportFileAction,
    FETCH_CUSTOMER_IMPORT_FILE,
    FetchCustomerImportJsonFileAction,
    FETCH_CUSTOMER_IMPORT_JSON_FILE,
} from "./CustomerImportFileActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { CustomerImportFile } from "../../models/CustomerImportFile";
import { CustomerImport } from "../../models/CustomerImport";
import { ICustomerImportService } from "../../services/customerImport/ICustomerImportService";
import { CUSTOMER_IMPORT_FILE_PAGE_SIZE } from "../../config/config";

export function getCustomerImportFiles(): KeysysThunkAction<void, CustomerImportFilesFetchedAction> {
    return async (dispatch, getState, container) => {
        const customerImportFileService = container.get<ICustomerImportFileService>(TYPES.CustomerImportFileService);
        const customerImportFilesCount = await customerImportFileService.getCustomerImportFilesCount();
        const pageSize = Number(CUSTOMER_IMPORT_FILE_PAGE_SIZE);
        const pages = Math.ceil(Number(customerImportFilesCount!) / pageSize);

        let customerImportFiles: CustomerImportFile[] = [];

        for (let currentPage = 1; currentPage <= pages; currentPage++) {
            const pageImportFiles = await customerImportFileService.getCustomerImportFilesPaged(currentPage, pageSize);
            if (pageImportFiles) {
                customerImportFiles = [...customerImportFiles, ...pageImportFiles];
            }
        }

        if (customerImportFiles.length > 0) {
            dispatch<CustomerImportFilesFetchedAction>({
                type: CUSTOMER_IMPORT_FILES_FETCHED,
                customerImportFiles: customerImportFiles,
            });
        }
    };
}

export function getCustomerImportFileById(
    customerImportFileId: number
): KeysysThunkAction<void, FetchCustomerImportFileAction> {
    return async (dispatch, getState, container) => {
        const customerImportFileService = container.get<ICustomerImportFileService>(TYPES.CustomerImportFileService);
        const customerImportFile = await customerImportFileService.getCustomerImportFileById(customerImportFileId);

        if (customerImportFile) {
            dispatch<FetchCustomerImportFileAction>({
                type: FETCH_CUSTOMER_IMPORT_FILE,
                customerImportFile: customerImportFile,
            });
        }
    };
}

export function getCustomerImportJsonFileById(
    customerImportFileId: number,
    accessToken: string
): KeysysThunkAction<void, FetchCustomerImportJsonFileAction> {
    return async (dispatch, getState, container) => {
        const customerImportFileService = container.get<ICustomerImportFileService>(TYPES.CustomerImportFileService);
        const customerImportFile = await customerImportFileService.getCustomerImportJsonFileById(
            customerImportFileId,
            accessToken
        );

        if (customerImportFile) {
            dispatch<FetchCustomerImportJsonFileAction>({
                type: FETCH_CUSTOMER_IMPORT_JSON_FILE,
                customerImportJsonFile: customerImportFile,
            });
        }
    };
}

export function createCustomerImportFile(
    customerImportFile: CustomerImportFile,
    customerImport?: CustomerImport
): KeysysThunkAction<void, CreateCustomerImportFileAction> {
    return async (dispatch, getState, container) => {
        const customerImportService = container.get<ICustomerImportService>(TYPES.CustomerImportService);
        if (customerImport) {
            const createdCustomerImport = await customerImportService.createCustomerImport(customerImport);
            if (createdCustomerImport) {
                customerImportFile.customerImportId = createdCustomerImport.id;
            }
        }
        const customerImportFileService = container.get<ICustomerImportFileService>(TYPES.CustomerImportFileService);
        const createdCustomerImportFile = await customerImportFileService.createCustomerImportFile(customerImportFile);
        if (createdCustomerImportFile) {
            dispatch<CreateCustomerImportFileAction>({
                type: CREATE_CUSTOMER_IMPORT_FILE,
                customerImportFile: createdCustomerImportFile,
            });
        }
        return !!createCustomerImportFile;
    };
}

export function editCustomerImportFile(
    originalCustomerImportFile: CustomerImportFile,
    newCustomerImportFile: CustomerImportFile
): KeysysThunkAction<void, CreateCustomerImportFileAction> {
    return async (dispatch, getState, container) => {
        const customerImportFileService = container.get<ICustomerImportFileService>(TYPES.CustomerImportFileService);
        const editedCustomerImportFile = await customerImportFileService.editCustomerImportFile(
            originalCustomerImportFile,
            newCustomerImportFile
        );
        if (editedCustomerImportFile) {
            dispatch<any>({
                type: EDIT_CUSTOMER_IMPORT_FILE,
                originalCustomerImportFile: originalCustomerImportFile,
                newCustomerImportFile: editedCustomerImportFile,
            });
        }
    };
}

export function deleteCustomerImportFile(
    customerImportFile: CustomerImportFile
): KeysysThunkAction<void, DeleteCustomerImportFileAction> {
    return async (dispatch, getState, container) => {
        const customerImportFileService = container.get<ICustomerImportFileService>(TYPES.CustomerImportFileService);
        const deletedCustomerImportFile = await customerImportFileService.deleteCustomerImportFile(customerImportFile);
        if (deletedCustomerImportFile) {
            dispatch<DeleteCustomerImportFileAction>({
                type: DELETE_CUSTOMER_IMPORT_FILE,
                customerImportFile: deletedCustomerImportFile,
            });
        }
    };
}
