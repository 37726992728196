/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { SsoService } from "../../models/SsoService";
import { useDispatch } from "react-redux";
import { LOGGED_IN, LoggedInAction } from "../../actions/AuthenticateActions";
import jwt_decode from "jwt-decode";

export type SignInOidcProps = {
    ssoService?: SsoService;
};
export default function SignInOidc(props: SignInOidcProps) {
    const ssoService: SsoService = props.ssoService ?? new SsoService();
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        async function signinAsync() {
            const user = await ssoService.signInRedirectCallback();
            const decodedAccessToken = jwt_decode(user.access_token) as any;
            dispatch<LoggedInAction>({
                accessToken: user.access_token,
                refreshToken: user.refresh_token ?? "",
                expiresIn: user.expires_in,
                type: LOGGED_IN,
                decodedAccessToken: {
                    ...decodedAccessToken,
                    tenant_id: +decodedAccessToken.tenant_id,
                    userId: parseInt((decodedAccessToken.user_id as any) as string),
                    email: decodedAccessToken.email,
                    reports: decodedAccessToken.reports as string,
                },
            });
            // redirect user to home page
            history.push("/");
        }

        signinAsync();
    }, [history]);
    return <></>;
}
