import { useEffect, useState, useCallback } from "react";
import { servicesContainer } from "../services/IOC/ServicesContainer";
import { TYPES } from "../dependencyInjection/Types";
import { ICustomerImportFileService } from "../services/customerImportFile/ICustomerImportFileService";
import _ from "lodash";
import FilterQueryParser from "react-filter-box";
import { ReportAttribute } from "../models/ReportAttribute";
import { SubReportAttribute } from "../models/SubReportAttribute";
import { useSelector } from "react-redux";
import { AppState } from "../state/AppState";

interface IHeaders {
    header: string;
    valueIndex: number;
    attribute: ReportAttribute & { subReport?: SubReportAttribute | null | undefined };
}

export const useCustomerImportJson = (
    customerImportFileId: number | undefined,
    attributes: any[],
    customerReportReviews: any[],
    subReportFilter?: string,
    customerReportFilter?: string,
    isCurrent?: boolean
) => {
    const customerImportFileService = servicesContainer.get<ICustomerImportFileService>(
        TYPES.CustomerImportFileService
    );
    const [importFileJsonData, setImportFileJsonData] = useState<any[] | undefined>(undefined);
    const [tableData, setTableData] = useState<any[]>([]);
    const [headers, setHeaders] = useState<IHeaders[]>([]);
    const [hasLoadedData, setHasLoadedData] = useState<boolean>(false);
    const { accessToken } = useSelector((state: AppState) => state.authenticationState);

    const parser = new FilterQueryParser({}).parser;

    const isLoadingState = useSelector((state: AppState) =>
        state.customerReportState
            ? state.customerReportState.loading || state.customerReportState.customerReportReviewsLoading
            : true
    );

    const hasLoadedCRRs = useSelector((state: AppState) => state.customerReportState.customerReportReviewsLoaded);

    const valueEqualsFilter = (value: any, filterValue: any, filterType: number) => {
        if (filterType === 1) {
            return ((value?.toLowerCase() as string) || "").includes(filterValue?.toLowerCase() || "");
        }
        if (filterType === 2) {
            return (value?.toLowerCase() || "") === (filterValue?.toLowerCase() || "");
        }
        return true;
    };

    const predicateSingle = (item: any, index: any, parsedResult: any) => {
        return filter(item.Values, index, parsedResult.operator, parsedResult.value);
    };

    const predicate = (item: any, headers: any, parsedResult: any | any[]): boolean => {
        let expressions: any[] = [];
        var result: boolean = true;

        if (_.isArray(parsedResult)) {
            expressions = parsedResult;
        } else if (_.isArray(parsedResult.expressions)) {
            expressions = parsedResult.expressions;
        } else {
            const index = headers.find((hr: any) => hr.attribute.attribute === parsedResult.category).valueIndex;
            return predicateSingle(item, index, parsedResult);
        }

        expressions.forEach((f) => {
            if (_.isUndefined(f.conditionType)) {
                result = predicate(item, headers, f);
            } else if (f.conditionType.toLowerCase() === "and") {
                result = result && predicate(item, headers, f);
            } else if (f.conditionType.toLowerCase() === "or") {
                result = result || predicate(item, headers, f);
            }
        });

        return result;
    };

    const processSubReportFilter = (headers: any, data: any, parsedResult: any[]): any[] => {
        return data.filter((f: any) => {
            return predicate(f, headers, parsedResult);
        });
    };

    const filter = (row: any, index: number, operator: string, value: string) => {
        switch (operator) {
            case "==":
                return row[index] === value;
            case "!=":
                return row[index] !== value;
            case "contains":
                return row[index].toLowerCase().indexOf(value.toLowerCase()) >= 0;
            case "!contains":
                return row[index].toLowerCase().indexOf(value.toLowerCase()) < 0;
        }

        return false;
    };

    const getImportFileJsonData = useCallback(async () => {
        if (customerImportFileId) {
            try {
                const result = await customerImportFileService.getCustomerImportJsonFileById(
                    customerImportFileId,
                    accessToken
                );
                if (result) {
                    setImportFileJsonData(result);
                }
            } catch (err) {
                console.log("customerImportJsonHook getImportFileJsonData error", JSON.stringify(err));
            }
        }
    }, [customerImportFileService, customerImportFileId]);

    useEffect(() => {
        getImportFileJsonData();
    }, [getImportFileJsonData]);

    const updateTableDataAndHeaders = useCallback(
        (updateHeaders?: boolean) => {
            if (!importFileJsonData?.length || !attributes.length) {
                if (!importFileJsonData?.length) {
                    console.log("no importFileJsonData found");
                }
                return;
            } else {
                console.log("importFileJsonData found");
            }
            const data = importFileJsonData;
            const rows = data?.[0]?.ExcelRows || [];
            const headersFromFile = data?.[0]?.Headers
                ? data[0].Headers?.map((h: string, idx: number) => ({ header: h, valueIndex: idx }))
                : [];
            const headersRes = attributes?.map((a, idx: number) => {
                const headerFromData = headersFromFile.find((hff: any) => hff.header === a.attribute);
                return {
                    header: a.attribute,
                    valueIndex: headerFromData?.valueIndex,
                    attribute: a,
                };
            });
            const newDataState = rows?.map((r: any) => ({
                ...r,
                customerReportReview: customerReportReviews.find((cr) => cr.lineNumber === r.Row) || {},
            }));
            let dataToSet = newDataState;
            if (!isCurrent && customerReportFilter) {
                const expressions: any = parser.parse(customerReportFilter);
                dataToSet = processSubReportFilter(headersRes, newDataState, expressions);
            } else if (isCurrent && subReportFilter) {
                const expressions: any = parser.parse(subReportFilter);
                dataToSet = processSubReportFilter(headersRes, newDataState, expressions);
            } else {
                dataToSet = newDataState.filter((dts: any) => {
                    let tryCount = 0;
                    let matchCount = 0;
                    headersRes.forEach((hr) => {
                        if (hr.attribute?.subReport?.filterValue) {
                            tryCount += 1;
                            const value = dts.Values[hr.valueIndex];
                            if (
                                valueEqualsFilter(
                                    value,
                                    hr.attribute.subReport.filterValue,
                                    hr.attribute.subReport.filterTypeId
                                )
                            ) {
                                matchCount += 1;
                            }
                        }
                    });
                    return tryCount === matchCount;
                });
            }
            setTableData(dataToSet);
            if (updateHeaders) {
                setHeaders(headersRes);
            }
            setHasLoadedData(true);
        },
        [customerReportReviews, attributes, importFileJsonData]
    );

    useEffect(() => {
        if (importFileJsonData?.length && !hasLoadedData && !isLoadingState && hasLoadedCRRs) {
            updateTableDataAndHeaders(true);
        }
    }, [updateTableDataAndHeaders, importFileJsonData, hasLoadedData, isLoadingState, hasLoadedCRRs]);

    useEffect(() => {
        if (isLoadingState && hasLoadedData) {
            setHasLoadedData(false);
        }
    }, [isLoadingState, hasLoadedData]);

    return {
        tableData,
        headers,
        loading: !hasLoadedData,
        setHasLoadedData,
    };
};
