import { ReportAttributeState } from "../../state/ReportAttributeState";
import {
    CREATE_REPORT_ATTRIBUTE,
    DELETE_REPORT_ATTRIBUTE,
    EDIT_REPORT_ATTRIBUTE,
    ReportAttributeActions,
    REPORT_ATTRIBUTES_FETCHED,
} from "../../actions/reportAttributes/ReportAttributeAction";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: ReportAttributeState = {
    reportAttributes: [],
};

export function reportAttributeReducer(
    state: ReportAttributeState = initialState,
    action: ReportAttributeActions
): ReportAttributeState {
    switch (action.type) {
        case REPORT_ATTRIBUTES_FETCHED: {
            return {
                ...state,
                reportAttributes: [
                    ...state.reportAttributes.filter(
                        (ra) => !(action.reportAttributes || []).map((ara) => ara.id).includes(ra.id)
                    ),
                    ...action.reportAttributes,
                ],
            };
        }

        case CREATE_REPORT_ATTRIBUTE: {
            return {
                ...state,
                reportAttributes: [...state.reportAttributes, action.reportAttribute],
            };
        }

        case DELETE_REPORT_ATTRIBUTE:
            // const originalActiveReportAttribute = state.reportAttributes.find(
            //     (ra) => ra.id === action.reportAttribute.id
            // );

            return {
                ...state,
                reportAttributes: state.reportAttributes.filter((ra) => ra.id !== action.reportAttribute.id),
            };
        case EDIT_REPORT_ATTRIBUTE:
            return {
                ...state,
                reportAttributes: replaceInCollection(
                    state.reportAttributes,
                    action.originalReportAttribute,
                    action.newReportAttribute,
                    (a, b) => `${a.id}`.localeCompare(`${b.id}`)
                ),
            };

        default:
            return state;
    }
}
