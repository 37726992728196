import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWorkflowFrequencies } from "../actions/workflowFrequency/WorkflowFrequencyActionCreators";
import { AppState } from "../state/AppState";

export const useWorkflowJobSchedules = () => {
    const dispatch = useDispatch();
    const { selectedWorkflowJobSchedule: selectedSchedule, workflowJobSchedules } = useSelector(
        (state: AppState) => state.workflowJobScheduleState
    );
    const frequencies = useSelector((state: AppState) => state.workflowFrequencyState.workflowFrequencies);
    const systems = useSelector((state: AppState) => state.finosecSystemState.finosecSystems);
    const tenants = useSelector((state: AppState) => state.tenantState.tenants);

    useEffect(() => {
        if (frequencies.length === 0) {
            dispatch(getWorkflowFrequencies());
        }
    }, []);

    const findTenant = useCallback(
        (id?: number) => {
            if (!id) return;
            return tenants.find((t) => t.id === id);
        },
        [tenants]
    );

    const findSystem = useCallback(
        (id?: number) => {
            if (!id) return;
            return systems.find((s) => s.id === id);
        },
        [systems]
    );

    const findFrequency = useCallback(
        (id?: number) => {
            if (!id) return;
            return frequencies.find((f) => f.id === id);
        },
        [frequencies]
    );

    return useMemo(
        () => ({
            frequencies,
            findFrequency,
            findSystem,
            findTenant,
            selectedSchedule,
            workflowJobSchedules,
        }),
        [frequencies, findFrequency, findSystem, findTenant, selectedSchedule, workflowJobSchedules]
    );
};
