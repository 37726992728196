import { Store } from "redux";
import { AppState } from "../state/AppState";
import { GETTING_SUBTABS, GettingSubtabsAction, NEW_SUBTABS, NewSubtabsAction } from "../actions/TabActions";
import { Subtab } from "../models/Subtab";
import { IHttpClient } from "./HttpClient/IHttpClient";
import { inject, injectable } from "inversify";
import { TYPES } from "../dependencyInjection/Types";

@injectable()
export class SubtabService {
    constructor(@inject(TYPES.Store) private store: Store, @inject(TYPES.HttpClient) private httpClient: IHttpClient) {
        this.store.subscribe(() => {
            getSubtabs(this.store, httpClient);
        });
    }
}

export function getSubtabs(store: Store<AppState>, httpClient: IHttpClient) {
    const state = store.getState();
    if (!state) {
        return;
    }
    if (state.tabState.fetchSubtabs) {
        store.dispatch<GettingSubtabsAction>({
            type: GETTING_SUBTABS
        });
        httpClient.get<Subtab[]>("/subtabs").then(t => {
            store.dispatch<NewSubtabsAction>({
                subtabs: t,
                type: NEW_SUBTABS
            });
        });
    }
}
