import { Tab } from "../models/Tab";
import { Subtab } from "../models/Subtab";

export const CHANGE_ACTIVE_TAB = "CHANGE_ACTIVE_TAB";
export const CHANGE_ACTIVE_SUBTAB = "CHANGE_ACTIVE_SUBTAB";
export const NEW_TABS = "NEW_TABS";
export const NEW_SUBTABS = "NEW_SUBTABS";
export const GET_TABS = "GET_TABS";
export const GET_SUBTABS = "GET_SUBTABS";
export const GETTING_TABS = "GETTING_TABS";
export const GETTING_SUBTABS = "GETTING_SUBTABS";

export interface ChangeActiveTabAction {
    type: typeof CHANGE_ACTIVE_TAB;
    activeTabId: number;
}

export interface ChangeActiveSubtabAction {
    type: typeof CHANGE_ACTIVE_SUBTAB;
    activeTabId: number;
}

export interface NewTabsAction {
    type: typeof NEW_TABS;
    tabs: Tab[];
}

export interface GettingTabsAction {
    type: typeof GETTING_TABS;
}

export interface GetTabsAction {
    type: typeof GET_TABS;
}

export interface NewSubtabsAction {
    type: typeof NEW_SUBTABS;
    subtabs: Subtab[];
}

export interface GettingSubtabsAction {
    type: typeof GETTING_SUBTABS;
}

export interface GetSubtabsAction {
    type: typeof GET_SUBTABS;
}

export type TabActionTypes =
    | ChangeActiveTabAction
    | ChangeActiveSubtabAction
    | NewTabsAction
    | NewSubtabsAction
    | GettingTabsAction
    | GetTabsAction
    | GetSubtabsAction
    | GettingSubtabsAction;
