import { CustomerReportAttribute } from "../../models/CustomerReportAttribute";

export const CUSTOMER_REPORT_ATTRIBUTES_FETCHED = "CUSTOMER_REPORT_ATTRIBUTES_FETCHED";

export interface CustomerReportAttributesFetchedAction {
    type: typeof CUSTOMER_REPORT_ATTRIBUTES_FETCHED;
    customerReportAttributes: CustomerReportAttribute[];
}

export type CustomerReportAttributeActions = CustomerReportAttributesFetchedAction;
