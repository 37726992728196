/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { AppState } from "../../state/AppState";
import { useDispatch, useSelector } from "react-redux";
import { getTenants } from "../../actions/tenants/TenantActionCreators";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Identifiable } from "../../models/Identifiable";
import { Ownable } from "../../models/Ownable";
import LinkWrapper from "../routing/LinkWrapper";
import { getCustomerDocumentsStatistics } from "../../actions/customerDocument/CustomerDocumentActionCreators";

export interface TenantWithOwner extends Identifiable, Ownable {
    name: string;
    logoUri: string;
    isActive: Boolean;
    owner?: string;
}

export default function DocumentExchangeList() {
    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);

    const history = useHistory();
    const dispatch = useDispatch();
    const tenants = useSelector((state: AppState) => state.tenantState.tenants);

    const customerDocument = useSelector(
        (state: AppState) => state.customerDocumentStatisticsState.customerDocumentStatistics
    );

    useEffect(() => {
        dispatch(getCustomerDocumentsStatistics());
        if (decodedAccessToken.roles !== "GlobalAdmin" && decodedAccessToken.roles !== "PartnerAdmin") {
            history.push("/");
        }
        if (!tenants || tenants.length === 0) {
            dispatch(getTenants());
        }
    }, []);

    const getName = (tenantId: number) => {
        return tenants.find((item) => item.id === tenantId);
    };

    const populateData = (tenantId: number) => {
        return customerDocument.find((item) => item.customerTenantId === tenantId);
    };

    const columnDefs: ColDef[] = [
        {
            headerName: "Customer",
            field: "customerName",
            cellRendererFramework: (data: any) => {
                return (
                    <LinkWrapper path={`document-exchange/customer-documents/${data?.data?.id}`}>
                        <span style={{ textDecorationLine: "underline" }}>{getName(data?.data?.id)?.name}</span>
                    </LinkWrapper>
                );
            },
            tooltipField: "Customer",
        },
        {
            headerName: "# of Documents",
            field: "totalDocuments",
            cellRendererFramework: (data: any) => <span>{populateData(data.data.id)?.totalDocuments}</span>,
            tooltipField: "Documents Count",
        },
        {
            headerName: "# of Documents for Partner Review",
            field: "totalDocumentsAdminReview",
            cellRendererFramework: (data: any) => {
                const totalAdminCount = populateData(data.data.id)?.totalDocumentsAdminReview;
                return (
                    <span style={totalAdminCount && totalAdminCount > 0 ? { color: "red" } : {}}>
                        {totalAdminCount}
                    </span>
                );
            },
            tooltipField: "Documents for Partner Review Count",
        },
        {
            headerName: "# of Documents for Customer Review",
            field: "totalDocumentsCustomerReview",
            cellRendererFramework: (data: any) => (
                <span>{populateData(data.data.id)?.totalDocumentsCustomerReview}</span>
            ),
            tooltipField: "Documents for Customer Review Count",
        },
    ];

    return (
        <div>
            <Row className={"tenants-grid-container"}>
                <Col className={"h-100 p-0"}>
                    <div className="ag-theme-alpine">
                        <KeysysGrid getRows={() => tenants} columnDefs={columnDefs} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
