import { ISubReportService } from "../../services/subReport/ISubReportService";
import { ISubReportAttributeService } from "../../services/subReportAttribute/ISubReportAttributeService";
import {
    SUB_REPORTS_FETCHED,
    SubReportsFetchedAction,
    CREATE_SUB_REPORT,
    CreateSubReportAction,
    DELETE_SUB_REPORT,
    DeleteSubReportAction,
    EditSubReportAction,
    EDIT_SUB_REPORT,
    FETCHING_SUB_REPORTS,
} from "./SubReportActions";
import {
    CREATE_SUB_REPORT_ATTRIBUTE,
    EDIT_SUB_REPORT_ATTRIBUTE,
} from "../subReportAttributes/SubReportAttributeAction";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { SubReport } from "../../models/SubReport";
import { SubReportAttribute } from "../../models/SubReportAttribute";

export function getSubReports(): KeysysThunkAction<void, SubReportsFetchedAction> {
    return async (dispatch, getState, container) => {
        dispatch<any>({
            type: FETCHING_SUB_REPORTS,
        });
        const subReportService = container.get<ISubReportService>(TYPES.SubReportService);
        const subReports = await subReportService.getSubReports();

        if (subReports) {
            dispatch<SubReportsFetchedAction>({
                type: SUB_REPORTS_FETCHED,
                subReports: subReports,
            });
        }
    };
}

export function createSubReport(
    subReport: SubReport,
    attributes: SubReportAttribute[]
): KeysysThunkAction<void, CreateSubReportAction> {
    return async (dispatch, getState, container) => {
        const subReportService = container.get<ISubReportService>(TYPES.SubReportService);
        const createdSubReport = await subReportService.createSubReport(subReport);
        if (createdSubReport) {
            dispatch<CreateSubReportAction>({
                type: CREATE_SUB_REPORT,
                subReport: createdSubReport,
            });
            if (attributes && attributes.length > 0) {
                const subReportAttributeService = container.get<ISubReportAttributeService>(
                    TYPES.SubReportAttributeService
                );
                for (let i = 0; i < attributes.length; i++) {
                    const attribute = attributes[i];
                    const createdAttribute = await subReportAttributeService.createSubReportAttribute(
                        createdSubReport.id,
                        { ...attribute, subReportId: createdSubReport.id }
                    );
                    if (createdAttribute) {
                        dispatch<any>({
                            type: CREATE_SUB_REPORT_ATTRIBUTE,
                            subReportAttribute: createdAttribute,
                        });
                    }
                }
            }
        }
        return !!createSubReport;
    };
}

export function editSubReport(
    originalSubReport: SubReport,
    newSubReport: SubReport,
    originalAttributes: SubReportAttribute[],
    attributes: SubReportAttribute[]
): KeysysThunkAction<void, EditSubReportAction> {
    return async (dispatch, getState, container) => {
        const subReportService = container.get<ISubReportService>(TYPES.SubReportService);
        const patchedSubReport = await subReportService.editSubReport(originalSubReport, newSubReport);
        if (patchedSubReport) {
            dispatch<EditSubReportAction>({
                type: EDIT_SUB_REPORT,
                originalSubReport,
                newSubReport,
            });
            if (attributes && attributes.length > 0) {
                const subReportAttributeService = container.get<ISubReportAttributeService>(
                    TYPES.SubReportAttributeService
                );
                for (let i = 0; i < attributes.length; i++) {
                    const attribute = attributes[i];
                    const originalAttribute = originalAttributes.find((oa) => oa.id === attribute.id);
                    if (originalAttribute) {
                        const patchedAttribute = await subReportAttributeService.editSubReportAttribute(
                            patchedSubReport.id,
                            originalAttribute,
                            attribute
                        );
                        if (patchedAttribute) {
                            dispatch<any>({
                                type: EDIT_SUB_REPORT_ATTRIBUTE,
                                originalAttribute,
                                newAttribute: attribute,
                            });
                        }
                    }
                }
            }
        }
    };
}

export function deleteSubReport(subReport: SubReport): KeysysThunkAction<void, DeleteSubReportAction> {
    return async (dispatch, getState, container) => {
        const subReportService = container.get<ISubReportService>(TYPES.SubReportService);
        const deletedSubReport = await subReportService.deleteSubReport(subReport);
        if (deletedSubReport) {
            dispatch<DeleteSubReportAction>({
                type: DELETE_SUB_REPORT,
                subReport: deletedSubReport,
            });
        }
    };
}
