import FormBuilder, { ApiViewFields, FormControlOption } from "../form-builder/FormBuilder";
import { Form, Formik } from "formik";
import { Button, Card } from "react-bootstrap";
import { useMasterListForm } from "../../hooks/masterListFormHook";
import { LoadingSpinner } from "../loading/Loading";

const MasterListForm = () => {
    const { report, systems, onSave, onCreateSystem, isEdit } = useMasterListForm();
    if (!report) {
        return <LoadingSpinner />;
    }

    const systemOptions: FormControlOption[] = systems
        ? systems.map((s) => ({ value: `${s.id}`, text: `${s.name}`, key: `${s.id}` } as FormControlOption))
        : [];
    const meta: ApiViewFields[] = [
        {
            key: "name",
            label: "Master List Name",
            type: "input",
        },
        {
            key: "finosecSystemId",
            label: "Select System",
            type: "dropdown",
            canAddValuesToOptions: true,
            onAddValueToOptions: (system) => onCreateSystem(system.name),
            options: systemOptions,
        },
        {
            key: "isSystemAccessReport",
            label: "System Access Report",
            type: "checkbox",
        },
        {
            key: "columns",
            label: "Columns",
            type: "array",
            arrayChunk: 6,
            dynamicData: true,
            fields: [
                {
                    key: "id",
                    label: "Identifier",
                    type: "input",
                    hidden: true,
                },
                {
                    key: "attribute",
                    label: "Column Name",
                    type: "input",
                    placeholder: "Enter Name",
                },
                {
                    key: "ordinal",
                    label: "Column Order",
                    type: "input",
                    placeholder: "Enter Order",
                },
                {
                    key: "columnWidth",
                    label: "Column Width",
                    type: "input",
                    placeholder: "Enter Number of Pixels",
                },
                {
                    key: "isHidden",
                    label: "Hide Column?",
                    type: "checkbox",
                    controlStyle: {
                        marginLeft: "20px",
                    },
                },
                {
                    key: "isIdField",
                    label: "Id Field?",
                    type: "checkbox",
                    controlStyle: {
                        marginLeft: "20px",
                    },
                },
                {
                    key: "isNameField",
                    label: "Name Field?",
                    type: "checkbox",
                    controlStyle: {
                        marginLeft: "20px",
                        marginRight: "20px",
                    },
                },
            ],
        },
    ];
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Formik enableReinitialize initialValues={report} onSubmit={(v) => onSave(v)}>
                <Card style={{ width: "75%", alignSelf: "center" }}>
                    <Form className="keysys-form">
                        <Card.Header className="finosec-button-info" style={{ color: "white" }}>
                            <Card.Title>Create Master List</Card.Title>
                        </Card.Header>
                        <Card.Body
                            style={{ display: "flex", flexDirection: "column" }}
                            className="justify-content-md-center"
                        >
                            <FormBuilder meta={meta} chunkNumber={2} initialValues={report}></FormBuilder>
                        </Card.Body>
                        <Card.Footer className="text-right" style={{ backgroundColor: "white" }}>
                            <Button type="submit" className="finosec-button-info">
                                {isEdit ? "Save Changes" : "Create Report"}
                            </Button>
                        </Card.Footer>
                    </Form>
                </Card>
            </Formik>
        </div>
    );
};

export default MasterListForm;
