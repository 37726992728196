/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, ChangeEvent } from "react";
import { Card, Col, Row, Form as bsForm, Button } from "react-bootstrap";
import { Form, Formik, FormikHelpers, FormikProps, Field } from "formik";
// import { useDispatch, useSelector } from "react-redux";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import KeysysInput from "../form/input/KeysysInput";
// import { KeysysSelectOption } from "../form/select/KeysysSelectOption";
import KeysysSelect from "../form/select/KeysysSelect";
import { CustomerDocument } from "../../models/CustomerDocument";
import SmallRoundCheckbox from "../checkbox/small-round-checkbox/SmallRoundCheckbox";
import { IProgramReference } from "../../models/ProgramReference";
import { useDispatch, useSelector } from "react-redux";
import { getFileStatuses } from "../../actions/fileStatus/FileStatusActionCreators";
import { AppState } from "../../state/AppState";
// import moment from "moment";

interface IDocumentUploadProps {
    programReferences: IProgramReference[];
    onSave: (file: File, customerDocument: CustomerDocument) => Promise<void>;
    tenantName?: string;
    isAdmin: boolean;
}

export default function DocumentUpload(props: IDocumentUploadProps) {
    const { programReferences, tenantName, onSave, isAdmin } = props;
    const programReferencesOptions = programReferences.map((reference) => ({
        value: reference.id,
        label: reference.name,
    }));
    const [showUploadForm, setShowUploadForm] = useState(false);

    const [file, setFile] = useState<File>();

    const dispatch = useDispatch();
    const selectFileStatuses = (state: AppState) => state.fileStatusState.statuses;
    const fileStatuses = useSelector(selectFileStatuses);
    const createdStatus = fileStatuses.find((status) => status.name === "Created");

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    useEffect(() => {
        dispatch(getFileStatuses());
    }, []);

    const handleSubmit = async (values: any) => {
        if (file) {
            await onSave(file, values);
            setShowUploadForm(false);
        }
    };

    const clearFormAndHide = (formProps: any) => {
        formProps.resetForm();
        setShowUploadForm(false);
    };

    return (
        <>
            <div>
                {!inIframe() && (
                    <Row className={"justify-content-end"}>
                        {/* <Col  className={"add-user-button-wrapper"}> */}
                        <div style={{ width: "fit-content" }}>
                            <Button
                                className={"upload-document-button finosec-button-info"}
                                onClick={() => setShowUploadForm(true)}
                                data-testid={"upload-document-button"}
                            >
                                Upload Document
                            </Button>
                        </div>
                        {/* </Col> */}
                    </Row>
                )}
                <Formik
                    initialValues={{
                        documentDescription: "",
                        programReferenceId: 0,
                        fileStatusId: createdStatus ? createdStatus.id : 0,
                        comments: "",
                        customerReviewRequired: false,
                        adminReviewRequired: false,
                    }}
                    // validationSchema={userSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                >
                    {(formProps: FormikProps<any>) => {
                        return (
                            <VerticallyCenteredModal
                                id="addEditPartnerModal"
                                show={showUploadForm}
                                title={`Document Upload for ${tenantName}`}
                                closeButtonText={"Close"}
                                okButtonText={"Upload"}
                                showSaveButton={true}
                                size="lg"
                                onCloseButtonClick={() => clearFormAndHide(formProps)}
                                onOkButtonClick={formProps.submitForm}
                            >
                                <Form id="userForm">
                                    <Row className={"mb-4"}>
                                        <Col>
                                            <KeysysInput
                                                formProps={formProps}
                                                id={"documentDescription"}
                                                placeholder={""}
                                                fieldName={"documentDescription"}
                                                label={"Description"}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col xs={12} lg={6}>
                                            <KeysysSelect
                                                formProps={formProps}
                                                id={"programReferenceId"}
                                                placeholder={"Program Reference..."}
                                                fieldName={"programReferenceId"}
                                                label={"Select Program Reference"}
                                                options={programReferencesOptions}
                                                multiple={false}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col>
                                            <label>Comments</label>
                                            <Field
                                                style={{
                                                    width: "100%",
                                                    border: "1px solid #ced4da",
                                                    borderRadius: "0.25rem",
                                                }}
                                                name="comments"
                                                as="textarea"
                                                rows={5}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col className={"d-inline-flex"}>
                                            <label>
                                                <Field
                                                    type="checkbox"
                                                    name={isAdmin ? "customerReviewRequired" : "adminReviewRequired"}
                                                />
                                                <span style={{ marginLeft: "5px" }}>Review Required</span>
                                            </label>
                                        </Col>
                                    </Row>
                                    <Row className={"mb-4"}>
                                        <Col>
                                            <bsForm.Group>
                                                <bsForm.Label>{file?.name}</bsForm.Label>
                                                <bsForm.Control
                                                    type="file"
                                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                        const eventFile: any = e.target.files![0];
                                                        setFile(eventFile);
                                                    }}
                                                    data-browse={"Upload Document"}
                                                    id={"UploadDocument"}
                                                />
                                            </bsForm.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </VerticallyCenteredModal>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
}
