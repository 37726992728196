import { ReportAttribute } from "../../models/ReportAttribute";

export const REPORT_ATTRIBUTES_FETCHED = "REPORT_ATTRIBUTES_FETCHED";
export const CREATE_REPORT_ATTRIBUTE = "CREATE_REPORT_ATTRIBUTE";
export const EDIT_REPORT_ATTRIBUTE = "EDIT_REPORT_ATTRIBUTE";
export const DELETE_REPORT_ATTRIBUTE = "DELETE_REPORT_ATTRIBUTE";


export interface ReportAttributesFetchedAction {
    type: typeof REPORT_ATTRIBUTES_FETCHED;
    reportAttributes: ReportAttribute[];
}

export interface CreateReportAttributeAction {
    type: typeof CREATE_REPORT_ATTRIBUTE;
    reportAttribute: ReportAttribute;
}

export interface EditReportAttributeAction {
    type: typeof EDIT_REPORT_ATTRIBUTE;
    originalReportAttribute: ReportAttribute;
    newReportAttribute: ReportAttribute;
}

export interface DeleteReportAttributeAction {
    type: typeof DELETE_REPORT_ATTRIBUTE;
    reportAttribute: ReportAttribute;
}

export type ReportAttributeActions = ReportAttributesFetchedAction 
    | CreateReportAttributeAction
    | EditReportAttributeAction
    | DeleteReportAttributeAction;
