import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { Notes } from "../notes/Notes";
import { FlagToggle } from "../customerReport/FlagToggle";
import { ReviewToggle } from "../customerReport/ReviewToggle";
import { forwardRef, useEffect, useMemo } from "react";
import _ from "lodash";
import { ColDef } from "ag-grid-community";
import "../customerReport/customerPrintModal.scss";
import "../customerReport/printModal.scss";
import { useCustomerImportReportList } from "../../hooks/customerReportsHook";
import "./pdfReport.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { useCustomerReportPdfListForm } from "../../hooks/customerReportPdfHook";

export const CustomerReportPdf = () => {
    let params = useParams<{ tenantId: any; customerImportId: any; customerReportId: any; page: any; pageSize: any }>();

    const isLoadingState = useSelector((state: AppState) =>
        state.customerReportState
            ? state.customerReportState.loading || state.customerReportState.customerReportReviewsLoading
            : true
    );

    useCustomerImportReportList(+params.customerImportId);

    const {
        finosecSystem,
        tableData,
        headers,
        customerReport,
        customerImport,
        notes,
        loading,
        notesLoading,
    } = useCustomerReportPdfListForm(+params.customerReportId);

    //const isLoading = loading || notesLoading || isLoadingState;
    const isLoading = loading || notesLoading;

    let tableDataToRender = tableData;
    let page = 1;
    let pageSize = 5000;
    if (params.page && params.pageSize) {
        page = +params.page;
        pageSize = +params.pageSize;
        tableDataToRender = tableData.slice((page - 1) * pageSize, page * pageSize);
    }

    const columnDefs = useMemo(() => {
        let defs = _.orderBy(headers, (h) => h.attribute?.ordinal)?.map(({ header, attribute, valueIndex }) => {
            let colDef: ColDef = {
                headerName: header,
                field: header,
                hide: attribute?.subReport?.hide,
                filterValueGetter: (params) => {
                    return params.data?.Values[valueIndex];
                },
                valueGetter: (params) => {
                    return params.data?.Values[valueIndex];
                },
            };
            return colDef;
        });

        if (!customerReport?.isReadOnlySubReport) {
            defs = [
                ...defs,
                {
                    headerName: "Flag",
                    cellRendererFramework: (data: any) => {
                        return data.data?.customerReportReview ? (
                            <FlagToggle
                                displayOnly={true}
                                customerReportReview={data?.data?.customerReportReview}
                                rowNo={data?.data?.Row}
                            />
                        ) : (
                            ""
                        );
                    },
                },
                {
                    headerName: "Review",
                    cellRendererFramework: (data: any) => {
                        return data.data?.customerReportReview ? (
                            <ReviewToggle
                                isReviewComplete={customerReport?.isReviewComplete}
                                displayOnly={true}
                                customerReportReview={data?.data?.customerReportReview}
                                rowNo={data?.data?.Row}
                                jsonIsReviewed={data?.data?.Review}
                                jsonReviewedBy={data?.data?.By}
                                jsonReviewedSR={data?.data?.BySR}
                                reviewedBy={customerReport?.reviewCompleteByName}
                            />
                        ) : (
                            ""
                        );
                    },
                },
            ];
        }
        return defs;
    }, [headers, customerReport]);

    const ReferingComponent = forwardRef((props: any, ref: any) => {
        return (
            <div>
                <table
                    style={{
                        width: "100%",
                        tableLayout: "fixed",
                        overflowWrap: "break-word",
                    }}
                >
                    <thead>
                        <tr>
                            {columnDefs
                                .filter((cd: any) => !cd.hide)
                                .map((cd: any) => (
                                    <th key={cd.headerName} style={{ backgroundColor: "grey" }}>
                                        {cd.headerName}
                                    </th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableDataToRender.map((td, idx) => (
                            <tr key={idx}>
                                {columnDefs
                                    .filter((cd: any) => !cd.hide)
                                    .map((cd: any) => (
                                        <td key={`${idx}-${cd.headerName}`}>
                                            {cd.cellRendererFramework
                                                ? cd.cellRendererFramework({ data: td })
                                                : cd.valueGetter
                                                ? (cd.valueGetter as any)({ data: td })
                                                : td[cd.field as any]}
                                        </td>
                                    ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    });

    return (
        <>
            <div className="print-modal-content">
                {page === 1 && (
                    <Row>
                        <Col>
                            <h6>Subreport: {customerReport?.subReportName}</h6>
                            <h6>System: {finosecSystem?.name}</h6>
                            <h6>Period: {customerImport?.periodName}</h6>
                            <div id="RecordCount" style={{ display: "none" }}>
                                {tableData.length}
                            </div>
                        </Col>
                    </Row>
                )}
                {page === 1 && (
                    <Notes
                        noScroll={true}
                        displayOnly={true}
                        notes={notes.filter((n) => n.customerReportId === customerReport?.id)}
                    />
                )}
                <ReferingComponent
                    getRowId={(data: any) => data.Row}
                    data={tableDataToRender}
                    columnDefs={columnDefs}
                />
                {!isLoading && (
                    <div id="Loaded" style={{ display: "none" }}>
                        Loaded
                    </div>
                )}
            </div>
        </>
    );
};
