import { WorkflowStatusActions, WORKFLOW_STATUSES_FETCHED } from "../../actions/workflowStatus/WorkflowStatusActions";
import { WorkflowStatusState } from "../../state/WorkflowStatusState";

const initialState: WorkflowStatusState = {
    workflowStatuses: [],
};

export function workflowStatusReducer(
    state: WorkflowStatusState = initialState,
    action: WorkflowStatusActions
): WorkflowStatusState {
    switch (action.type) {
        case WORKFLOW_STATUSES_FETCHED:
            return {
                ...state,
                workflowStatuses: [...action.workflowStatuses].sort((a, b) => a.name.localeCompare(b.name)),
            };
        default:
            return state;
    }
}
