import { Role } from "../models/Role";

export const GET_ROLES = "GET_ROLES";
export const GETTING_ROLES = "GETTING_ROLES";
export const ROLES_FETCHED = "ROLES_FETCHED";
export const GET_INACTIVE_ROLES = "GET_INACTIVE_ROLES";
export const GETTING_INACTIVE_ROLES = "GETTING_INACTIVE_ROLES";
export const INACTIVE_ROLES_FETCHED = "INACTIVE_ROLES_FETCHED";
export const CREATE_ROLE = "CREATE_ROLE";
export const CREATING_ROLE = "CREATING_ROLE";
export const ROLE_CREATED = "ROLE_CREATED";
export const DELETE_ROLE = "DELETE_ROLE";
export const DELETING_ROLE = "DELETING_ROLE";
export const ROLE_DELETED = "ROLE_DELETED";
export const EDIT_ROLE = "EDIT_ROLE";
export const EDITING_ROLE = "EDITING_ROLE";
export const ROLE_EDITED = "ROLE_EDITED";
export const RESTORE_ROLE = "RESTORE_ROLE";
export const RESTORING_ROLE = "RESTORING_ROLE";
export const ROLE_RESTORED = "ROLE_RESTORED";
export const CREATE_ROLE_ASSOCIATIONS = "CREATE_ROLE_ASSOCIATIONS";
export const CREATING_ROLE_ASSOCIATIONS = "CREATING_ROLE_ASSOCIATIONS";
export const ROLE_ASSOCIATIONS_CREATED = "ROLE_ASSOCIATIONS_CREATED";
export const SELECT_ROLE = "SELECT_ROLE";
export const CLEAR_SELECTED_ROLE = "CLEAR_SELECTED_ROLE";



export interface RolesFetchedAction {
    type: typeof ROLES_FETCHED;
    roles: Role[];
}

export type RoleActions =
    | RolesFetchedAction;



