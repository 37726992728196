import { CustomerReportState } from "../../state/CustomerReportState";
import {
    ADD_CUSTOMER_REPORT_REVIEW_SUCCESS,
    CustomerReportActions,
    CUSTOMER_REPORTS_FETCHED,
    CUSTOMER_REPORTS_REVIEW_FETCHED,
    ALL_CUSTOMER_REPORTS_REVIEW_FETCHED,
    FETCH_CUSTOMER_REPORTS,
    EDIT_CUSTOMER_REPORT_SUCCESS,
    BULK_CUSTOMER_REPORT_REVIEW_UPSERT,
    FETCH_CUSTOMER_REPORTS_REVIEW,
} from "../../actions/customerReport/CustomerReportAction";
import { replaceInCollection } from "../ReplaceInCollection";
import { ICustomerReportReview } from "../../models/CustomerReportReview";

const initialState: CustomerReportState = {
    customerReports: [],
    customerReportReviews: [],
    loading: false,
    loaded: false,
    customerReportReviewsLoading: false,
    customerReportReviewsLoaded: false,
};

export function customerReportReducer(
    state: CustomerReportState = initialState,
    action: CustomerReportActions
): CustomerReportState {
    switch (action.type) {
        case FETCH_CUSTOMER_REPORTS: {
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        }
        case CUSTOMER_REPORTS_FETCHED: {
            return {
                ...state,
                customerReports: action.customerReports,
                loading: false,
                loaded: true,
            };
        }
        case FETCH_CUSTOMER_REPORTS_REVIEW: {
            return {
                ...state,
                customerReportReviewsLoaded: false,
                customerReportReviewsLoading: true,
            };
        }
        case CUSTOMER_REPORTS_REVIEW_FETCHED: {
            return {
                ...state,
                customerReportReviewsLoaded: true,
                customerReportReviewsLoading: false,
                customerReportReviews: [
                    ...state.customerReportReviews.filter(
                        (customerReportReview) =>
                            !(action.customerReportReviews || [])
                                .map((customerReportReviewFromAction) => customerReportReviewFromAction.id)
                                .includes(customerReportReview.id)
                    ),
                    ...action.customerReportReviews,
                ],
            };
        }
        case BULK_CUSTOMER_REPORT_REVIEW_UPSERT: {
            // updating state manually so we don't have to wait for the bulk action response
            const updatedReviews: ICustomerReportReview[] = action.bulkRequest.lineNumbers.map((line) => {
                return {
                    id: 0,
                    isReviewed: action.bulkRequest.isReviewed,
                    isAutoReviewed: action.bulkRequest.isAutoReviewed,
                    lineNumber: line,
                    lastModifiedByName: action.bulkRequest.lastModifiedByName,
                    tenantId: action.bulkRequest.tenantId,
                    customerReportId: action.bulkRequest.customerReportId,
                    customerImportFileId: action.bulkRequest.customerImportFileId,
                } as ICustomerReportReview;
            });
            let updatedReviewState = [...state.customerReportReviews];
            for (let review of updatedReviews) {
                const existingReview = updatedReviewState.find((x) => isSameReview(x, review));
                if (!existingReview) {
                    updatedReviewState.push(review);
                } else {
                    const reviewIndex = updatedReviewState.findIndex((x) => isSameReview(x, review));
                    const updatedReview: ICustomerReportReview = {
                        ...existingReview,
                        isReviewed: action.bulkRequest.isReviewed || false,
                        isAutoReviewed: action.bulkRequest.isAutoReviewed || false,
                        lastModifiedByName: action.bulkRequest.lastModifiedByName,
                        customerReportId: action.bulkRequest.customerReportId,
                    };
                    updatedReviewState[reviewIndex] = updatedReview;
                }
            }
            return {
                ...state,
                customerReportReviews: updatedReviewState.sort((a, b) =>
                    `${a.lineNumber}`.localeCompare(`${b.lineNumber}`)
                ),
                customerReportReviewsLoading: false,
            };
        }
        case ALL_CUSTOMER_REPORTS_REVIEW_FETCHED: {
            return {
                ...state,
                customerReportReviewsLoaded: true,
                customerReportReviewsLoading: false,
                customerReportReviews: action.customerReportReviews,
            };
        }
        case ADD_CUSTOMER_REPORT_REVIEW_SUCCESS:
            let updatedCustomerReportReviews = [...state.customerReportReviews].filter(
                (x) =>
                    x.customerImportFileId !== action.customerReportReview.customerImportFileId ||
                    x.lineNumber !== action.customerReportReview.lineNumber
            );
            updatedCustomerReportReviews.push(action.customerReportReview);
            return {
                ...state,
                customerReportReviews: updatedCustomerReportReviews,
            };
        case EDIT_CUSTOMER_REPORT_SUCCESS:
            return {
                ...state,
                customerReports: replaceInCollection(
                    state.customerReports,
                    action.customerReport,
                    action.newCustomerReport,
                    (a, b) => `${a.id}`.localeCompare(`${b.id}`)
                ),
                loading: false,
            };
        default:
            return state;
    }
}

const isSameReview = (firstReview: ICustomerReportReview, secondReview: ICustomerReportReview): boolean => {
    return (
        firstReview.lineNumber === secondReview.lineNumber &&
        firstReview.customerImportFileId === secondReview.customerImportFileId
    );
};
