import { ChangeEvent, useState } from "react";
import { IImportForm } from "./IImportForm";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { ImportReportModel } from "./ImportReportModel";
import { IMP0RT_CONFIRM_MODAL } from "../../../constants/ModalConstants";
import { hideModal } from "../../../actions/ModalActionCreators";
import { useDispatch, useSelector } from "react-redux";
import VerticallyCenteredModal from "../../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { AppState } from "../../../state/AppState";
import { Card, Col, Row, Form as bsForm } from "react-bootstrap";
import KeysysInput from "../../form/input/KeysysInput";
import { KeysysSelectOption } from "../../form/select/KeysysSelectOption";
import KeysysSelect from "../../form/select/KeysysSelect";
import { createCustomerImportFile } from "../../../actions/customerImportFile/CustomerImportFileActionCreators";
import moment from "moment";
import { CustomerImport } from "../../../models/CustomerImport";
import * as Yup from "yup";
import { CustomerImportFile } from "../../../models/CustomerImportFile";
import { getPreSignedUrl, uploadFileToS3WithPreSignedUrl } from "../../../helper-functions";
import { CUSTOMER_IMPORT_BUCKET_NAME } from "../../../config/config";
import { PreSignedUrlType } from "../../../models/PreSignedUrlType";

export default function UserForm(props: IImportForm) {
    const modalShow = useSelector((state: AppState) => state.modalState.activeModals.includes(IMP0RT_CONFIRM_MODAL));
    // const confirmImportIsVisible = useSelector((state: AppState) =>
    //     state.modalState.activeModals.includes(IMP0RT_CONFIRM_MODAL)
    // );
    const dispatch = useDispatch();

    const decodedAccessToken = useSelector((state: AppState) => state.authenticationState.decodedAccessToken);
    const claimTenantName = decodedAccessToken.tenant_name;
    const loggedInUserRole = decodedAccessToken.roles;
    const customerImports = useSelector((state: AppState) => state.customerImportState).customerImports;

    // const roles = useSelector<AppState, Role[]>((state) => state.roleState.roles);
    // const tenants = useSelector<AppState, Tenant[]>((state) => state.tenantState.tenants);

    const errorMessage = "File type is not valid";
    const defaultFileInputMessage = "Select File";
    const [importFile, setFilePath] = useState<File>();
    const masterList = useSelector((state: AppState) => state.reportState.reports);
    const { accessToken } = useSelector((state: AppState) => state.authenticationState);

    const currentCustomerImport = customerImports.find(
        (ci) => !ci.completedDate && ci.finosecSystemId === props.systemId && ci.tenantId === props.tenantId
    );

    const reportNameOptions: KeysysSelectOption[] = masterList
        .filter((ml) => ml.finosecSystemId == props.systemId)
        .map((s: any) => ({ value: s.id, label: `${s.name}` } as KeysysSelectOption));

    function stripFilePath(path: string): string {
        return path.split("\\").pop()!;
    }

    function isExtensionValid(path: string): boolean {
        const extension = path.substring(path.lastIndexOf(".") + 1).toLowerCase();
        return extension === "xlsx" || extension === "xls";
    }

    // const awsFiles = useSelector<AppState, AwsFile[]>((state) => state.awsFileState.awsFiles);

    function handleFileInputChange(file?: File) {
        return file;
    }

    function getInitialValues() {
        return {
            masterListId: props?.report?.id ?? 0,
            exportDate: "",
        };
    }
    const schema = Yup.object<ImportReportModel>().shape({
        masterListId: Yup.number().min(1, "Master list is required"),
        exportDate: Yup.string().required("Export date is required"),
    });

    function getTitle() {
        if (props.type === "import") {
            return "Import Master List";
        }
        return "Import";
    }

    function clearFormAndHide(formProps: FormikProps<ImportReportModel> | FormikHelpers<ImportReportModel>): void {
        formProps.resetForm();
        dispatch(hideModal(IMP0RT_CONFIRM_MODAL));
    }

    // const closeImportConfirmationModal = () => {
    //     dispatch(hideModal(IMP0RT_CONFIRM_MODAL));
    // };

    const handleSubmit = async (values: ImportReportModel, FormikHelpers: FormikHelpers<ImportReportModel>) => {
        if (importFile) {
            //File Properties
            const filePathNameSplit = importFile.name.split(".");
            const fileExtension = importFile.name.split(".")[filePathNameSplit.length - 1];
            const fileName = importFile.name.replace(`.${fileExtension}`, "");
            const timeStamp = moment().format("MMDDYYYYHHmmss");
            const fileNameForAws = `${fileName}_${timeStamp}.${fileExtension}`;

            const preSignedUrlType = PreSignedUrlType.Upload;
            var preSignedUrl = await getPreSignedUrl(
                accessToken,
                fileNameForAws,
                CUSTOMER_IMPORT_BUCKET_NAME!,
                preSignedUrlType
            );

            const awsResult = await uploadFileToS3WithPreSignedUrl(importFile, preSignedUrl);

            var customerImportId = currentCustomerImport?.id;
            var customerImportFile: CustomerImportFile = {
                id: 0,
                reportId: values.masterListId,
                exportDate: values.exportDate,
                fileName: fileNameForAws,
                customerImportId: customerImportId!,
                awsFileId: fileNameForAws,
            };

            if (!currentCustomerImport) {
                const customerImportToCreate: CustomerImport = {
                    tenantId: props.tenantId,
                    finosecSystemId: props.systemId,
                    id: 0,
                };
                dispatch(createCustomerImportFile(customerImportFile, customerImportToCreate));
            } else {
                dispatch(createCustomerImportFile(customerImportFile));
            }
        }
        dispatch(hideModal(IMP0RT_CONFIRM_MODAL));
    };

    return (
        <>
            <Formik
                initialValues={getInitialValues()}
                validationSchema={schema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<ImportReportModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditPartnerModal"
                            show={modalShow}
                            title={getTitle()}
                            closeButtonText={"Close"}
                            okButtonText={"Import"}
                            showSaveButton={!props.report || props.report?.isActive}
                            showDeleteButton={props.type === "import" && props.report?.isActive}
                            // deleteButtonText={"Deactivate"}
                            // onDeleteButtonClick={() => showConfirmDeleteUserModal()}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="userForm">
                                <Card>
                                    <Card.Body>
                                        {loggedInUserRole === "CustomerAdmin" ||
                                        loggedInUserRole === "ReadOnlyCustomer" ? (
                                            <Row className={"mb-4"}>
                                                <Col xs={12} md={{ span: 6, offset: 3 }}>
                                                    <h3>{claimTenantName}</h3>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <Row className={"mb-4"}>
                                                {props.type === "import" && (
                                                    <Col xs={12} lg={6}>
                                                        <KeysysSelect
                                                            formProps={formProps}
                                                            id={"masterListId"}
                                                            placeholder={"Master List..."}
                                                            fieldName={"masterListId"}
                                                            label={"Select Master List"}
                                                            options={reportNameOptions}
                                                            multiple={false}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        )}

                                        <Row className={"mb-4"}>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"exportDate"}
                                                    placeholder={"Calendar Picker"}
                                                    fieldName={"exportDate"}
                                                    label={"System Export Date"}
                                                    type="date"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <bsForm.Group>
                                                    <bsForm.Label>{importFile?.name}</bsForm.Label>
                                                    <bsForm.Control
                                                        type="file"
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            const importFile: File = e.target.files![0];
                                                            setFilePath(importFile);
                                                        }}
                                                        data-browse={"Upload Report"}
                                                        id={"UploadReport"}
                                                    />
                                                    <img className={"mt-3"} width="200px" alt=""></img>
                                                </bsForm.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
            {/* <ConfirmationModal
                id="confirmDeleteUserModal"
                show={confirmImportIsVisible}
                title="Close Import?"
                message={"Do you really want to deactivate this user?"}
                onCloseButtonClick={closeImportConfirmationModal}
                // onOkButtonClick={() => handleDeleteUser(props.user!)}
                theme={"danger"}
            /> */}
        </>
    );
}
