import {
    CustomerImportFileStatusUpsertedAction,
    UPSERT_CUSTOMER_IMPORT_FILE_STATUS,
} from "./CustomerImportFileStatusActions";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { CustomerImportFileStatus } from "../../models/CustomerImportFileStatus";
import { ICustomerImportFileStatusService } from "../../services/customerImportFileStatus/ICustomerImportFileStatusService";

export function upsertCustomerImportFileStatus(
    customerImportFileStatus: CustomerImportFileStatus
): KeysysThunkAction<void, CustomerImportFileStatusUpsertedAction> {
    return async (dispatch, getState, container) => {
        const customerImportFileStatusService = container.get<ICustomerImportFileStatusService>(
            TYPES.CustomerImportFileStatusService
        );
        // fire and forget, not awaiting
        customerImportFileStatusService.upsertCustomerImportFileStatus(customerImportFileStatus);
        dispatch<CustomerImportFileStatusUpsertedAction>({
            type: UPSERT_CUSTOMER_IMPORT_FILE_STATUS,
        });
    };
}
