export const FlagToggle = (props: any) => {
    const {
        displayOnly,
        onFlag,
        customerReportReview: { ...review },
        rowNo,
    } = props;
    const isFlagged = props.customerReportReview.isFlagged;
    const id = props.customerReportReview.id;
    return (
        <i
            className={isFlagged ? "fas fa-flag" : "far fa-flag"}
            style={
                isFlagged
                    ? { fontSize: "1rem", textAlign: "center", color: "#980101", cursor: "pointer" }
                    : { fontSize: "1rem", textAlign: "center", cursor: "pointer" }
            }
            id={`Flagged_${id}`}
            onClick={() => {
                !displayOnly && onFlag(!isFlagged, rowNo, id, review);
            }}
        />
    );
};
