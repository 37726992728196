import {
    AddCustomerReportReviewAction,
    ADD_CUSTOMER_REPORT_REVIEW_SUCCESS,
    AllCustomerReportReviewsFetchedAction,
    ALL_CUSTOMER_REPORTS_REVIEW_FETCHED,
    BulkCustomerReportReviewUpsertAction,
    BULK_CUSTOMER_REPORT_REVIEW_UPSERT,
    CustomerReportReviewsFetchedAction,
    CustomerReportsFetchedAction,
    CUSTOMER_REPORTS_FETCHED,
    CUSTOMER_REPORTS_REVIEW_FETCHED,
    EditCustomerReportAction,
    EDIT_CUSTOMER_REPORT_SUCCESS,
    FetchCustomerReportsAction,
    FETCH_CUSTOMER_REPORTS,
    FETCH_CUSTOMER_REPORTS_REVIEW,
    PdfRequestSubmittedAction,
    PDF_REQUEST_SUBMITTED,
    CUSTOMER_REPORT_FLAG,
    CustomerReportFlagAction,
    CUSTOMER_REPORTS_LOADING,
    CustomerReportsLoadingAction,
    CustomerReportReviewsLoadingAction,
    CUSTOMER_REPORT_REVIEWS_LOADING,
} from "./CustomerReportAction";
import { KeysysThunkAction } from "../../store/KeysysThunkAction";
import { TYPES } from "../../dependencyInjection/Types";
import { ICustomerReportService } from "../../services/customerReport/ICustomerReportService";
import {
    CustomerReportReviewCreate,
    CustomerReportReviewCreateBulk,
    ICustomerReportReview,
} from "../../models/CustomerReportReview";
import { CustomerReport, CustomerReportFlag } from "../../models/CustomerReport";
import { INoteService } from "../../services/note/INoteService";
import { IPostNote } from "../../models/Note";
import { GeneratePdfRequest } from "../../models/GeneratePdfRequest";

export function getCustomerReportsByCustomerImportId(
    customerImportId: number
): KeysysThunkAction<void, CustomerReportsFetchedAction> {
    return async (dispatch, getState, container) => {
        dispatch<any>({
            type: FETCH_CUSTOMER_REPORTS,
        });
        const customerReportService = container.get<ICustomerReportService>(TYPES.CustomerReportService);
        const customerReports = await customerReportService.getCustomerReportsByCustomerImportId(customerImportId);

        if (customerReports) {
            dispatch<CustomerReportsFetchedAction>({
                type: CUSTOMER_REPORTS_FETCHED,
                customerReports: customerReports,
            });
        }
    };
}

export function setCustomerReportsLoading(): KeysysThunkAction<void, CustomerReportsLoadingAction> {
    return async (dispatch) => {
        dispatch<any>({
            type: CUSTOMER_REPORTS_LOADING,
        });
    };
}

export function setCustomerReportReviewsLoading(): KeysysThunkAction<void, CustomerReportReviewsLoadingAction> {
    return async (dispatch) => {
        dispatch<any>({
            type: CUSTOMER_REPORT_REVIEWS_LOADING,
        });
    };
}

export function editCustomerReport(
    customerReport: CustomerReport,
    newCustomerReport: CustomerReport,
    createNote?: IPostNote
): KeysysThunkAction<void, EditCustomerReportAction> {
    return async (dispatch, getState, container) => {
        const customerReportService = container.get<ICustomerReportService>(TYPES.CustomerReportService);
        const editedCustomerReport = await customerReportService.editCustomerReport(customerReport, newCustomerReport);
        if (editedCustomerReport) {
            if (createNote) {
                const noteService = container.get<INoteService>(TYPES.NoteService);
                noteService.createNote(createNote);
            }
            dispatch<any>({
                type: EDIT_CUSTOMER_REPORT_SUCCESS,
                customerReport: customerReport,
                newCustomerReport: editedCustomerReport,
            });
        }
    };
}

export function getCustomerReportReviews(
    reportId: number,
    customerImportFileId: number
): KeysysThunkAction<void, CustomerReportReviewsFetchedAction> {
    return async (dispatch, getState, container) => {
        dispatch<any>({
            type: FETCH_CUSTOMER_REPORTS_REVIEW,
        });
        const customerReportService = container.get<ICustomerReportService>(TYPES.CustomerReportService);
        const customerReportReviews = await customerReportService.getCustomerReportReviews(
            reportId,
            customerImportFileId
        );

        if (customerReportReviews) {
            dispatch<CustomerReportReviewsFetchedAction>({
                type: CUSTOMER_REPORTS_REVIEW_FETCHED,
                customerReportReviews: customerReportReviews,
            });
        }
    };
}

export function getAllCustomerReportReviews(): KeysysThunkAction<void, AllCustomerReportReviewsFetchedAction> {
    return async (dispatch, getState, container) => {
        dispatch<any>({
            type: FETCH_CUSTOMER_REPORTS_REVIEW,
        });
        const customerReportService = container.get<ICustomerReportService>(TYPES.CustomerReportService);
        const customerReportReviews = await customerReportService.getAllCustomerReportReviews();

        if (customerReportReviews) {
            dispatch<AllCustomerReportReviewsFetchedAction>({
                type: ALL_CUSTOMER_REPORTS_REVIEW_FETCHED,
                customerReportReviews: customerReportReviews,
            });
        }
    };
}

export function createCustomerReportReview(
    customerReportReview: CustomerReportReviewCreate,
    customerReportFlag: CustomerReportFlag
): KeysysThunkAction<void, AddCustomerReportReviewAction> {
    return async (dispatch, getState, container) => {
        const customerReportService = container.get<ICustomerReportService>(TYPES.CustomerReportService);
        const createdCustomerReportReview = await customerReportService.createCustomerReportReview(
            customerReportReview
        );

        customerReportService.flagCustomerReport(customerReportFlag);
        if (createdCustomerReportReview) {
            dispatch<AddCustomerReportReviewAction>({
                type: ADD_CUSTOMER_REPORT_REVIEW_SUCCESS,
                customerReportReview: createdCustomerReportReview,
            });
        }
        return !!createdCustomerReportReview;
    };
}

export function bulkCreateCustomerReportReviews(
    bulkRequest: CustomerReportReviewCreateBulk
): KeysysThunkAction<void, BulkCustomerReportReviewUpsertAction> {
    return async (dispatch, getState, container) => {
        const customerReportService = container.get<ICustomerReportService>(TYPES.CustomerReportService);
        // fire and forget; not awaiting due to potentially long processing time
        customerReportService.bulkCreateCustomerReportReviews(bulkRequest);
        dispatch<BulkCustomerReportReviewUpsertAction>({
            type: BULK_CUSTOMER_REPORT_REVIEW_UPSERT,
            bulkRequest: bulkRequest,
        });
    };
}

export function submitGeneratePdfRequest(
    generatePdfRequest: GeneratePdfRequest,
    isSingleReport: boolean
): KeysysThunkAction<void, PdfRequestSubmittedAction> {
    return async (dispatch, getState, container) => {
        const customerReportService = container.get<ICustomerReportService>(TYPES.CustomerReportService);
        await customerReportService.submitGeneratePdfRequest(generatePdfRequest, isSingleReport);
        dispatch<PdfRequestSubmittedAction>({
            type: PDF_REQUEST_SUBMITTED,
        });
    };
}
