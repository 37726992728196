import { User } from "../../models/User";

export const USERS_FETCHED = "USERS_FETCHED";
export const INACTIVE_USERS_FETCHED = "INACTIVE_USERS_FETCHED";
export const USER_CREATED = "USER_CREATED";
export const USER_DELETED = "USER_DELETED";
export const USER_EDITED = "USER_EDITED";
export const USER_RESTORED = "USER_RESTORED";
export const SELECT_USER = "SELECT_USER";
export const CLEAR_SELECTED_USER = "CLEAR_SELECTED_USER";
export const REFRESH = "REFRESH";
export const USER_IMPERSONATED = "User Impersonated";
export const USER_UNIMPERSONATED = "User Unimpersonated";

export interface UsersFetchedAction {
    type: typeof USERS_FETCHED;
    users: User[];
}

export interface InactiveUsersFetchedAction {
    inactiveUsers: User[];
    type: typeof INACTIVE_USERS_FETCHED;
}

export interface UserCreatedAction {
    type: typeof USER_CREATED;
    user: User;
}

export interface UserDeletedAction {
    type: typeof USER_DELETED;
    user: User;
}

export interface UserEditedAction {
    newUser: User;
    originalUser: User;
    type: typeof USER_EDITED;
}

export interface UserRestoredAction {
    type: typeof USER_RESTORED;
    user: User;
}

export interface SelectUserAction {
    type: typeof SELECT_USER;
    user: User;
}

export interface ClearSelectedUserAction {
    type: typeof CLEAR_SELECTED_USER;
}

export interface RefreshAction {
    type: typeof REFRESH;
}

export interface UserImpersonatedAction {
    type: typeof USER_IMPERSONATED;
    impersonatorId: number;
}

export interface UserUnimpersonatedAction {
    type: typeof USER_UNIMPERSONATED;
}

export type UserActions =
    | UsersFetchedAction
    | InactiveUsersFetchedAction
    | UserCreatedAction
    | UserDeletedAction
    | UserEditedAction
    | UserRestoredAction
    | SelectUserAction
    | ClearSelectedUserAction
    | RefreshAction
    | UserImpersonatedAction
    | UserUnimpersonatedAction;
