import { CustomerImportSubReportAssignment } from "../../models/CustomerImportSubReportAssignment";

export const CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED = "CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED";

export interface CustomerImportSubReportAssignmentsFetchedAction {
    type: typeof CUSTOMER_IMPORT_SUB_REPORT_ASSIGNMENTS_FETCHED;
    customerImportSubReportAssignments: CustomerImportSubReportAssignment[];
}

export type CustomerImportSubReportAssignmentActions = CustomerImportSubReportAssignmentsFetchedAction;
