import { createSelector } from "reselect";
import _ from "lodash";
import { AppState } from "../state/AppState";

export const selectTenants = (state: AppState) => state.tenantState.tenants || [];

export const selectFilteredPartnerCustomerTenants = createSelector(
    [selectTenants, (state: AppState, id: number | null) => id],
    (tenants, partnerId) => {
        return {
            partnerTenants: tenants.filter((t) => !t.parentTenantId),
            customerTenants: partnerId ? tenants.filter((t) => t.parentTenantId === partnerId) : [],
        };
    }
);
