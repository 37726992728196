import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useCallback, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateEmployeeCoreIds } from "../../actions/employeeCoreId/EmployeeCoreIdActionCreator";
import { updateEmployeeSamAccounts } from "../../actions/employeeSamAccount/EmployeeSamAccountActionCreator";
import KeysysInput from "../form/input/KeysysInput";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { EditEmployeeFormModel } from "./EditEmployeeFormModel";
import { IEmployeeForm } from "./IEmployeeForm";

export default function EmployeeForm(props: IEmployeeForm) {
    const dispatch = useDispatch();
    const formikRef = useRef<FormikProps<any>>(null);

    const getInitialValues = useCallback(() => {
        return {
            firstName: props.employee?.firstName ?? "",
            lastName: props.employee?.lastName ?? "",
            emailAddress: props.employee?.email ?? "",
            nickName: props.employee?.nickName ?? "",
            employeeSamAccounts: props.employee?.employeeSamAccounts
                ? props.employee?.employeeSamAccounts.map((esam) => esam.samAccount).join(",")
                : "",
            employeeCoreIds: props.employee?.employeeCoreIds
                ? props.employee?.employeeCoreIds.map((ecid) => ecid.coreId).join(",")
                : "",
        };
    }, [props]);

    function handleSubmit(values: EditEmployeeFormModel, formikHelpers: FormikHelpers<EditEmployeeFormModel>) {
        if (props.employee?.id) {
            dispatch(updateEmployeeCoreIds(props.employee?.id, values.employeeCoreIds.split(",")));
            dispatch(
                updateEmployeeSamAccounts(
                    props.employee?.id,
                    props.employee?.tenantId,
                    values.employeeSamAccounts.split(",")
                )
            );
        }
        clearFormAndHide(formikHelpers);
    }

    function clearFormAndHide(
        formProps: FormikProps<EditEmployeeFormModel> | FormikHelpers<EditEmployeeFormModel>
    ): void {
        formProps.resetForm();
        props.onClose();
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<EditEmployeeFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="editEmployeeModal"
                            show={true}
                            title={"Edit Employee"}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                            showSaveButton={true}
                        >
                            <Form id="employee-edit-form">
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"firstName"}
                                            placeholder={""}
                                            fieldName={"firstName"}
                                            label={"First Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"lastName"}
                                            placeholder={""}
                                            fieldName={"lastName"}
                                            label={"Last Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"nickName"}
                                            placeholder={""}
                                            fieldName={"nickName"}
                                            label={"Alternate First Name"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"emailAddress"}
                                            placeholder={""}
                                            fieldName={"emailAddress"}
                                            label={"Email Address"}
                                            disabled={true}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"employeeCoreIds"}
                                            placeholder={""}
                                            fieldName={"employeeCoreIds"}
                                            label={"Employee Core Ids (separated by comma)"}
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col xs={12} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"employeeSamAccounts"}
                                            placeholder={""}
                                            fieldName={"employeeSamAccounts"}
                                            label={"Employee SAM Accounts (separated by comma)"}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
        </>
    );
}
