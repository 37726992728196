import {
    EmployeeSamAccountActions,
    EMPLOYEE_SAM_ACCOUNTS_FETCHED,
} from "../../actions/employeeSamAccount/EmployeeSamAccountAction";
import { EmployeeSamAccountState } from "../../state/EmployeeSamAccountState";

const initialState: EmployeeSamAccountState = {
    employeeSamAccounts: [],
};

export function employeeSamAccountReducer(
    state: EmployeeSamAccountState = initialState,
    action: EmployeeSamAccountActions
): EmployeeSamAccountState {
    switch (action.type) {
        case EMPLOYEE_SAM_ACCOUNTS_FETCHED: {
            return {
                ...state,
                employeeSamAccounts: action.employeeSamAccounts,
            };
        }
        default:
            return state;
    }
}
