import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import type { IHttpClient } from "../HttpClient/IHttpClient";
import { ICustomerImportFileService } from "./ICustomerImportFileService";
import type { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { CustomerImportFile } from "../../models/CustomerImportFile";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import {
    ADD_CUSTOMER_IMPORT_FILE_SUCCESS,
    DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS,
    EDIT_CUSTOMER_IMPORT_FILE_SUCCESS,
} from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import type { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import axios from "axios";
import { getJsonContentFromPresignedUrl, getPreSignedUrl } from "../../helper-functions/S3";
import { CUSTOMER_IMPORT_BUCKET_NAME } from "../../config/config";
import { PreSignedUrlType } from "../../models/PreSignedUrlType";

@injectable()
export class CustomerImportFileService implements ICustomerImportFileService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createCustomerImportFile(customerImportFile: CustomerImportFile): Promise<void | CustomerImportFile> {
        return this.httpClient
            .post<CustomerImportFile>("/customer-import-file", customerImportFile)
            .then((t: any) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Customer Import File created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a Customer Import File. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    createCustomerImportFiles(customerImportFile: FormData): Promise<void | CustomerImportFile> {
        return axios
            .post("/customer-import-file", customerImportFile, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((t: any) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerImportFile "${t.id}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return t;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a Customer Import File. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    editCustomerImportFile(
        customerImportFile: CustomerImportFile,
        newCustomerImportFile: CustomerImportFile
    ): Promise<void | CustomerImportFile> {
        const operations = createPatch(customerImportFile, newCustomerImportFile);
        return this.httpClient
            .patch<CustomerImportFile>(`/customer-import-file/${customerImportFile.id}`, operations)
            .then((patchedCustomerImportFile) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Tenant "${patchedCustomerImportFile.id}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patchedCustomerImportFile;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while editing customerImportFile "${customerImportFile.id}". Please try again. If the problem continues, contact your administrator`
                )
            );
    }

    deleteCustomerImportFile(customerImportFile: CustomerImportFile): Promise<void | CustomerImportFile> {
        return this.httpClient
            .delete(`/customer-import-file/${customerImportFile.id}`)
            .then(() => {
                const returnCustomerImportFile = { ...customerImportFile, isActive: !customerImportFile.isActive };
                const toastProps: KeysysToastProps = {
                    name: DEACTIVATE_CUSTOMER_IMPORT_FILE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `CustomerImportFile "${customerImportFile.id}" ${
                        customerImportFile.isActive ? " deactivated" : " reactivated"
                    } successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return returnCustomerImportFile;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while deactivating the customerImportFile. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportFilesCount(): Promise<void | number> {
        return this.httpClient
            .get<number>(`/customer-import-file/count`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customerImportFiles. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportFiles(): Promise<void | CustomerImportFile[]> {
        return this.httpClient
            .get<CustomerImportFile[]>(`/customer-import-file`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customerImportFiles. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getCustomerImportFilesPaged(pageNo: number, pageSize: number): Promise<void | CustomerImportFile[]> {
        return this.httpClient
            .get<CustomerImportFile[]>(`/customer-import-file/page/${pageNo}/pagesize/${pageSize}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving customerImportFiles. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    async getCustomerImportFileById(id: number): Promise<void | CustomerImportFile> {
        try {
            const response = await this.httpClient.get<CustomerImportFile>(`/customer-import-file/${id}`);
            return response;
        } catch (exception) {
            console.log("customerImportFileService getCustomerImportFileById error", JSON.stringify(exception));
            return this.exceptionHandler.handleError(
                exception,
                "We encountered an error while retrieving the customerImportFile. Please try again. If the problem continues, contact your administrator"
            );
        }
    }

    async getCustomerImportJsonFileById(id: number, accessToken: string): Promise<void | any[]> {
        try {
            const customerImportFile = await this.getCustomerImportFileById(id);

            if (customerImportFile) {
                const preSignedUrlType = PreSignedUrlType.Download;
                const preSignedUrl = await getPreSignedUrl(
                    accessToken,
                    customerImportFile.awsJsonFileId!,
                    CUSTOMER_IMPORT_BUCKET_NAME!,
                    preSignedUrlType
                );

                return await getJsonContentFromPresignedUrl(preSignedUrl);
            } else {
                console.log("no customerImportFile found in getCustomerImportJsonFileById()");
            }
        } catch (err) {
            console.log("customerImportFileService getCustomerImportJsonFileById error", JSON.stringify(err));
        }
    }
}
