import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import type { IHttpClient } from "../HttpClient/IHttpClient";
import type { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import {
    ADD_WORKFLOW_JOB_SUCCESS,
    DELETE_WORKFLOW_JOB_SUCCESS,
    EDIT_WORKFLOW_JOB_SUCCESS,
} from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import type { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import { IWorkflowJobService } from "./IWorkflowJobService";
import { WorkflowJob } from "../../models/WorkflowJob";

@injectable()
export class WorkflowJobService implements IWorkflowJobService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createWorkflowJob(workflowJob: WorkflowJob): Promise<void | WorkflowJob> {
        return this.httpClient
            .post<WorkflowJob>("/workflow-job", workflowJob)
            .then((w) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_WORKFLOW_JOB_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow Job "${w.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return w;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a workflow job. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getWorkflowJobs(): Promise<void | WorkflowJob[]> {
        return this.httpClient
            .get<WorkflowJob[]>("/workflow-job")
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow jobs. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getWorkflowJobsByTenantId(tenantId: number): Promise<void | WorkflowJob[]> {
        return this.httpClient
            .get<WorkflowJob[]>(`/workflow-job/tenant/${tenantId}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow jobs. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getWorkflowJob(id: number): Promise<void | WorkflowJob> {
        return this.httpClient
            .get<WorkflowJob>(`/workflow-job/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow jobs. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    editWorkflowJob(workflowJob: WorkflowJob, newWorkflowJob: WorkflowJob): Promise<void | WorkflowJob> {
        const operations = createPatch(workflowJob, newWorkflowJob);
        return this.httpClient
            .patch<WorkflowJob>(`/workflow-job/${workflowJob.id}`, operations)
            .then((patched) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_WORKFLOW_JOB_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow Job "${patched.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patched;
            })
            .catch((exception) => {
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while updating workflow job "${workflowJob.name}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    deleteWorkflowJob(workflowJob: WorkflowJob): Promise<void | WorkflowJob> {
        return this.httpClient
            .delete(`/workflow-job/${workflowJob.id}`)
            .then(() => {
                const toastProps: KeysysToastProps = {
                    name: DELETE_WORKFLOW_JOB_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow Job "${workflowJob.name}" deactivated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return { ...workflowJob, isActive: false };
            })
            .catch((exception) => {
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while deactivating workflow job "${workflowJob.name}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }
}
