import { createPatch } from "rfc6902";
import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import type { IHttpClient } from "../HttpClient/IHttpClient";
import type { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";
import { KeysysToastProps } from "../../components/toast/KeysysToastProps";
import {
    ADD_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS,
    DELETE_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS,
    EDIT_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS,
} from "../../constants/ToastConstants";
import { showToast } from "../../actions/ToastActionCreators";
import type { Store } from "redux";
import { ShowToastAction } from "../../actions/ToastActions";
import { IWorkflowNotificationTemplateService } from "./IWorkflowNotificationTemplateService";
import { WorkflowNotificationTemplate } from "../../models/WorkflowNotificationTemplate";

@injectable()
export class WorkflowNotificationTemplateService implements IWorkflowNotificationTemplateService {
    constructor(
        @inject(TYPES.Store) private store: Store,
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    createWorkflowNotificationTemplate(
        workflowNotificationTemplate: WorkflowNotificationTemplate
    ): Promise<void | WorkflowNotificationTemplate> {
        return this.httpClient
            .post<WorkflowNotificationTemplate>("/workflow-notification-template", workflowNotificationTemplate)
            .then((w) => {
                const toastProps: KeysysToastProps = {
                    name: ADD_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow notification template "${w.name}" created successfully.`,
                };

                this.store.dispatch<ShowToastAction>(showToast(toastProps));
                return w;
            })
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while creating a workflow notification template. Please try again. If the problem continues, contact your administrator"
                )
            );
    }

    getWorkflowNotificationTemplates(): Promise<void | WorkflowNotificationTemplate[]> {
        return this.httpClient
            .get<WorkflowNotificationTemplate[]>("/workflow-notification-template")
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow notification templates. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    getWorkflowNotificationTemplate(id: number): Promise<void | WorkflowNotificationTemplate> {
        return this.httpClient
            .get<WorkflowNotificationTemplate>(`/workflow-notification-template/${id}`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while retrieving workflow notification templates. Please try again. If the problem continues, contact your administrator.`
                )
            );
    }

    editWorkflowNotificationTemplate(
        workflowNotificationTemplate: WorkflowNotificationTemplate,
        newWorkflowNotificationTemplate: WorkflowNotificationTemplate
    ): Promise<void | WorkflowNotificationTemplate> {
        const operations = createPatch(workflowNotificationTemplate, newWorkflowNotificationTemplate);
        return this.httpClient
            .patch<WorkflowNotificationTemplate>(
                `/workflow-notification-template/${workflowNotificationTemplate.id}`,
                operations
            )
            .then((patched) => {
                const toastProps: KeysysToastProps = {
                    name: EDIT_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow notification template "${patched.name}" updated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return patched;
            })
            .catch((exception) => {
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while updating workflow notification template "${workflowNotificationTemplate.name}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }

    deleteWorkflowNotificationTemplate(
        workflowNotificationTemplate: WorkflowNotificationTemplate
    ): Promise<void | WorkflowNotificationTemplate> {
        return this.httpClient
            .delete(`/workflow-notification-template/${workflowNotificationTemplate.id}`)
            .then(() => {
                const toastProps: KeysysToastProps = {
                    name: DELETE_WORKFLOW_NOTIFICATION_TEMPLATE_SUCCESS,
                    theme: "success",
                    titleInHeader: "Success!",
                    body: `Workflow notification template "${workflowNotificationTemplate.name}" deactivated successfully.`,
                };

                this.store.dispatch(showToast(toastProps));
                return { ...workflowNotificationTemplate, isActive: false };
            })
            .catch((exception) => {
                this.exceptionHandler.handleError(
                    exception,
                    `We encountered an error while deactivating workflow notification template "${workflowNotificationTemplate.name}". Please try again. If the problem continues, contact your administrator.`
                );
            });
    }
}
