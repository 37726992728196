import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as yup from "yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    createIntakeCustomerFile,
    editIntakeCustomerFile,
} from "../../actions/intakeCustomerFiles/IntakeCustomerFilesActionCreators";
import { getFileTypes } from "../../actions/fileType/FileTypeActionCreators";
import { IntakeCustomerFile } from "../../models/IntakeCustomerFile";
import { IntakeSystemFile } from "../../models/IntakeSystemFile";
import { WorkflowJob } from "../../models/WorkflowJob";
import { AppState } from "../../state/AppState";
import { Tenant } from "../../models/Tenant";
import { FinosecSystem } from "../../models/FinosecSystem";
import { KeysysToastProps } from "../toast/KeysysToastProps";
import { showToast } from "../../actions/ToastActionCreators";
import { getPreSignedUrl, uploadFileToS3WithPreSignedUrl } from "../../helper-functions";
import KeysysInput from "../form/input/KeysysInput";
import { getFileStatuses } from "../../actions/fileStatus/FileStatusActionCreators";
import { CUSTOMER_INTAKE_BUCKET_NAME } from "../../config/config";
import { PreSignedUrlType } from "../../models/PreSignedUrlType";

interface IIntakeCustomerFileUploadProps {
    intakeCustomerFile?: IntakeCustomerFile;
    intakeSystemFile: IntakeSystemFile;
    workflowJob: WorkflowJob;
    uploadFile?: File;
    onSave: () => void;
    onClose?: VoidFunction;
    isRejectModal?: boolean;
    fileStatusMessage?: string;
    filesStatusId?: number;
}

export const IntakeCustomerFileForm = ({
    onClose,
    onSave,
    intakeCustomerFile,
    intakeSystemFile,
    workflowJob,
    uploadFile,
    isRejectModal,
}: IIntakeCustomerFileUploadProps) => {
    const dispatch = useDispatch();
    const fileTypes = useSelector((state: AppState) => state.fileTypeState.fileTypes);
    const fileStatuses = useSelector((state: AppState) => state.fileStatusState.statuses);
    const tenants = useSelector<AppState, Tenant[]>((state: any) => state.tenantState.tenants);
    const systems = useSelector<AppState, FinosecSystem[]>((state: any) => state.finosecSystemState.finosecSystems);
    const [intakeCustomerFilePath, setIntakeCustomerFilePath] = useState<File>();
    const currentExtension = fileTypes.find((ft: any) => ft.id === intakeSystemFile.fileTypeId)?.extension;
    const currentExtensionExpression = new RegExp(".(" + currentExtension + ")");
    const { accessToken } = useSelector((state: AppState) => state.authenticationState);

    const schema = yup.object<IIntakeCustomerFileUploadProps>().shape({
        uploadFileName: yup
            .string()
            .matches(currentExtensionExpression, "Must match required file type " + currentExtension),
    });
    useEffect(() => {
        dispatch(getFileTypes());
        dispatch(getFileStatuses());
    }, [dispatch]);

    const initialValues = useMemo(
        (): IIntakeCustomerFileUploadProps => ({
            intakeSystemFile: intakeSystemFile,
            onSave: onSave,
            intakeCustomerFile: intakeCustomerFile,
            workflowJob: workflowJob,
            isRejectModal: isRejectModal,
            fileStatusMessage: intakeCustomerFile?.fileStatusMessage,
        }),
        [intakeCustomerFile, intakeSystemFile, isRejectModal, onSave, workflowJob]
    );
    const clearFormAndHide = useCallback(
        (formProps: FormikProps<IIntakeCustomerFileUploadProps> | FormikHelpers<IIntakeCustomerFileUploadProps>) => {
            formProps.resetForm();
            if (onClose) {
                onClose();
            }
        },
        [onClose]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getDirectory = function () {
        var customer = tenants?.find((t: any) => t.id === workflowJob?.customerId);
        var system = systems?.find((s: any) => s.id === intakeSystemFile?.systemId);
        var fullDirectory = `${customer?.name}/${system?.name}/Intake`;
        if (customer && system) return fullDirectory;
        return "Unspecified";
    };

    const handleSubmit = useCallback(
        async (values: IIntakeCustomerFileUploadProps, helpers: FormikHelpers<IIntakeCustomerFileUploadProps>) => {
            if (isRejectModal && intakeCustomerFile) {
                let updatedFileStatusId = fileStatuses.find((fs: any) => fs.name === "Rejected")?.id;
                values.filesStatusId = updatedFileStatusId;

                dispatch(
                    editIntakeCustomerFile(intakeCustomerFile, {
                        ...intakeCustomerFile,
                        fileStatusId: updatedFileStatusId,
                        fileStatusMessage: values.fileStatusMessage,
                    })
                );
                clearFormAndHide(helpers);
            }
            if (!intakeCustomerFilePath) {
            } else {
                const fileName = intakeCustomerFilePath?.name ?? "";
                const processingFileNameFull =
                    intakeSystemFile.processingFileName +
                    "_" +
                    Date.now() +
                    "." +
                    fileTypes.find((ft: any) => ft.id === intakeSystemFile.fileTypeId)?.extension;

                const preSignedUrlType = PreSignedUrlType.Upload;
                var preSignedUrl = await getPreSignedUrl(
                    accessToken,
                    processingFileNameFull,
                    CUSTOMER_INTAKE_BUCKET_NAME!,
                    preSignedUrlType
                );

                if (!preSignedUrl) return;

                const awsResult = await uploadFileToS3WithPreSignedUrl(intakeCustomerFilePath, preSignedUrl);

                // eslint-disable-next-line eqeqeq
                if (awsResult == 200) {
                    if (!intakeCustomerFile) {
                        dispatch(
                            createIntakeCustomerFile({
                                //...values,
                                systemId: intakeSystemFile.systemId,
                                uploadFileName: fileName, //values.UploadDocument,
                                intakeSystemFileId: intakeSystemFile.id,
                                jobId: workflowJob.id, //intakeSystemFile.jobId,
                                displayFileName: intakeSystemFile.displayFileName,
                                fileTypeId: intakeSystemFile.fileTypeId,
                                fileStatusId: fileStatuses.find((fs: any) => fs.name === "Uploaded")?.id,
                                processingFileName: processingFileNameFull,
                                processingFilePath: `${getDirectory()}/${processingFileNameFull}}`,
                                isActive: true,
                                id: 0,
                                CustomerId: workflowJob.customerId,
                            })
                        );
                    } else {
                        let updatedFileStatusId = fileStatuses.find((fs: any) => fs.name === "Uploaded")?.id;
                        dispatch(
                            editIntakeCustomerFile(intakeCustomerFile, {
                                ...intakeCustomerFile,
                                processingFileName: processingFileNameFull,
                                processingFilePath: `${getDirectory()}/${processingFileNameFull}}`,
                                fileStatusId: updatedFileStatusId,
                                fileStatusMessage: "",
                            })
                        );
                    }
                    clearFormAndHide(helpers);
                } else {
                    const toastProps: KeysysToastProps = {
                        name: "Error",
                        theme: "danger",
                        titleInHeader: "Error",
                        body:
                            "We encountered an error while uploading the file. Please try again. If the problem continues, contact your administrator",
                    };
                    dispatch(showToast(toastProps));
                }
            }
        },
        [
            accessToken,
            clearFormAndHide,
            dispatch,
            fileStatuses,
            fileTypes,
            getDirectory,
            intakeCustomerFile,
            intakeCustomerFilePath,
            intakeSystemFile.displayFileName,
            intakeSystemFile.fileTypeId,
            intakeSystemFile.id,
            intakeSystemFile.processingFileName,
            intakeSystemFile.systemId,
            isRejectModal,
            workflowJob.customerId,
            workflowJob.id,
        ]
    );

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<IIntakeCustomerFileUploadProps>) => (
                    <VerticallyCenteredModal
                        id="addEditIntakeCustomerFileModal"
                        show={true}
                        title={!intakeCustomerFile ? "Create Intake Customer File" : "Edit Intake Customer File"}
                        closeButtonText={"Close"}
                        okButtonText={"Save"}
                        showSaveButton={true}
                        showDeleteButton={false}
                        deleteButtonText={"Deactivate"}
                        showRestoreButton={false}
                        restoreButtonText={"Activate"}
                        onDeleteButtonClick={() => console.log("delete")}
                        onRestoreButtonClick={() => console.log("restore")}
                        onCloseButtonClick={() => clearFormAndHide(formProps)}
                        onOkButtonClick={formProps.submitForm}
                    >
                        <Form id="addEditIntakeCustomerFileForm">
                            {(isRejectModal || (!isRejectModal && intakeCustomerFile?.fileStatusMessage)) && (
                                <Row className={"mb-4"}>
                                    <Col xs={8} md={{ span: 6, offset: 3 }}>
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"fileStatusMessage"}
                                            label={"File Rejection Details"}
                                            fieldName={"fileStatusMessage"}
                                            type={"textarea"}
                                            placeholder={""}
                                        />
                                    </Col>
                                </Row>
                            )}
                            <Row className={"mb-4"}>
                                <Col>
                                    {!isRejectModal && (
                                        <KeysysInput
                                            formProps={formProps}
                                            id={"fullDirectory"}
                                            placeholder={""}
                                            fieldName={"uploadFileName"}
                                            label={"Description"}
                                            type={"file"}
                                            onChangeFile={(f: File | null) => {
                                                if (f) setIntakeCustomerFilePath(f);
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </VerticallyCenteredModal>
                )}
            </Formik>
        </>
    );
};
