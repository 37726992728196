import SmallSquareDeleteButton from "../buttons/SmallSquareDeleteButton/SmallSquareDeleteButton";
import { Identifiable } from "../../models/Identifiable";

interface DeleteButtonRenderProps {
    data: Identifiable;
    entityName: string;
    showDelete: (id: number) => void;
    isActive?: boolean;
}

export default function DeleteButtonRender(props: DeleteButtonRenderProps) {
    return (
        <SmallSquareDeleteButton
            key={`delete-${props.data.id}`}
            entityNameSingular={props.entityName}
            onClick={() => props.showDelete(props.data.id)}
            isActive={props.isActive}
        />
    );
}
