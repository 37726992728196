import { createSelector } from "reselect";
import _ from "lodash";
import { AppState } from "../state/AppState";
import { MappedCustomerReport } from "../models/CustomerReport";
import { SubReport } from "../models/SubReport";
import { CustomerReportDisplay } from "../models/CustomerReportDisplay";
import { CustomerImportSubReportAssignment } from "../models/CustomerImportSubReportAssignment";
import { CustomerImport } from "../models/CustomerImport";

export const selectCustomerReports = (state: AppState) => state.customerReportState.customerReports;
export const selectSubReports = (state: AppState) => state.subReportState.subReports;
export const selectSubReportCategories = (state: AppState) => state.subReportCategoryState.subReportCategories;
export const selectCustomerReportDisplays = (state: AppState) =>
    state.customerReportDisplayState.customerReportDisplays;
const selectTenantId = (state: AppState) => state.authenticationState.decodedAccessToken.tenant_id;
const selectCustomerImports = (state: AppState) => state.customerImportState.customerImports;
const selectCustomerImportSubReportAssignments = (state: AppState) =>
    state.customerImportSubReportAssignmentState.customerImportSubReportAssignments;

export const isSubReportHidden = (
    isSubReport: boolean,
    subReport: SubReport | undefined,
    display: CustomerReportDisplay | undefined,
    assignments: CustomerImportSubReportAssignment[] | undefined,
    customerImport: CustomerImport | undefined
): boolean => {
    if (!isSubReport || !subReport) return false;
    if (!customerImport?.isCurrent && assignments && assignments.length > 0 && subReport.reportTypeId === 2) {
        const assignment = assignments.find((cisra) => cisra.subReportId === subReport.id);
        if (assignment && !display?.isHidden) {
            return false;
        }
        return true;
    }
    if (customerImport?.isCurrent && (subReport.isForced || !display)) {
        return false;
    }
    return display ? display.isHidden : false;
};

export const selectMappedCustomerReports = createSelector(
    [
        selectCustomerReports,
        selectSubReportCategories,
        selectSubReports,
        selectCustomerReportDisplays,
        selectCustomerImportSubReportAssignments,
        selectCustomerImports,
        selectTenantId,
    ],
    (
        customerReports,
        subReportCategories,
        subreports,
        customerReportDisplays,
        customerImportSubReportAssignments,
        customerImports,
        tenantId
    ) => {
        let result = _.orderBy(
            customerReports.map((cr) => {
                const subReport = cr.isSubReport ? subreports.find((sr) => sr.id === cr.subReportId) : undefined;
                const subReportCategory = subReport
                    ? subReportCategories.find((sc) => sc.id === subReport.categoryId)
                    : null;
                const maxOrdinal = _.max(subReportCategories.map((sc) => sc.ordinal)) || 0;
                const display = cr.isSubReport
                    ? customerReportDisplays.find(
                          (crd) => crd.subReportId === cr.subReportId && crd.customerImportId === cr.customerImportId
                      )
                    : undefined;
                const customerImport = customerImports.find((ci) => ci.id === cr.customerImportId);
                const isHidden = isSubReportHidden(
                    cr.isSubReport,
                    subReport,
                    display,
                    customerImportSubReportAssignments,
                    customerImport
                );

                return {
                    ...cr,
                    categoryName: subReportCategory
                        ? subReportCategory.name
                        : subReport
                        ? "Access Report"
                        : "Master List",
                    categoryOrdinal: subReportCategory ? subReportCategory.ordinal : subReport ? -1 : maxOrdinal + 1,
                    isHidden: isHidden,
                    isForced: cr.isSubReport && subReport?.isForced,
                    tenantIds: cr.isSubReport ? subReport?.tenantIds : null,
                } as MappedCustomerReport;
            }),
            (cr) => cr.categoryOrdinal
        );
        // filter by tenants if report type is not default
        return result.filter(
            (cr) => cr.reportTypeId !== 2 || (cr.reportTypeId === 2 && cr.tenantIds?.includes(tenantId))
        );
    }
);
