import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useCustomerReportListForm } from "../../hooks/customerReportHook";
import { AppState } from "../../state/AppState";
import PageHeader from "../header/PageHeader";
import _ from "lodash-es";
import KeysysGrid from "../grid/KeysysGrid";
import { useCallback, useMemo, useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { FlagToggle } from "./FlagToggle";
import { ReviewToggle } from "./ReviewToggle";
import { GroupReviewToggle } from "./GroupReviewToggle";
import { Notes } from "../notes/Notes";
import { CustomerReportReviewCreate, CustomerReportReviewCreateBulk } from "../../models/CustomerReportReview";
import { ColDef } from "ag-grid-community";
import { Col, Row, Button } from "react-bootstrap";
import CustomerReportPrintModal from "./customerReportPrintModal";
import CustomerReportMarkReviewModal from "./customerReportMarkReviewModal";
import "./customerPrintModal.scss";
import LinkWrapper from "../routing/LinkWrapper";
import { bulkCreateCustomerReportReviews } from "../../actions/customerReport/CustomerReportActionCreator";
import VerticallyCenteredModal from "../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { LoadingSpinner } from "../loading/Loading";
import { CustomerImportFileStatus } from "../../models/CustomerImportFileStatus";
import { upsertCustomerImportFileStatus } from "../../actions/customerImportFileStatus/CustomerImportFileStatusActionCreators";
import { CustomerReportFlag } from "../../models/CustomerReport";

const hasProps = (item: object) => {
    if (!item) return false;

    return Object.keys(item).length > 0;
};

const CustomerReportSummary = () => {
    let params = useParams<{ customerImportId: any; customerReportId: any }>();

    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { impersonator } = useSelector((state: AppState) => state.userState);
    const isReadOnlyCustomer = decodedAccessToken.roles === "ReadOnlyCustomer" || !!impersonator;

    const {
        finosecSystem,
        tenantName,
        user,
        markCustomerReportAsReviewed,
        markCustomerReportAsNotReviewed,
        updateReportReview,
        tableData,
        headers,
        pageSize,
        setPageSize,
        tenantId,
        customerReport,
        attributes,
        createNote,
        notes,
        loading,
        notesLoading,
        customerImportFileId,
        setHasLoadedData,
    } = useCustomerReportListForm(+params.customerReportId);

    const dispatch = useDispatch();

    const isLoadingState = useSelector((state: AppState) =>
        state.customerReportState
            ? state.customerReportState.loading || state.customerReportState.customerReportReviewsLoading
            : true
    );
    const isLoading = loading || notesLoading || isLoadingState;
    const [manuallyMarkedAsReviewed, setManuallyMarkedAsReviewed] = useState<boolean>(false);
    const [markedAsNotReviewed, setMarkedAsNotReviewed] = useState<boolean>(false);

    const initiateMarkAsReviewed = useCallback(
        (note: string, automatic: boolean = false) => {
            setMarkedAsNotReviewed(false);
            setManuallyMarkedAsReviewed(true);
            markCustomerReportAsReviewed(note, automatic);
        },
        [markCustomerReportAsReviewed]
    );

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const getInitialHeader = useCallback((): string => {
        let resultArray: string[] = [];
        if (attributes) {
            attributes.forEach((attribute) => {
                if (attribute.subReport && attribute.subReport.groupBy) {
                    resultArray.push(attribute.attribute);
                }
            });
        }
        return resultArray.join(" / ");
    }, [attributes]);

    const customerImport = useSelector((state: AppState) =>
        state.customerImportState.customerImports.find((ci) => ci.id === +params.customerImportId)
    );

    const rowIsReviewed = useCallback((row: any): boolean => {
        if (hasProps(row.customerReportReview)) {
            // customerReportReview exists
            return row.customerReportReview.isReviewed;
        }
        // customerReportReview does not exist || row.Review
        return row.Review === 1;
    }, []);

    const numberOfReviewedRows = useMemo(
        () => (tableData && !isLoading ? tableData.filter((row: any) => rowIsReviewed(row)).length : 0),
        [tableData, isLoading, rowIsReviewed]
    );

    const numberOfTableRows = useMemo(() => (tableData && !isLoading ? tableData.length : 0), [tableData, isLoading]);

    const allRowsAreReviewed = useMemo(() => numberOfTableRows > 0 && numberOfTableRows === numberOfReviewedRows, [
        numberOfTableRows,
        numberOfReviewedRows,
    ]);

    useEffect(() => {
        if (
            !isLoading &&
            customerReport?.isSubReport &&
            allRowsAreReviewed &&
            !customerReport.isReviewComplete &&
            numberOfTableRows > 1 &&
            !manuallyMarkedAsReviewed &&
            !markedAsNotReviewed
        ) {
            console.log("All records have been marked as reviewed.");
            markCustomerReportAsReviewed("All records have been marked as reviewed.", true);
        }

        if (
            !isLoading &&
            customerReport?.isSubReport &&
            customerReport.isReviewComplete &&
            !allRowsAreReviewed &&
            numberOfTableRows > 0 &&
            !markedAsNotReviewed &&
            !manuallyMarkedAsReviewed
        ) {
            setMarkedAsNotReviewed(true);
            markCustomerReportAsNotReviewed();
        }
    }, [
        allRowsAreReviewed,
        customerReport,
        isLoading,
        numberOfTableRows,
        manuallyMarkedAsReviewed,
        markedAsNotReviewed,
        markCustomerReportAsReviewed,
        markCustomerReportAsNotReviewed,
    ]);

    useEffect(() => {
        if (!isLoading && numberOfTableRows === 0 && customerReport && !customerReport.isReviewComplete) {
            setShowEmptySubreportModal(true);
        }
    }, [numberOfTableRows, isLoading, customerReport]);

    const [searchText, setSearchText] = useState<string>("");
    const [showEmptySubreportModal, setShowEmptySubreportModal] = useState<boolean>(false);

    const debounceSearch = useMemo(() => {
        return _.debounce((value: string) => setSearchText(value), 300);
    }, []);

    const handleRowFlag = useCallback((flagValue: boolean, rowNumber: number, id?: number, review?: any) => {
        const currentReview = hasProps(review)
            ? review
            : {
                  isReviewed: false,
                  isAutoReviewed: false,
                  tenantId,
                  customerReportId: +params.customerReportId,
                  customerImportFileId: customerImportFileId,
              };
        const reportReview: CustomerReportReviewCreate = {
            ...currentReview,
            id,
            lineNumber: rowNumber,
            isFlagged: flagValue,
            isAutoReviewed: false,
            lastModifiedByName: user,
            lastModifiedBySubReport: customerReport?.subReportName,
            customerReportId: +params.customerReportId,
            customerImportFileId: customerImportFileId, // including customerImportFileId here for backward compatibility
        };

        const customerReportFlag: CustomerReportFlag = flagValue
            ? {
                  id: +params.customerReportId,
                  hasFlags: true,
              }
            : {
                  id: +params.customerReportId,
                  hasFlags: false,
              };

        updateReportReview(reportReview, customerReportFlag);
    }, []);

    const handleRowReviewToggle = useCallback(
        (reviewValue: boolean, rowNumber: number, isFlagged: boolean, id?: number, review?: any) => {
            if (customerImportFileId) {
                setMarkedAsNotReviewed(false);
                const currentReview = hasProps(review)
                    ? review
                    : {
                          isFlagged: isFlagged,
                          isAutoReviewed: false,
                          tenantId,
                          customerReportId: +params.customerReportId,
                          customerImportFileId: customerImportFileId,
                      };
                const reportReview: CustomerReportReviewCreate = {
                    ...currentReview,
                    id,
                    lineNumber: rowNumber,
                    isReviewed: reviewValue,
                    isFlagged: isFlagged,
                    isAutoReviewed: false,
                    lastModifiedByName: user,
                    customerReportId: +params.customerReportId,
                    lastModifiedBySubReport: customerReport?.subReportName,
                    customerImportFileId: customerImportFileId, // including customerImportFileId here for backward compatibility
                };

                const customerReportFlag: CustomerReportFlag = isFlagged
                    ? {
                          id: +params.customerReportId,
                          hasFlags: true,
                      }
                    : {
                          id: +params.customerReportId,
                          hasFlags: false,
                      };

                updateReportReview(reportReview, customerReportFlag);

                const customerImportFileStatus: CustomerImportFileStatus = reviewValue
                    ? {
                          customerImportFileId: customerImportFileId,
                          isPositiveCheck: true,
                      }
                    : {
                          customerImportFileId: customerImportFileId,
                          isNegativeCheck: true,
                      };
                dispatch(upsertCustomerImportFileStatus(customerImportFileStatus));

                if (
                    !isLoading &&
                    !reviewValue &&
                    customerReport?.isSubReport &&
                    customerReport.isReviewComplete &&
                    numberOfTableRows > 0
                ) {
                    setMarkedAsNotReviewed(true);
                    markCustomerReportAsNotReviewed();
                }

                if (
                    !isLoading &&
                    numberOfTableRows === 1 &&
                    reviewValue &&
                    customerReport?.isSubReport &&
                    !customerReport.isReviewComplete
                ) {
                    markCustomerReportAsReviewed("All records have been marked as reviewed.", true);
                }

                setManuallyMarkedAsReviewed(false);
            }
        },
        [customerImportFileId, customerReport, numberOfTableRows, isLoading]
    );

    const handleGroupReviewClick = useCallback(
        (isCurrentlyChecked: boolean, lineNumbers: number[]) => {
            if (customerReport && customerImportFileId) {
                setMarkedAsNotReviewed(false);
                const request: CustomerReportReviewCreateBulk = {
                    isReviewed: !isCurrentlyChecked,
                    lineNumbers: lineNumbers,
                    lastModifiedByName: user,
                    customerReportId: customerReport.id,
                    tenantId: tenantId,
                    customerImportFileId: customerImportFileId,
                    lastModifiedBySubReport: customerReport.subReportName,
                };
                dispatch(bulkCreateCustomerReportReviews(request));

                const customerImportFileStatus: CustomerImportFileStatus = isCurrentlyChecked
                    ? {
                          customerImportFileId: customerImportFileId,
                          isNegativeCheck: true,
                      }
                    : {
                          customerImportFileId: customerImportFileId,
                          isPositiveCheck: true,
                      };
                dispatch(upsertCustomerImportFileStatus(customerImportFileStatus));

                if (
                    !isLoading &&
                    isCurrentlyChecked &&
                    customerReport?.isSubReport &&
                    customerReport.isReviewComplete &&
                    numberOfTableRows > 0
                ) {
                    setMarkedAsNotReviewed(true);
                    markCustomerReportAsNotReviewed();
                }

                if (
                    !isLoading &&
                    numberOfTableRows === 1 &&
                    !isCurrentlyChecked &&
                    customerReport?.isSubReport &&
                    !customerReport.isReviewComplete
                ) {
                    markCustomerReportAsReviewed("All records have been marked as reviewed.", true);
                }

                setManuallyMarkedAsReviewed(false);
            }
        },
        [customerReport, customerImportFileId, isLoading, numberOfTableRows]
    );

    const reviewEmptySubReport = () => {
        markCustomerReportAsReviewed("No matches or changes recorded for this report. Acknowledged by:");
        setShowEmptySubreportModal(false);
    };

    const columnDefs = useMemo(() => {
        let defs = _.orderBy(headers, (h) => h.attribute?.ordinal).map(({ header, attribute, valueIndex }) => {
            const rowGroup = attribute?.subReport?.groupBy || false;
            const hideRow = customerReport?.isSubReport ? attribute?.subReport?.hide || rowGroup : attribute?.isHidden;

            const customWidth = attribute?.columnWidth;
            let colDef: ColDef = {
                headerName: header,
                rowGroup,
                hide: hideRow,
                minWidth: customWidth,
                maxWidth: customWidth,
                filterValueGetter: (params) => {
                    return params.data?.Values[valueIndex];
                },
                valueGetter: (params) => {
                    return params.data?.Values[valueIndex];
                },
            };
            if (attribute?.subReport?.useAttributeInColHeader) {
                colDef = {
                    ...colDef,
                    aggFunc: "first",
                };
            }
            return colDef;
        });

        if (customerReport?.isSubReport && !customerReport.isReadOnlySubReport) {
            defs = [
                ...defs,
                {
                    headerName: "Flag",
                    minWidth: 60,
                    maxWidth: 60,
                    cellRendererFramework: (data: any) => {
                        return data.data?.customerReportReview ? (
                            <FlagToggle
                                displayOnly={
                                    customerReport.isReviewComplete || !customerImport?.isCurrent || isReadOnlyCustomer
                                }
                                onFlag={handleRowFlag}
                                customerReportReview={data?.data?.customerReportReview}
                                rowNo={data?.data?.Row}
                            />
                        ) : (
                            ""
                        );
                    },
                },
                {
                    headerName: "Review",
                    minWidth: 200,
                    maxWidth: 200,
                    cellRendererFramework: (data: any) => {
                        if (data.data?.customerReportReview) {
                            return (
                                <ReviewToggle
                                    displayOnly={!customerImport?.isCurrent || isReadOnlyCustomer}
                                    isReviewComplete={customerReport.isReviewComplete}
                                    onReview={handleRowReviewToggle}
                                    customerReportReview={data?.data?.customerReportReview}
                                    rowNo={data?.data?.Row}
                                    jsonIsReviewed={data?.data?.Review}
                                    jsonReviewedBy={data?.data?.By}
                                    jsonReviewedSR={data?.data?.BySR}
                                    reviewedBy={user}
                                />
                            );
                        }
                        return (
                            <GroupReviewToggle
                                displayOnly={!customerImport?.isCurrent || isReadOnlyCustomer}
                                disabled={searchText?.length > 0}
                                onReview={handleGroupReviewClick}
                                lineNumbersWithJsonStatus={data?.node.allLeafChildren.map((x: any) => {
                                    return {
                                        line: x.data?.Row ? x.data?.Row : 0,
                                        status: x.data?.Review === 1,
                                    };
                                })}
                                customerReportReviews={data?.node.allLeafChildren.map((x: any) => {
                                    if (x.data?.customerReportReview) {
                                        return x.data.customerReportReview;
                                    } else {
                                        return {};
                                    }
                                })}
                            />
                        );
                    },
                },
            ];
        }
        return defs;
    }, [
        headers,
        customerReport?.isSubReport,
        customerReport?.isReadOnlySubReport,
        customerReport?.isReviewComplete,
        customerImport?.isCurrent,
        isReadOnlyCustomer,
        handleRowFlag,
        searchText?.length,
        handleGroupReviewClick,
        handleRowReviewToggle,
        user,
    ]);

    const tableJSX = useMemo(() => {
        return (
            <>
                {columnDefs && tableData && (
                    <KeysysGrid
                        getRowId={(data: any) => data.Row}
                        data={tableData}
                        columnDefs={columnDefs}
                        pagination={true}
                        pageSize={pageSize}
                        searchText={searchText}
                        initialHeader={getInitialHeader()}
                    />
                )}
            </>
        );
    }, [columnDefs, tableData, pageSize, searchText, getInitialHeader]);

    const changePageSize = (value: number) => {
        setPageSize(value);
    };

    const system = useSelector((state: AppState) => state.finosecSystemState.finosecSystems).find(
        (fs) => fs.id === customerImport?.finosecSystemId
    );

    return (
        <>
            {!inIframe() && (
                <div>
                    <PageHeader>
                        <h2>{customerReport?.subReportName || customerReport?.reportName}</h2>
                    </PageHeader>

                    <p className="title-bar ps-3 pt-2 pb-2">
                        <LinkWrapper path={`/uar`}>UAR Home</LinkWrapper> /{" "}
                        <LinkWrapper path={`/uar/customer-import/${params.customerImportId}`}>
                            {system?.name}
                        </LinkWrapper>{" "}
                        / {customerReport?.subReportName || customerReport?.reportName}
                    </p>
                </div>
            )}
            {customerImport && inIframe() && (
                <div className="sticky-notes-iframe">
                    <Notes
                        displayOnly={!customerImport?.isCurrent || isReadOnlyCustomer}
                        currentUserName={user}
                        finosecSystemId={customerImport.finosecSystemId}
                        customerImportId={params.customerImportId}
                        customerReportId={+params.customerReportId}
                        notes={notes}
                        onSave={(note) => createNote(note)}
                    />
                </div>
            )}
            {customerImport && !inIframe() && (
                <div className="sticky-notes">
                    <Notes
                        displayOnly={!customerImport?.isCurrent || isReadOnlyCustomer}
                        currentUserName={user}
                        finosecSystemId={customerImport.finosecSystemId}
                        customerImportId={params.customerImportId}
                        customerReportId={+params.customerReportId}
                        notes={notes}
                        onSave={(note) => createNote(note)}
                    />
                </div>
            )}
            <br />
            <div className="ag-theme-alpine">
                <Row>
                    <Col md={4}>
                        <div>
                            <input
                                className="form-control"
                                placeholder="Search"
                                onChange={(e) => debounceSearch(e.target.value)}
                            />
                        </div>
                    </Col>
                    <Col md={2}>
                        <CreatableSelect
                            style={{ width: "20%" }}
                            onChange={(v) => changePageSize(v?.value || 15)}
                            defaultValue={null}
                            placeholder={"Select Page Size"}
                            options={[
                                { value: 10, label: "10" },
                                { value: 15, label: "15" },
                                { value: 25, label: "25" },
                                { value: 50, label: "50" },
                                { value: 100, label: "100" },
                            ]}
                        />
                    </Col>
                    {customerReport?.isSubReport && (
                        <Col style={{ justifyContent: "flex-end", display: "flex" }} md={6}>
                            <div style={{ marginRight: "5px" }}>
                                <CustomerReportPrintModal
                                    isReviewComplete={customerReport.isReviewComplete}
                                    subReportName={customerReport.subReportName}
                                    systemName={finosecSystem?.name}
                                    tenantName={tenantName}
                                    periodName={customerImport?.periodName}
                                    importDate={customerImport?.createdOn}
                                    notes={notes}
                                    tableData={tableData}
                                    headers={headers}
                                    isReadOnly={customerReport.isReadOnlySubReport}
                                    loading={isLoading}
                                />
                            </div>
                            {!customerReport.isReadOnlySubReport && (
                                <div>
                                    {!customerImport?.isCurrent || isReadOnlyCustomer ? (
                                        <Button disabled={true} className="finosec-button-info">
                                            Mark All Reviewed
                                        </Button>
                                    ) : (
                                        <CustomerReportMarkReviewModal
                                            onOk={initiateMarkAsReviewed}
                                            subReportName={customerReport.subReportName}
                                            triggerTableUpdate={() => setHasLoadedData(false)}
                                        />
                                    )}
                                </div>
                            )}
                        </Col>
                    )}
                </Row>
                <br />
                {isLoading ? <LoadingSpinner /> : tableJSX}
            </div>
            <VerticallyCenteredModal
                id="emptySubReport"
                title=""
                show={showEmptySubreportModal}
                closeButtonText="Close"
                showSaveButton={!isReadOnlyCustomer}
                okButtonText="Acknowledge"
                onCloseButtonClick={() => setShowEmptySubreportModal(false)}
                onOkButtonClick={reviewEmptySubReport}
                type="info"
                size="lg"
                dynamicHeight={true}
            >
                <p>No matches or changes recorded for this report.</p>
            </VerticallyCenteredModal>
        </>
    );
};

export default CustomerReportSummary;
