import { useCallback } from "react";
import DeleteButtonRender from "../grid/DeleteButtonRender";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import KeysysAccordion from "../accordion/KeysysAccordion";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, showModal } from "../../actions/ModalActionCreators";
import {
    COMPLETE_SYSTEM_REPORT_MODAL,
    CONFIRM_DELETE_CUSTOMER_IMPORT_FILE_MODAL,
    CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL,
    IMP0RT_CONFIRM_MODAL,
    IMPORT_DETAILS_MODAL,
} from "../../constants/ModalConstants";
import ConfirmationModal from "../modal/ConfirmationModal/ConfirmationModal";
import { AppState } from "../../state/AppState";
import { useImportList } from "../../hooks/importListHook";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";
import CreatableSelect from "react-select/creatable";
import { CustomerImportFile } from "../../models/CustomerImportFile";
import SmallSquareDeleteButton from "../buttons/SmallSquareDeleteButton/SmallSquareDeleteButton";
import { getCustomerImports } from "../../actions/customerImport/CustomerImportActionCreators";
import { getCustomerImportFiles } from "../../actions/customerImportFile/CustomerImportFileActionCreators";

const ImportList = () => {
    const dispatch = useDispatch();
    const {
        systems,
        deactivateCustomerImportFile,
        deactivateCustomerImport,
        setSelectedCustomerOption,
        selectPartnerId,
        filterImports,
        partnerTenants,
        customerTenants,
        selectedTenants,
        selectedCustomerOption,
    } = useImportList();
    const confirmDeleteCustomerImportFileModalIsVisible = useSelector((state: AppState) =>
        state.modalState.activeModals.includes(CONFIRM_DELETE_CUSTOMER_IMPORT_FILE_MODAL)
    );

    const customerImportFileToDelete = useSelector((state: AppState) =>
        state.modalState.modalValues.get("CONFIRM_DELETE_CUSTOMER_IMPORT_FILE_MODAL")
    );

    const confirmDeleteCustomerImportModalIsVisible = useSelector((state: AppState) =>
        state.modalState.activeModals.includes(CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL)
    );

    const customerImportToDelete = useSelector((state: AppState) =>
        state.modalState.modalValues.get("CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL")
    );

    const customerImportFilesRef = useSelectorAsRef((state: AppState) => {
        const customerImportFiles = state.customerImportFileState.customerImportFiles || [];
        return customerImportFiles;
    });

    const customerImportRef = useSelectorAsRef((state: AppState) => {
        const customerImports = state.customerImportState.customerImports || [];
        return customerImports;
    });

    const showConfirmDeleteCustomerImportFileModal = (customerImportFile: any) => {
        dispatch(showModal(CONFIRM_DELETE_CUSTOMER_IMPORT_FILE_MODAL, customerImportFile));
    };

    const showDeleteCallback = useCallback((params: number) => {
        // Retrieve CustomerImportFile from redux store
        showConfirmDeleteCustomerImportFileModal(
            customerImportFilesRef.current.find((customerImportFile: any) => customerImportFile.id === params)
        );
    }, []);

    const closeDeleteCustomerImportFileConfirmationModal = () => {
        dispatch(hideModal(CONFIRM_DELETE_CUSTOMER_IMPORT_FILE_MODAL));
    };

    const closeDeleteCustomerImportConfirmationModal = () => {
        dispatch(hideModal(CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL));
    };

    function showCompleteSystemReportForm(system: any): void {
        const customerImport = systems.find((s) => s.id === system.id)?.currentImport;
        dispatch(showModal(COMPLETE_SYSTEM_REPORT_MODAL, { formType: "edit", customerImport, systemId: system.id }));
    }

    // const handleDeleteReport = () => {
    //     // Delete report based on reportType
    //     console.log("Delete Selected Report");
    //     console.log(reportToDelete);
    //     if (reportToDelete) {
    //         const { reportType, report } = reportToDelete;
    //         deleteReport(report, reportType);
    //         closeDeleteReportConfirmationModal();
    //     }
    // };
    const handleDeleteCustomerImportFile = () => {
        if (customerImportFileToDelete) {
            deactivateCustomerImportFile(customerImportFileToDelete);
            closeDeleteCustomerImportFileConfirmationModal();
        }
    };

    const handleDeleteCustomerImport = () => {
        if (customerImportToDelete) {
            deactivateCustomerImport(customerImportToDelete);
            closeDeleteCustomerImportConfirmationModal();
        }
    };

    function showImportForm(system: any): void {
        dispatch(
            showModal(IMP0RT_CONFIRM_MODAL, {
                formType: "import",
                systemId: system.id,
                tenantId: selectedTenants.customerId,
            })
        );
    }

    function refreshData(): void {
        dispatch(getCustomerImports());
        dispatch(getCustomerImportFiles());
        filterImports();
    }

    function getCustomerImportFile(customerImportFileId: number): CustomerImportFile | undefined {
        return customerImportFilesRef.current.find(
            (customerImportFile: any) => customerImportFile.id === customerImportFileId
        );
    }

    function getImportStatus(customerImportFileId: number): string {
        const currentImportFile = getCustomerImportFile(customerImportFileId);
        if (!currentImportFile?.startDate && !currentImportFile?.endDate) {
            return "Not Started";
        }
        if (currentImportFile?.endDate) {
            return "Complete";
        }
        return "Processing";
    }

    function showImportDetails(rowData: any): void {
        const customerImportFile = getCustomerImportFile(rowData?.id);
        const importStatus = getImportStatus(rowData?.id);
        dispatch(
            showModal(IMPORT_DETAILS_MODAL, {
                masterList: rowData.list,
                customerImportFile,
                status: importStatus,
            })
        );
    }

    const showConfirmDeleteCustomerImportModal = (customerImport: any) => {
        dispatch(showModal(CONFIRM_DELETE_CUSTOMER_IMPORT_MODAL, customerImport));
    };

    const showConfirmDeleteCustomeImportCallback = useCallback((customerImportId?: number) => {
        if (!customerImportId) {
            return;
        }
        var customerImport = customerImportRef.current.find((ci: any) => ci.id === customerImportId);
        showConfirmDeleteCustomerImportModal(customerImport);
    }, []);

    const accordionActionButtons = [
        {
            text: "",
            icon: "fas fa-sync-alt",
            iconColor: "#C1DF9C",
            cb: refreshData,
        },
        {
            text: "Complete System Import",
            cb: showCompleteSystemReportForm,
            icon: undefined,
            iconColor: undefined,
        },
        {
            text: "Import Master List",
            cb: showImportForm,
            icon: undefined,
            iconColor: undefined,
        },
    ];
    const columnDefs: ColDef[] = [
        {
            headerName: "Master List",
            field: "list",
        },
        {
            headerName: "Import Date",
            field: "date",
        },
        {
            headerName: "Import Status",
            field: "status",
            cellRendererFramework: (data: any) => {
                return getImportStatus(data?.data?.id);
            },
        },
        {
            headerName: "Rows",
            field: "rows",
        },
        {
            headerName: "Columns Matched",
            field: "columnsMatched",
        },
        {
            headerName: "",
            // colId: "id",
            // suppressMovable: true,
            // suppressMenu: true,
            // field: "view details",
            cellRendererFramework: (data: any) => {
                return data?.data?.hasFile ? (
                    <Button key={data?.data?.id} variant={"link"} onClick={() => showImportDetails(data?.data)}>
                        View Details
                    </Button>
                ) : (
                    <></>
                );
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 150,
            tooltipValueGetter: () => "View Details",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: (props: any) => (props?.data?.hasFile ? DeleteButtonRender(props) : <></>),
            cellRendererParams: {
                entityName: "Import",
                // showDelete: showDeleteCallback,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 55,
            tooltipValueGetter: () => "Delete Import",
        },
    ];
    return (
        <>
            <Row className="keysys-action-bar">
                <Col lg={2} sm={3}>
                    <CreatableSelect
                        onChange={(v) => selectPartnerId(v?.value || null)}
                        defaultValue={null}
                        placeholder={"Select Partner"}
                        options={[
                            { value: null, label: "Select Partner" },
                            ...partnerTenants.map((pt) => ({
                                value: pt.id,
                                label: pt.name,
                            })),
                        ]}
                    />
                </Col>
                <Col lg={2} sm={3}>
                    <CreatableSelect
                        onChange={(v) => setSelectedCustomerOption(v || null)}
                        value={selectedCustomerOption}
                        placeholder={"Select Customer"}
                        options={[
                            { value: null, label: "Select Customer" },
                            ...customerTenants
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((pt) => ({
                                    value: pt.id,
                                    label: pt.name,
                                })),
                        ]}
                    />
                </Col>
                <Col md={1}>
                    <Button onClick={() => filterImports()} className="finosec-button-info">
                        Select
                    </Button>
                </Col>
            </Row>

            {systems.map((s) => (
                <div key={s.id}>
                    <KeysysAccordion
                        actionButtons={accordionActionButtons}
                        data={s}
                        key={s.id}
                        title={s.name}
                        count={s.imports.length}
                        renderOnExpanded
                    >
                        <div>
                            <div className="ag-theme-alpine">
                                <KeysysGrid getRows={() => s.imports} columnDefs={columnDefs} />
                            </div>
                            {s.completedImport && s.completedImport.id && (
                                <Alert style={{ marginBottom: "unset", marginTop: "10px" }} variant="danger">
                                    <Row>
                                        <Col style={{ marginBottom: "unset" }}>{s.completedImport.periodName}</Col>
                                        <Col style={{ marginBottom: "unset" }}>{s.completedImport.completedDate}</Col>
                                        <Col style={{ marginBottom: "unset" }}>-</Col>
                                        <Col style={{ marginBottom: "unset" }} md={1}>
                                            <SmallSquareDeleteButton
                                                key={`delete-${s.completedImport.id}`}
                                                entityNameSingular={s.completedImport.periodName || ""}
                                                onClick={() =>
                                                    showConfirmDeleteCustomeImportCallback(s.completedImport?.id)
                                                }
                                                isActive
                                            />
                                        </Col>
                                    </Row>
                                </Alert>
                            )}
                        </div>
                    </KeysysAccordion>
                </div>
            ))}
            <div>
                <ConfirmationModal
                    id="confirmDeleteMasterCustomerImportFile"
                    show={confirmDeleteCustomerImportFileModalIsVisible}
                    title="Deactivate Customer Import File?"
                    message={"Do you really want to deactivate this Customer Import File?"}
                    onCloseButtonClick={closeDeleteCustomerImportFileConfirmationModal}
                    onOkButtonClick={() => handleDeleteCustomerImportFile()}
                    theme={"danger"}
                />
            </div>
            <div>
                <ConfirmationModal
                    id="confirmDeleteCustomerImport"
                    show={confirmDeleteCustomerImportModalIsVisible}
                    title="Deactivate Customer Import?"
                    message={"Do you really want to deactivate this Customer Import ?"}
                    onCloseButtonClick={closeDeleteCustomerImportConfirmationModal}
                    onOkButtonClick={() => handleDeleteCustomerImport()}
                    theme={"danger"}
                />
            </div>
        </>
    );
};

export default ImportList;
