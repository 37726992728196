import { Form, Formik, FormikProps } from "formik";
import { Button, Card } from "react-bootstrap";
import FormBuilder, { ApiViewFields } from "../form-builder/FormBuilder";
import { useSubReportForm } from "../../hooks/subReportHook";
import { LoadingSpinner } from "../loading/Loading";
import KeysysSelect from "../form/select/KeysysSelect";

const SubReportForm = () => {
    const {
        subReport,
        isEdit,
        loading,
        selectItem,
        setSelectedMasterListId,
        setSelectedSystemId,
        setSelectedSubReportCategory,
        setSelectedReportType,
        setSelectedTenantIds,
        onCreateCategory,
        categoryOptions,
        reportTypeOptions,
        masterListOptions,
        systemOptions,
        tenantOptions,
        onSave,
        selectedReportType,
    } = useSubReportForm();

    if (loading || !subReport) return <LoadingSpinner />;
    if (isEdit && (!subReport?.columns || subReport?.columns?.length === 0)) return <LoadingSpinner />;

    function getReportTypeId(formProps: any): any {
        if (selectedReportType === undefined) {
            return formProps.values?.reportTypeId;
        }
        return selectedReportType;
    }

    const meta: ApiViewFields[] = [
        {
            key: "id",
            label: "Identifier",
            type: "input",
            hidden: true,
        },
        {
            key: "name",
            label: "Sub Report Name",
            type: "input",
            placeholder: "Report Name",
        },
        {
            key: "system",
            label: "Select System",
            type: "dropdown",
            onChange: (value, formCtx) => selectItem(+value, setSelectedSystemId, formCtx),
            options: systemOptions,
        },
        {
            key: "reportId",
            label: "Select Master List",
            type: "dropdown",
            onChange: (value, formCtx) => selectItem(+value, setSelectedMasterListId, formCtx),
            options: masterListOptions,
        },
        {
            key: "categoryId",
            label: "Select Category",
            type: "dropdown",
            onChange: (value, formCtx) => selectItem(+value, setSelectedSubReportCategory, formCtx),
            addValuesToOptionsProps: ["name", "ordinal"],
            canAddValuesToOptions: true,
            onAddValueToOptions: (category) => onCreateCategory(category.name, category.ordinal),
            options: categoryOptions,
        },
        {
            key: "reportTypeId",
            label: "Select Report Type",
            type: "dropdown",
            onChange: (value, formCtx) => {
                selectItem(+value, setSelectedReportType, formCtx);
            },
            addValuesToOptionsProps: ["name", "ordinal"],
            canAddValuesToOptions: false,
            options: reportTypeOptions,
        },
        {
            key: "isReadOnly",
            label: "Read Only",
            type: "checkbox",
        },
        {
            key: "isForced",
            label: "Force",
            type: "checkbox",
        },
        {
            key: "filter",
            label: "Filter",
            info: "Setting will negate filters in table below",
            type: "filterBuilder",
            options:
                subReport.columns?.map((c: any) => ({ key: c.attribute, text: c.attribute, value: c.attribute })) || [],
            group: "Customize Columns",
        },
        {
            key: "columns",
            label: "Columns",
            type: "table",
            dynamicData: true,
            fields: [
                {
                    key: "reportAttributeId",
                    label: "Column",
                    type: "input",
                    hidden: true,
                    readonly: true,
                },
                {
                    key: "attribute",
                    label: "Column",
                    type: "input",
                    placeholder: "Enter Column Name",
                    readonly: true,
                },
                {
                    key: "filterTypeId",
                    label: "Filter Type",
                    type: "dropdown",
                    options: [
                        {
                            key: 1,
                            value: 1,
                            text: "Contains",
                        },
                        {
                            key: 2,
                            value: 2,
                            text: "Equals",
                        },
                    ],
                },
                {
                    key: "filterValue",
                    label: "Filter Value",
                    type: "input",
                },
                {
                    key: "groupBy",
                    label: "Group By",
                    type: "checkbox",
                },
                {
                    key: "useAttributeInColHeader",
                    label: "User Attribute in Column Header",
                    type: "checkbox",
                },
                {
                    key: "hide",
                    label: "Hide",
                    type: "checkbox",
                },
            ],
            group: "Customize Columns",
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Formik enableReinitialize initialValues={subReport} onSubmit={(v) => onSave(v)}>
                {(formProps: FormikProps<any>) => {
                    return (
                        <Card style={{ width: "75%", alignSelf: "center" }}>
                            <Form className="keysys-form">
                                <Card.Header className="finosec-button-info" style={{ color: "white" }}>
                                    <Card.Title>Create Sub Report</Card.Title>
                                </Card.Header>
                                <Card.Body
                                    style={{ display: "flex", flexDirection: "column" }}
                                    className="justify-content-md-center"
                                >
                                    <FormBuilder meta={meta} chunkNumber={3}></FormBuilder>
                                    {getReportTypeId(formProps) === 2 && (
                                        <KeysysSelect
                                            formProps={formProps}
                                            id={"tenantIds"}
                                            placeholder={"Customers..."}
                                            fieldName={"tenantIds"}
                                            label={"Select Customers"}
                                            options={tenantOptions}
                                            multiple={true}
                                            onChange={(value: any) => {
                                                setSelectedTenantIds(value?.value?.tenantIds);
                                            }}
                                        />
                                    )}
                                </Card.Body>
                                <Card.Footer className="text-right" style={{ backgroundColor: "white" }}>
                                    <Button type="submit" className="finosec-button-info">
                                        {isEdit ? "Save Changes" : "Create Report"}
                                    </Button>
                                </Card.Footer>
                            </Form>
                        </Card>
                    );
                }}
            </Formik>
        </div>
    );
};

export default SubReportForm;
