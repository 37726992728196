import { ChangeEvent, useEffect, useState, useRef } from "react";
import { IPartnerForm } from "./IPartnerForm";
import { Field, Form, FieldProps, Formik, FormikHelpers, FormikProps } from "formik";
import { AddPartnerFormModel } from "./AddPartnerFormModel";
import VerticallyCenteredModal from "../../modal/VerticallyCenteredModal/VerticallyCenteredModal";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../state/AppState";
import { Card, Col, Row, Form as bsForm } from "react-bootstrap";
import KeysysInput from "../../form/input/KeysysInput";
import { createTenant, deleteTenant, editTenant } from "../../../actions/tenants/TenantActionCreators";
import { uploadImage, clearImage } from "../../../actions/aws/UploadImageActionCreators";
import { Tenant } from "../../../models/Tenant";
import * as Yup from "yup";
import ConfirmationModal from "../../modal/ConfirmationModal/ConfirmationModal";
import { AwsFile } from "../../../models/AwsFile";

export default function PartnerForm(props: IPartnerForm) {
    const dispatch = useDispatch();

    const formikRef = useRef<FormikProps<any>>(null);

    const errorMessage = "File type is not valid";
    const defaultFileInputMessage = "Choose a file...";
    const [filePath, setFilePath] = useState<string>(defaultFileInputMessage);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);

    function stripFilePath(path: string): string {
        return path.split("\\").pop()!;
    }

    function isExtensionValid(path: string): boolean {
        const extension = path.substring(path.lastIndexOf(".") + 1).toLowerCase();
        return extension === "gif" || extension === "png" || extension === "jpeg" || extension === "jpg";
    }

    const awsFiles = useSelector<AppState, AwsFile[]>((state) => state.awsFileState.awsFiles);

    function handleFileInputChange(file: File) {
        if (file) {
            if (isExtensionValid(file.name)) {
                var reader = new FileReader();

                reader.onload = function (event) {
                    const uploadedImage: AwsFile = {
                        filename: file.name,
                        content: reader.result?.toString().split(",")[1] || "",
                        url: "",
                    };
                    dispatch(uploadImage(uploadedImage));
                };

                reader.readAsDataURL(file);

                if (reader.DONE === 2) {
                    return stripFilePath(file.name);
                }

                return defaultFileInputMessage;
            }

            if (file.name === "") {
                return defaultFileInputMessage;
            }
        }

        return errorMessage;
    }

    const [isActiveValue, setIsActiveValue] = useState<boolean>(false);

    useEffect(() => {
        const formReference = formikRef.current;
        if (props.tenant?.isActive) {
            setIsActiveValue(props.tenant?.isActive);
        }
        if (formReference && awsFiles.length > 0) {
            formReference.setFieldValue("logoUri", awsFiles[awsFiles.length - 1].url);
        }
    }, [props, awsFiles]);

    function getInitialValues() {
        const parentTenantId = props.type === "add" ? undefined : props.tenant?.parentTenantId;
        return {
            name: props.tenant?.name ?? "",
            logoUri: props.tenant?.logoUri ?? "",
            supportUrl: props.tenant?.supportUrl ?? "",
            parentTenantId,
            usersCount: props.tenant?.usersCount ?? 0,
            isActive: props.tenant?.isActive ?? true,
        };
    }

    const partnerSchema = Yup.object<AddPartnerFormModel>().shape({
        name: Yup.string().max(50, "Name must be 50 characters or less").required("Name is required"),
        // logoUri: Yup.string().required("Logo is required"),
    });

    function clearFormAndHide(formProps: FormikProps<AddPartnerFormModel> | FormikHelpers<AddPartnerFormModel>): void {
        dispatch(clearImage());
        formProps.resetForm();
        props.onClose();
    }

    function handleSubmit(values: AddPartnerFormModel, FormikHelpers: FormikHelpers<AddPartnerFormModel>) {
        if (props.type === "add") {
            const tenantToCreate: Tenant = {
                id: 0,
                name: values.name,
                logoUri: values.logoUri || "",
                usersCount: values.usersCount,
                parentTenantId: values.parentTenantId,
                pathfinderUrl: "",
                sysorecUrl: "",
                supportUrl: values.supportUrl || "",
                pullSmartsheets: false,
                finosecAcademy: false,
                isActive: true,
                uarAccess: false,
            };
            dispatch(createTenant(tenantToCreate));
        } else {
            const editedTenant: Tenant = {
                ...props.tenant!,
                id: props.tenant!.id,
                name: values.name,
                logoUri: values.logoUri,
                usersCount: values.usersCount,
                parentTenantId: values.parentTenantId,
                pathfinderUrl: "",
                sysorecUrl: "",
                pullSmartsheets: false,
                supportUrl: values.supportUrl,
                finosecAcademy: false,
                isActive: isActiveValue,
            };
            dispatch(editTenant(props.tenant!, editedTenant));
        }
        clearFormAndHide(FormikHelpers);
    }

    function getTitle() {
        if (props.type === "add") {
            return "Add Partner";
        }
        if (props.type === "edit") {
            return "Edit Partner";
        }
        return "Partner";
    }

    const handleDeletePartner = (tenant: Tenant) => {
        dispatch(deleteTenant(tenant));
        setShowConfirmDeleteModal(false);
        props.onClose();
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={getInitialValues()}
                validationSchema={partnerSchema}
                onSubmit={handleSubmit}
                enableReinitialize={true}
            >
                {(formProps: FormikProps<AddPartnerFormModel>) => {
                    return (
                        <VerticallyCenteredModal
                            id="addEditPartnerModal"
                            show={true}
                            title={getTitle()}
                            closeButtonText={"Close"}
                            okButtonText={"Save"}
                            showSaveButton={true}
                            showDeleteButton={props.type === "edit"}
                            deleteButtonText={"Deactivate"}
                            onDeleteButtonClick={() => setShowConfirmDeleteModal(true)}
                            onCloseButtonClick={() => clearFormAndHide(formProps)}
                            onOkButtonClick={formProps.submitForm}
                        >
                            <Form id="addEditPartnerForm" data-testid={"add-edit-partner-form"}>
                                <Card>
                                    <Card.Body>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"PartnerName"}
                                                    placeholder={"Name"}
                                                    fieldName={"name"}
                                                    label={"Name"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <KeysysInput
                                                    formProps={formProps}
                                                    id={"PartnerSupportUrl"}
                                                    placeholder={"Support Url"}
                                                    fieldName={"supportUrl"}
                                                    label={"Support Url"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className={"mb-4"}>
                                            <Col>
                                                <div>
                                                    <Field name="logoUri">
                                                        {({ field }: FieldProps<any>) => (
                                                            <div>
                                                                <label htmlFor="logoUri">Logo Uri</label>
                                                                <input
                                                                    value={
                                                                        awsFiles.length > 0
                                                                            ? awsFiles[awsFiles.length - 1].url
                                                                            : formProps.values.logoUri
                                                                    }
                                                                    id="logoUri"
                                                                    className="form-control"
                                                                    placeholder="Upload Image"
                                                                    disabled={true}
                                                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                                        setFilePath(
                                                                            handleFileInputChange(e.target.files![0])
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className={"mb-4"}>
                                            <Col>
                                                <bsForm.Group>
                                                    <bsForm.Label>{filePath}</bsForm.Label>
                                                    <bsForm.Control
                                                        isInvalid={filePath === errorMessage}
                                                        accept={"image/*"}
                                                        type="file"
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                                            setFilePath(handleFileInputChange(e.target.files![0]))
                                                        }
                                                        data-browse={"Upload Logo"}
                                                        id={"UploadImage"}
                                                    />
                                                    <img
                                                        className={"mt-3"}
                                                        width="200px"
                                                        alt=""
                                                        src={
                                                            awsFiles.length > 0
                                                                ? awsFiles[awsFiles.length - 1].url
                                                                : formProps.values.logoUri
                                                        }
                                                    ></img>
                                                </bsForm.Group>
                                            </Col>
                                        </Row>
                                        {/* <Row className={"mb-4"}>
                                            {props.type === "edit" && (
                                                <Col className={"d-inline-flex"}>
                                                    <SmallRoundCheckbox
                                                        entityNameSingular="IsActive"
                                                        id={"isActiveCheckbox"}
                                                        checked={isActiveValue}
                                                        onClick={() => {
                                                            setIsActiveValue(!isActiveValue);
                                                        }}
                                                        disabled={true}
                                                    />
                                                    <label htmlFor="isActive">Is Active?</label>
                                                </Col>
                                            )}
                                        </Row> */}
                                    </Card.Body>
                                </Card>
                            </Form>
                        </VerticallyCenteredModal>
                    );
                }}
            </Formik>
            <ConfirmationModal
                id="confirmDeletePartnerModal"
                show={showConfirmDeleteModal}
                title="Deactivate Partner?"
                message={
                    "Are you sure you want to deactivate this partner? This will also deactivate associated customers."
                }
                onCloseButtonClick={() => setShowConfirmDeleteModal(false)}
                onOkButtonClick={() => handleDeletePartner(props.tenant!)}
                theme={"danger"}
            />
        </>
    );
}
