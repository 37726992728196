import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../state/AppState";
import { useCallback, useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import CreatableSelect from "react-select/creatable";
import EditButtonRender from "../grid/EditButtonRender";
import { useSelectorAsRef } from "../../hooks/useSelectorAsRef";
import PageHeader from "../header/PageHeader";
import { getEmployeeSystemExceptionssByTenantId } from "../../actions/employeeSystemException/EmployeeSystemExceptionActionCreator";
import { EmployeeSystemException } from "../../models/EmployeeSystemException";
import EditEmployeeSystemExceptionForm from "./EditEmployeeSystemException";
import { getSystemAccountTypes } from "../../actions/systemAccountType/SystemAccountTypeActionCreator";
import MergeButtonRender from "../grid/MergeButtonRender";
import { getEmployeesByTenantId } from "../../actions/employee/EmployeeActionCreators";
import EmployeeSystemForm from "./EmployeeSystemForm";
import { KeysysSelectOption } from "../form/select/KeysysSelectOption";

export default function EmployeeSystemExceptionList() {
    const dispatch = useDispatch();
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const tenants = useSelector((state: AppState) => state.tenantState.tenants.filter((t) => t.parentTenantId != null));
    var employees = useSelector((state: AppState) => state.employeeState.employees);
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showMergeModal, setShowMergeModal] = useState<boolean>(false);
    const [clickedCustomerFilter, setCustomerFilterClick] = useState<boolean>(false);
    const [selectedGridFilterId, setSelectedGridFilterId] = useState<number | null>(1);
    const [pageSize, setPageSize] = useState<number>(15);

    const { impersonator } = useSelector((state: AppState) => state.userState);
    const isReadOnlyCustomer = decodedAccessToken.roles === "ReadOnlyCustomer" || !!impersonator;

    const employeeSystemExceptions = useSelector(
        (state: AppState) => state.employeeSystemExceptionState.employeeSystemExceptions
    );
    const [selectedEmployeeSystemException, setSelectedEmployeeSystemException] = useState<
        EmployeeSystemException | undefined
    >(undefined);
    const [textSearch, setSearchText] = useState<string>("");

    const selectCustomerId = (tenantId: number | null) => {
        setSelectedCustomerId(tenantId);
        setCustomerFilterClick(false);
    };

    useEffect(() => {
        if (!isGlobalAdmin) {
            const tenantId = decodedAccessToken.tenant_id;
            setSelectedCustomerId(tenantId);
            setCustomerFilterClick(true);
            dispatch(getEmployeesByTenantId(tenantId));
            dispatch(getEmployeeSystemExceptionssByTenantId(tenantId));
        }
        dispatch(getSystemAccountTypes());
    }, [decodedAccessToken.tenant_id, dispatch, isGlobalAdmin]);

    const getGridFilterOptions = (): KeysysSelectOption[] => {
        return [
            { value: 1, label: "Show Exceptions" } as KeysysSelectOption,
            { value: 2, label: "Show Resolved" } as KeysysSelectOption,
            { value: 3, label: "Show All" } as KeysysSelectOption,
        ];
    };

    const filterGrid = (gridFilterId: any) => {
        setSelectedGridFilterId(gridFilterId);
    };

    const filterEmployeeSystemExceptions = () => {
        setCustomerFilterClick(true);
        if (selectedCustomerId) {
            dispatch(getEmployeeSystemExceptionssByTenantId(selectedCustomerId));
            if (employees.length === 0) {
                dispatch(getEmployeesByTenantId(selectedCustomerId));
            }
        }
    };

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const employeeSystemExceptionsRef = useSelectorAsRef(
        (state: AppState) => state.employeeSystemExceptionState.employeeSystemExceptions
    );

    const showEditCallback = useCallback(
        (params: EmployeeSystemException) => {
            showEditForm(
                employeeSystemExceptionsRef.current.find(
                    (employeeSystemException: EmployeeSystemException) => employeeSystemException.id === params.id
                )!
            );
        },
        [employeeSystemExceptionsRef]
    );

    function showEditForm(employeeSystemException: EmployeeSystemException): void {
        setSelectedEmployeeSystemException(employeeSystemException);
        setShowEditModal(true);
    }

    const showMergeCallback = useCallback(
        (params: EmployeeSystemException) => {
            showMergeForm(
                employeeSystemExceptionsRef.current.find(
                    (employeeSystemException: EmployeeSystemException) => employeeSystemException.id === params.id
                )!
            );
        },
        [employeeSystemExceptionsRef]
    );

    function showMergeForm(employeeSystemException: EmployeeSystemException): void {
        setSelectedEmployeeSystemException(employeeSystemException);
        setShowMergeModal(true);
    }

    const columDefs: ColDef[] = [
        {
            headerName: "User Id",
            field: "userId",
            cellRendererFramework: (data: any) => data.data?.userId,
            tooltipField: "User Id",
        },
        {
            headerName: "Name",
            field: "name",
            cellRendererFramework: (data: any) => data.data?.name,
            tooltipField: "Name",
        },
        {
            headerName: "System",
            field: "finosecSystem",
            cellRendererFramework: (data: any) => {
                return data.data?.finosecSystem?.name;
            },
            tooltipField: "System",
            valueGetter: (data: any) => {
                return data.data?.finosecSystem?.name;
            },
        },
        {
            headerName: "Account Type",
            field: "systemAccountType",
            cellRendererFramework: (data: any) => {
                return data.data?.systemAccountType !== null ? data.data?.systemAccountType.name : "";
            },
            tooltipField: "System",
            valueGetter: (data: any) => {
                return data.data?.systemAccountType !== null ? data.data?.systemAccountType.name : "";
            },
        },
        {
            headerName: "Employee",
            field: "employee",
            cellRendererFramework: (data: any) => {
                return data.data?.employee !== null
                    ? `${data.data?.employee.firstName} ${data.data?.employee.lastName}`
                    : "";
            },
            tooltipField: "Employee",
            valueGetter: (data: any) => {
                return data.data?.employee !== null
                    ? `${data.data?.employee.firstName} ${data.data?.employee.lastName}`
                    : "";
            },
        },
        {
            headerName: "",
            field: "edit",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "EmployeeSystemException",
                    showEdit: showEditCallback,
                    isActive: data.data?.employeeId === null && data.data?.systemAccountTypeId === null,
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Employee System Exception",
        },
        {
            headerName: "",
            field: "merge",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: MergeButtonRender,
            cellRendererParams: (data: any) => {
                return {
                    entityName: "EmployeeSystemException",
                    showEdit: showMergeCallback,
                    isActive: data.data?.employeeId === null && data.data?.systemAccountTypeId === null,
                };
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Merge Employee System Exception",
        },
    ];

    return (
        <>
            {!inIframe() && (
                <Row className="padding-0">
                    <Col md={12}>
                        <PageHeader>
                            <h2>System Exception Report</h2>
                        </PageHeader>
                    </Col>
                </Row>
            )}
            <br />

            <Row className="keysys-action-bar padding-0">
                {isGlobalAdmin && (
                    <>
                        <Col lg={2} sm={3}>
                            <CreatableSelect
                                onChange={(v) => selectCustomerId(v?.value || null)}
                                defaultValue={null}
                                placeholder={"Select Customer"}
                                options={[
                                    { value: null, label: "Select Customer" },
                                    ...tenants.map((pt) => ({
                                        value: pt.id,
                                        label: pt.name,
                                    })),
                                ]}
                            />
                        </Col>
                        <Col md={1}>
                            <Button onClick={() => filterEmployeeSystemExceptions()} className="finosec-button-info">
                                Select
                            </Button>
                        </Col>
                    </>
                )}
            </Row>
            <br />
            {selectedCustomerId && clickedCustomerFilter && (
                <div className="ps-3 pe-3">
                    <div style={{ marginBottom: "10px" }}>
                        <Row className={"ag-theme-alpine"}>
                            <Col md={4}>
                                <input
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Col>
                            <Col md={2}>
                                <CreatableSelect
                                    style={{ width: "20%" }}
                                    onChange={(v) => setPageSize(v?.value || 15)}
                                    defaultValue={null}
                                    placeholder={"Page Size"}
                                    options={[
                                        { value: 10, label: "10" },
                                        { value: 15, label: "15" },
                                        { value: 25, label: "25" },
                                        { value: 50, label: "50" },
                                        { value: 100, label: "100" },
                                    ]}
                                />
                            </Col>
                            <Col md={2}>
                                <CreatableSelect
                                    onChange={(v) => filterGrid(v?.value || null)}
                                    defaultValue={null}
                                    placeholder={"Grid Filter"}
                                    options={getGridFilterOptions()}
                                />
                            </Col>
                        </Row>
                        <Row className={"ag-theme-alpine"}>
                            <Col>
                                <KeysysGrid
                                    getRows={() => {
                                        if (selectedGridFilterId === 1) {
                                            return employeeSystemExceptions.filter(
                                                (ese) => ese.systemAccountTypeId === null && ese.employeeId === null
                                            );
                                        }
                                        if (selectedGridFilterId === 2) {
                                            return employeeSystemExceptions.filter(
                                                (ese) => ese.systemAccountTypeId !== null || ese.employeeId !== null
                                            );
                                        }
                                        return employeeSystemExceptions;
                                    }}
                                    columnDefs={
                                        isReadOnlyCustomer
                                            ? columDefs.filter((c) => c.field !== "edit" && c.field !== "merge")
                                            : columDefs
                                    }
                                    pagination={true}
                                    pageSize={pageSize}
                                    searchText={textSearch}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            )}

            {showEditModal && (
                <EditEmployeeSystemExceptionForm
                    type="edit"
                    employeeSystemException={selectedEmployeeSystemException}
                    onClose={() => setShowEditModal(false)}
                />
            )}

            {showMergeModal && (
                <EmployeeSystemForm
                    type="edit"
                    employeeSystemException={selectedEmployeeSystemException}
                    onClose={() => setShowMergeModal(false)}
                />
            )}
        </>
    );
}
