/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { ColDef, ValueGetterParams } from "ag-grid-community";
import KeysysGrid from "../grid/KeysysGrid";
import { AppState } from "../../state/AppState";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    getWorkflowNotificationTemplates,
    selectWorkflowNotificationTemplate,
} from "../../actions/workflowNotificationTemplate/WorkflowNotificationTemplateActionCreators";
import { WorkflowNotificationTemplate } from "../../models/WorkflowNotificationTemplate";
import EditButtonRender from "../grid/EditButtonRender";
import PageHeader from "../header/PageHeader";
import WorkflowNotificationTemplateForm from "./WorkfowNotificationTemplateForm";

export default function WorkflowNotificationTemplates() {
    const dispatch = useDispatch();
    const [
        showAddWorkflowNotificationTemplateFormModal,
        setShowAddWorkflowNotificationTemplateFormModal,
    ] = useState<boolean>(false);
    const selectedWorkflowNotificationTemplate = useSelector(
        (state: AppState) => state.workflowNotificationTemplateState?.selectedWorkflowNotificationTemplate
    );
    const workflowNotificationTemplates = useSelector(
        (state: AppState) => state.workflowNotificationTemplateState?.workflowNotificationTemplates
    );

    useEffect(() => {
        dispatch(getWorkflowNotificationTemplates());
    }, []);

    useEffect(() => {}, [workflowNotificationTemplates]);

    const showEditFileModal = useCallback(
        (params: WorkflowNotificationTemplate) => {
            dispatch(selectWorkflowNotificationTemplate(params));
            setShowAddWorkflowNotificationTemplateFormModal(true);
        },
        [workflowNotificationTemplates]
    );

    const columnDefs: ColDef[] = [
        {
            headerName: "Name",
            field: "name",
        },
        {
            headerName: "Subject",
            field: "subject",
        },
        {
            headerName: "",
            colId: "id",
            suppressMovable: true,
            suppressMenu: true,
            cellRendererFramework: EditButtonRender,
            cellRendererParams: {
                entityName: "WorkflowNotificationTemplate",
                showEdit: showEditFileModal,
            },
            resizable: false,
            sortable: false,
            filter: false,
            maxWidth: 45,
            tooltipValueGetter: () => "Edit Notification Template",
        },
    ];

    return (
        <>
            <PageHeader>
                <h2>Workflow Notification Templates</h2>
            </PageHeader>
            <div className="ag-theme-alpine">
                <KeysysGrid getRows={() => workflowNotificationTemplates} columnDefs={columnDefs} />{" "}
            </div>
            <div>
                {showAddWorkflowNotificationTemplateFormModal && (
                    <WorkflowNotificationTemplateForm
                        onClose={() => setShowAddWorkflowNotificationTemplateFormModal(false)}
                    />
                )}
            </div>
        </>
    );
}
