import { Employee } from "../../models/Employee";
import { EmployeeSamAccount } from "../../models/EmployeeSamAccount";

export const EMPLOYEE_SAM_ACCOUNTS_FETCHED = "EMPLOYEE_SAM_ACCOUNTS_FETCHED";
export const UPDATE_EMPLOYEE_SAM_ACCOUNTS = "UPDATE_EMPLOYEE_SAM_ACCOUNTS";
export const EMPLOYEES_FETCHED = "EMPLOYEES_FETCHED";

export interface EmployeeSamAccountsFetchedAction {
    type: typeof EMPLOYEE_SAM_ACCOUNTS_FETCHED;
    employeeSamAccounts: EmployeeSamAccount[];
}

export interface UpdateEmployeeSamAccountsAction {
    type: typeof UPDATE_EMPLOYEE_SAM_ACCOUNTS;
    employeeSamAccounts: string[];
}

export interface EmployeesFetchedAction {
    type: typeof EMPLOYEES_FETCHED;
    employees: Employee[];
}

export type EmployeeSamAccountActions =
    | EmployeeSamAccountsFetchedAction
    | UpdateEmployeeSamAccountsAction
    | EmployeesFetchedAction;
