import { WorkflowNotificationTemplate } from "../../models/WorkflowNotificationTemplate";

export const WORKFLOW_NOTIFICATION_TEMPLATES_FETCHED = "WORKFLOW_NOTIFICATION_TEMPLATES_FETCHED";
export const WORKFLOW_NOTIFICATION_TEMPLATE_FETCHED = "WORKFLOW_NOTIFICATION_TEMPLATE_FETCHED";
export const WORKFLOW_NOTIFICATION_TEMPLATE_CREATED = "WORKFLOW_NOTIFICATION_TEMPLATE_CREATED";
export const WORKFLOW_NOTIFICATION_TEMPLATE_EDITED = "WORKFLOW_NOTIFICATION_TEMPLATE_EDITED";
export const WORKFLOW_NOTIFICATION_TEMPLATE_DELETED = "WORKFLOW_NOTIFICATION_TEMPLATE_DELETED";
export const WORKFLOW_NOTIFICATION_TEMPLATE_SELECTED = "WORKFLOW_NOTIFICATION_TEMPLATE_SELECTED";

export interface WorkflowNotificationTemplatesFetched {
    type: typeof WORKFLOW_NOTIFICATION_TEMPLATES_FETCHED;
    workflowNotificationTemplates: WorkflowNotificationTemplate[];
}

export interface WorkflowNotificationTemplateFetched {
    type: typeof WORKFLOW_NOTIFICATION_TEMPLATE_FETCHED;
    workflowNotificationTemplate: WorkflowNotificationTemplate;
}

export interface WorkflowNotificationTemplateCreated {
    type: typeof WORKFLOW_NOTIFICATION_TEMPLATE_CREATED;
    workflowNotificationTemplate: WorkflowNotificationTemplate;
}

export interface WorkflowNotificationTemplateEdited {
    type: typeof WORKFLOW_NOTIFICATION_TEMPLATE_EDITED;
    workflowNotificationTemplate: WorkflowNotificationTemplate;
    newWorkflowNotificationTemplate: WorkflowNotificationTemplate;
}

export interface WorkflowNotificationTemplateDeleted {
    type: typeof WORKFLOW_NOTIFICATION_TEMPLATE_DELETED;
    workflowNotificationTemplate: WorkflowNotificationTemplate;
}

export interface WorkflowNotificationTemplateSelected {
    type: typeof WORKFLOW_NOTIFICATION_TEMPLATE_SELECTED;
    workflowNotificationTemplate: WorkflowNotificationTemplate;
}

export type WorkflowNotificationTemplateActions =
    | WorkflowNotificationTemplatesFetched
    | WorkflowNotificationTemplateFetched
    | WorkflowNotificationTemplateCreated
    | WorkflowNotificationTemplateEdited
    | WorkflowNotificationTemplateDeleted
    | WorkflowNotificationTemplateSelected;
