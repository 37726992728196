import Header from "../header/Header";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppState } from "../../state/AppState";
import KeysysToast from "../toast/KeysysToast";
import KeysysSidebar from "../sidebar/KeysysSidebar";
import _ from "lodash";
import { SsoService } from "../../models/SsoService";
import { Col } from "react-bootstrap";
import Tenants from "../tenants/Tenants";
import Users from "../users/Users";
import IntakeSystemFiles from "../intakeSystemFile/IntakeSystemFile";
import AdminCustomerIntakeReview from "../adminCustomerIntakeReview/adminCustomerIntakeReview";
import DocumentExchangeList from "../documentExchange/DocumentExchangeList";
import {
    IMP0RT_CONFIRM_MODAL,
    COMPLETE_SYSTEM_REPORT_MODAL,
    IMPORT_DETAILS_MODAL,
    UPLOAD_EMPLOYEE_CONFIRM_MODAL,
} from "../../constants/ModalConstants";
import CompleteSystemReportForm from "../import/completeSystemReportForm";
import ImportForm from "../import/forms/ImportForm";
import MasterListForm from "../reports/master-list-form";
import SubReportForm from "../reports/sub-repot-form";
import Home from "../home/home";
import { useCurrentTenant } from "../../hooks/currentTenantHook";
import Footer from "../footer/Footer";
import { useEffect, useState } from "react";
import { useCurrentUser } from "../../hooks/currentUserHook";
import ReportsList from "../reports/reports-list";
import ImportList from "../import/import-list";
import UARList from "../uar/uar-list";
import CustomerReportList from "../customerReport/customerReport-list";
import CustomerReportSummary from "../customerReport/customerReportSummary";
import ImportDetailsModal from "../import/importDetailsModal";
import CustomerDocuments from "../documentExchange/customerDocuments";
import DashboardsList from "../dashboards/dashboards-list";
import DashboardForm from "../dashboards/dashboard-form";
import MfaSetup from "../mfa/MfaSetup";
import EmployeeList from "../employee/EmployeeList";
import EmployeeChangeList from "../employee/EmployeeChangeList";
import EmployeeUploadForm from "../employee/EmployeeUploadForm";
import EmployeeSystemList from "../employee/EmployeeSystemList";
import EmployeeSystemExceptionList from "../employee/EmployeeSystemExceptionList";
import SessionTimeout from "../session-timeout/SessionTimeout";
import CustomerIntakeReview from "../CustomerIntakeReview/CustomerIntakeReview";
import UAROverview from "../reports/uar-overview";
import WorkflowNotificationTemplate from "../workflow-notificationtemplate/WorkflowNotificationTemplate";
import { impersonateUser } from "../../actions/AuthenticationActionCreators";
import { getRoles } from "../../actions/RoleActionCreators";

export default function App() {
    const ssoService = new SsoService();
    const isAuthenticated = useSelector((state: AppState) => state.authenticationState.isAuthenticated);
    const toasts = useSelector((state: AppState) => state.toastState.activeToasts);
    const dispatch = useDispatch();

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    useEffect(() => {
        dispatch(getRoles());
    }, [dispatch]);

    const importFormType = useSelector<AppState, "import">(
        (state: AppState) => state.modalState.modalValues.get(IMP0RT_CONFIRM_MODAL)?.formType
    );

    const systemIdFromImportForm = useSelector<AppState, number>(
        (state: AppState) => state.modalState.modalValues.get(IMP0RT_CONFIRM_MODAL)?.systemId || 0
    );

    const tenantIdFromImportForm = useSelector<AppState, number>(
        (state: AppState) => state.modalState.modalValues.get(IMP0RT_CONFIRM_MODAL)?.tenantId || 0
    );

    const systemIdFromImportList = useSelector<AppState, number>(
        (state: AppState) => state.modalState.modalValues.get(COMPLETE_SYSTEM_REPORT_MODAL)?.systemId || 0
    );

    const shouldDisplayImportForm = useSelector<AppState, boolean>((s) =>
        s.modalState.activeModals.includes(IMP0RT_CONFIRM_MODAL)
    );

    const uploadEmployeeFormType = useSelector<AppState, "upload">(
        (state: AppState) => state.modalState.modalValues.get(UPLOAD_EMPLOYEE_CONFIRM_MODAL)?.formType
    );

    const shouldDisplayEmployeeUploadForm = useSelector<AppState, boolean>((s) =>
        s.modalState.activeModals.includes(UPLOAD_EMPLOYEE_CONFIRM_MODAL)
    );

    const completeSystemFormType = useSelector<AppState, "edit">(
        (state: AppState) => state.modalState.modalValues.get(COMPLETE_SYSTEM_REPORT_MODAL)?.formType
    );

    const selectCustomerImport = useSelector(
        (state: AppState) => state.modalState.modalValues.get(COMPLETE_SYSTEM_REPORT_MODAL)?.customerImport
    );

    const masterListName = useSelector(
        (state: AppState) => state.modalState.modalValues.get(IMPORT_DETAILS_MODAL)?.masterList
    );

    const selectedImportFile = useSelector(
        (state: AppState) => state.modalState.modalValues.get(IMPORT_DETAILS_MODAL)?.customerImportFile
    );

    const selectedFileUploadStatus = useSelector(
        (state: AppState) => state.modalState.modalValues.get(IMPORT_DETAILS_MODAL)?.status
    );

    const shouldDisplayCompleteSystemReport = useSelector((s: AppState) =>
        s.modalState.activeModals.includes(COMPLETE_SYSTEM_REPORT_MODAL)
    );

    const shouldDisplayImportDetailsModal = useSelector((s: AppState) =>
        s.modalState.activeModals.includes(IMPORT_DETAILS_MODAL)
    );

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
        vh = window.innerHeight * 0.01;
        debouncedSetViewportHeight(vh);
    });
    const debouncedSetViewportHeight = _.debounce((vh: number) => {
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }, 100);

    if (!isAuthenticated) {
        ssoService.signIn();
    }
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const { currentTenant, parentTenant } = useCurrentTenant(decodedAccessToken.tenant_id);
    const loggedInUser = useCurrentUser(decodedAccessToken.userId);
    const { users, impersonator } = useSelector((state: AppState) => state.userState);
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const isAdmin = decodedAccessToken.roles === "GlobalAdmin" || decodedAccessToken.roles === "PartnerAdmin";
    const isCustomerAdmin = decodedAccessToken.roles === "CustomerAdmin";
    const isReadOnlyCustomer = decodedAccessToken.roles === "ReadOnlyCustomer" || !!impersonator;

    useEffect(() => {
        if (decodedAccessToken.impersonating_id && decodedAccessToken.impersonating_id !== "0" && !impersonator) {
            const email = users.find((u) => u.id.toString() === decodedAccessToken.impersonating_id)?.emailAddress;
            if (email) {
                dispatch(impersonateUser(email));
            }
        }
    }, [impersonator, users, dispatch]);

    const redirectTo = () => {
        if (loggedInUser?.mfaEnabled && !loggedInUser.mfaSetupComplete) {
            return "/mfasetup";
        }
        if (!isAdmin) {
            return "/uar";
        }
        return "/users";
    };

    const [footerRef, setFooterRef] = useState<HTMLDivElement | null>();

    if (isAuthenticated && !!loggedInUser) {
        return (
            <div>
                {!!toasts &&
                    toasts.length > 0 &&
                    toasts.map((toast, index) => <KeysysToast key={`${toast.name}-${index}`} {...toast} />)}

                <Header partnerLogo={parentTenant?.logoUri || currentTenant?.logoUri} />
                <SessionTimeout />
                {!inIframe() && <KeysysSidebar />}
                <div
                    className={"content-container"}
                    style={{
                        display: "flex",
                        paddingBottom: "20px",
                        marginLeft: inIframe() ? 0 : "40px",
                        marginTop: inIframe() ? 0 : "100px",
                    }}
                >
                    <Switch>
                        <Route path="/mfasetup">
                            <Col xs={12} style={{ height: "100%" }}>
                                <MfaSetup />
                            </Col>
                        </Route>
                        <Route path="/tenants">
                            <Col xs={12} style={{ height: "100%" }}>
                                <Tenants />
                            </Col>
                        </Route>
                        <Route path="/users">
                            <Col xs={12} style={{ height: "100%" }}>
                                <Users />
                            </Col>
                        </Route>
                        {isGlobalAdmin && (
                            <Route path="/intakesystemfile">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <IntakeSystemFiles />
                                </Col>
                            </Route>
                        )}
                        <Route path="/customerintakereview">
                            <Col xs={12} style={{ height: "100%" }}>
                                <CustomerIntakeReview />
                            </Col>
                        </Route>
                        {isAdmin && (
                            <Route path="/documents">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <DocumentExchangeList />
                                </Col>
                            </Route>
                        )}
                        {isAdmin && (
                            <Route path="/admincustomerintakereview">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <AdminCustomerIntakeReview />
                                </Col>
                            </Route>
                        )}
                        {(isAdmin || isCustomerAdmin || isReadOnlyCustomer) && (
                            <Route path="/employees">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <EmployeeList />
                                </Col>
                            </Route>
                        )}
                        {(isAdmin || isCustomerAdmin || isReadOnlyCustomer) && (
                            <Route path="/employeechangereport">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <EmployeeChangeList />
                                </Col>
                            </Route>
                        )}
                        {(isAdmin || isCustomerAdmin || isReadOnlyCustomer) && (
                            <Route path="/systemaccessreport">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <EmployeeSystemList />
                                </Col>
                            </Route>
                        )}
                        {(isAdmin || isCustomerAdmin || isReadOnlyCustomer) && (
                            <Route path="/employeesystemexception">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <EmployeeSystemExceptionList />
                                </Col>
                            </Route>
                        )}
                        {isGlobalAdmin && (
                            <Route path="/workflownotificationtemplate">
                                <Col xs={12} style={{ height: "100%" }}>
                                    <WorkflowNotificationTemplate />
                                </Col>
                            </Route>
                        )}
                        {/* <Route path="/pathfinder">
                            <Col
                                xs={12}
                                style={{
                                    height: `calc(var(--vh, 1vh) * 100 - 100px - (${footerRef?.current?.clientHeight}px + 20px))`,
                                }}
                            >
                                <SmartSheetIFrame smartSheetUrl={currentTenant?.pathfinderUrl || ""} />
                            </Col>
                        </Route>
                        <Route path="/sysorec">
                            <Col
                                xs={12}
                                style={{
                                    height: `calc(var(--vh, 1vh) * 100 - 100px - (${footerRef?.current?.clientHeight}px + 20px))`,
                                }}
                            >
                                <SmartSheetIFrame smartSheetUrl={currentTenant?.sysorecUrl || ""} />
                            </Col>
                        </Route> */}
                        <Route path="/home">
                            <Col xs={12} style={{ display: "flex", minHeight: "100%", minWidth: "100%" }}>
                                <Home></Home>
                            </Col>
                        </Route>
                        <Route exact path="/">
                            <Col xs={12} style={{ height: "100%" }}>
                                <Redirect to={redirectTo()} />
                            </Col>
                        </Route>
                        <Route path="/reports/master-list/add">
                            <Col xs={12}>{isGlobalAdmin ? <MasterListForm /> : <Users />}</Col>
                        </Route>
                        <Route path="/reports/master-list/:id/edit">
                            <Col xs={12}>{isGlobalAdmin ? <MasterListForm /> : <Users />}</Col>
                        </Route>
                        <Route path="/reports/sub-reports/add">
                            <Col xs={12}>{isGlobalAdmin ? <SubReportForm /> : <Users />}</Col>
                        </Route>
                        <Route path="/reports/sub-reports/:id/edit">
                            <Col xs={12}>{isGlobalAdmin ? <SubReportForm /> : <Users />}</Col>
                        </Route>
                        <Route path="/reports">
                            <Col xs={12}>{isGlobalAdmin ? <ReportsList /> : <Users />}</Col>
                        </Route>
                        <Route path="/uar-overview">
                            <Col xs={12}>{isAdmin ? <UAROverview /> : <Users />}</Col>
                        </Route>
                        <Route path="/import">
                            <Col xs={12}>{isGlobalAdmin ? <ImportList /> : <Users />}</Col>
                        </Route>
                        <Route path="/dashboards/add">
                            <Col xs={12}>
                                <DashboardForm />
                            </Col>
                        </Route>
                        <Route path="/dashboards/:id/edit">
                            <Col xs={12}>
                                <DashboardForm />
                            </Col>
                        </Route>
                        <Route path="/dashboards">
                            <Col xs={12}>
                                <DashboardsList />
                            </Col>
                        </Route>
                        <Route path="/uar/customer-import/:customerImportId/customer-report/:customerReportId">
                            <Col xs={12} style={{ height: "calc(100% - 200px)" }}>
                                <CustomerReportSummary />
                            </Col>
                        </Route>
                        <Route path="/uar/customer-import/:customerImportId">
                            <Col xs={12}>
                                <CustomerReportList />
                            </Col>
                        </Route>
                        <Route path="/uar">
                            <Col xs={12}>
                                <UARList />
                            </Col>
                        </Route>
                        <Route path="/document-exchange/customer-documents/:tenantId">
                            <Col xs={12}>
                                <CustomerDocuments />
                            </Col>
                        </Route>
                    </Switch>
                </div>
                {shouldDisplayCompleteSystemReport && (
                    <CompleteSystemReportForm
                        type={completeSystemFormType}
                        customerImport={selectCustomerImport}
                        systemId={systemIdFromImportList}
                    />
                )}
                {shouldDisplayImportDetailsModal && (
                    <ImportDetailsModal
                        masterList={masterListName}
                        customerImportFile={selectedImportFile}
                        status={selectedFileUploadStatus}
                    />
                )}
                {shouldDisplayImportForm && (
                    <ImportForm
                        type={importFormType}
                        systemId={systemIdFromImportForm}
                        tenantId={tenantIdFromImportForm}
                    />
                )}
                {shouldDisplayEmployeeUploadForm && <EmployeeUploadForm type={uploadEmployeeFormType} />}
                <div className="push" style={{ height: footerRef?.clientHeight }} />
                <Footer
                    ref={(el: HTMLDivElement) => {
                        setFooterRef(el);
                    }}
                    partnerLogo={parentTenant?.logoUri || currentTenant?.logoUri}
                />
            </div>
        );
    }
    return <></>;
}
