import {
    EDIT_EMPLOYEE_SYSTEM_EXCEPTION,
    EmployeeSystemExceptionActions,
    EMPLOYEE_SYSTEM_EXCEPTIONS_FETCHED,
} from "../../actions/employeeSystemException/EmployeeSystemExceptionAction";
import { EmployeeSystemExceptionState } from "../../state/EmployeeSystemExceptionState";
import { replaceInCollection } from "../ReplaceInCollection";

const initialState: EmployeeSystemExceptionState = {
    employeeSystemExceptions: [],
};

export function employeeSystemExceptionReducer(
    state: EmployeeSystemExceptionState = initialState,
    action: EmployeeSystemExceptionActions
): EmployeeSystemExceptionState {
    switch (action.type) {
        case EMPLOYEE_SYSTEM_EXCEPTIONS_FETCHED: {
            return {
                ...state,
                employeeSystemExceptions: action.employeeSystemExceptions,
            };
        }
        case EDIT_EMPLOYEE_SYSTEM_EXCEPTION: {
            return {
                ...state,
                employeeSystemExceptions: replaceInCollection(
                    state.employeeSystemExceptions,
                    action.originalEmployeeSystemException!,
                    action.editedEmployeeSystemException
                ),
            };
        }
        default:
            return state;
    }
}
