import { inject, injectable } from "inversify";
import { TYPES } from "../../dependencyInjection/Types";
import { Role } from "../../models/Role";
import { IHttpClient } from "./../HttpClient/IHttpClient";
import "reflect-metadata";
import { IRoleService } from "./IRoleService";
import { IExceptionHandler } from "../exceptionHandlers/IExceptionHandler";

@injectable()
export class RoleService implements IRoleService {
    constructor(
        @inject(TYPES.HttpClient) private httpClient: IHttpClient,
        @inject(TYPES.IExceptionHandler) private exceptionHandler: IExceptionHandler
    ) {}

    getRoles(): Promise<void | Role[]> {
        return this.httpClient
            .get<Role[]>(`/roles`)
            .catch((exception) =>
                this.exceptionHandler.handleError(
                    exception,
                    "We encountered an error while retrieving roles. Please try again. If the problem continues, contact your administrator"
                )
            );
    }
}