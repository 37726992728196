import {
    CustomerReportDisplayActions,
    CUSTOMER_REPORT_DISPLAYS_FETCHED,
    UPSERT_CUSTOMER_REPORT_DISPLAYS,
    FETCHING_CUSTOMER_REPORT_DISPLAYS,
} from "../../actions/customerReportDisplay/CustomerReportDisplayAction";
import { CustomerReportDisplayState } from "../../state/CustomerReportDisplayState";

const initialState: CustomerReportDisplayState = {
    customerReportDisplays: [],
    loading: false,
    loaded: false,
};

export function customerReportDisplayReducer(
    state: CustomerReportDisplayState = initialState,
    action: CustomerReportDisplayActions
): CustomerReportDisplayState {
    switch (action.type) {
        case FETCHING_CUSTOMER_REPORT_DISPLAYS:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case CUSTOMER_REPORT_DISPLAYS_FETCHED:
            return {
                ...state,
                customerReportDisplays: action.customerReportDisplays,
                loaded: true,
                loading: false,
            };
        case UPSERT_CUSTOMER_REPORT_DISPLAYS:
            const updatedSubReportIds = [...action.customerReportDisplays].map((x) => x.subReportId);
            const nonUpdatedDisplays = [
                ...state.customerReportDisplays.filter((x) => !updatedSubReportIds.includes(x.subReportId)),
            ];
            return {
                ...state,
                customerReportDisplays: [...nonUpdatedDisplays, ...action.customerReportDisplays],
            };
        default:
            return state;
    }
}
