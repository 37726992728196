import { useState, useMemo, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeesByTenantId } from "../../actions/employee/EmployeeActionCreators";
import { AppState } from "../../state/AppState";
import PageHeader from "../header/PageHeader";
import CreatableSelect from "react-select/creatable";
import KeysysGrid from "../grid/KeysysGrid";
import { ColDef, RowGroupingDisplayType } from "ag-grid-community";
import { selectEmployeeSystems } from "../../selectors/EmployeeSystemSelector";
import { getFinosecSystems } from "../../actions/finosecSystem/FinosecSystemActionCreator";

export default function EmployeeSystemList() {
    const dispatch = useDispatch();
    const tenants = useSelector((state: AppState) => state.tenantState.tenants.filter((t) => t.parentTenantId != null));
    const { decodedAccessToken } = useSelector((state: AppState) => state.authenticationState);
    const isGlobalAdmin = decodedAccessToken.roles === "GlobalAdmin";
    const [selectedCustomerId, setSelectedCustomerId] = useState<number | null>(null);
    const [clickedCustomerFilter, setCustomerFilterClick] = useState<boolean>(false);
    const [textSearch, setSearchText] = useState<string>("");
    const [pageSize, setPageSize] = useState<number>(15);

    const systemsWithEmployees = useSelector(selectEmployeeSystems);

    useEffect(() => {
        dispatch(getFinosecSystems());
        if (!isGlobalAdmin) {
            setSelectedCustomerId(decodedAccessToken.tenant_id);
            setCustomerFilterClick(true);
            dispatch(getEmployeesByTenantId(decodedAccessToken.tenant_id));
        }
    }, [decodedAccessToken.tenant_id, dispatch, isGlobalAdmin]);

    const selectCustomerId = (tenantId: number | null) => {
        setSelectedCustomerId(tenantId);
        setCustomerFilterClick(false);
    };

    const filterEmployees = () => {
        setCustomerFilterClick(true);
        if (selectedCustomerId) {
            dispatch(getEmployeesByTenantId(selectedCustomerId));
        }
    };

    function inIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    const columnDefs: ColDef[] = useMemo(
        () => [
            {
                rowGroup: true,
                hide: true,
                field: "employeeName",
                showRowGroup: true,
            },
            {
                headerName: "",
                maxWidth: 200,
            },
            {
                headerName: "System",
                valueGetter: (data: any) => data.data?.name || "  ",
                sort: "asc",
            },
        ],
        []
    );

    const groupRowRendererParams = useMemo(() => {
        return {
            innerRenderer: (params: any) => {
                const node = params.node;
                const count =
                    node?.allChildrenCount === 1 && node?.allLeafChildren[0]?.data?.name === "No System"
                        ? 0
                        : node?.allChildrenCount;
                let eGui = document.createElement("div");
                eGui.innerHTML = `<span>${node?.key} (${count})</span>`;
                return eGui;
            },
            suppressCount: true,
        };
    }, []);

    return (
        <>
            {!inIframe() && (
                <Row className="padding-0">
                    <Col md={12}>
                        <PageHeader>
                            <h2>System Access</h2>
                        </PageHeader>
                    </Col>
                </Row>
            )}
            <br />
            <Row className="keysys-action-bar padding-0">
                {isGlobalAdmin && (
                    <>
                        <Col lg={2} sm={3}>
                            <CreatableSelect
                                onChange={(v) => {
                                    selectCustomerId(v?.value || null);
                                }}
                                defaultValue={null}
                                placeholder={"Select Customer"}
                                options={[
                                    { value: null, label: "Select Customer" },
                                    ...tenants.map((pt) => ({
                                        value: pt.id,
                                        label: pt.name,
                                    })),
                                ]}
                            />
                        </Col>
                        <Col md={1}>
                            <Button onClick={() => filterEmployees()} className="finosec-button-info">
                                Select
                            </Button>
                        </Col>
                    </>
                )}
            </Row>
            <br />
            {selectedCustomerId && clickedCustomerFilter && (
                <div className="ps-3 pe-3">
                    <div style={{ marginBottom: "10px" }}>
                        <Row className={"ag-theme-alpine"}>
                            <Col md={4}>
                                <input
                                    className="form-control"
                                    placeholder="Search"
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </Col>
                            <Col md={2}>
                                <CreatableSelect
                                    style={{ width: "40%" }}
                                    onChange={(v) => setPageSize(v?.value || 15)}
                                    defaultValue={null}
                                    placeholder={"Page Size"}
                                    options={[
                                        { value: 10, label: "10" },
                                        { value: 15, label: "15" },
                                        { value: 25, label: "25" },
                                        { value: 50, label: "50" },
                                        { value: 100, label: "100" },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <Row className={"ag-theme-alpine"}>
                            <Col>
                                <KeysysGrid
                                    getRows={() => systemsWithEmployees}
                                    columnDefs={columnDefs}
                                    gridOptions={{
                                        groupDisplayType: RowGroupingDisplayType.GROUP_ROWS,
                                        groupRowRendererParams: groupRowRendererParams,
                                    }}
                                    pagination={true}
                                    pageSize={pageSize}
                                    searchText={textSearch}
                                    getRowId={(data) => `${data.id}-${data.employee.id}`}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            )}
        </>
    );
}
