import { useState, useMemo } from "react";
import { ICustomerReportReview } from "../../models/CustomerReportReview";

const hasProps = (item: object) => {
    if (!item) return false;

    return Object.keys(item).length > 0;
};

export const GroupReviewToggle = (props: any) => {
    const { displayOnly, onReview, customerReportReviews, lineNumbersWithJsonStatus, disabled } = props;

    const lineNumbers = lineNumbersWithJsonStatus.map((x: any) => x.line);

    const unreviewedCrrsExist = useMemo((): boolean => {
        const unreviewedCRRsInDB = customerReportReviews.filter(
            (x: ICustomerReportReview) => hasProps(x) && !x.isReviewed
        );
        if (!!unreviewedCRRsInDB.length) {
            return true;
        }
        const dbLineNumbers = customerReportReviews.map((dbReview: ICustomerReportReview) => dbReview.lineNumber);
        const unreviewedJsonRecordsNotInDB = lineNumbersWithJsonStatus.filter(
            (x: any) => !dbLineNumbers.includes(x.line) && !x.status
        );
        return !!unreviewedJsonRecordsNotInDB.length;
    }, [customerReportReviews]);

    const [hasBeenClicked, setHasBeenClicked] = useState(false);

    function getCheckedStatus() {
        return hasBeenClicked ? !unreviewedCrrsExist : unreviewedCrrsExist;
    }

    const color = disabled ? "grey" : "#0e63ac";
    const cursor = disabled ? "not-allowed" : "pointer";

    return (
        <>
            <i
                className={getCheckedStatus() ? "far fa-circle" : "fas fa-check-circle"}
                style={{ fontSize: "1rem", textAlign: "center", cursor: cursor, color }}
                onClick={() => {
                    if (!disabled) {
                        setHasBeenClicked((x) => !x);
                    }
                    if (!displayOnly && customerReportReviews.length && !disabled) {
                        onReview(!getCheckedStatus(), lineNumbers);
                    }
                }}
            />
        </>
    );
};
